<script lang="ts">
	import { BlendZoneEditorController } from "./BlendZoneEditorController"

	let pathPoints: Array<BlendZoneEditorController.Point> = $state()
	BlendZoneEditorController.subscribe((ctx) => (pathPoints = ctx.pathPoints))

	function reset() {
		BlendZoneEditorController.Canvas.resetCanvas()
	}

	async function saveBlendZone() {
		if (!pathPoints) {
			BlendZoneEditorController.save(null)
		} else {
			const imgData = BlendZoneEditorController.createImage()
			BlendZoneEditorController.save(imgData)
		}
	}
</script>

<div class="blend-zone-controls">
	<div class="button-container">
		<button disabled={!pathPoints} onclick={reset} class="alt">Reset</button>
		<button onclick={saveBlendZone}>Save</button>
	</div>
</div>

<style>
	.button-container {
		position: absolute;
		width: fit-content;
		bottom: 2rem;
		right: 2rem;
		margin-bottom: 0;
	}

	button:first-of-type {
		margin-right: 1rem;
	}
</style>
