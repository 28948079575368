<script lang="ts">
	import Paragraph from "../../comps/Paragraph.svelte"
	import type { InputOverlayButton } from "./types"

	interface Props {
		prompt?: string | Array<string>
		buttons: Array<InputOverlayButton>
	}

	let { prompt = undefined, buttons }: Props = $props()
</script>

<div>
	{#if prompt}
		<Paragraph textContent={prompt} />
	{/if}
	<span class="button-container">
		{#each buttons as button}
			<button class={button.className} onclick={button.onClick}>{button.text}</button>
		{/each}
	</span>
</div>

<style>
	div {
		min-width: 10rem;
		max-width: 40vw;
		width: 100%;
		height: 100%;
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
</style>
