import type { Media } from "luxedo-data"
import { openOverlay } from "../../overlay"
import MediaPreview from "./MediaPreview.svelte"

export function openMediaPreview(
	media: Media,
	ctxMenuTrigger?: (e: Event) => void
) {
	openOverlay(MediaPreview, {
		classOverlay: "no-pad",
		classHeading: "no-underline media-preview-heading",
		props: { media, ctxMenuTrigger },
		heading: media.name,
	})
}
