<script lang="ts">
	import type { Snippet } from "svelte"

	/** Very similar to the collapsible section, but with the option to await a function before expanding - this allows for more performant load times. */

	import { ArrowUp } from "../icons"

	interface Props {
		isExpanded?: boolean
		className?: string
		beforeExpand: () => Promise<void>
		header?: Snippet<[any]>
		title?: Snippet<[any]>
		content?: Snippet
	}

	let {
		isExpanded = $bindable(),
		className = "",
		beforeExpand,
		header,
		title,
		content,
	}: Props = $props()

	let contentHeight: number = $state()
	let contentContainer: HTMLDivElement = $state()

	export async function toggle(expand?: boolean) {
		if (beforeExpand) await beforeExpand()
		setTimeout(() => {
			isExpanded = expand ?? !isExpanded
		})
	}

	const resizeObserver = new ResizeObserver((entries) => {
		const entry = entries[0]
		updateContentHeight(entry.target as HTMLDivElement)
	})

	function updateContentHeight(div: HTMLDivElement) {
		contentHeight = div.offsetHeight
	}

	function initializeResizeObserver(div: HTMLDivElement) {
		if (!div) return

		resizeObserver.observe(contentContainer)
	}

	$effect(() => {
		if (contentContainer) initializeResizeObserver(contentContainer)
	})
</script>

<div class="collapsible-section {isExpanded ? 'expanded' : ''} {className}">
	<div class="section-header" onclick={() => toggle()}>
		{#if header}
			{@render header?.({ isExpanded })}
		{:else if title}
			<div class="title-container">
				{@render title?.({ isExpanded })}
				<ArrowUp />
			</div>
		{/if}
	</div>
	<div
		class="section-content-container"
		style="height: {isExpanded ? contentHeight : 0}px;"
	>
		<div bind:this={contentContainer} class="section-content">
			{@render content?.()}
		</div>
	</div>
</div>

<style>
	.section-header {
		position: relative;
		width: 100%;
		display: flex;
		align-items: start;
		cursor: pointer;
	}

	.collapsible-section {
		position: relative;
		width: 100%;
	}

	.section-content-container {
		position: relative;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;
		height: 0px;
		transition: height 250ms;
	}

	.section-header :global(svg.arrow) {
		transition: transform 250ms;
	}

	.expanded .section-header :global(svg.arrow) {
		transform: rotate(180deg);
	}

	.title-container {
		display: flex;
		flex-direction: row;
	}
</style>
