<script lang="ts">
	import { ThirdPartyProjectorManager } from "luxedo-data"
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"

	let resolution = $derived(
		ProjectorPreferencesManager.pendingChanges.resolution ??
			ProjectorPreferencesManager.preferences.resolution
	)

	function onResolutionInput(
		e: Event & { currentTarget: EventTarget & HTMLSelectElement }
	) {
		const target = e.currentTarget
		setTimeout(() => {
			ProjectorPreferencesManager.stageChange("resolution", target.value)
		})
	}
</script>

<div class="select-container">
	<label id="projector-resolution-label" for="resolution-input"
		>Resolution:
	</label>
	<select id="resolution-input" onchange={onResolutionInput} value={resolution}>
		{#each Object.entries(ThirdPartyProjectorManager.getSupportedResolutions()) as [name, res], i}
			<option value={name}>{res.width} x {res.height}</option>
		{/each}
	</select>
</div>

<style>
</style>
