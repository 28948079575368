<script lang="ts">
	import QuickSelectorRadio from "../../../reusable/input/quick-select/QuickSelectorRadio.svelte"
	import { MediaLibraryController } from "../MediaLibraryController"

	let isHidden = $state(true)

	let filterOptions = {
		filter: ["Name", "Last Modified", "Type"],
		order: ["Ascending", "Descending"],
	}

	let filterSelection: { [key in keyof typeof filterOptions]: string } = $state(
		{
			filter: "Last Modified",
			order: "Descending",
		}
	)

	function toggleHidden(e: MouseEvent) {
		e.preventDefault()
		isHidden = !isHidden
	}

	$effect(() => {
		MediaLibraryController.updateFilterOptions(filterSelection)
	})
</script>

<a href="#" onclick={toggleHidden}>
	Filter By
	<QuickSelectorRadio
		bind:selections={filterSelection}
		options={filterOptions}
		defaultSelection={{
			filter: "Last Modified",
			order: "Descending",
		}}
		{isHidden}
		allowClear
	/>
</a>

<style>
	a {
		margin-right: 1rem;
	}
</style>
