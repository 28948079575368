<script lang="ts">
	import Paragraph from "../../comps/Paragraph.svelte"

	interface Props {
		prompt?: string | Array<string>
		buttonText?: string
		onVerify?: () => void
		closeOverlay: () => void
	}

	let {
		prompt = undefined,
		buttonText = undefined,
		onVerify = undefined,
		closeOverlay,
	}: Props = $props()

	function handleEnterPress(e: Event) {
		e.preventDefault()
		if (onVerify) onVerify()
		closeOverlay()
	}
</script>

<div>
	{#if prompt}
		<Paragraph textContent={prompt} />
	{/if}
	<button
		onclick={() => {
			if (onVerify) onVerify()
			closeOverlay()
		}}>{buttonText || "OK"}</button
	>
</div>
<svelte:window onkeypress={handleEnterPress} />

<style>
	div {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}
</style>
