<script lang="ts">
	interface Props {
		title: string;
		info: string;
		action?: () => void;
		children?: import('svelte').Snippet;
	}

	let {
		title,
		info,
		action = undefined,
		children
	}: Props = $props();
</script>

<div class="instruction-card">
	<h3>{title}</h3>
	<p class="info">
		{info}
		{@render children?.()}
	</p>
</div>

<style>
	.instruction-card {
		background-color: var(--color-bg);
		border-radius: var(--br);
		flex-grow: 1;
		height: 100%;
		max-width: 24%;
		padding: 1rem 1rem;
		display: flex;
		flex-direction: column;
	}

	h3 {
		margin: 0 0 1rem 0;
		font-size: var(--h1);
		color: var(--color-main);
	}

	.info {
		font-size: var(--h2);
		color: var(--color-text-light);
		margin: auto 0;
	}

	@media only screen and (max-width: 800px) {
		.instruction-card {
			max-width: 100%;
			margin-bottom: 1rem;
		}
	}
</style>
