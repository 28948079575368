<script lang="ts">
	import {
		DataHandlerCalibration,
		Device,
		DeviceSpotlightManager,
	} from "luxedo-data"
	import Guides from "luxedo-data/src/data/Guides"

	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import { onDestroy, onMount } from "svelte"
	import CalibrationList from "./calibration-list/CalibrationList.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { CalibrationOverlayController } from "../../../../reusable/overlays/calibration/CalibrationOverlayController"
	import { hideMinimizedCalibration } from "../../../../reusable/overlays/calibration/calibration-minimized"
	import { openCalibrationOverlay } from "../../../../reusable/overlays/calibration"
	import { tooltip } from "svelte-comps/tooltip"
	import { Reactivity } from "svelte-comps/reactivity"

	let device = $derived(ProjectorMenuController.ctx.device)

	$effect.pre(() => {
		Reactivity.useProps(device, ["isOnline"])
	})

	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))

	$effect(() => {
		onDeviceUpdate(device)
	})

	const OFFLINE_TEXT = `Your device must be online to calibrate.`
	const NO_CAL_TEXT = `This device has not yet been calibrated, click "Calibrate Projector" to get started.`
	const FAILED_CAL_TEXT = `This device has not yet been successfully calibrated. If you running into issues when calibrating, check out <a target="_blank" href="${Guides["Device"]["Troubleshooting Calibration"]}">this article</a>.`

	let isCalibrated = $state(false)

	function beginCalibration() {
		if (!device.isOnline)
			return Toast.error("Device must be online to calibrate!")

		// If device is already calibrating
		if (
			CalibrationOverlayController.get().step === 1 &&
			device.id === CalibrationOverlayController.device?.id
		) {
			hideMinimizedCalibration()
			openCalibrationOverlay(device, true)
		} else openCalibrationOverlay(device)
	}

	function onDeviceUpdate(device: Device) {
		isCalibrated = device.isCalibrated
	}

	const onCalibrationListUpdate = () => {
		if (!device) return
		onDeviceUpdate(device)
	}

	onMount(() => {
		DataHandlerCalibration.addListener(onCalibrationListUpdate)
	})

	onDestroy(() =>
		DataHandlerCalibration.removeListener(onCalibrationListUpdate)
	)
</script>

<div class="calibration-menu projector-menu">
	<div class="projector-section">
		<div class="flex-row">
			<h2 class="setting-heading">About Calibration</h2>

			<button
				id="calibrate-button"
				class="outline-button"
				onclick={beginCalibration}
				disabled={!isOnline}
				use:tooltip={{
					content:
						"Calibration is the process of shining and capturing patterns from your Luxedo in order to accurately map your projection space.",
					pointing: "top",
				}}>{isCalibrated ? "Rec" : "C"}alibrate</button
			>
		</div>
		<div class="cal-info">
			{#if !isCalibrated || !isOnline}
				<div class="warn-text">
					{#if !isOnline}
						{@html OFFLINE_TEXT}
					{:else if DataHandlerCalibration.filterByDevice(device).length}
						{@html FAILED_CAL_TEXT}
					{:else}
						{@html NO_CAL_TEXT}
					{/if}
				</div>
			{/if}
			Calibration is an automated process where your Luxedo will project patterns
			while capturing images of the projection. Once captured, the images will be
			processed to create a snapshot that truly reflects your projection space. This
			snapshot will be used as a guide to create your shows. You can learn more
			<a target="_blank" href={Guides.Device["Calibrating Your Device"]}
				>here
			</a>.
		</div>
	</div>
	{#if isCalibrated}
		<CalibrationList {device} />
	{/if}
</div>

<style>
	.calibration-menu {
		width: 100%;
		flex-grow: 1;
		overflow-y: hidden;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.projector-section {
		margin-bottom: 1rem;
	}

	.projector-section .flex-row {
		justify-content: space-between;
	}

	.cal-info {
		margin-bottom: 0;
		margin-top: 0.5rem;
		line-height: 1.25em;
		color: var(--color-text);
	}

	.warn-text {
		margin-bottom: 0.5rem;
	}
</style>
