<script lang="ts">
	import {
		Device,
		DeviceRPi,
		ProjectorPowerManager,
		type ProjectorPowerState,
	} from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import type { Unsubscriber } from "svelte/store"

	interface Props {
		device: DeviceRPi
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	let isPowerChanging: boolean = $state()
	let projectorPower: ProjectorPowerState = $state("UNDEFINED")

	let togglePreviewState: "On" | "Off" | "" = $derived.by(() => {
		if (projectorPower.includes("OFF")) return "On"
		else if (projectorPower.includes("ON")) return "Off"
		return ""
	})

	$effect(() => {
		onDeviceChange(device)
	})

	let unsubscriber: Unsubscriber
	function onDeviceChange(device: DeviceRPi) {
		isPowerChanging = false
		if (unsubscriber) unsubscriber()
		if (device)
			unsubscriber = ProjectorPowerManager.subscribeTo(
				device.id,
				onPowerStateUpdate
			)
	}

	function onPowerStateUpdate(status: {
		state: "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"
		isChanging: boolean
	}) {
		projectorPower = status.state
		isPowerChanging = status.isChanging
	}

	async function toggleProjectorPower() {
		let newPower: "ON" | "OFF"
		if (projectorPower === "ON") {
			newPower = "OFF"
		} else {
			newPower = "ON"
		}

		setButtonsDisabled(true)

		try {
			Toast.text(`Powering projector ${newPower.toLowerCase()}`)
			await ProjectorPowerManager.setPower(device, newPower)
			Toast.success(`The projector has powered ${newPower.toLowerCase()}`)
		} catch (e) {
			console.error("Unable to modify projector power state.")
			Toast.error(`Unable to power ${newPower.toLowerCase()} the projector`)
		}

		setButtonsDisabled(false)
	}
</script>

<div class="action-container">
	<button
		class="outline-button"
		onclick={toggleProjectorPower}
		disabled={isPowerChanging || isDisabled}
	>
		{#if isPowerChanging}
			Changing Power State...
		{:else if togglePreviewState}
			Power Projector {togglePreviewState}
		{:else}
			Toggle Projector Power
		{/if}
	</button>
	<div class="action-info">Toggle the power of the connected projector.</div>
</div>

<style>
</style>
