import type { Device } from "luxedo-data"
import { writable } from "svelte/store"

interface ProjectorRegistrationCtx_Closed {
	isRegistering: false
	type?: "Group" | "LuxLink" | "Cast"
}

interface ProjectorRegistrationCtx_Open {
	isRegistering: true
	prevSelectedDevice: Device
	colorIndex: number
	type: "Group" | "LuxLink" | "Cast"
}

export const ProjectorRegistrationContext = writable<
	ProjectorRegistrationCtx_Open | ProjectorRegistrationCtx_Closed
>({
	isRegistering: false,
})
