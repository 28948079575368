<script lang="ts">
	import { onMount } from "svelte"
	import type { Media } from "luxedo-data"
	import { LoadingSpinner } from "../../loading"

	interface Props {
		media: Media
	}

	let { media }: Props = $props()

	let isLoading = $state(true)
	let isUnableToFetch = $state(false)
	let isDeleted = $state(false)

	async function checkIfVideoIsAvailable() {
		const src = media.src.compressed
		isDeleted = media.is_src_deleted

		if (isDeleted) {
			isUnableToFetch = true
			isLoading = false
			return
		}

		try {
			const res = await fetch(src)
			if (res.status >= 400) {
				isUnableToFetch = true
			}
		} catch (e) {
			isUnableToFetch = true
		}
		isLoading = false
	}

	onMount(checkIfVideoIsAvailable)
</script>

<div class="flex-center">
	{#if isLoading}
		<LoadingSpinner height="4rem" />
	{:else if isUnableToFetch}
		<span class="info">
			{#if isDeleted}
				This video has been deleted. Select the "Reupload Source" option to
				restore it.
			{:else}
				There was an error loading this video.
			{/if}
		</span>
	{:else}
		<video controls autoplay>
			<source src={media.src.compressed} type="video/mp4" />
		</video>
	{/if}
</div>

<style>
	.flex-center {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		align-items: center;
		justify-content: center;
	}

	video {
		max-width: 80vw;
		max-height: 80vh;
		object-fit: contain;
		height: 100%;
		width: 100%;
	}

	.info {
		margin-top: 5rem;
		padding: 1rem 2rem;
		width: 80%;
		margin-bottom: 2rem;
		color: var(--color-text);
		text-align: center;
	}
</style>
