<script lang="ts">
	import { ClippingMask, MaskBase } from "luxedo-canvas/asset"
	import { MaskSidebarController } from "./MaskEditorSidebarController"
	import { MaskEditorController } from "../MaskEditorController"
	import { HouseSections } from "luxedo-data"

	let maskEdit: ClippingMask | MaskBase = $state()
	let selectedSection: string = $state()
	let trackName: string = $state()
	let maskAffects: "in" | "out"

	MaskSidebarController.subscribe(({ mask }) => initMaskData(mask))

	function initMaskData(mask: ClippingMask | MaskBase) {
		if (!mask) return

		maskEdit = mask
		selectedSection = mask.data.section
		trackName = mask.name
		maskAffects = mask.inverted ? "out" : "in"
	}

	function cancel() {
		MaskEditorController.cancelEdit()
	}

	function complete() {
		MaskEditorController.completeEdit()

		maskEdit.name = trackName
		maskEdit.data.section = selectedSection
	}
</script>

<div class="mask-edit-menu">
	<div class="heading">
		<h3>Editing {maskEdit?.name}</h3>
	</div>
	<p class="info">
		Click and drag the points on Mask to edit. Click along the outline to create new points or hold
		alt and click points to remove them.
	</p>
	<hr />

	<div class="select-container full-width">
		<label for="mask-layer-input">Section: </label>
		<select id="mask-layer-input" bind:value={selectedSection}>
			<option value={undefined}>-- Select Section --</option>
			{#each HouseSections as section}
				<option value={section}>{section}</option>
			{/each}
			<option value="other">Other</option>
		</select>
	</div>

	<div class="input-container full-width">
		<label for="name-input">Name: </label>
		<input type="text" id="name-input" bind:value={trackName} />
	</div>
	<br />

	<!-- <div id="visibility-input" class="input-container">
		<span class="label">Visible: </span>
		<IconRowSelector
			bind:selectedOption={maskAffects}
			options={{
				in: {
					comp: MaskInsideIcon,
					title: "Show Inside Mask",
				},
				out: {
					comp: MaskOutsideIcon,
					title: "Show Outside Mask",
				},
			}}
		/>
		<span class="visibility-text">{maskAffects === "in" ? "Inside" : "Outside"}</span>
	</div> -->
</div>
<div class="button-container">
	<button class="warn" onclick={cancel}>Cancel</button>
	<button onclick={complete}>Complete</button>
</div>

<style>
	h3 {
		font-size: var(--h0);
		margin: 0;
		margin-bottom: 0.5rem;
	}

	.mask-edit-menu {
		flex-grow: 1;
	}

	.button-container,
	.mask-edit-menu {
		padding-right: 0.5rem;
	}

	.button-container {
		margin-bottom: 0;
		justify-content: flex-end;
	}

	.button-container button:not(:first-of-type) {
		margin-left: 0.5rem;
	}

	#visibility-input {
		margin-top: 0.5rem;
	}

	.visibility-text {
		color: var(--color-text);
		margin-left: 0.5rem;
	}

	label,
	.label {
		font-size: var(--h3);
		color: var(--color-text);
		margin-right: 0.5rem;
	}
</style>
