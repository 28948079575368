<script lang="ts">
	import { onMount, type Component } from "svelte"
	import { OverlayController, type OverlayOptions } from "./OverlayController"
	import { PlusIcon } from "../icons"
	import OverlaySideMenu from "./OverlaySideMenu.svelte"

	interface Props {
		id: any
		options: OverlayOptions
		component: Component
	}

	let { id, options, component }: Props = $props()
	let blurAmount = $state(0)

	const closeOverlay = () => {
		OverlayController.closeOverlay(id)
	}

	onMount(() => {
		setTimeout(() => {
			blurAmount = 1
		})
	})

	const SvelteComponent_1 = $derived(component)
</script>

<div
	class="overlay-bg"
	onclick={closeOverlay}
	style="opacity: {blurAmount}; z-index: {(options.zIndex + 1) * 100 - 1};"
>
	<div
		class="overlay {options.classOverlay ?? ''}"
		style="z-index: {(options.zIndex + 1) * 100};"
		onclick={(e) => {
			e.stopImmediatePropagation()
			e.stopPropagation()
		}}
	>
		<button class="close-overlay-button icon" onclick={closeOverlay}>
			<PlusIcon />
		</button>
		{#if options.heading}
			<div class={`overlay-heading ${options.classHeading ?? ""}`}>
				{options.heading}
			</div>
		{/if}
		{#if options.sideMenu}
			<OverlaySideMenu options={options.sideMenu} />
		{/if}
		<SvelteComponent_1
			{...{ overlayID: id, ...options.props }}
			{closeOverlay}
		/>
	</div>
</div>

<style>
	:global(.overlay) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		overflow: hidden;
		max-height: 80vh;
		max-width: 80vw;
	}

	.overlay-bg {
		/* backdrop-filter: blur(0.5rem); */
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: opacity 200ms;
	}

	.overlay-heading.align-left {
		padding-left: 1rem;
		text-align: left;
	}

	.overlay-heading {
		z-index: 15;
		width: 100%;
		padding: 0.25rem 3rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: var(--text-h5);
		text-align: center;
		border-bottom: 2px solid var(--color-main);
		margin-bottom: 1rem;
	}

	.close-overlay-button {
		z-index: 20;
		position: absolute;
	}

	.close-overlay-button:hover,
	.close-overlay-button:focus-visible {
		color: var(--color-main);
	}

	.close-overlay-button :global(svg) {
		transform: rotate(45deg);
		width: 2rem;
	}

	.close-overlay-button :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	.close-overlay-button:hover :global(.svg-stroke),
	.close-overlay-button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	@media only screen and (max-width: 900px) {
		.overlay {
			width: 90vw;
		}
	}
</style>
