<script lang="ts">

	import { SearchIcon } from "svelte-comps/icons"

	interface Props {
		searchInput?: string
		onSearch: (searchInput: string) => void
		onClose: () => void
		isOpen?: boolean
	}

	let {
		searchInput = $bindable(undefined),
		onSearch,
		onClose,
		isOpen = $bindable(false),
	}: Props = $props()
	let inputElem: HTMLInputElement = $state()

	function search(e?: SubmitEvent) {
		e?.preventDefault()
		onSearch(searchInput)
	}

	function onButtonClick() {
		if (isOpen) {
			if (searchInput) search()
			else isOpen = false
		} else isOpen = true
	}

	function closeOnEscape(e: KeyboardEvent) {
		if (e.key === "Escape") isOpen = false
	}

	function initEscapeListener() {
		inputElem.focus()
		document.addEventListener("keydown", closeOnEscape)
	}

	function cancelEscapeListener() {
		onClose()
		if (inputElem) inputElem.value = ""
		document.removeEventListener("keydown", closeOnEscape)
	}

	$effect(() => {
		if (isOpen) initEscapeListener()
		else cancelEscapeListener()
	})
</script>

<form onsubmit={search} class="search-container {isOpen ? 'open' : ''}">
	<input type="text" placeholder="Search" bind:value={searchInput} bind:this={inputElem} />
	<button onclick={onButtonClick} type={isOpen ? "submit" : "button"}>
		<SearchIcon strokeColor="var(--color-text)" />
	</button>
</form>

<style>
	.search-container {
		border: 1px solid var(--color-text);
		border-radius: 100px;
		line-height: 0;
		width: 2rem;
		height: 2rem;

		position: relative;

		display: flex;
		flex-direction: row;
		justify-content: space-between;

		transition: width 250ms;
	}

	.search-container.open {
		width: 24rem;
	}

	button {
		background-color: unset;
		border: none;
		padding: 0;
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		right: 0;
	}

	input {
		width: 100%;
		margin-right: 0.5rem;
		background-color: transparent;
		border: none;
		opacity: 1;
		transition: opacity 250ms;
	}

	.search-container:not(.open) input {
		opacity: 0;
		width: unset;
	}

	button :global(svg) {
		width: 1rem;
		position: relative;
		right: -1px;
		top: -1px;
	}

	button :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button:hover :global(.svg-stroke),
	button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	input::placeholder {
		color: var(--color-text);
	}
</style>
