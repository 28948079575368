<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_703_61)">
		<path
			d="M19.9611 10.2635C21.3048 11.0313 21.3048 12.9687 19.9611 13.7365L4.99228 22.2901C3.65897 23.052 2 22.0893 2 20.5536L2 3.44636C2 1.91071 3.65896 0.94798 4.99228 1.70987L19.9611 10.2635Z"
			fill={strokeColor}
			class="svg-fill"
		/>
	</g>
	<defs>
		<clipPath id="clip0_703_61">
			<rect width="24" height="24" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
