<script lang="ts">
	import type { Media } from "luxedo-data"
	import MediaPreviewImage from "./MediaPreview_Image.svelte"
	import MediaPreviewAudio from "./MediaPreview_Audio.svelte"
	import MediaPreviewVideo from "./MediaPreview_Video.svelte"
	import ContextMenuButton from "../../context-menu/ContextMenuButton.svelte"

	const COMPS = {
		image: MediaPreviewImage,
		audio: MediaPreviewAudio,
		video: MediaPreviewVideo,
	}

	interface Props {
		media: Media
		ctxMenuTrigger: (e: Event) => void
	}

	let { media, ctxMenuTrigger }: Props = $props()

	let type = media.fileType
	let activeComp = COMPS[type]

	const SvelteComponent = $derived(activeComp)
</script>

<div id="media-preview">
	{#if ctxMenuTrigger}
		<ContextMenuButton onClick={ctxMenuTrigger} />
	{/if}
	<SvelteComponent {media} />
</div>

<style>
	:global(.overlay):has(:global(#media-preview)) {
		display: flex;
		min-width: unset;
		min-height: unset;
		padding-top: unset;
	}

	:global(.overlay):has(:global(#media-preview)) :global(.media-preview-heading) {
		padding-left: 2rem;
		margin-top: 0.25rem;
	}

	#media-preview :global(button.actions) {
		position: absolute;
		top: 1rem;
		right: 3.5rem;
		height: 3rem;
		z-index: 100;
	}

	#media-preview :global(button.actions .action-dot) {
		height: 0.3rem;
		width: 0.3rem;
	}

	#media-preview {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		/* height: 100%;
		width: 100%; */
	}

	#media-preview :global(image) {
		max-height: 100%;
		max-width: 100%;
	}
</style>
