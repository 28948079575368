<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_614_62)">
		<path
			d="M7.32175 1.95239C10.2936 4.9015 -0.497514 15.6114 2.47435 18.56C5.44622 21.5101 20.0565 7.00918 23.0289 9.95932C26.0002 12.9084 8.6718 30.1071 11.6431 33.0563C14.6166 36.0074 34.6631 16.1109 37.6365 19.0621C40.6099 22.0132 26.4205 36.0965 29.3939 39.0476"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
	</g>
	<defs>
		<clipPath id="clip0_614_62">
			<rect width="40" height="41" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
