<script lang="ts">
	import { CanvasAsset, MaskBase } from "luxedo-canvas/asset"
	import { AddMask, DrawPath } from "luxedo-canvas/lib"
	import { TrackGroup } from "luxedo-canvas/tracks"
	import { getEditor } from "luxedo-canvas"
	import { ClippingMask } from "luxedo-canvas/asset"
	import { ShapeSelector } from "svelte-comps/inputs"
	import SidebarHeading from "./SidebarHeading.svelte"
	import { MaskSidebarController } from "./MaskEditorSidebarController"
	import { HouseSections } from "luxedo-data"
	import SidebarCreateDraw from "./Sidebar_CreateDraw.svelte"

	const DEFAULT_NAME = "New Mask"

	let isDrawing: boolean = $state(false)

	let trackName: string = $state(DEFAULT_NAME)
	let selectedSection: string = $state()

	let selectedShape:
		| "triangle"
		| "square"
		| "pentagon"
		| "hexagon"
		| "heptagon"
		| "octagon"
		| "freedraw" = $state("square")

	async function initializeAddMaskMode() {
		isDrawing = true

		try {
			let newMask: MaskBase
			if (selectedShape === "freedraw") {
				const path = await DrawPath.CreateAsync(getEditor(), {
					closed: true,
					initiator: "Mask",
					tempFabricOptions: {
						stroke: "#faae1b",
						strokeWidth: 4,
					},
				})

				newMask = new MaskBase(path, {
					name: trackName,
					data: {
						section: selectedSection,
					},
				})
				newMask.data.section = selectedSection

				newMask.register()

				const maskedGroup = new TrackGroup<CanvasAsset>(getEditor(), {
					name: `${newMask}`,
				})
				maskedGroup.addMask(newMask)
				getEditor().tracks.attach(maskedGroup)
				getEditor().tracks.emitEvent("tracklist:add", { track: maskedGroup })
			} else {
				const numSides = {
					triangle: 3,
					square: 4,
					pentagon: 5,
					hexagon: 6,
					heptagon: 7,
					octagon: 8,
				}

				newMask = await AddMask.CreateAsync(getEditor(), {
					name: trackName,
					angle: 0,
					height: 10,
					width: 10,
					left: 0,
					top: 0,
					shape: "Polygon",
					numSides: numSides[selectedShape],
					trackID: undefined,
					addOnComplete: false,
					data: {
						section: selectedSection,
					},
				})
				newMask.data.section = selectedSection

				newMask.register()
			}

			MaskSidebarController.goTo("list")
		} catch (e) {
			console.error("Mask create cancelled", e)
		}

		isDrawing = false
	}

	let prevSection: string
	function updateName(section: string) {
		if (!section) return
		if (trackName === DEFAULT_NAME || trackName === prevSection) {
			trackName = section
			prevSection = section
		}
	}

	$effect(() => {
		updateName(selectedSection)
	})
</script>

<div class="mask-creation-menu">
	{#if isDrawing}
		<SidebarCreateDraw {selectedShape} />
	{:else}
		<div>
			<SidebarHeading title="New Mask" />
			<div class="select-container full-width">
				<label for="mask-layer-input">Section: </label>
				<select id="mask-layer-input" bind:value={selectedSection}>
					<option value={undefined}>-- Select Section --</option>
					{#each HouseSections as section}
						<option value={section}>{section}</option>
					{/each}
					<option value="other">Other</option>
				</select>
			</div>

			<div class="input-container full-width">
				<label for="name-input">Name: </label>
				<input type="text" id="name-input" bind:value={trackName} />
			</div>

			<br />
			<div class="shape-selection-row">
				<span class="label">Shape: </span>
				<ShapeSelector hiddenOptions={["circle", "heptagon"]} bind:selectedShape />
				<span class="shape-selection"
					>{`${selectedShape.charAt(0).toUpperCase()}${selectedShape.slice(1)}`}</span
				>
			</div>
		</div>
		<div class="button-container create-buttons">
			<button class="create-button outline-button" onclick={initializeAddMaskMode}
				>Create Mask</button
			>
		</div>
	{/if}
</div>

<style>
	label,
	.label {
		font-size: var(--h3);
		color: var(--color-text);
		margin-right: 0.5rem;
	}

	.shape-selection-row {
		margin-top: 0.5rem;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.shape-selection {
		margin-left: 0.5rem;
		color: var(--color-text);
	}

	.mask-creation-menu {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
</style>
