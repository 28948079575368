import { Calendar as fcCalendar } from "@fullcalendar/core"
import {
	DataHandlerDevice,
	type Device,
	type Lightshow,
	type Scene,
} from "luxedo-data"
import { get, writable } from "svelte/store"
import { navigateTo } from "../../../stores/NavigationContext"
import type { Timetable } from "luxedo-data/src/json-model/timetable/Timetable"
import {
	TimetableEventRepeat,
	type FrequencyType,
	TimetableEvent,
	TimetableEventSingle,
	TimetableError,
	type FullCalendarEvent,
	TEMPORARY_PLAY_NOW_EVENT_NAME,
} from "luxedo-data/src/json-model/timetable/TimetableEvent"
import dayGridPlugin from "@fullcalendar/daygrid"
import { EventImpl, getUniqueDomId } from "@fullcalendar/core/internal"
import rrulePlugin from "@fullcalendar/rrule"

import { RRule } from "rrule"
import { DateTime, Duration, Interval } from "luxon"
import { v4 as uuid } from "uuid"
import { closeOverlay } from "svelte-comps/overlay"
import { Toast } from "svelte-comps/toaster"

type ScheduleCTX = {
	isScheduling: boolean
	filterDevice?: Device
	currentStep?: number
}

type TimingInputs = {
	start: string
	end: string
	duration?: number
	frequencyAmount?: number
	frequencyType?: FrequencyType
	doRepeat?: boolean
}

type NewEventCTX = {
	selectedDevice: Device
	selectedShow: Lightshow | Scene
	eventDraft?: TimetableEvent
}

export const SCHEDULE_STEPS = {
	DEVICE: 0,
	SHOW: 1,
	TIME: 2,
}

export namespace ScheduleController {
	let calendarInstance: fcCalendar = new fcCalendar(
		document.getElementById("hidden-calendar"),
		{
			plugins: [dayGridPlugin, rrulePlugin],
			initialView: "dayGridMonth",
		}
	)
	const scheduleStore = writable<ScheduleCTX>({ isScheduling: false })

	const newEventStore = writable<NewEventCTX>()

	let timezone: string
	let timing: TimingInputs = getDefaultTime()

	/** Use to listen for top level component state (isScheduling/ currentStep) */
	export function subscribe(cb: (ctx: ScheduleCTX) => void) {
		return scheduleStore.subscribe(cb)
	}

	export function selectDeviceFilter(device: Device) {
		return scheduleStore.update((ctx) => {
			return {
				...ctx,
				filterDevice: device,
			}
		})
	}

	/** Use to update the current scheule step - used for starting the scheduler in a variety of states (selected device, selected show) */
	export async function updateStepIndex(newStepIndex: number) {
		if (newStepIndex < SCHEDULE_STEPS.DEVICE)
			ScheduleController.EventEditor.cancel()
		else if (newStepIndex > SCHEDULE_STEPS.TIME) {
			try {
				await ScheduleController.EventEditor.save()
			} catch (e) {
				console.error("SCHEDULING ERROR", e)
				newStepIndex = newStepIndex - 1
				if (e instanceof TimetableOverlapError) Toast.error(e.message)
			}
		}

		if (newStepIndex === SCHEDULE_STEPS.TIME)
			Calendar.showNewEventPreview(timing, get(newEventStore))

		scheduleStore.update((ctx) => {
			return {
				...ctx,
				currentStep: newStepIndex,
			}
		})
	}

	/** All management of event editing / creating will be done here */
	export namespace EventEditor {
		/** Use to subscribe to edit event updates (timing updates will not happen here) */
		export function subscribe(cb: (ctx: NewEventCTX) => void) {
			return newEventStore.subscribe(cb)
		}

		/** Updates the store to select the provided device */
		export function selectDevice(device: Device) {
			newEventStore.update((ctx) => {
				return {
					...ctx,
					selectedDevice: device,
				}
			})

			Calendar.refreshEvents({ deviceFilter: device })
		}

		/** Updates the store to select the provided show */
		export function selectShow(show: Lightshow | Scene) {
			newEventStore.update((ctx) => {
				return {
					...ctx,
					selectedShow: show,
				}
			})
		}

		export function setTimeZone(tz: string) {
			timezone = tz
		}

		/** Clears the timezone, assumes user is setting up based on local timezone */
		export function clearTimeZone() {
			timezone = undefined
		}

		/** Updates the ScheduleController's reference of all of the user inputs */
		export function updateTimingBlock(timingInputs: TimingInputs) {
			timing = { ...timingInputs }
		}

		/** Updates the ScheduleController's reference of a specific user input value (e.g., start), updating the calendar preview if applicable */
		export function updateTimingValue(
			key: keyof TimingInputs,
			value: number | string | boolean
		) {
			timing[key as string] = value

			try {
				Calendar.showNewEventPreview(timing, get(newEventStore))
			} catch (e) {
				console.error("[ERROR] ", e)
				if (e instanceof TimetableError)
					console.error(
						"Cannot show preview where start date is after end date."
					)
			}
		}

		/** Initializes the scheduler with the passed event (if applicable) */
		export async function editEvent(
			passedEvent?: TimetableEvent | FullCalendarEvent,
			options?: {
				show?: Scene | Lightshow
				device?: Device
			}
		) {
			closeOverlay() // Just in case an overlay is open
			let event: TimetableEvent

			// Gets the timetable event if passed a full calendar event instance
			if (passedEvent instanceof EventImpl) {
				const device = DataHandlerDevice.get(
					(passedEvent as FullCalendarEvent).extendedProps.deviceId
				)
				event = device.timetableManager.getEvent(
					(passedEvent as FullCalendarEvent).extendedProps.eventId
				)
			} else if (passedEvent instanceof TimetableEvent) {
				event = passedEvent
			}

			navigateTo("schedule")

			if (event) {
				newEventStore.set({
					selectedShow: event.show,
					selectedDevice: event.device,
					eventDraft: event,
				})
				scheduleStore.set({
					isScheduling: true,
					currentStep: passedEvent
						? SCHEDULE_STEPS.TIME
						: SCHEDULE_STEPS.DEVICE,
				})
			} else if (options) {
				let { show, device } = options

				if (show && !device)
					device = DataHandlerDevice.get(show.target_device_id)

				newEventStore.set({
					selectedShow: show,
					selectedDevice: device,
				})

				let step = 0
				if (device) step = 1
				if (show) step = 2

				scheduleStore.set({
					isScheduling: true,
					currentStep: step,
				})
			} else {
				scheduleStore.set({
					isScheduling: true,
					currentStep: 0,
				})
			}

			setTimeout(async () => {
				// Wrapping this in set timeout ensure the newEventStore has updated its values
				await Calendar.refreshEvents({
					deviceFilter:
						get(newEventStore)?.selectedDevice ?? undefined,
				})
				Calendar.showNewEventPreview(timing, get(newEventStore))

				if (!passedEvent)
					ScheduleController.Calendar.initiateScheduleMode()
			})
		}

		/** Resets the timing and de-selects the show and device */
		function reset() {
			timezone = undefined
			timing = getDefaultTime()
			newEventStore.set({
				selectedShow: undefined,
				selectedDevice: undefined,
			})
		}

		/** Cancel the editing/creating of a new event, resetting the calendar to default */
		export function cancel() {
			reset()
			Calendar.refreshEvents()
			Calendar.resetCalendarView()

			scheduleStore.update((ctx) => {
				return { ...ctx, isScheduling: false }
			})
		}

		/** Saves the new event using the new event data, will pull events and re-render the calendar when finished */
		export async function save() {
			const doesOverlap = Calendar.checkForOverlap()
			if (doesOverlap)
				throw new TimetableOverlapError(
					"Would overlap with existing event."
				)

			const storeData = get(newEventStore)

			const eventDraft = storeData.eventDraft
			const device = storeData.selectedDevice
			const show = storeData.selectedShow

			let id = uuid()
			let name = show.name

			if (eventDraft) {
				id = eventDraft.id
				name = eventDraft.name
			}

			let tempEvent
			if (timing.doRepeat) {
				tempEvent = new TimetableEventRepeat(
					{
						id,
						name,
						project_id: show.id,
						repeat: 1,
						timing: TimetableEventRepeat.createTiming(
							timing.start,
							timing.end,
							timing.duration,
							timing.frequencyAmount,
							timing.frequencyType,
							timezone
						),
					},
					device
				)
			} else {
				const timingBlock = TimetableEventSingle.createTiming(
					timing.start,
					timing.end,
					timezone
				)
				console.log({ timingBlock, timezone })

				tempEvent = new TimetableEventSingle(
					{
						id,
						name,
						project_id: show.id,
						repeat: 0,
						timing: {
							t_start: timingBlock.t_start,
							t_end: timingBlock.t_end,
						},
					},
					device
				)
			}
			await device.timetableManager.addEvent(tempEvent)

			reset()
			Calendar.resetCalendarView()
			await Calendar.refreshEvents()

			setTimeout(() => {
				scheduleStore.set({
					isScheduling: false,
				})
				document.getElementById("close-scheduler")?.click()
			})
		}
	}

	/** All interactions with the full calendar will happen here */
	export namespace Calendar {
		let refreshingEvents: Promise<void>

		/**
		 * Returns timetables for all devices
		 * @param ignore An array of device ids to ignore when pulling timetables
		 */
		async function getAllDeviceTimetables(
			ignore?: Array<number>
		): Promise<Array<Timetable>> {
			const timetables: Array<Timetable> = []
			const allDevices = DataHandlerDevice.getMany()
			for (const device of allDevices) {
				if (ignore && ignore.includes(device.id)) continue
				const timetable = await device.timetableManager
				timetables.push(timetable)
			}

			return timetables
		}

		export async function getEventByEventId(eventId: string) {
			if (refreshingEvents) await refreshingEvents

			const fcEvents =
				calendarInstance.getEvents() as Array<FullCalendarEvent>
			return fcEvents.find(
				(event) => event.extendedProps.eventId === eventId
			)
		}

		/**
		 * Gets an array of full calendar events in order of appearance
		 */
		export async function getEvents(
			filterBy: { device?: Device; show?: Scene | Lightshow } = {}
		) {
			const { device, show } = filterBy
			if (refreshingEvents) await refreshingEvents

			const fcEvents =
				calendarInstance.getEvents() as Array<FullCalendarEvent>

			let returnEvents: Array<FullCalendarEvent> = []
			for (const event of fcEvents) {
				const dev = DataHandlerDevice.get(event.extendedProps.deviceId)

				const ttEvent = dev.timetableManager.getEvent(
					event.extendedProps.eventId
				)

				if (device && dev.id !== device.id) continue
				if (show && ttEvent.show.id !== show.id) continue
				returnEvents.push(event)
			}

			return returnEvents
		}

		/**
		 * Clears and populates the full calendar instance with events pulled from the specified device (or all if not specified)
		 * @param options.deviceFilter the device to filter by
		 * @param options.showFilter if passed, the timetable will be pulled for the related device
		 * @param options.hideTitle if true, the event will not display a title
		 */
		export async function refreshEvents(
			options: {
				deviceFilter?: Device
				showFilter?: Scene | Lightshow
				hideTitle?: boolean
			} = {}
		) {
			if (!calendarInstance) {
				return console.error(
					"Trying to refresh calendar events, but full calendar has not been passed to ScheduleController."
				)
			}

			if (refreshingEvents) await refreshingEvents

			refreshingEvents = new Promise(async (res) => {
				const {
					deviceFilter: device,
					showFilter: show,
					hideTitle,
				} = options

				let timetables: Array<Timetable> = []
				calendarInstance.removeAllEvents()
				if (device) {
					const timetable = await device.timetableManager
					timetables = [timetable]
				} else if (show) {
					const device = DataHandlerDevice.get(show.target_device_id)
					const timetable = await device.timetableManager
					timetables[device.id] = timetable
				} else {
					timetables = await getAllDeviceTimetables()
				}

				for (const timetable of timetables) {
					if (!timetable) continue

					for (const event of timetable.events) {
						if (event.name === TEMPORARY_PLAY_NOW_EVENT_NAME)
							continue
						try {
							const eventJson = event.toFullCalendarEvent()
							if (!eventJson.extendedProps.inherited)
								calendarInstance.addEvent(eventJson)
						} catch (e) {
							console.error(
								"Caught broken full calendar event, removing from the timetable",
								e,
								event
							)
						}
					}
				}

				res()
			})
		}

		/** Sets the internal reference to a full calendar instance */
		export function setFullCalendarInstance(cal: fcCalendar) {
			calendarInstance = cal
		}

		/** Checks all of the full calendar events for overlap with the new event data */
		export function checkForOverlap() {
			const eventDraft = get(newEventStore).eventDraft

			let events = calendarInstance
				.getEvents()
				.filter((event) => !event.extendedProps["temp"])
			if (eventDraft)
				events = events.filter((e) => e.id !== eventDraft.id)

			const newIntervals = []
			if (timing.doRepeat) {
				const repeatTiming = TimetableEventRepeat.createTiming(
					timing.start,
					timing.end,
					timing.duration,
					timing.frequencyAmount,
					timing.frequencyType
				)
				const occurrences = RRule.fromString(
					repeatTiming.rrule_string
				).all()
				for (const occurence of occurrences) {
					const start = DateTime.fromJSDate(occurence)
					const end = start.plus(
						Duration.fromISO(repeatTiming.duration_iso)
					)
					const interval = Interval.fromDateTimes(start, end)
					newIntervals.push(interval)
				}
			} else {
				const singleTiming = TimetableEventSingle.createTiming(
					timing.start,
					timing.end
				)
				newIntervals.push(
					Interval.fromDateTimes(
						DateTime.fromISO(singleTiming.t_start),
						DateTime.fromISO(singleTiming.t_end)
					)
				)
			}

			for (const interval of newIntervals) {
				for (const event of events) {
					if (
						("inherited" in event.extendedProps &&
							event.extendedProps.inherited) ||
						("temp" in event.extendedProps &&
							event.extendedProps.temp) ||
						(eventDraft &&
							eventDraft.id === event.extendedProps.eventId)
					)
						continue

					const dtStart = DateTime.fromJSDate(event.start)
					const dtEnd = DateTime.fromJSDate(event.end)
					const existingInverval = Interval.fromDateTimes(
						dtStart,
						dtEnd
					)

					if (interval.overlaps(existingInverval)) {
						return true
					}
				}
			}

			for (let i = 0; i < Math.min(newIntervals.length, 5); i++) {
				const intervalX = newIntervals[i]
				for (let j = 0; j < Math.min(newIntervals.length, 5); j++) {
					const intervalY = newIntervals[j]
					if (i != j && intervalY.overlaps(intervalX)) {
						return true
					}
				}
			}

			return false
		}

		/** Focuses the calendar onto the specified time range  */
		function focusView(timing: TimingInputs) {
			const startDate = DateTime.fromISO(timing.start)
			const endTime = DateTime.fromISO(timing.end)

			if (!endTime.isValid) {
				calendarInstance.changeView("timeGridWeek")
				calendarInstance.scrollToTime(
					`${Math.max(0, startDate.hour - 1)}:00:00`
				)
				calendarInstance.render()
				return
			}

			let numOfDays = Math.ceil(endTime.diff(startDate).as("days"))
			if (endTime.day > startDate.day) numOfDays += 1

			if (numOfDays >= 5) calendarInstance.changeView("timeGridWeek")
			else {
				calendarInstance.changeView("eventFocusedView")
				calendarInstance.setOption("duration", {
					days: numOfDays,
				})

				calendarInstance.gotoDate(startDate.toISODate())

				setTimeout(() => {
					zoomInOn(startDate, endTime)
				})

				calendarInstance.render()
			}
		}

		/** Changes the focus of the calendar, ensuring the date range is between start and end. */
		function zoomInOn(startDateTime: DateTime, endDateTime: DateTime) {
			const startTime = Duration.fromObject({
				hour: startDateTime.hour,
				minute: startDateTime.minute,
				second: startDateTime.second,
			})
			const endTime = Duration.fromObject({
				hour: endDateTime.hour,
				minute: endDateTime.minute,
				second: endDateTime.second,
			})
			const overnight = endTime < startTime

			const zoomLevels = [
				[2, 4],
				[1.5, 3],
				[2, 4],
				[1, 2],
				[0.5, 1],
				// [0.05, 0.25],
			]

			let zoomLevel = 0

			if (overnight) {
				calendarInstance.setOption("slotDuration", "04:00:00")
				calendarInstance.setOption("slotLabelInterval", "04:00:00")
				calendarInstance.setOption("scrollTime", "00:00:00")
				calendarInstance.scrollToTime("00:00:00")
				return
			}

			const harnessElement = document.querySelector(
				".fc-scroller-harness.fc-scroller-harness-liquid"
			)
			const harnessHeight = harnessElement.clientHeight

			// Get the height of one slot element
			// Assuming all slot elements have the same height,
			// so we just need to measure one
			const slotHeight = document.querySelector(
				".fc-scroller-harness .fc-timegrid-slot"
			).clientHeight

			// Calculate how many slot elements fit inside the harness
			const slotsFit = harnessHeight / slotHeight

			let scrollTarget = Duration.fromMillis(0)
			// Iterate through the zoom levels to find the best fit
			for (let i = 0; i < zoomLevels.length; i++) {
				const [slotHours, labelHourInterval] = zoomLevels[i]
				const minimumMargin = Duration.fromObject({
					hours: 0.5 * slotHours,
				})

				const minHour = Math.max(
					startTime.minus(minimumMargin).as("hours"),
					0
				)
				const maxHour = Math.min(
					endTime.plus(minimumMargin).as("hours"),
					24
				)

				const scrollHour =
					Math.floor(minHour / labelHourInterval) * labelHourInterval

				const minCoverage = maxHour - scrollHour
				const totalCoverage = slotsFit * slotHours

				if (totalCoverage < minCoverage) {
					break
				}

				scrollTarget = Duration.fromObject({ hours: scrollHour })
				zoomLevel = i
			}

			const [slotIntervalHours, labelIntervalHours] =
				zoomLevels[zoomLevel]

			let scrollTime = scrollTarget.toFormat("hh:mm:ss")

			calendarInstance.setOption(
				"slotDuration",
				Duration.fromMillis(
					slotIntervalHours * 60 * 60 * 1000
				).toFormat("hh:mm:ss")
			)
			calendarInstance.setOption(
				"slotLabelInterval",
				Duration.fromMillis(
					labelIntervalHours * 60 * 60 * 1000
				).toFormat("hh:mm:ss")
			)
			calendarInstance.setOption("scrollTime", scrollTime)
			calendarInstance.scrollToTime(scrollTime)
			calendarInstance.render()
		}

		/** Clears all events with the temp extendedProp */
		function clearTemporaryEvents() {
			const tempEvents =
				calendarInstance
					?.getEvents()
					.filter((event) => event.extendedProps.temp) ?? []

			for (const tempEvent of tempEvents) {
				calendarInstance.getEventById(tempEvent.id)?.remove()
			}
		}

		/** Shows a temporary event to reflect the user's timing inputs */
		export function showNewEventPreview(
			timing: TimingInputs,
			newEventCtx: NewEventCTX
		) {
			clearTemporaryEvents()

			const device = newEventCtx?.selectedDevice
			const show = newEventCtx?.selectedShow
			const existingEvent = newEventCtx?.eventDraft

			if (!device || !show) return focusView(timing)

			if (existingEvent) {
				setTimeout(async () => {
					const fcEvent = await Calendar.getEventByEventId(
						existingEvent.id
					)
					if (fcEvent) fcEvent.remove()
				})
			}

			const previewShowName = `New Event - ${device.name} playing ${show.name}`
			const tempTimetableEvent = timing.doRepeat
				? new TimetableEventRepeat(
						{
							id: getUniqueDomId(),
							name: previewShowName,
							project_id: show.id,
							repeat: 1,
							timing: TimetableEventRepeat.createTiming(
								timing.start,
								timing.end,
								timing.duration,
								timing.frequencyAmount,
								timing.frequencyType
							),
						},
						device
				  )
				: new TimetableEventSingle(
						{
							id: getUniqueDomId(),
							name: previewShowName,
							project_id: show.id,
							repeat: 0,
							timing: TimetableEventSingle.createTiming(
								timing.start,
								timing.end
							),
						},
						device
				  )

			const fullCalendarEvent =
				tempTimetableEvent.toFullCalendarEvent(true)
			fullCalendarEvent.display = "background"
			calendarInstance.addEvent(fullCalendarEvent)

			focusView(timing)
		}

		/**  Updates the calendar view to align better with the space */
		export function initiateScheduleMode() {
			calendarInstance.changeView("timeGridWeek")
			setTimeout(() => calendarInstance.render())
		}

		/** Resets the calendar to the base month view and removes any temporary events */
		export function resetCalendarView() {
			clearTemporaryEvents()
			setTimeout(() => {
				calendarInstance.setOption("duration", undefined)
				calendarInstance.changeView("dayGridMonth")
				calendarInstance.render()
			})
		}
	}
}

/** Use to format DateTime into an ISO with no millisecond */
function formatISOString(date: DateTime) {
	return `${date.toFormat("yyyy-MM-dd")}T${date.toFormat("HH:mm")}`
}

/** Creates a default time based on now */
function getDefaultTime() {
	const now = DateTime.now()
	const tomorrow = now.plus({ days: 1 })
	return {
		start: `${now.toFormat(`yyyy-MM-dd`)}T${now.toFormat(`HH:mm`)}`,
		end: `${tomorrow.toFormat(`yyyy-MM-dd`)}T${tomorrow.toFormat(`HH:mm`)}`,
	}
}

export class TimetableOverlapError extends Error {
	constructor(msg) {
		super(msg)
	}
}
