<script lang="ts">
	import type { Component } from "svelte-comps/comps"
	import { ProjectorMenuController } from "./ProjectorMenuController.svelte"
	import { DataHandlerDevice, Device, DeviceGroup } from "luxedo-data"
	import DeviceSelectorSidebar from "./device-selector/DeviceSelectorSidebar.svelte"
	import { onMount } from "svelte"
	import { ScheduleController } from "../schedule/ScheduleController"
	import DeviceNextSteps from "./next-steps/DeviceNextSteps.svelte"
	import ProjectorMenuAudio from "./view/audio/DeviceAudio.svelte"
	import ProjectorMenuCalibration from "./view/calibration/DeviceCalibration.svelte"
	import ProjectorMenuConfiguration from "./view/config/DeviceGroupConfiguration.svelte"
	import ProjectorMenuControls from "./view/controls/DeviceControls.svelte"
	import ProjectorMenuInfo from "./view/about/DeviceAbout.svelte"
	import ProjectorMenuSettings from "./view/settings/DeviceSettings.svelte"
	import ProjectorRegistration from "./view/registration/DeviceRegistration.svelte"
	import ProjectorMenuNavigation from "./DeviceMenu_Navigation.svelte"
	import ProjectorMenuSelectedDevice from "./DeviceMenu_SelectedDevice.svelte"
	import NetworkMenu from "./view/network/NetworkMenu.svelte"

	// #region View Management

	let { view, device } = $derived(ProjectorMenuController.ctx)

	const VIEWS: { [key in typeof view]: Component } = {
		Audio: ProjectorMenuAudio,
		Calibration: ProjectorMenuCalibration,
		Config: ProjectorMenuConfiguration,
		Controls: ProjectorMenuControls,
		About: ProjectorMenuInfo,
		Network: NetworkMenu,
		Registration: ProjectorRegistration,
		Settings: ProjectorMenuSettings,
	}

	let deviceType: string = $derived(
		device && device instanceof DeviceGroup ? "Group" : "Projector"
	)
	let CurrentMenu: Component = $derived(VIEWS[view])

	// #endregion
	// #region Device Management

	let allDevices: Array<Device> = $state([])
	let hasDevice = $derived(allDevices?.length > 0)

	DataHandlerDevice.addListener(updateDevices)

	function updateDevices() {
		allDevices = DataHandlerDevice.getMany()
		if (allDevices.length && !device)
			ProjectorMenuController.setDevice(allDevices[0])
	}

	async function refreshCalendar(device: Device) {
		ScheduleController.Calendar.refreshEvents({ deviceFilter: device })
	}

	function handleKeyDown(e: KeyboardEvent) {
		if (e.key !== "Enter") return

		const saveButton = document.getElementById("save-device-settings-button")
		saveButton?.click()
	}

	onMount(updateDevices)

	// #endregion
</script>

<div id="projectors">
	<div id="projectors-left">
		<div id="projectors-heading" class="flex-row">
			{#if view === "Registration"}
				<h1>Register New Device</h1>
			{:else}
				<h1>{deviceType}s</h1>
			{/if}
		</div>
		<div id="projector-menu">
			{#if view !== "Registration"}
				<ProjectorMenuSelectedDevice />
				<hr />
				<ProjectorMenuNavigation />
			{/if}
			{#if device || view == "Registration"}
				<CurrentMenu />
			{/if}
		</div>
	</div>
	<div id="projectors-right">
		{#if hasDevice && view !== "Registration"}
			<DeviceSelectorSidebar
				{allDevices}
				selected={device}
				onselect={ProjectorMenuController.setDevice}
			/>
		{/if}
		{#await refreshCalendar(device) then}
			<DeviceNextSteps />
		{/await}
	</div>
</div>

<svelte:window onkeydown={handleKeyDown} />

<style>
	#projectors {
		height: 100%;
		padding: 0;
		padding-bottom: 1rem;
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	#projectors-heading {
		gap: 0.5rem;
	}

	#projectors-heading :global(.text-row-selector) {
		height: 100%;
		align-items: center;
	}

	#projectors :global(.device-settings-inputs) {
		margin-left: 1rem;
	}

	h1 {
		font-size: var(--h1);
		color: var(--color-text-light);
		margin: 0 0 0 2rem;
		line-height: 3rem;
	}

	#projectors-left {
		flex-grow: 1;
		margin-right: 2rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	#projector-menu {
		padding: 1rem 2rem 0 2rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	#projector-menu hr {
		margin-bottom: 0.5rem;
	}

	:global(.projector-menu .input-container) {
		max-width: 26rem;
	}

	:global(.projector-menu input) {
		flex-grow: 1;
		margin-left: 0.5rem;
		height: 1.5rem;
		transition: background-color 250ms;
	}

	:global(.projector-menu label) {
		flex-shrink: 0;
	}

	:global(.projector-menu input:focus-visible),
	:global(.projector-menu input:hover) {
		background-color: var(--color-bg);
	}

	#projector-menu :global(.projector-menu) {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	#projectors-right {
		display: flex;
		flex-direction: column;
		flex-basis: 40%;
		max-width: 20rem;
		min-width: 20rem;
		overflow: hidden;
		gap: 1rem;

		/* height: 12rem; */
	}

	#projector-menu
		:global(.projector-menu)
		:global(#projector-resolution-label) {
		display: flex;
		flex-direction: row;
	}

	:global(.projector-menu) :global(.switch-container) {
		width: fit-content;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	:global(.projector-menu) :global(.switch-container .label) {
		margin-right: 1rem;
	}

	:global(.projector-menu) :global(.button-container) {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}

	:global(.projector-menu) :global(.flex-row) {
		align-items: center;
	}

	:global(.projector-menu)
		:global(.invert-camera-container :global(.info-dialogue-button)) {
		margin-left: 0;
		margin-bottom: 0.5rem;
	}

	:global(.projector-menu) :global(.label),
	:global(.projector-menu) :global(label) {
		color: var(--color-text);
		white-space: nowrap;
		width: fit-content;
	}

	:global(.projector-menu) :global(select) {
		color: var(--color-text-light);
		width: 100%;
		margin-left: 0.5rem;
		transition: background-color 250ms;
	}

	:global(.projector-menu) :global(input[type="number"]) {
		background-color: unset;
		color: var(--color-text-light);
		margin: 0 0.5rem;
		border: none;
		padding: 0;
		box-shadow: none;
		width: 2rem;
		border-radius: 0;
		text-align: end;
	}

	:global(.projector-menu) :global(select:focus-visible),
	:global(.projector-menu) :global(select:hover) {
		background-color: var(--color-main-transparent);
	}

	:global(.projector-menu) :global(.power-control-info) {
		margin-left: 0.5rem;
		color: var(--color-text);
		font-size: var(--h3);
	}

	:global(.projector-menu) :global(.projector-heading:not(:first-of-type)) {
		margin-top: 0.5rem;
	}

	#projectors-right :global(.device-filter-container) {
		max-height: 50%;
	}

	#projectors-left :global(.icon-container) {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 2rem;
	}

	#projectors-left :global(.icon-container svg) {
		width: 4rem;
		height: 4rem;
		z-index: 5;
	}

	#projectors-left :global(.icon-container .icon-bg) {
		position: absolute;
		width: 500%;
		height: 500%;
		backdrop-filter: blur(2rem);
		z-index: -1;
	}

	#projectors-left :global(.icon-container .icon-bg-color) {
		position: absolute;
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		z-index: -2;
	}

	#projector-menu :global(.download-list) {
		background-color: var(--color-bg-dark);
		overflow: hidden;
		margin-top: 1rem;
		min-width: 1rem;
		min-height: 1rem;
		padding: 1rem;
		border-radius: var(--br);
	}

	:global(.low-pad) {
		padding: 1rem 0 0 0;
	}

	:global(.setting-heading) {
		margin: 0;
		display: flex;
		flex-direction: row;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	:global(.projector-section) {
		padding: 1rem;
		background-color: var(--color-bg);
		border-radius: var(--br);
		width: 100%;
	}

	:global(.projector-menu) {
		overflow-y: auto;
	}

	:global(.projector-menu .warn-text) {
		color: var(--color-main);
		line-height: 1.25rem;
	}

	:global(.projector-menu .warn-text::before) {
		content: "⚠";
		position: relative;
	}
</style>
