<script lang="ts">
	import type { Media } from "luxedo-data"

	interface Props {
		media: Media
	}

	let { media }: Props = $props()
</script>

<img src={media.src.compressed} alt={media.name} />

<style>
	img {
		object-fit: contain;
		height: 100%;
		width: 100%;
	}
</style>
