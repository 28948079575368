<script lang="ts">
	import { GroupCalibrationController } from "./GroupCalibrationController"
	import GroupCalibrationCal from "./GroupCalibration_Cal.svelte"
	import GroupCalibrationInfo from "./GroupCalibration_Info.svelte"
	import GroupCalibrationSnapshot from "./GroupCalibration_Snapshot.svelte"
	import GroupCalibrationError from "./GroupCalibration_Error.svelte"
	import { onMount } from "svelte"
	import { GroupEditorController } from "../../GroupEditorController"

	let stepIndex: number = $state(0)
	GroupCalibrationController.subscribe((ctx) => {
		stepIndex = ctx.step
	})

	const STEP_COMPS = {
		[-1]: GroupCalibrationError,
		0: GroupCalibrationInfo,
		1: GroupCalibrationCal,
		2: GroupCalibrationSnapshot,
	}

	onMount(() => {
		GroupEditorController.Grid.deactivate()
	})

	const SvelteComponent = $derived(STEP_COMPS[stepIndex])
</script>

<SvelteComponent />

<style>
</style>
