<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="57" height="64" viewBox="0 0 57 64" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M9.31689 22.2758C9.14943 21.0732 10.0835 20 11.2978 20H45.644C46.8782 20 47.8179 21.1069 47.6174 22.3247L41.7757 57.8247C41.6167 58.791 40.7814 59.5 39.8022 59.5H16.2408C15.2428 59.5 14.3976 58.7643 14.2599 57.7758L9.31689 22.2758Z"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
	/>
	<path
		d="M6.5 14C6.5 12.6193 7.61929 11.5 9 11.5H48C49.3807 11.5 50.5 12.6193 50.5 14V19.5H6.5V14Z"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
	/>
	<line
		x1="28.5"
		y1="26.5"
		x2="28.5"
		y2="51.5"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
		stroke-linecap="round"
	/>
	<line
		x1="1.5"
		y1="-1.5"
		x2="26.5"
		y2="-1.5"
		transform="matrix(0.173648 0.984808 0.984808 -0.173648 20 25)"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
		stroke-linecap="round"
	/>
	<line
		x1="38.0789"
		y1="26.7377"
		x2="33.7377"
		y2="51.3579"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
		stroke-linecap="round"
	/>
	<path
		d="M38 10C38 7.23858 33.7467 5 28.5 5C23.2533 5 19 7.23858 19 10"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
	/>
</svg>

<style>
</style>
