<script lang="ts">
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { GroupCalibrationController } from "./GroupCalibrationController"
	import type { DeviceRPi } from "luxedo-data"
	import CalibrationOverlayAnimation from "../../../calibration/calibration-overlay/CalibrationOverlay_Animation.svelte"
	import ProjectorIcon from "../../../../icons/ProjectorIcon.svelte"

	let progress = $state(0)
	let message: string = $state()
	let device: DeviceRPi = $state()
	let deviceIndex: number = $state()
	let deviceAmount: number = $state()

	GroupCalibrationController.subscribe((ctx) => {
		device = ctx.calibratingDevice
		message = ctx.message ?? "Initializing..."
		progress = ctx.progress
		deviceIndex = ctx.deviceIndex
		deviceAmount = ctx.deviceAmount
	})
</script>

{#if device}
	<div class="calibration-progress">
		<div class="cal-anim-container">
			<CalibrationOverlayAnimation />
		</div>
		<div class="cal-prog-bottom">
			<ProgressBar percentage={progress} width="100%" />
			<span>{message}</span>
			<p class="cal-status">
				Calibrating <span class="device-name" style="color: {device.color};">
					<ProjectorIcon {device} />
					{device.name}
				</span>
				(device {deviceIndex + 1} of {deviceAmount}).
			</p>
		</div>
	</div>
{/if}

<style>
	.calibration-progress :global(.progress-bar) {
		margin: 1rem 0;
	}

	.calibration-progress {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.cal-status {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		color: var(--color-text);
	}

	.cal-status .device-name {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: fit-content;
		white-space: nowrap;
		height: fit-content;
		margin: 0 0.25rem;
	}

	.cal-status :global(svg) {
		height: 1em;
		width: 2em;
	}

	.cal-prog-bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.cal-anim-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
</style>
