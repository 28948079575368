<script lang="ts">
	import { onMount } from "svelte"
	import PasswordInput from "../../reusable/input/PasswordInput.svelte"
	import { InfoDialogue } from "svelte-comps/info-dialogue"


	let {
		firstName = $bindable(),
		lastName = $bindable(),
		email = $bindable(),
		password = $bindable(),
		passwordConfirm = $bindable(),
		CAPTCHA = $bindable()
	} = $props();

	onMount(() => {
		grecaptcha.render("captcha", {
			sitekey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
			callback: (c) => (CAPTCHA = c),
		})
	})
</script>

<div class="login-inputs" id="register-form">
	<div class="input-row">
		<div class="input-container">
			<label for="first-name-input">First Name</label>
			<input id="last-name-input" type="text" bind:value={firstName} />
		</div>

		<div class="input-container">
			<label for="last-name-input">Last Name</label>
			<input id="last-name-input" type="text" bind:value={lastName} />
		</div>
	</div>

	<div class="input-container">
		<label for="email-input">Email</label>
		<input id="email-input" type="email" bind:value={email} />
	</div>

	<div class="input-row">
		<div class="input-container">
			<label for="pass-input"
				>Password
				<InfoDialogue
					textContent={"Passwords should be at least 8 characters long with at least one uppercase and one lowercase letter."}
					inline
				/>
			</label>
			<PasswordInput id="pass-input" bind:value={password} />
		</div>
		<div class="input-container">
			<label for="pass-conf-input">Confirm Password</label>
			<PasswordInput id="pass-conf-input" bind:value={passwordConfirm} />
		</div>
	</div>

	<!-- class="g-recaptcha"
		data-callback="imNotARobot"
		data-sitekey={} -->
</div>
<div class="flex-row center-hor">
	<div id="captcha"></div>
</div>

<style>
	.input-row {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}

	label[for="pass-input"] {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	label[for="pass-input"] :global(.info-dialogue-button) {
		margin-left: 0.25rem;
		line-height: 1rem;
	}

	.flex-row.center-hor {
		margin-top: 1.5rem;
	}
</style>
