import { Device, DeviceGroup, DeviceRPi } from "."
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"

const FEATURES = [
	"API_THREADS", // eidos has new 'api_threads' property
	"RENDERLESS_LIGHTSHOWS", // lightshows no longer need to be rendered
	"DOWNLOADS", // compatible with downloads tab (has cache eidos property)
	"HDMI_FIX", // compatible with HDMI fix
	"EIDOS_PLAYER", // eidos has new 'player' property
	"NETWORK_TEST", // to check the speed and strength of Internet/WiFi
	"TOGGLE_HOTSPOT", // to stop the hotspot running on the device
] as const

export type DeviceFeature = (typeof FEATURES)[number]

/**
 * Checks a device group's children for the specified feature.
 * Returns true if EACH child device has the feature.
 * @param device the device group
 * @param feature the feature to check for
 * @returns true if each child has the feature
 */
function childrenHaveFeature(device: DeviceGroup, feature: DeviceFeature) {
	let hasFeature = false
	for (const slot of device.children) {
		const childDevice = DataHandlerDevice.get(slot.device_id)
		hasFeature = getDeviceFeatures(childDevice).includes(feature)
		if (!hasFeature) break
	}
	return hasFeature
}

/**
 * Gets each available feature for the specified device
 * @param device the Device
 * @returns a list of device features
 */
export function getDeviceFeatures(device: Device): Array<DeviceFeature> {
	let features: Array<DeviceFeature> = []

	if (device instanceof DeviceRPi) {
		if (device.compareVersion("3.11.0") >= 0) {
			features.push("TOGGLE_HOTSPOT")
		}

		if (device.compareVersion("3.9.0") >= 0) {
			features.push("NETWORK_TEST")
		}

		if (device.compareVersion("3.8.0") >= 0) {
			features.push("API_THREADS")
		}

		if (device.compareVersion("3.4.0") >= 0) {
			features.push("HDMI_FIX")
		}

		if (device.compareVersion("3.2.0") >= 0) {
			features.push("EIDOS_PLAYER")
			features.push("DOWNLOADS")
			features.push("RENDERLESS_LIGHTSHOWS")
		}
	} else if (device instanceof DeviceGroup) {
		if (childrenHaveFeature(device, "API_THREADS"))
			features.push("API_THREADS")
		if (childrenHaveFeature(device, "RENDERLESS_LIGHTSHOWS"))
			features.push("RENDERLESS_LIGHTSHOWS")
	}

	return features
}
