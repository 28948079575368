<script lang="ts">
	import type { DeviceGroup, Snapshot } from "luxedo-data"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import DownloadIcon from "../../../../reusable/icons/DownloadIcon.svelte"
	import { openSnapshotPreview } from "../../../../reusable/overlays"
	import { LoadingSpinner } from "svelte-comps/loading"
	import DeviceMenuBlock from "../../DeviceMenuBlock.svelte"
	import { openGroupEditorOverlay } from "../../../../reusable/overlays/group-editor"

	let device: DeviceGroup = $derived(
		ProjectorMenuController.ctx.device as DeviceGroup
	)
	let snapshot: Snapshot = $state()

	$effect(() => {
		onDeviceUpdate(device)
	})

	let listener: string
	function onDeviceUpdate(dev: DeviceGroup) {
		if (listener) device.removeUpdateListener(listener)
		listener = dev.addUpdateListener(refreshSnapshot)
	}

	async function refreshSnapshot(device: DeviceGroup) {
		snapshot = await device.getSnapshot()
	}

	function downloadSnapshot() {
		snapshot.triggerDownload()
	}
</script>

<div class="projector-menu">
	<div class="projector-section">
		<div class="flex-row">
			<h2 class="setting-heading">Group Configuration</h2>

			<button
				id="calibrate-button"
				class="outline-button"
				onclick={() => openGroupEditorOverlay(device)}>Edit</button
			>
		</div>
		<div class="config-info">
			Group Configuration is the process of combining multiple devices into a
			single group,” creating one large canvas from multiple projectors. During
			the setup, you'll align each device's scale and position so their
			projections seamlessly merge into one shared display. By unifying these
			projectors into a single group, you can design and showcase synchronized
			content across a wider visual space—making your shows bigger, bolder, and
			perfectly aligned.
		</div>
	</div>

	<div class="snapshot-preview">
		<div class="flex-row">
			<h3 class="setting-heading">Combined Snapshot</h3>
			<button
				class="icon small"
				id="download-snapshot-button"
				onclick={downloadSnapshot}
			>
				<DownloadIcon />
			</button>
		</div>
		<div class="snapshot-container">
			{#if snapshot}
				<img
					src={snapshot.src}
					crossorigin="anonymous"
					alt=""
					onclick={() => openSnapshotPreview(snapshot)}
				/>
			{:else}
				<LoadingSpinner color="var(--color-text)" height="6rem" />
			{/if}
		</div>
	</div>
</div>

<style>
	.projector-menu {
		justify-content: space-between;
	}

	.snapshot-preview {
		margin-top: 1rem;
		padding: 1rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
	}

	.snapshot-preview > .flex-row {
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.snapshot-container {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.snapshot-container img {
		cursor: pointer;
	}

	.config-info {
		margin-bottom: 0;
		margin-top: 0.5rem;
		line-height: 1.25em;
		color: var(--color-text);
	}

	.projector-section > .flex-row {
		justify-content: space-between;
	}
</style>
