<script lang="ts">
	import { Device, DeviceGroup, DeviceRPi } from "luxedo-data"
	import { HoldableButton } from "svelte-comps/inputs"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		device: DeviceRPi
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	let isRebooting = $state(false)

	function onDeviceChange(dev: DeviceRPi) {
		isRebooting = false
	}

	async function temporarilyDisableReboot(softReboot?: boolean) {
		setButtonsDisabled(true)
		isRebooting = true
		try {
			if (softReboot) {
				await new Promise((res) => {
					setTimeout(res, 30 * 1000)
				})
			} else {
				await device.listenEidosCondition((eidos) => eidos.status === "OFF", 45)
				await device.listenEidosCondition(
					(eidos) => eidos.status === "IDLE",
					45
				)
			}
		} catch (e) {
			console.error("Device did not come back online within 1 minute")
		}
		isRebooting = false
		setButtonsDisabled(false)
	}

	async function softReboot() {
		Toast.text(
			"Your device is undergoing a quick restart. This process may take a minute."
		)
		temporarilyDisableReboot()
		await device.platoCall("reboot", [false])
	}

	async function hardReboot() {
		Toast.text(
			"Your device is restarting completely. This process may take a few minutes."
		)
		temporarilyDisableReboot()
		await device.platoCall("reboot", [true])
	}

	$effect(() => {
		onDeviceChange(device)
	})
</script>

<div class="action-container">
	<HoldableButton
		labelDefault="Restart"
		actionDefault={softReboot}
		labelHold="Hard Reboot"
		actionHold={hardReboot}
		isLoading={isRebooting}
		disabled={isDisabled}
	/>
	<span class="action-info">
		Click once for a quick restart—this restarts internal processes without
		powering down the device. Press and hold for a full power cycle.
	</span>
</div>

<style>
	.action-container :global(button.hold-button) {
		--border-color: var(--ct-red);
	}
</style>
