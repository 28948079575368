// NetworkTestController.ts

import { type Unsubscriber } from "svelte/store"
import { APIThread } from "luxedo-data/src/modules/device-operation-managers/DeviceAPIThread"
import type { DeviceRPi } from "luxedo-data"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import { Controller } from "svelte-comps/stores"
import NetworkTestRouter from "./NetworkTestRouter.svelte"
import { Toast } from "svelte-comps/toaster"

// Type definition to handle all states in a single union
export type NetworkTestState = {
	state: "initial" | "loading" | "completed" | "error"
	results?: {
		download_mbps?: number
		upload_mbps?: number
		packet_loss_percent?: number
	}
	errorMsg?: string
}

class NetworkTestControl extends Controller<NetworkTestState> {
	device?: DeviceRPi
	overlayID?: string
	unsubscriber?: Unsubscriber

	constructor() {
		super({ state: "initial" })
	}

	reset = () => {
		super.reset()
		if (this.unsubscriber) this.unsubscriber()
	}

	open = (device: DeviceRPi) => {
		this.device = device

		this.overlayID = openOverlay(NetworkTestRouter, {
			heading: `Performing Network Test on ${device.name}`,
			classHeading: "no-underline",
		})
	}

	close = () => {
		closeOverlay(this.overlayID)
	}

	cancel = () => {
		this.close()
		this.device = undefined
		this.reset()
	}

	retry = () => {
		this.beginTest()
	}

	public beginTest = async () => {
		// Move to "loading"
		this.store.set({ state: "loading" })

		if (!this.device) {
			// If there is no device assigned, it fails
			return this.store.set({
				state: "error",
				errorMsg: "No device has been assigned for the network test.",
			})
		}

		// Check for old thread
		const oldAPIThread = this.device.eidos?.api_threads?.network_test
		let ignoredThreadID: number | undefined

		if (
			oldAPIThread &&
			(oldAPIThread.status === "SUCCESS" ||
				oldAPIThread.status == "ERROR")
		) {
			console.warn("Ignoring old network test thread.")
			ignoredThreadID = oldAPIThread.id
		}

		// If no thread is RUNNING, we start a new one
		if (!oldAPIThread || oldAPIThread.status !== "RUNNING") {
			try {
				// e.g., start the test
				await this.device.platoCall("network_test", [])

				// Wait for final status
				const finalThread = await APIThread.waitFor(
					this.device,
					"network_test",
					ignoredThreadID
				)

				Toast.success("Network test complete!")
				this.store.set({
					state: "completed",
					results: {
						download_mbps: finalThread.download_mbps,
						upload_mbps: finalThread.upload_mbps,
						packet_loss_percent: finalThread.packet_loss_percent,
					},
				})
			} catch (err) {
				// Set to error
				this.store.set({
					state: "error",
					errorMsg:
						"Failed to start network test. Please check your internet connection!",
				})
			}
		}
	}

	public async cancelTest() {
		this.reset()
		closeOverlay()
	}
}

export const NetworkTestController = new NetworkTestControl()
