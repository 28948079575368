<script lang="ts">
	interface Props {
		title: string
		priceInfo: string | string[]
		children?: import("svelte").Snippet
	}

	let { title, priceInfo, children }: Props = $props()
</script>

<div class="subscription-option">
	<div class="flex-row option-heading">
		<h3 class="subscription-option-heading">{title}</h3>
		<div class="flex-column">
			{#if typeof priceInfo === "string"}
				<span class="price-info">{priceInfo}</span>
			{:else}
				{#each priceInfo as info}
					<span class="price-info">{info}</span>
				{/each}
			{/if}
		</div>
	</div>
	<div class="content">
		{@render children?.()}
	</div>
</div>

<style>
	.subscription-option {
		height: fit-content;
	}

	.subscription-option .option-heading.flex-row h3.subscription-option-heading {
		font-size: var(--h1);
		margin: 0;
	}

	.option-heading {
		justify-content: space-between;
		align-items: center;
	}

	.price-info {
		/* font-size: var(--h3); */
		line-height: 1em;
	}

	.price-info:last-of-type {
		color: var(--color-main);
	}

	.subscription-option {
		padding: 1rem;
		width: 100%;
		background-color: var(--color-bg-darker);
		border-radius: var(--br);
		box-shadow: var(--shadow-small);
		transition:
			transform var(--transition-duration),
			box-shadow var(--transition-duration);
		margin-bottom: 1rem;
	}

	.subscription-option:hover {
		transform: scale(1.025);
		box-shadow: var(--shadow-large);
	}
</style>
