<script lang="ts">
	import type { Device, DeviceRPi } from "luxedo-data"
	import { closeOverlay, openOverlay } from "svelte-comps/overlay"
	import ActionRollbackReport from "./RollbackReport.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { LuxedoRPC } from "luxedo-rpc"

	interface Props {
		device: DeviceRPi
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	async function verifyRollback() {
		return new Promise<void>((res, rej) => {
			const id = openOverlay(ActionRollbackReport, {
				heading: "Rollback Beta Update",
				classHeading: "no-underline",
				props: {
					postSubmit: () => {
						res()
						closeOverlay(id)
					},
				},
			})
		})
	}

	async function rollbackDevice() {
		setButtonsDisabled(true)
		try {
			await verifyRollback()

			Toast.text(
				"Rolling back firmware version... Please give up to 10 minutes before restarting your device."
			)

			await LuxedoRPC.api.deviceControl.device_rollback(device.id)
		} catch {
			console.error("Failed to rollback device")
		}
		setButtonsDisabled(false)
	}
</script>

<div class="action-container">
	<button
		class="outline-button"
		id="rollback-button"
		onclick={rollbackDevice}
		disabled={isDisabled}>Rollback beta update</button
	>
	<div class="action-info">
		Revert from the currently installed beta version to the latest release
		version.
	</div>
</div>

<style>
</style>
