<script lang="ts">
	interface Props {
		percentage: any
		width?: string
	}

	let { percentage = $bindable(), width = undefined }: Props = $props()
	let renderablePercent = $derived(Math.min(percentage, 1))
</script>

<div class="progress-bar" style="width: {width}">
	<div
		class="progress-bar-indicator"
		style="transform: translateX({renderablePercent * 100 - 100}%)"
	></div>
</div>

<style>
	.progress-bar {
		border-radius: var(--br);
		min-width: 10rem;
		height: 0.5rem;
		/* border: 1px solid var(--color-text); */
		margin: 1rem;
		overflow: hidden;
		background-color: var(--color-bg-dark);
	}

	.progress-bar-indicator {
		position: relative;
		border-radius: var(--br);
		background-color: var(--color-main);
		width: 100%;
		height: 100%;
		transform: translateX(-100%);
		transition: transform var(--transition-duration);
	}
</style>
