<script lang="ts">
	let { eventDetails } = $props();
</script>

<div>
	{#if eventDetails}
		<h3>Scheduled Event</h3>
		<div class="details">
			<ul>
				<li>
					<span class="label">Start: </span>
					{eventDetails.start}
				</li>
				<li>
					<span class="label">End: </span>
					{eventDetails.end}
				</li>
			</ul>
		</div>
	{/if}
</div>

<style>
	.details {
		margin-left: 1rem;
	}

	.details .label {
		color: var(--color-text);
	}
</style>
