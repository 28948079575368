<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="34" height="60" viewBox="0 0 34 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M29.5 55L4.5 30L29.5 5"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="8.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
</style>
