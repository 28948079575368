<script lang="ts">
	import { GroupAlignController } from "./GroupAlignController"
	import GroupAlignCanvas from "./GroupAlign_Canvas.svelte"
	import GroupAlignTutorial from "./GroupAlign_Tutorial.svelte"
	import GroupAlignInputs from "./inputs/GroupAlign_Inputs.svelte"
	import type { Component } from "../../../../../../types/SvelteTypes"
	import { onDestroy, onMount } from "svelte"
	import { GroupEditorController } from "../../GroupEditorController"

	let stepIndex: number = $state(0)
	interface Props {
		inputComponent?: Component
	}

	let { inputComponent = $bindable(undefined) }: Props = $props()

	GroupAlignController.subscribe((ctx) => {
		stepIndex = ctx.step

		if (stepIndex > 0) inputComponent = GroupAlignInputs
		else inputComponent = undefined
	})

	const STEPS = {
		0: GroupAlignTutorial,
		1: GroupAlignCanvas,
	}

	onMount(() => {
		GroupEditorController.Grid.activate("rainbow", false)
	})

	onDestroy(() => {
		GroupAlignController.setStep(0)
	})

	const SvelteComponent = $derived(STEPS[stepIndex])
</script>

<SvelteComponent />

<style>
</style>
