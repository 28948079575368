<script lang="ts">
	import { DataHandlerDevice, type Snapshot } from "luxedo-data"
	import { GroupCalibrationController } from "./GroupCalibrationController"
	import { ImageViewer } from "svelte-comps/comps"
	import { GroupEditorController } from "../../GroupEditorController"

	let snapshots: Array<Snapshot> = $state()

	GroupCalibrationController.subscribe((ctx) => {
		snapshots = ctx.snapshots
	})

	async function calibrationSuccess() {
		for (const snapshot of snapshots) {
			const device = DataHandlerDevice.get(snapshot.dev_id)
			await device.setDefaultSnapshot(snapshot.id)
		}

		GroupEditorController.next()
	}
</script>

<h3>Calibration Results</h3>
<p class="info">Verify the snapshots below and click "continue" to move onto the next step.</p>
<ImageViewer images={snapshots.map((snap) => snap.src)} />
<div id="step-buttons">
	<button id="back-step-button" class="link-button" onclick={() => GroupEditorController.back()}>
		Back
	</button>
	<button class="alt" onclick={() => GroupCalibrationController.calibrate()}>Retry</button>
	<button id="next-step-button" onclick={calibrationSuccess}> Continue </button>
</div>

<style>
	h3 {
		margin: 0;
		font-size: var(--h1);
	}

	p.info {
		color: var(--color-text);
		margin-top: 0;
	}

	button.alt {
		margin-right: 0.5rem;
	}
</style>
