<script lang="ts">
	import { PlusIcon } from "svelte-comps/icons"
	import { DragController } from "../../../../stores/DragContext"
	import LightshowBreakInput from "../../../reusable/input/LightshowBreakInput.svelte"
	import { LightshowBreak } from "luxedo-data"

	interface Props {
		breakObj: LightshowBreak;
		onBreakUpdate: (newTimespan: number) => void;
		lightshowIndex?: number;
		remove?: () => void;
	}

	let {
		breakObj,
		onBreakUpdate,
		lightshowIndex = undefined,
		remove = undefined
	}: Props = $props();

	let isDragging = false

	function onDragStart(e) {
		window.getSelection().removeAllRanges()
		isDragging = true
	}

	function onDragEnd(e) {
		isDragging = false
	}
</script>

<div class="lightshow-block">
	<div
		role="application"
		class="break-tile-container tile-container"
		ondragstart={DragController.onDragStart(breakObj, onDragStart, lightshowIndex)}
		ontouchstart={DragController.onDragStart(breakObj, onDragStart, lightshowIndex)}
		ondragend={DragController.onDragEnd(onDragEnd)}
		draggable={true}
	>
		<div class="tile break-tile">
			<LightshowBreakInput lightshowBreak={breakObj} onUpdate={onBreakUpdate} />
		</div>

		<div class="tile-label-container">
			<span class="tile-label"> Break </span>
		</div>
	</div>
	{#if remove}
		<button title="Delete" class="icon" onclick={remove}>
			<PlusIcon />
		</button>
	{/if}
</div>

<style>
	.break-tile-container {
		color: var(--color-text);
	}

	.tile.break-tile {
		border: none;
		text-align: center;
		line-height: var(--tile-width);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.tile.break-tile :global(.input-container span) {
		height: 1.5rem;
		line-height: 1.5rem;
	}

	button.icon {
		padding: 0;
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		z-index: 6;
	}

	button.icon :global(svg) {
		margin: 0;
		width: 1rem;
		height: 1rem;
		transform: rotate(45deg);
	}

	button.icon :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button.icon:hover :global(.svg-stroke),
	button.icon:focus-visible :global(.svg-stroke) {
		stroke: red;
	}
</style>
