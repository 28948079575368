import { Track } from "../tracks"
import {
	KeyframeList,
	LocalTimestamp,
	canAnimate,
	timestampAbsoluteToLocal,
} from "."
import { EditorAsset } from "../asset"
import { AnimationPreset } from "./presets/AnimationPreset"
import { KeyframeEdit } from "../lib/modes/KeyframeEdit"
import { Serializable, convertInstanceToObject } from "../modules/serialize"
import { EditorClass, getEditor } from ".."

/**
 * An extension to the general keyframe list which specializes it for animating properties of editor assets
 */
export class AnimatedProperty<T extends EditorAsset>
	extends KeyframeList
	implements Serializable
{
	//#region    ===========================			   Initialization				==============================

	enabled: boolean = true
	presets: {
		base: AnimationPreset<T>
		offset: AnimationPreset<T>[]
		multiplier: AnimationPreset<T>[]
	}

	animationName: string
	track: Track<T>

	property: T["AnimatableProperty"]
	initialValue: number
	initialEditValue: number // Use as a reference when in KeyframeEdit mode to get the initialValue before the canvas action is finalized

	editor: EditorClass

	/**
	 * Create a keyframed animation for an objects properties and bind them to a track
	 * This replaces the object's real numeric property with a getter/setter that gets the info from the keyframe list
	 * @param track
	 * @param object
	 * @param property
	 * @param animationName
	 */
	protected constructor(
		editor: EditorClass,
		track: Track,
		property: T["AnimatableProperty"]
	) {
		super()

		this.track = track
		this.editor = editor
		this.property = property
		this.initialValue = this.track.asset[property as string] || 0
		this.initialEditValue = this.initialValue

		this.presets = {
			base: undefined,
			multiplier: [],
			offset: [],
		}
	}

	public static Create<T extends EditorAsset>(
		editor: EditorClass,
		track: Track<T>,
		property: T["AnimatableProperty"]
	): AnimatedProperty<T> {
		if (!canAnimate(track.asset, property as string))
			throw TypeError(`Property ${property as string} cannot be animated`)

		const animatedProp = new this(editor, track, property)

		// Now bind the property to the target object
		Object.defineProperty(
			track.asset,
			property,
			animatedProp.genPropertyDescriptor()
		)

		// Set up event forwarding for keyframe events
		animatedProp.on("*", (e) => {
			e.track = track
			e.animatedProperty = animatedProp

			animatedProp.track.emitEvent("animation:update", {
				track: e.track,
				keyframeEvent: e,
			})
		})

		return animatedProp
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Interface 				==============================

	/**
	 * Apply an animation preset to this property
	 */
	applyPreset(preset: AnimationPreset<T>) {
		if (preset.type === "base") {
			if (this.presets.base)
				throw new TypeError(
					"Cannot assign multiple base presets to a single property"
				)

			this.presets.base = preset
		} else {
			this.presets[preset.type].push(preset)
		}

		this.refresh()
	}

	removePreset(preset: AnimationPreset<T>) {
		if (preset.type == "base" && this.presets.base === preset) {
			this.presets.base = undefined
			this.refresh()
			return
		}

		const i =
			this.presets[preset.type as "multiplier" | "offset"].indexOf(preset)
		if (i == -1) return

		this.presets[preset.type as "multiplier" | "offset"].splice(i, 1)
	}

	/**
	 * Get the value of this property at a given time or at the current timeline playback position
	 * @param time Optional, specify a time to animate
	 * @returns
	 */
	getValue(time?: number, alreadyLocalTime?: boolean) {
		// If the animation is disabled, we always use the initial value
		if (!this.enabled) return this.initialValue
		if (time === undefined) time = this.editor.timeline.currentTime

		const timeLocal = alreadyLocalTime
			? (time as LocalTimestamp)
			: timestampAbsoluteToLocal(time, this.track)
		if (timeLocal < 0 || timeLocal > 1) return this.initialValue

		// First calculate the base offset, either from base keyframes or from a preset which creates the base value
		let base = this.presets.base
			? this.presets.base.getValue(this.property, timeLocal)
			: this.interp(timeLocal)

		// Calculate any offset introduced by non-base presets
		let offset = 0
		for (const offsetPreset of this.presets.offset) {
			offset += offsetPreset.getValue(this.property, timeLocal)
		}

		// Finally apply any multiplier keyframes from presets
		let factor = 1
		for (const multiplierPreset of this.presets.multiplier) {
			factor *= multiplierPreset.getValue(this.property, timeLocal)
		}

		// Finally, we can calculate the output value
		return (this.initialValue + base + offset) * factor
	}

	enable() {
		this.enabled = true
	}

	disable() {
		this.enabled = true
	}

	//#endregion =====================================================================================================

	//#region    ===========================			   Object Bindings				==============================

	private genPropertyDescriptor(): PropertyDescriptor {
		const animatedProperty = this
		return {
			get() {
				return animatedProperty.getValue()
			},
			set(v) {
				const mode = getEditor().mode.get()

				if (!(mode instanceof KeyframeEdit)) {
					const post = animatedProperty.getValue()
					const offset = v - post
					animatedProperty.initialValue += offset
					animatedProperty.initialEditValue =
						animatedProperty.initialValue

					return
				}

				if (mode instanceof KeyframeEdit) {
					// If the editor is in KeyframeEdit mode, update the keyframes for the updated property rather than the initial value
					animatedProperty.set(
						mode.timestamp,
						v - animatedProperty.initialValue
					)
					// animatedProperty.refresh()
				}
			},
		}
	}

	//#endregion =====================================================================================================

	//#region 	 ===========================			   Serialization  				==============================

	getDefaultPropertyExports(): {
		include: string[]
		deepCopy: string[]
		exclude?: string[]
	} {
		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		return {
			include: expProps,
			deepCopy: [],
			exclude: [
				"track",
				"cachedTime",
				"cachedValue",
				"cacheIndex",
				"presets",
			],
		}
	}

	toObject(properties?: string[]): Partial<this> {
		const json = {}

		if (!properties) properties = this.getDefaultPropertyExports().include
		for (const prop of properties) {
			if (prop in this) json[prop] = this[prop]
			else
				throw new Error(
					`Unable to find ${prop} in ${this.constructor.name}.`
				)
		}

		return json
	}

	serialize(forExport?: boolean) {
		let json = {}

		if (forExport) {
			json = convertInstanceToObject(this, {
				forExport,
				propertyGetters: {
					flattenKeyframes: ["flattenedKeyframes"],
				},
			})
		} else {
			json = convertInstanceToObject(this, {})
		}

		console.warn(json)

		return json
	}

	/**
	 * Used for the backend in the rendering process
	 * Flattens this property's animations into a single list of keyframes that combines them all
	 */
	flattenKeyframes() {
		const allKeyframeTimes = new Set<number>([0, 1])

		const bases = this.presets.base
			? this.presets.base.keyframes[this.property].keyframes
			: this.keyframes

		for (const keyframe of bases) {
			allKeyframeTimes.add(keyframe.timestamp)
		}

		const nonBasePresets: AnimationPreset<T>[] = []
			.concat(this.presets.offset)
			.concat(this.presets.multiplier)
		for (const preset of nonBasePresets) {
			const keyframes = preset.keyframes[this.property].keyframes

			for (const keyframe of keyframes) {
				allKeyframeTimes.add(keyframe.timestamp)
			}
		}

		const orderedKeyframeTimes = Array.from(allKeyframeTimes).sort()
		const flattenedKeyframeList = new KeyframeList()

		for (const ts of orderedKeyframeTimes) {
			const timestamp = this.constrainTimestamp(ts)
			const val = this.getValue(timestamp, true)
			flattenedKeyframeList.insert(timestamp, val)
		}

		return flattenedKeyframeList.toArray()
	}

	constrainTimestamp = (ts: number) => {
		return Math.min(Math.max(0, ts), 1)
	}

	//#endregion =========================================================================================
}
