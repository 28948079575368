import { v4 as uuid } from "uuid"

/**
 * @file /entry/Entry.ts
 * @author Austin Day
 * An abstract class for bringing rows from a remote database into the client-side in a consistent way that prioritizes
 * conceptual simplicity
 */

import objectHash from "object-hash"
import deepCopy from "deepcopy"
import type { EntryID, EntryRawData } from "../types"

export abstract class Entry<RawData extends EntryRawData> {
	/** All entries will have a unique ID */
	public declare id?: EntryID

	/**
	 * Builds an instance of the entry:
	 * - Call super() to assign the ID and loads all the data into its originalData model and freezes it
	 * - Assign any parameters that are important to the class
	 *
	 * @param rawEntryData Raw data from the datahandler's fetch function
	 */
	constructor(rawEntryData: RawData) {
		this.id = rawEntryData.id

		this.setRawData(rawEntryData)
	}

	//#region    =========================	   				Readonly Data				==============================

	/**
	 * A read-only copy of the original data that was used to build the entry
	 *
	 * If a property of the bundled data is important enough to need to access it outside of the class, add it as its
	 * 	own public property or add a function which returns the data needed.
	 *
	 * For minor values that are only needed in the class scope, use this.readModel()
	 *
	 * If a read-only property needs to be publicly accessed but feels too minor to be worth cluttering the class
	 * 		with properties or functions, ask yourself it the data in question is appropriate for the use case.
	 * 		It may be worth changing the bundle on the backend.
	 */
	private originalData: RawData

	/**
	 * Read from the originalData table.
	 * This should only be used for minor data passed from the bundler which will only be read in the context of
	 * 	this class.
	 * @param bundlePropertyName A key from the bundled data format
	 * @returns The original, unaltered value from the database
	 */
	protected readModel<K extends keyof RawData>(
		bundlePropertyName: K
	): RawData[K] {
		return this.originalData[bundlePropertyName]
	}

	//#endregion =====================================================================================================

	//#region    =========================		  JSON conversions for data layer		==============================

	/**
	 * Do any conversions/exporting needed from this class for it to be ready to bundle up
	 * @returns key/value pairs for all RawData values that may have changed (ideally all values that NOT read only)
	 */
	protected abstract exportData(): Partial<RawData>

	/**
	 * Set all properties needed on the front-end here. This is called anytime the data changes, or a new entry is created.
	 */
	protected abstract importData(data: RawData): void

	public setRawData(data: RawData) {
		this.originalData = data
		this.importData(deepCopy(data))
		this.triggerUpdateCallbacks()
	}

	/**
	 * Bundles up data for the datahandler to munch on
	 * Not intended to be overloaded. See exportData()
	 * @returns
	 */
	public toBundle(): Partial<RawData> {
		const bundleOut = this.exportData()

		/* Iterate through the original data and assign it to the bundle */
		for (const colName of Object.getOwnPropertyNames(this.originalData)) {
			if (!(colName in bundleOut))
				bundleOut[colName] = this.originalData[colName]
		}

		return bundleOut
	}

	//#endregion =====================================================================================================
	//#region    =========================			 Hashing & Save State 				==============================

	/**
	 * Hashing functionality used to detect changes in the contents, used for data synchronization
	 */
	public hash(): string {
		return Entry.hashRawData(this.toBundle())
	}

	static hashRawData<RawData extends EntryRawData>(rawData: RawData) {
		return objectHash(rawData, {
			replacer: (val) => {
				return val === undefined ? null : val
			},
		})
	}

	/** Property which tells if this entry is modified */
	public get dirty(): boolean {
		return this.hash() != Entry.hashRawData(this.originalData)
	}

	//#endregion =====================================================================================================

	protected statusListeners: {
		[index: string]: Function
	} = {}

	/**
	 * Calls each status listener with this entry's data
	 */
	protected triggerUpdateCallbacks() {
		Object.values(this.statusListeners).forEach((fn) => {
			fn(this)
		})
	}

	/**
	 * Add a status update listener to be called on entry updates
	 * @param updater the fn to be called on an entry update
	 * @returns the id of the update listener
	 */
	public addUpdateListener(updater: (entry: this) => void): string {
		const id = uuid()
		this.statusListeners[id] = updater
		updater(this)
		return id
	}

	/**
	 * Removes an update listener added in addUpdateListener
	 * @param id the id of the listener
	 * @returns void
	 */
	public removeUpdateListener(id: string) {
		if (!id) return
		if (!(id in this.statusListeners)) {
			return console.error(
				`${id} cannot be found in device status listeners`
			)
		}
		delete this.statusListeners[id]
	}
}
