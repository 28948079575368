<script lang="ts">
	import LuxLinkLogo from "../../../../reusable/icons/LuxLinkLogo.svelte"
	import SubmenuHeading from "../SubmenuHeading.svelte"
	import { AccountNavigator } from "../../AccountStore"
	import SubscriptionOption from "../SubscriptionOption.svelte"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import type { ChargebeePlanPrice } from "luxedo-rpc/src/routes/chargebee"
	import { LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"

	let priceOptions = ChargebeeManager.getPlanPricing("Phantasm")
	/**
	 * If monthly, returns the formatted price; if annual, returns an array of yearly and monthly prices
	 */
	function getPlanPriceInfo(price: ChargebeePlanPrice) {
		if (price.period_unit === "month") {
			return `${ChargebeeManager.formatPrice(price.price, price.currency_code)} / month`
		} else {
			return [
				`${ChargebeeManager.formatPrice(price.price, price.currency_code)} / year`,
				`${ChargebeeManager.formatPrice(price.price / 12, price.currency_code)} / month`,
			]
		}
	}
</script>

<div id="luxlink-subscriber">
	<SubmenuHeading onBack={() => AccountNavigator.closeSubscription()}>
		<div class="flex-column">
			<LuxLinkLogo />
			<span class="description"
				>Plans differ in pricing but both provide the same functionality</span
			>
		</div>
	</SubmenuHeading>
	<div class="subscription-options">
		{#each priceOptions as price}
			<SubscriptionOption
				title="{ChargebeeManager.getPlanFrequency(price)} Plan"
				priceInfo={getPlanPriceInfo(price)}
			>
				<p>
					{price.period_unit === "month"
						? "Great for one-off events and individual holidays!"
						: "Cost-effective plan for events and holidays year round!"}
				</p>
				<button
					class="outline-button {price.period_unit === 'month' ? 'alt' : ''}"
					onclick={() => LuxLinkSubscriptionManager.selectPlan(price)}>Subscribe</button
				>
			</SubscriptionOption>
		{/each}
	</div>
</div>

<style>
	.subscription-options {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.description {
		font-size: var(--h3);
	}

	p {
		font-size: var(--h3);
	}
</style>
