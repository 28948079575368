<script lang="ts">
	import { run } from "svelte/legacy"

	import type { Media } from "luxedo-data"
	import { MediaTrimController } from "../MediaToolController"
	import TimeInput from "../base/MediaVideo_TimeInput.svelte"

	interface Props {
		media: Media
	}

	let { media }: Props = $props()
	let timestamp: number
	let timestampPercent: number = $state()

	let trimStart: number
	let trimEnd: number

	let updatedTrimStart: number = $state()
	let updatedTrimEnd: number = $state()

	let trimStartPercent: number = $state()
	let trimEndPercent: number = $state()

	let backgroundElem: HTMLDivElement = $state()

	let updateStartTimeValues: (duration: number) => void = $state()
	let updateEndTimeValues: (duration: number) => void = $state()

	MediaTrimController.subscribe((ctx) => {
		trimStart = ctx.trimStart
		trimEnd = ctx.trimEnd
		timestamp = ctx.timestamp
		timestampPercent = ctx.timestampPercent
		trimStartPercent = ctx.trimStartPercent
		trimEndPercent = ctx.trimEndPercent

		if (updateStartTimeValues) updateStartTimeValues(trimStart)
		if (updateEndTimeValues) updateEndTimeValues(trimEnd)
	})

	function handleMouseDown(event: MouseEvent & { currentTarget: HTMLDivElement }) {
		const target = event.currentTarget

		const moveHandler = (e: MouseEvent) => {
			if (e.target !== target) return
			const percentPos = e.offsetX / target.clientWidth
			MediaTrimController.skipToDurationPercent(percentPos)
		}

		moveHandler(event)

		document.addEventListener("mousemove", moveHandler)
		document.addEventListener("mouseup", () => {
			document.removeEventListener("mousemove", moveHandler)
		})
	}

	function handleControlBarMouseDown(
		isStart: boolean,
		e: MouseEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		const handler = (e: MouseEvent) => {
			const relativeX = e.clientX - backgroundElem.getBoundingClientRect().left
			const positionPercent = relativeX / backgroundElem.clientWidth
			if (isStart) MediaTrimController.setTrimStartPercent(positionPercent)
			else MediaTrimController.setTrimEndPercent(positionPercent)
		}

		handler(e)

		document.addEventListener("mousemove", handler)
		document.addEventListener("mouseup", () => {
			document.removeEventListener("mousemove", handler)
		})
	}

	function updateTrimStart() {
		if (updatedTrimStart !== trimStart) MediaTrimController.setTrimStart(updatedTrimStart)
	}

	function updateTrimEnd() {
		if (updatedTrimEnd !== trimEnd) MediaTrimController.setTrimEnd(updatedTrimEnd)
	}

	function setStartAsPreview() {
		MediaTrimController.setTrimStart(timestamp)
	}

	function setEndAsPreview() {
		MediaTrimController.setTrimEnd(timestamp)
	}

	run(() => {
		updatedTrimStart && updateTrimStart()
	})
	run(() => {
		updatedTrimEnd && updateTrimEnd()
	})
</script>

<div id="media-timeline">
	<div id="timeline-background" bind:this={backgroundElem} onmousedown={handleMouseDown}>
		<div id="timestamp" style="left: {timestampPercent * 100}%"></div>
	</div>
	<div
		id="control-bar"
		style="margin-left: {trimStartPercent * 100}%; margin-right:{(1 - trimEndPercent) *
			100}%; width: {(trimEndPercent - trimStartPercent) * 100}%;"
	>
		<div
			class="draggable-trimmer"
			id="control-bar-start"
			onmousedown={(e) => handleControlBarMouseDown(true, e)}
		>
			<!-- <DragArrowIcon isForward={true} /> -->
		</div>

		<div
			class="draggable-trimmer"
			id="control-bar-end"
			onmousedown={(e) => handleControlBarMouseDown(false, e)}
		>
			<!-- <DragArrowIcon isForward={false} /> -->
		</div>
	</div>
</div>
<div class="time-inputs">
	<div class="time-container">
		<TimeInput
			label="Start Time"
			bind:duration={updatedTrimStart}
			bind:updateDuration={updateStartTimeValues}
			{media}
		/>
		<button class="small outline-button" onclick={setStartAsPreview}>Use Preview Time</button>
	</div>
	<div class="time-container">
		<TimeInput
			label="End Time"
			bind:duration={updatedTrimEnd}
			bind:updateDuration={updateEndTimeValues}
			{media}
		/>
		<button class="small outline-button" onclick={setEndAsPreview}>Use Preview Time</button>
	</div>
</div>

<style>
	#media-timeline {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		width: 100%;
		position: relative;
		height: 2rem;
	}

	#control-bar {
		height: 2rem;
		background-color: var(--color-main-transparent);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: absolute;
		width: 100%;
		top: 0;
		pointer-events: none;
		--control-bar-width: 1rem;
		border-radius: var(--br);
	}

	.draggable-trimmer {
		height: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: grab;
		pointer-events: all;
		width: var(--control-bar-width);
	}

	.draggable-trimmer :global(svg) {
		z-index: 1;
	}

	#control-bar-start {
		background: linear-gradient(90deg, var(--color-main-transparent), transparent);
		position: relative;
		border-top-left-radius: var(--br);
		border-bottom-left-radius: var(--br);
	}

	#control-bar-end {
		background: linear-gradient(-90deg, var(--color-main-transparent), transparent);
		position: relative;
		border-top-right-radius: var(--br);
		border-bottom-right-radius: var(--br);
	}

	#timestamp {
		top: 0;
		position: absolute;
		width: 1px;
		background-color: var(--color-main);
		height: 2rem;
	}

	#timeline-background {
		width: 100%;
		height: 100%;
		position: relative;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
	}

	.time-inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-grow: 1;
		margin-bottom: 0.5rem;
	}

	.time-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.time-container :global(.input-container) {
		margin: 0;
	}

	.time-container button {
		margin-top: 0.5rem;
	}
</style>
