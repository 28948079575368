<script lang="ts">
	import { DataHandlerFolder, Media, Folder } from "luxedo-data"
	import { FolderIcon } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import { DragController } from "../../../../stores/DragContext"
	import { FileSystemMedia } from "../../../../modules/file-system/FileSystemMedia"
	import { TileLabel } from "svelte-comps/luxedo"
	import { initContextMenu, type ContextMenuOptions } from "svelte-comps/context-menu"

	interface Props {
		folder: Folder
		onClick: (folder: Folder) => void
	}

	let { folder, onClick }: Props = $props()

	let triggerRename: () => void = $state()

	const generateMoveToOptions = () => {
		const submenuOpts = []
		const parent = DataHandlerFolder.get(folder.parent_id)
		const parentRoot = DataHandlerFolder.get(parent.parent_id)
		if (parentRoot) {
			submenuOpts.push({
				title: parentRoot.name === "froot_media" ? "My Media" : parentRoot.name,
				// @ts-ignore
				onClick: async () => await FileSystemMedia.move(folder, parentRoot),
			})
		}
		const availFolders = DataHandlerFolder.getByFolder(folder.parent_id).map((newFolder) => {
			if (newFolder.id === folder.id) return null
			return {
				title: newFolder.name,
				// @ts-ignore
				onClick: async () => await FileSystemMedia.move(folder, newFolder),
			}
		})
		return submenuOpts.concat(availFolders).filter((folder) => !!folder)
	}

	const generateCtxMenuOpts = (): ContextMenuOptions => {
		return [
			{
				title: "Rename",
				onClick: () => triggerRename(),
			},
			{
				title: "Move to",
				hasSubmenu: true,
				submenu: generateMoveToOptions(),
			},
			{
				title: "Delete",
				onClick: async () => {
					try {
						await DataHandlerFolder.deleteEntry(folder)
						Toast.success("Folder deleted.")
					} catch (e) {
						console.error("Error deleting folder", e)
						Toast.error("There was an error deleting this folder, please refresh and try again.")
					}
				},
			},
		]
	}

	let isDraggingOver = false
	let isDragging = $state(false)

	function onDragStart() {
		isDragging = true
	}

	function onDragEnd() {
		isDragging = false
	}

	function onDragLeave(e: DragEvent) {
		isDraggingOver = false
	}

	function onDragOver(e: DragEvent) {
		if (!(DragController.dragContent instanceof Media)) return
		isDraggingOver = true
	}

	async function onRename(newName: string) {
		try {
			await DataHandlerFolder.rename(folder, newName)
			Toast.success("Folder renamed.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error("There was an error renaming this folder, please try again.")
		}
	}

	async function onDrop(e: DragEvent, content: Media | Folder) {
		if (!(content instanceof Media || content instanceof Folder)) return
		if (content === folder) return

		try {
			// @ts-ignore
			await FileSystemMedia.move(content, folder)
			Toast.success("Media successfully relocated.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error("Unable to move content, please try again.")
		}
	}
</script>

<div
	class="tile-container {isDragging ? 'is-dragging' : ''}"
	id="folder-tile-{folder.id}"
	role="application"
	ondragend={DragController.onDragEnd(onDragEnd)}
	ondragstart={DragController.onDragStart(folder, onDragStart)}
	ontouchstart={DragController.onDragStart(folder, onDragStart)}
	ondragleave={onDragLeave}
	ondragover={DragController.onDragOver(onDragOver)}
	ondrop={DragController.onDrop(onDrop)}
	onkeydown={undefined}
	ondblclick={() => onClick(folder)}
	onclick={() => onClick(folder)}
	oncontextmenu={(e) => initContextMenu(generateCtxMenuOpts())(e)}
	draggable={true}
>
	<div class="tile">
		<FolderIcon strokeColor="var(--color-text)" />
	</div>
	<div class="tile-label-container">
		<TileLabel file={folder} {onRename} bind:triggerRename />
	</div>
</div>

<style>
	.tile-container:global(.focused) .tile {
		border-color: var(--color-main);
		border-width: 4px;
	}

	.tile {
		user-select: none;
		display: flex;
		align-items: center;
		justify-content: center;
		transition:
			border-color 250ms,
			border-width 250ms;
	}

	.tile :global(svg) {
		width: 90%;
		height: 90%;
	}
</style>
