<script lang="ts">
	import { preventDefault } from 'svelte/legacy';

	import AnimatedBackground from "../../reusable/AnimatedBackground.svelte"
	import Logo from "../../../assets/icons/LuxedoLogo.svg"
	import { TextRowSelector } from "svelte-comps/inputs"
	import LoginForm from "./LoginForm.svelte"
	import RegisterForm from "./RegisterForm.svelte"
	import { Tooltip } from "svelte-comps/tooltip"
	import { Toast, Toaster } from "svelte-comps/toaster"
	import { LuxedoRPC } from "luxedo-rpc"
	import { navigateTo } from "../../../stores/NavigationContext"
	import { pageLoader } from "../../../modules/auth/onUserLogin"
	import { onMount } from "svelte"

	const LOGIN = "Login with existing account"
	const REGISTER = "Create an account"

	let selectedOption = $state(LOGIN)
	let isLoading = $state(false)

	let firstName: string = $state()
	let lastName: string = $state()
	let email: string = $state()
	let password: string = $state()
	let passwordConfirm: string = $state()

	let isLoadingCaptcha = $state(true)
	let captcha: string = $state()

	function failed(text: string) {
		isLoading = false
		captcha = undefined
		Toast.error(text)

		grecaptcha?.reset()
	}

	async function login() {
		isLoading = true

		await pageLoader.initRPC()

		if (!email) return failed("Email is required.")
		if (!password) return failed("Password is required.")

		try {
			await LuxedoRPC.api.login.login_login(email, password, "")
			postLogin()
		} catch (e) {
			console.error("Failed to log in", e)
			return failed("Invalid email or password, please try again.")
		}
	}

	async function register() {
		isLoading = true

		await pageLoader.initRPC()

		if (!email) return failed("Email is required.")
		if (!password) return failed("Password is required.")
		if (!firstName) return failed("First name is required.")
		if (!lastName) return failed("Last name is required.")
		if (password !== passwordConfirm) return failed("Passwords must match!")

		try {
			await LuxedoRPC.api.login.signup_signup(firstName, lastName, email, password, captcha)
			postLogin()
		} catch (e) {
			if (e.statusCode === 705) return failed("CAPTCHA verification failed. Please try again.")
			if (e.statusCode === 704)
				return failed(
					"Passwords must be at least 8 characters long with at least one uppercase and one lowercase letter."
				)
			if (e.statusCode === 403) return failed("Email already in use.")

			return failed("Failed to create an account at this time, please refresh and try again.")
		}
	}

	function postLogin() {
		navigateTo("projector")
	}

	onMount(() => {
		//
		document.addEventListener("loadedcaptcha", () => {
			isLoadingCaptcha = false
		})
	})
</script>

<svelte:head>
	<script type="text/javascript">
		// Trigger event which will be listened to in order to update captcha loading state (see above)
		var onloadCallback = function () {
			document.dispatchEvent(new Event("loadedcaptcha"))
		}
	</script>

	<script
		src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
		async
		defer
	>
	</script>
</svelte:head>

<form
	class="card-container"
	style="--card-width: {selectedOption == LOGIN ? 24 : 33}rem; --card-height: {selectedOption ==
	LOGIN
		? '34rem'
		: '36rem'}"
	onsubmit={preventDefault(selectedOption === LOGIN ? login : register)}
>
	<img id="luxedo-logo" src={Logo} alt="Luxedo Logo" />
	<TextRowSelector options={[LOGIN, REGISTER]} bind:selectedOption fontSize="var(--h3)" />

	<div class="form-container">
		{#if selectedOption == REGISTER}
			<RegisterForm
				bind:email
				bind:password
				bind:passwordConfirm
				bind:firstName
				bind:lastName
				bind:CAPTCHA={captcha}
			/>
		{:else}
			<LoginForm bind:email bind:password />
		{/if}
	</div>

	<!-- Just in case we get ANYONE saying they don't know how to create an account -->
	<!-- <button class="link-button">Need an account?</button> -->

	<button
		id="login-button"
		class="outline-button"
		type="submit"
		disabled={isLoading || (selectedOption == REGISTER && (isLoadingCaptcha || !captcha))}
		>{selectedOption == LOGIN ? "Login" : "Create Account"}</button
	>
	<div class="link-footer">
		<a target="_blank" href="https://www.luxedo.com">Luxedo Store</a>
		<a target="_blank" href="https://www.luxedo.com/pages/help">Contact Us</a>
		<a target="_blank" href="https://www.luxedo.com/pages/terms-of-conditions">Terms of Service</a>
	</div>
</form>

<style>
</style>
