<script lang="ts">
	import {
		DataHandlerDevice,
		DeviceBrowser,
		DeviceGroup,
		DeviceRPi,
		GroupConflictError,
	} from "luxedo-data"
	import { ProjectorMenuController } from "./ProjectorMenuController.svelte"
	import { launchLuxLink } from "./LuxLinkLauncher"
	import { Toast } from "svelte-comps/toaster"
	import { asyncConfirmOverlay } from "svelte-comps/overlay"
	import { Reactivity } from "svelte-comps/reactivity"
	import DeviceIcon from "../../reusable/icons/DeviceIcon.svelte"
	import { openDeviceColorPicker } from "./device-color-picker/DeviceColorPickerContext"
	import DeviceColorPicker from "./device-color-picker/DeviceColorPicker.svelte"

	let deviceIconElem: SVGElement = $state()
	let childDevices: Array<DeviceRPi> = $derived.by(() => {
		if (!(device instanceof DeviceGroup)) return []
		return device.getChildDevices()
	})

	let device = $derived(ProjectorMenuController.ctx.device)

	$effect.pre(() => {
		Reactivity.useProps(device, ["name", "status", "statusColor", "color"])
	})

	let deviceColor = $derived(Reactivity.propOf(device, "color"))
	let deviceName = $derived(Reactivity.propOf(device, "name"))
	let deviceStatus = $derived(Reactivity.propOf(device, "status"))
	let deviceStatusColor: string = $derived(
		Reactivity.propOf(device, "statusColor")
	)

	// #region Actions

	/**
	 * Opens the color input component, providing a method to update the device color when changed
	 */
	function openColorInput() {
		async function onColorChange(colorIndex: number) {
			device._color = colorIndex
			await DataHandlerDevice.push([device])

			ProjectorMenuController.setDevice(device)
		}

		openDeviceColorPicker(device, deviceIconElem, onColorChange)
	}

	/**
	 * Reactivates the selected group by deactivating active conflicting groups
	 */
	async function reactivateGroup() {
		if (!(device instanceof DeviceGroup)) return

		let conflictingGroups: DeviceGroup[] = []

		// Try to activate the group
		try {
			await device.activate()
		} catch (e) {
			// If it fails with a group conflict, get the list of conflicting groups so we can resolve it
			if (e instanceof GroupConflictError) {
				if (!e.resolvable) {
					Toast.error(
						"This group shares devices with an active group which you do not own."
					)
					return
				}

				conflictingGroups = e.conflictingGroups
				console.warn({ conflictingGroups })
			} else {
				throw e
			}
		}

		// Resolve the group conflict if there are any
		if (conflictingGroups.length > 0) {
			try {
				await asyncConfirmOverlay({
					title: "Group Conflict",
					prompt: [
						`One or more devices in this group are shared by another active group.\n`,
						`By continuing, the following groups will be deactivated:`,
						conflictingGroups.map((group) => `<b>${group.name}</b>`).join("\n"),
						"Are you sure you want to continue?",
					],
					confirmText: "Continue",
					denyText: "Cancel",
				})
			} catch (e) {
				return
			}

			// Deactivate the conflicting groups
			for (let group of conflictingGroups) {
				await group.deactivate()
			}

			await device.activate()
		}

		Toast.success(
			`Group ${device.isDeactivated ? "deactivated" : "activated"} successfully!`
		)
	}

	// #endregion
</script>

<div id="device-preview">
	<div id="device-title-info" class="flex-row">
		<DeviceColorPicker />
		<div class="icon-container">
			<DeviceIcon
				{device}
				onclick={openColorInput}
				bind:iconElem={deviceIconElem}
			/>
			<div class="icon-bg"></div>

			{#if device instanceof DeviceGroup && childDevices.length === device.children.length}
				{#each childDevices as d}
					<div
						style="background-color: {d && 'color' in d ? d.color : ''}"
						class="icon-bg-color"
					></div>
				{/each}
			{:else}
				<div
					style="background-color: {deviceColor}"
					class="icon-bg-color"
				></div>
			{/if}
		</div>
		<div class="flex-column device-title-row">
			<h1 id="device-name">{deviceName}</h1>
			<span class="projector-status">
				<div
					class="projector-status-indicator"
					style="background-color: {deviceStatusColor};"
				></div>
				{deviceStatus}
			</span>
		</div>
	</div>

	<div class="device-action-container">
		{#if device instanceof DeviceGroup && device.isDeactivated}
			<button
				id="group-reactivate"
				class="outline-button"
				onclick={reactivateGroup}
			>
				Reactivate
			</button>
		{:else if device instanceof DeviceBrowser && !DeviceBrowser.ONLINE_STATUSES.includes(device._status)}
			<button
				id="LuxLink-launcher"
				class="outline-button"
				onclick={() => launchLuxLink(device)}>Start LuxLink</button
			>
		{/if}
	</div>
</div>

<style>
	button {
		font-size: var(--h1);
	}

	.projector-status {
		line-height: 1rem;
		color: var(--color-text);
		display: flex;
		align-items: center;
	}

	#device-preview {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	#device-title-info {
		flex-shrink: 1;
		min-width: 0;
	}

	.device-title-row {
		overflow: hidden;
	}

	.projector-status-indicator {
		margin-right: 0.5rem;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 100%;
	}

	h1#device-name {
		font-size: 3rem;
		color: var(--color-text-light);
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.device-action-container {
		display: flex;
		align-items: center;
	}

	button {
		margin-left: 1rem;
		text-wrap: nowrap;
	}
</style>
