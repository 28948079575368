import { Toast } from "svelte-comps/toaster"
import { get, writable } from "svelte/store"
import { Device } from "../../entries/Device"
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"

type SpotlightCTX = { [index: string]: boolean }

export namespace DeviceSpotlightManager {
	const store = writable<SpotlightCTX>({})

	export function initialize() {
		const updateStatus = (device: Device) => {
			store.update((ctx) => ({
				...ctx,
				[device.id]: device.isSpotlightActive(),
			}))
		}

		const devices = DataHandlerDevice.getMany()
		for (const device of devices) {
			updateStatus(device)
			device.addUpdateListener((dev) => {
				updateStatus(dev)
			})
		}
	}

	export function subscribe(cb: (ctx: SpotlightCTX) => void) {
		return store.subscribe(cb)
	}

	export function getSpotlightStatus(device: Device) {
		if (!device) return false
		return get(store)[device.id]
	}

	export async function deactivateSpotlight(device: Device): Promise<void> {
		try {
			await device.deactivateSpotlight()
			store.update((ctx) => {
				return {
					...ctx,
					[device.id]: false,
				}
			})
			Toast.success("Projector spotlight deactivated.")
		} catch (e) {
			Toast.error(
				"Unable to deactivate spotlight, please refresh and try again. "
			)
			console.error(
				`Unable to deactivate spotlight for device <${device.id}|${device.name}>.`,
				e
			)
		}
	}

	export async function activateSpotlight(device: Device): Promise<void> {
		try {
			await device.activateSpotlight()
			store.update((ctx) => {
				return {
					...ctx,
					[device.id]: true,
				}
			})
			Toast.success("Projector spotlight activated.")
		} catch (e) {
			Toast.error(
				"Unable to activate spotlight, please refresh and try again. "
			)
			console.error(
				`Unable to activate spotlight for device <${device.id}|${device.name}>.`,
				e
			)
		}
	}
}
