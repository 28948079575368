<script lang="ts">
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import LuxLinkLogo from "../../../../reusable/icons/LuxLinkLogo.svelte"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"
	import { DataHandlerUser, User } from "luxedo-data"
	import { UserStore, getCurrentUser } from "../../../../../stores/UserStore"
	import { TimeUtils } from "../../../../../modules/TimeUtils"
	import { DateTime } from "luxon"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import CancelButton from "../CancelButton.svelte"
	import { onMount } from "svelte"
	import { LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"
	import { AccountNavigator } from "../../AccountStore"

	const LUXLINK_DEMO_LENGTH = 3600 * 24 * 3
	// const LUXLINK_DEMO_MAX_PROJECTS = 3
	// const LUXLINK_DEMO_MAX_PROJECT_LENGTH = 30

	let user: User

	let isPlanActive: boolean = $state(false)
	let allowedLuxLinkAmount: number = $state(0)

	let isPlanCanceled: boolean = $state(false)
	let planEndDate: string = $state()

	let isDemoActive: boolean = $state(false)
	let demoRemainingTime: string = $state()

	UserStore.subscribe((u) => {
		user = u

		if (u.is_demo) {
			isDemoActive = true
			const demoRemainingDate = DateTime.fromSeconds(user.demo_remaining_time)
			const now = DateTime.local()
			const secDiff = now.diff(demoRemainingDate).as("seconds")
			demoRemainingTime = TimeUtils.secondsToReadableTime(LUXLINK_DEMO_LENGTH - secDiff)
		} else {
			isDemoActive = false
			demoRemainingTime = undefined
		}

		if (u.chargebee.luxlinkPlan) {
			isPlanActive = true

			allowedLuxLinkAmount = 2
			if (u.chargebee.luxlinkPlan.addon) {
				allowedLuxLinkAmount += u.chargebee.luxlinkPlan.addon.quantity
			}
			const cancelStatus = ChargebeeManager.getCancelStatus(u.chargebee.luxlinkPlan)
			if (cancelStatus) {
				isPlanCanceled = cancelStatus.cancelled
				planEndDate = cancelStatus.endDateString
			}
		}
	})

	async function activateDemo() {
		try {
			await LuxedoRPC.api.all.luxlink_demo_enable()
			await DataHandlerUser.pull([user.id])

			Toast.success("LuxLink demo activated!")
		} catch (e) {
			console.error("[ERROR] ", e)
			Toast.error("Unable to enable LuxLink demo at this time. Please try again. ")
		}
	}

	function getDemoClick() {
		openConfirmOverlay({
			prompt: [
				"If you'd like to give LuxLink a try before purchasing, activate our demo below.",
				"To learn more about using LuxLink, check out our getting started tutorial by clicking 'Learn More' below.",
			],
			buttons: {
				deny: {
					text: "Learn More",
					onClick: () =>
						window
							.open(
								"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fgetting-started-with-luxlink-629149",
								"_blank"
							)
							.focus(),
				},
				confirm: {
					text: "Activate Demo",
					onClick: activateDemo,
				},
			},
		})
	}

	function onCancelClick() {
		AccountNavigator.openSubscription()
		LuxLinkSubscriptionManager.navigateTo("Cancel")
	}

	function upgradeExistingSubscription() {
		AccountNavigator.openSubscription()
		LuxLinkSubscriptionManager.navigateTo("Additional Projectors")
	}

	onMount(() => LuxLinkSubscriptionManager.navigateTo("Subscriber"))
</script>

<h3>LuxLink Subscription</h3>
<div class="flex-row">
	<div id="subscribe-menu">
		<LuxLinkLogo />
		<p>Utilize our state of the art software with your own projector and camera.</p>
		{#if !isPlanActive}
			<button class="outline-button" onclick={() => AccountNavigator.openSubscription()}>
				Subscribe Now
			</button>
		{:else}
			<button class="outline-button" onclick={() => upgradeExistingSubscription()}>
				Upgrade Plan
			</button>
		{/if}
	</div>

	{#if !isDemoActive && !isPlanActive}
		<div id="demo-menu">
			<h3>Try It Free!</h3>
			<p>Try our software free for 3 days including:</p>
			<ul>
				<li>3 free scenes</li>
				<li>2 free LuxLink devices</li>
				<li>Create projection mapping of any space!</li>
			</ul>
			<div class="button-container">
				<button id="get-demo-button" class="small" onclick={getDemoClick}>Get the demo</button>
			</div>
		</div>
	{/if}
</div>
<div id="current-plan">
	<div class="flex-row">
		<span class="current-plan-label"> Current plan: </span>

		{#if isPlanActive}
			{#if isPlanCanceled}
				LuxLink Access (Cancelled)
			{:else if planEndDate}
				LuxLink Access (Ends {planEndDate})
			{:else}
				LuxLink Access
				<CancelButton title="Cancel Plan" onClick={() => onCancelClick()} />
			{/if}
		{:else if demoRemainingTime}
			Demo ({demoRemainingTime} remaining)
			<CancelButton title="Cancel Demo" onClick={() => onCancelClick()} />
		{:else}
			None
		{/if}
	</div>
	{#if isPlanActive}
		<span class="current-plan-label"> Allowed LuxLink Sessions: </span>
		{allowedLuxLinkAmount}
	{/if}
</div>

<style>
	div.flex-row div {
		flex-basis: 50%;
	}

	#subscribe-menu {
		border-radius: var(--br);
		background-color: var(--color-bg-darker);
		padding: 1rem;
		margin-right: 0.5rem;
		flex-grow: 1;
	}

	span {
		font-size: var(--h2);
		color: var(--color-text);
	}

	#demo-menu {
		margin-left: 0.5rem;
		padding: 0 1rem;
	}

	.flex-row p,
	.flex-row ul {
		font-size: var(--h3);
	}

	#demo-menu h3 {
		font-size: var(--h1);
		color: var(--color-main);
	}

	#demo-menu ul {
		list-style-type: disc;
		margin-left: 1rem;
	}

	.button-container {
		margin: 1rem 0 0 0;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
	}

	.button-container button {
		font-size: var(--h2);
	}

	.current-plan-label {
		margin-right: 1rem;
	}

	#current-plan {
		position: absolute;
		bottom: 1rem;
	}

	#current-plan .flex-row {
		align-items: center;
	}
</style>
