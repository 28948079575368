type PixelData = any

export class BackgroundRemoverCanvas {
	canvas: HTMLCanvasElement
	media: HTMLImageElement | HTMLVideoElement
	intermediateCanvas: HTMLCanvasElement
	options: {
		hueThreshold: number
		satThreshold: number
		valThreshold: number
		chromaKeyColor: string
		key: {
			H: number
			S: number
			V: number
		}
	}

	constructor(
		media: HTMLVideoElement | HTMLImageElement,
		canvas: HTMLCanvasElement
	) {
		this.media = media
		this.canvas = canvas
		this.intermediateCanvas = document.createElement("canvas")
		this.options = {
			hueThreshold: 30,
			satThreshold: 10,
			valThreshold: 40,
			chromaKeyColor: "#000000",
			key: this.convertRGBtoHSV(0, 0, 0),
		}
	}

	set hueThreshold(newVal: number) {
		this.options.hueThreshold = newVal
	}

	get hueThreshold() {
		return this.options.hueThreshold
	}

	set satThreshold(newVal: number) {
		this.options.satThreshold = newVal
	}

	get satThreshold() {
		return this.options.satThreshold
	}

	set valThreshold(newVal: number) {
		this.options.valThreshold = newVal
	}

	get valThreshold() {
		return this.options.valThreshold
	}

	set keyColor(newVal: string) {
		this.options.chromaKeyColor = newVal

		const keyRed = parseInt(newVal.slice(1, 3), 16)
		const keyGreen = parseInt(newVal.slice(3, 5), 16)
		const keyBlue = parseInt(newVal.slice(5, 7), 16)

		this.options.key = this.convertRGBtoHSV(keyRed, keyGreen, keyBlue)
	}

	get keyColor() {
		return this.options.chromaKeyColor
	}

	get keyColorHSV() {
		return this.options.key
	}

	applyCanvasDimensions(w: number, h: number) {
		this.canvas.width = w
		this.canvas.height = h
	}

	/**
	 * Apply chroma key to the frame of a video DOM in the intermediate canvas
	 * @param {HTMLVideoElement} video
	 */
	chromaKeyVideoFrame(video: HTMLVideoElement) {
		const { videoWidth, videoHeight } = video

		this.intermediateCanvas.width = videoWidth
		this.intermediateCanvas.height = videoHeight

		this.drawChromaKeyImage(video)
	}

	/**
	 * Apply chroma key to an image in the intermediate canvas
	 * @param {HTMLImageElement} image
	 */
	chromaKeyImage(image: HTMLImageElement) {
		const { width, height } = image
		this.intermediateCanvas.width = width
		this.intermediateCanvas.height = height

		this.drawChromaKeyImage(image)
	}

	drawChromaKeyImage(media: HTMLVideoElement | HTMLImageElement) {
		let intermediateData = this.drawToIntermediateCanvas(media)
		let processData = {
			pixels: intermediateData,
		}

		let pixelData = this.applyChromaKeyToPixels(processData)
		this.drawIntermediateDataToCanvas(pixelData)
	}

	drawToIntermediateCanvas(media: HTMLVideoElement | HTMLImageElement) {
		const {
			width: intermediateCanvasWidth,
			height: intermediateCanvasHeight,
		} = this.intermediateCanvas

		const intermediateCtx = this.intermediateCanvas.getContext("2d")

		// @ts-ignore
		intermediateCtx.drawImage(
			media,
			0,
			0,
			intermediateCanvasWidth,
			intermediateCanvasHeight
		)
		const intermediateData = intermediateCtx.getImageData(
			0,
			0,
			intermediateCanvasWidth,
			intermediateCanvasHeight
		)
		return intermediateData
	}

	applyChromaKeyToPixels(data: PixelData) {
		let pxs_out = data.pixels.data
		for (let p = 0; p < pxs_out.length; p += 4) {
			if (this.chromaKeyOut(pxs_out[p], pxs_out[p + 1], pxs_out[p + 2]))
				pxs_out[p + 3] = 0
		}

		return data.pixels
	}

	drawIntermediateDataToCanvas(intermediateData: any) {
		if (!this.intermediateCanvas) return

		const {
			width: intermediateCanvasWidth,
			height: intermediateCanvasHeight,
		} = this.intermediateCanvas

		const intermediateCtx = this.intermediateCanvas.getContext("2d")

		const ctx = this.canvas.getContext("2d")
		ctx.imageSmoothingEnabled = true
		const { width: canvasWidth, height: canvasHeight } = this.canvas

		intermediateCtx.putImageData(intermediateData, 0, 0)

		ctx.clearRect(0, 0, canvasWidth, canvasHeight)
		ctx.drawImage(
			this.intermediateCanvas,
			0,
			0,
			intermediateCanvasWidth,
			intermediateCanvasHeight,
			0,
			0,
			canvasWidth,
			canvasHeight
		)
	}

	drawCanvas() {
		if (this.media instanceof HTMLVideoElement)
			this.chromaKeyVideoFrame(this.media)
		else this.chromaKeyImage(this.media)
	}

	private chromaKeyOut = (r: number, g: number, b: number) => {
		const { key, hueThreshold, valThreshold, satThreshold } = this.options
		const { H, S, V } = this.convertRGBtoHSV(r, g, b)
		if (Math.abs(key.H - H) >= hueThreshold) return false
		if (Math.abs(key.S - S) >= satThreshold) return false
		if (Math.abs(key.V - V) >= valThreshold) return false
		return true
	}

	private convertRGBtoHSV(r: number, g: number, b: number) {
		let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn
		rabs = r / 255
		gabs = g / 255
		babs = b / 255
		;(v = Math.max(rabs, gabs, babs)),
			(diff = v - Math.min(rabs, gabs, babs))
		diffc = (c) => (v - c) / 6 / diff + 1 / 2
		percentRoundFn = (num) => Math.round(num * 100) / 100
		if (diff == 0) {
			h = s = 0
		} else {
			s = diff / v
			rr = diffc(rabs)
			gg = diffc(gabs)
			bb = diffc(babs)

			if (rabs === v) {
				h = bb - gg
			} else if (gabs === v) {
				h = 1 / 3 + rr - bb
			} else if (babs === v) {
				h = 2 / 3 + gg - rr
			}
			if (h < 0) {
				h += 1
			} else if (h > 1) {
				h -= 1
			}
		}
		return {
			H: Math.round(h * 360),
			S: percentRoundFn(s * 100),
			V: percentRoundFn(v * 100),
		}
	}
}
