<script lang="ts">
	import { DataHandlerScene, type Scene } from "luxedo-data"
	import { SuperscaleProgressManager } from "../../../../../modules/progress-reporting/ProgressContext_Superscale"
	import { UnderscaledIcon } from "svelte-comps/icons"

	interface Props {
		scene: Scene
	}

	let { scene = $bindable() }: Props = $props()

	let isUnderscaled: boolean = $state(scene.isUnderscaled)
	let hasStarted: boolean = $state(false)

	SuperscaleProgressManager.subscribeTo(scene, (ctx) => {
		hasStarted = ctx.hasStarted
	})

	DataHandlerScene.addListener((ids) => {
		if (scene.id in ids) updateSceneStatus()
	})

	function updateSceneStatus() {
		scene = DataHandlerScene.get(scene.id)
		isUnderscaled = scene.isUnderscaled
	}
</script>

{#if isUnderscaled}
	<div class="underscale-icon {hasStarted ? 'started' : ''}">
		<UnderscaledIcon />
	</div>
{/if}

<style>
	.underscale-icon {
		background-color: var(--color-bg);

		position: absolute;
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 0.25rem;

		top: 0.5rem;
		right: 0.5rem;
	}

	@keyframes upscaling {
		0% {
			fill: var(--color-text);
		}

		25% {
			fill: var(--color-text);
		}

		50% {
			fill: var(--color-text-light);
		}

		75% {
			fill: var(--color-text);
		}

		100% {
			fill: var(--color-text);
		}
	}

	.underscale-icon :global(.svg-fill) {
		fill: var(--ct-red);
	}

	.started :global(.svg-fill) {
		animation: upscaling 5s infinite;
	}
</style>
