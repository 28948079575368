<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_1_4)">
		<mask
			id="mask0_1_4"
			style="mask-type:luminance"
			maskUnits="userSpaceOnUse"
			x="-1"
			y="0"
			width="24"
			height="24"
		>
			<path d="M23 0H-1V24H23V0Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1_4)">
			<path
				d="M18.9611 10.2635C20.3048 11.0313 20.3048 12.9687 18.9611 13.7365L3.99228 22.2901C2.65897 23.052 1 22.0893 1 20.5536V3.44632C1 1.91067 2.65896 0.947937 3.99228 1.70983L18.9611 10.2635Z"
				fill={strokeColor}
				class="svg-fill"
			/>
		</g>
		<path
			d="M23 3V21"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
	</g>
	<!-- <defs>
	<clipPath id="clip0_1_4">
	<rect width="25" height="24" fill="white"/>
	</clipPath>
	</defs> -->
</svg>

<style>
</style>
