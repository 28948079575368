<script lang="ts">
	import {
		DeviceRPi,
		ProjectorPowerManager,
		type Device,
		type ProjectorPowerState,
	} from "luxedo-data"
	import PowerButton from "../../../../reusable/icons/PowerButton.svelte"
	import ProjectorResolutionInput from "./inputs/ProjectorResolutionInput.svelte"
	import ProjectorTimeoutInput from "./inputs/ProjectorTimeoutInput.svelte"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import type { Unsubscriber } from "svelte/store"
	import { Toast } from "svelte-comps/toaster"

	let device: DeviceRPi = $derived(
		ProjectorMenuController.ctx.device
	) as DeviceRPi

	let projectorPower: ProjectorPowerState = $state()
	let isPowerChanging: boolean = $state()

	let unsubscriber: Unsubscriber
	function onDeviceChange(device: Device) {
		if (unsubscriber) unsubscriber()
		if (device)
			unsubscriber = ProjectorPowerManager.subscribeTo(
				device.id,
				onPowerStateUpdate
			)
	}

	function onPowerStateUpdate(ctx: {
		state: ProjectorPowerState
		isChanging: boolean
	}) {
		const { state, isChanging } = ctx
		projectorPower = state
		isPowerChanging = isChanging
	}

	async function toggleProjectorPower() {
		let newPower: "ON" | "OFF"
		if (projectorPower === "ON") {
			newPower = "OFF"
		} else {
			newPower = "ON"
		}

		try {
			Toast.text(`Powering projector ${newPower.toLowerCase()}`)
			await ProjectorPowerManager.setPower(device, newPower)
			Toast.success(`The projector has powered ${newPower.toLowerCase()}`)
		} catch (e) {
			console.error("Unable to modify projector power state.")
			Toast.error(`Unable to power ${newPower.toLowerCase()} the projector`)
		}
	}

	$effect(() => {
		onDeviceChange(device)
	})
</script>

<div class="settings-block">
	<div class="flex-row projector-heading">
		<h2 class="setting-heading">Projector</h2>

		<PowerButton
			isOn={projectorPower === "ON"}
			togglePower={toggleProjectorPower}
			isChanging={isPowerChanging}
		/>
		<span class="power-control-info"
			>{ProjectorPowerManager.convertToReadableState(projectorPower)}</span
		>
	</div>
	<ProjectorResolutionInput />
	<ProjectorTimeoutInput />
</div>

<style>
	.setting-heading {
		margin-right: 0.25rem;
	}
</style>
