import { Device } from "luxedo-data"
import { writable } from "svelte/store"

interface ColorPickerStoreContext_Closed {
	isOpen: false
}

interface ColorPickerStoreContext_Open {
	isOpen: true
	device: Device
	left: number
	top: number
	onColorChange: (colorIndex: number) => void
}

type ColorPickerStoreContext =
	| ColorPickerStoreContext_Closed
	| ColorPickerStoreContext_Open

export const ColorPickerStore = writable<ColorPickerStoreContext>({
	isOpen: false,
})

export function openDeviceColorPicker(
	device: Device | ((colorIndex: number) => void),
	colorBlock: SVGElement,
	onColorChange?: (colorIndex: number) => void
) {
	const { left, top } = colorBlock.getBoundingClientRect()

	ColorPickerStore.set({
		isOpen: true,
		device: device instanceof Device ? device : undefined,
		left,
		top,
		onColorChange:
			onColorChange ?? device instanceof Device ? undefined : device,
	})
}

export function closeDeviceColorPicker() {
	ColorPickerStore.set({
		isOpen: false,
	})
}
