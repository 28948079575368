<script lang="ts">
	import ArrowForward from "../icons/ArrowRight.svelte"

	type Step = {
		title?: string
		description: string
	}

	interface Props {
		onStepClick?: (stepNumber: number) => void
		index?: number
		steps: {
			[index: number]: Step
		}
	}

	let { onStepClick = undefined, index = 0, steps }: Props = $props()
</script>

<div class="instruction-steps {onStepClick ? 'clickable' : ''}">
	{#each Object.entries(steps) as [i, step]}
		<div
			role="button"
			tabindex="0"
			onclick={onStepClick ? () => onStepClick(Number(i)) : undefined}
			onkeydown={onStepClick
				? (e) => (e.key === "Enter" ? onStepClick(Number(i)) : null)
				: undefined}
			class="step {index === Number(i) ? 'active ' : ''}"
		>
			<span class="step-number">
				{Number(i) + 1}
			</span>
			<div class="step-text">
				{#if step.title}
					<h3>{step.title}</h3>
				{/if}
				{step.description}
				{#if index === Number(i)}
					<ArrowForward />
				{/if}
			</div>
		</div>
	{/each}
</div>

<style>
	.instruction-steps {
		display: flex;
		flex-direction: column;
	}

	.step {
		cursor: default;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		align-items: center;
		min-height: 3rem;
	}

	.instruction-steps.clickable .step {
		cursor: pointer;
	}

	.step-number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 2.5rem;
		height: 2.5rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.step-text {
		position: relative;
		font-size: var(--h3);
		padding-right: 3rem;
		padding-top: calc(var(--h1) - var(--h2));
		max-width: calc(100% - 4rem);
		line-height: 1em;
	}

	.step.active {
		color: var(--color-main);
	}

	.step.active .step-text h3 {
		color: var(--color-main);
	}

	.step.active .step-text h3 {
		font-size: var(--h1);
	}

	.step.active .step-text {
		color: var(--color-text-light);
		padding-top: 0;
	}

	@keyframes arrowAnim {
		0% {
			transform: translateX(-400%);
		}

		100% {
			transform: translateX(0%);
		}
	}

	.step-text :global(svg) {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		width: 2rem;
		overflow: hidden;
	}

	.step-text :global(svg > path) {
		animation: arrowAnim;
		animation-duration: 250ms;
	}

	.step-text :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.step-text h3 {
		margin: 0;
		font-size: var(--h2);
	}

	.step-number,
	.step-text h3,
	.step-text {
		transition:
			color 250ms,
			font-size 250ms,
			border-color 250ms,
			padding 250ms;
	}

	.step.active .step-number {
		color: var(--color-main);
	}
</style>
