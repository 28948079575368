<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg
	width="64"
	height="64"
	viewBox="0 0 64 64"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class="folder-icon"
>
	<path
		d="M25.3909 22.2072C26.2262 18.5744 29.4598 16 33.1874 16H59.5229C60.7972 16 61.7465 17.1758 61.478 18.4215L56.0316 43.6859C55.2375 47.3698 51.9799 50 48.2113 50H19L25.3909 22.2072Z"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
	/>
	<path
		d="M45.5 50H15.3396C11.8607 50 8.78053 47.7516 7.72023 44.4382L2.33506 27.6096C1.92209 26.319 2.88491 25 4.2399 25H18.5H25.5"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
	/>
</svg>

<style>
</style>
