<script lang="ts">
	import { onMount } from "svelte"
	import {
		NetworkTestController,
		type NetworkTestState,
	} from "./NetworkTestController"

	let errorMsg = ""

	const unsubscribe = NetworkTestController.subscribe(
		(ctx: NetworkTestState) => {
			if (ctx.state === "error") {
				errorMsg = ctx.errorMsg
			}
		}
	)

	function retryTest() {
		NetworkTestController.reset()
	}

	onMount(() => {
		return () => unsubscribe()
	})
</script>

<div class="error-msg flex-row">
	⚠
	{errorMsg}
</div>
<div class="button-container">
	<button on:click={retryTest} class="warn">Retry</button>
</div>

<style>
	.error-msg {
		margin-bottom: auto;
		color: var(--color-error);
		font-size: var(--h2);
		max-width: var(--network-test-width);
	}
</style>
