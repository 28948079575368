<script lang="ts">
	import { Guides } from "luxedo-data"
	import { showContentSuggestions } from "../content-suggestions"
</script>

<div id="guide-list">
	<h2>Guides</h2>
	<ul>
		<h3>Looking for help creating content?</h3>
		<li>
			<a
				onclick={(e) => {
					e.preventDefault()
					showContentSuggestions()
				}}
				href="#">Content Solutions</a
			>
		</li>
		{#each Object.entries(Guides.default) as [category, guideList]}
			<h3>{category}</h3>
			{#each Object.entries(guideList) as [title, url]}
				<li>
					<a target="_blank" href={url}>{title}</a>
				</li>
			{/each}
		{/each}
	</ul>
</div>

<style>
	#guide-list {
		position: relative;
		height: 100%;
		font-size: var(--h3);
		padding: 1rem 0 1rem 1rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		overflow: hidden;
	}

	#guide-list ::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}

	/* Handle */
	#guide-list ::-webkit-scrollbar-thumb {
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
		background-color: var(--color-text);
	}

	h2 {
		margin: 0;
		font-size: var(--h1);
	}

	h3 {
		margin: 1rem 0 0.5rem 0;
		font-size: var(--h2);
		color: var(--color-main);
	}

	ul {
		height: calc(100% - 2rem);
		width: 100%;
		padding: 0;
		overflow-y: scroll;
	}

	li {
		margin-left: 1rem;
	}

	a {
		border: none;
		color: var(--color-text-light);
		border-width: 1px;
		transition:
			color 250ms,
			border-color 250ms;
	}

	a:hover,
	a:focus-visible {
		color: var(--color-main);
		border-color: var(--color-main);
	}
</style>
