<script lang="ts">
	import type { Device } from "luxedo-data"
	import { TextRowSelector } from "svelte-comps/inputs"
	import DeviceSelectorSidebarDeviceList from "./DeviceSelectorSidebar_DeviceList.svelte"
	import DeviceSelectorSidebarGroupList from "./DeviceSelectorSidebar_GroupList.svelte"
	import DeviceRegisterButton from "./DeviceRegisterButton.svelte"
	import { DeviceSelectorController } from "./DeviceSelectorController"

	interface Props {
		selected: Device
		allDevices: Array<Device>
		onselect: (d: Device) => void
	}

	let { selected, allDevices, onselect }: Props = $props()

	let hasMultipleDevices = $derived(allDevices.length > 1)

	let view: "My Devices" | "My Groups" = $derived(
		DeviceSelectorController.ctx.view
	)

	let isRegistering: boolean = $state(false)
</script>

<div class="device-filter-container">
	<div class="heading">
		{#if hasMultipleDevices}
			<TextRowSelector
				onChange={DeviceSelectorController.setView}
				selectedOption={view}
				options={["My Devices", "My Groups"]}
				fontSize="var(--h1)"
			/>
		{:else}
			<h3>Select a Device</h3>
		{/if}
	</div>
	<div class="device-filter">
		<DeviceRegisterButton {view} bind:isExpanded={isRegistering} />
		{#if !isRegistering}
			{#if view === "My Devices"}
				<DeviceSelectorSidebarDeviceList {allDevices} {selected} {onselect} />
			{:else}
				<DeviceSelectorSidebarGroupList {allDevices} {selected} {onselect} />
			{/if}
		{/if}
	</div>
</div>

<style>
	h3 {
		color: var(--color-text-light);
		margin: 0;
		font-size: var(--h1);
		text-align: center;
	}

	.heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1rem;
	}

	.device-filter {
		position: relative;
		--head-height: calc(var(--h1) + 1.5rem);
		height: calc(100% - var(--head-height));
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 0.25rem;
	}
</style>
