import { ComponentController } from "svelte-comps/stores"

type View = "My Devices" | "My Groups"

type CTX = {
	view: View
}

class Ctrlr extends ComponentController<CTX> {
	constructor() {
		super({ view: "My Devices" })
	}

	setView = (view: View) => {
		this.ctx.view = view
	}
}

export const DeviceSelectorController = new Ctrlr()
