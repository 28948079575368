<script lang="ts">
	import {
		DataHandlerDevice,
		type DeviceGroup,
		type Device,
		DeviceRPi,
	} from "luxedo-data"
	import ProjectorGroupCircle from "./ProjectorGroupCircle.svelte"

	interface Props {
		group: DeviceGroup
		onClick?: () => void
	}

	let { group, onClick }: Props = $props()

	let childDevices: Array<DeviceRPi> = $derived(group.getChildDevices())
</script>

<div>
	{#if childDevices.length > 0}
		{#each childDevices as child}
			<ProjectorGroupCircle deviceColor={child.color} />
		{/each}
	{:else}
		<ProjectorGroupCircle deviceColor={"var(--color-main-transparent)"} />
	{/if}
</div>

<style>
	div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 2.5rem;
		max-height: 2.5rem;
	}

	div :global(svg) {
		flex: 1;
	}
</style>
