<script lang="ts">
	import type { DeviceGroup, DeviceRPi } from "luxedo-data"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		device: DeviceGroup
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()
	let isRestarting = $state(false)

	async function awaitDevicePowerCycle(device: DeviceRPi) {
		await device.listenEidosCondition(() => !device.isOnline)
		await device.listenEidosCondition(() => device.isOnline)
	}

	async function restartDevice() {
		isRestarting = true
		setButtonsDisabled(true)
		Toast.text("Restarting devices... This may take a few minutes.")

		await LuxedoRPC.api.device.device_group_restart_children(device.id)

		const promises = device.getChildDevices().map(awaitDevicePowerCycle)
		await Promise.all(promises)

		Toast.success("Devices successfully restarted.")

		isRestarting = false
		setButtonsDisabled(false)
	}

	function onDeviceChange(device: DeviceGroup) {
		isRestarting = false
	}

	$effect(() => {
		onDeviceChange(device)
	})
</script>

<div class="action-container">
	<button class="outline-button" onclick={restartDevice} disabled={isDisabled}
		>{isRestarting ? "Restarting..." : "Restart devices"}</button
	>
	<span class="action-info"> Restart each device linked to this group. </span>
</div>

<style>
	button {
		--border-color: var(--ct-red);
	}
</style>
