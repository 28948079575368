<script lang="ts">
	import type { Component } from "svelte"
	import { STORAGE_MENUS, StoragSubscriptionController } from "./StorageSubscriptionController"

	let currentMenu: Component = $state()
	StoragSubscriptionController.subscribe(({ route }) => (currentMenu = STORAGE_MENUS[route]))
</script>

{#if currentMenu}
	{@const SvelteComponent = currentMenu}
	<SvelteComponent />
{/if}

<style>
</style>
