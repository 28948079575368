import { Guides } from "luxedo-data"

const GROUP_NETWORK_REQUIREMENT_LINK =
	Guides.default.Other["Group Network Requirements"]

/**
 * The keys of this object reflect known issues that may cause the group connection to fail
 * The values are the solutions / troubleshooting steps for said issue
 */
export const GROUP_CONNECTION_TROUBLESHOOT_MAP = {
	"Connected to the internet": `This device is not connected to the internet. To play scenes in a group, all devices must be online. For assistance with getting this device connected, refer to <a target="_blank" href="${Guides.default.Device["Connecting to Wi-Fi"]}">this guide</a>.`,
	"Connected to the group": `This device is not connected to the other devices in the group. Ensure all devices are on the same network and that the network is configured correctly. You can review the network requirements <a target="_blank" href="${GROUP_NETWORK_REQUIREMENT_LINK}">here</a>.`,
	"Devices are all updated": `One or more devices in this group are running an outdated version of the Luxedo firmware. To ensure compatibility, update all devices from their "About" tab and try again.`,
	"Devices are all online": `One or more devices in this group appear to be offline. Verify that each projector is powered on and has a stable internet connection. For assistance, refer to <a target="_blank" href="${Guides.default.Device["Connecting to Wi-Fi"]}">this guide</a>.`,
	"Devices are on the same network": `Some devices in this group seem to be connected to different networks. All devices must be on the same local network to function correctly. Double-check network configurations and reconnect any devices that may have switched networks.`,
	"Devices can communicate with each other": `Even if devices are online and on the same network, they must be able to communicate with each other. Check for firewall settings, network isolation (AP isolation), or any restrictions that might prevent devices from discovering and syncing with each other. Review the network requirements <a target="_blank" href="${GROUP_NETWORK_REQUIREMENT_LINK}">here</a>.`,
}

export type GroupConnectionIssue =
	keyof typeof GROUP_CONNECTION_TROUBLESHOOT_MAP
