/**
 * @file asset/canvas/Text.ts
 * @author Josiah Eakle 2023
 * @description An extention of the fabric TextArea with Editor data
 */

import { fabric } from "fabric"
import { AssetOptions } from "../Asset"
import { CanvasAsset } from "."
import {
	Serializable,
	convertInstanceToObject,
	registerSerializableConstructor,
} from "../../modules/serialize"
import { EditorClass } from "../.."

interface TextBoxOptions extends AssetOptions {
	text: string
}

export interface TextAsset extends fabric.Textbox, CanvasAsset {}

@CanvasAsset.Mixin
export class TextAsset extends fabric.Textbox implements Serializable {
	constructor(
		options: AssetOptions | TextBoxOptions,
		objectOptions?: fabric.ITextboxOptions
	) {
		super("text" in options ? options.text : "Enter text...", objectOptions)
		this.initAsset(options)
	}

	onDeselect(options: { e?: Event; object?: fabric.Object }): boolean
	onDeselect(options: { e?: Event; object?: fabric.Object }): boolean
	onDeselect(options: unknown): boolean {
		if (this.text === undefined || this.text === "") {
			this.track.parent.delete(this.track)
			this.canvas.requestRenderAll()
		}

		this.exitEditing()
		return false
	}

	serialize(forExport?: boolean) {
		const ret = convertInstanceToObject(this, {
			forExport,
			propertiesToInclude: ["text"],
			propertyGetters: {
				getBaseValue: this.AnimatableProperties,
			},
		})

		ret["exportedSVG"] = this.toSVG()

		return ret
	}

	static async loadJSON(
		editor: EditorClass,
		data: Partial<TextAsset>,
		id?: string
	) {
		console.log("loading json for text", data)

		const poly = new TextAsset(
			{
				id,
				text: data.text,
			},
			{ ...data }
		)

		return poly
	}

	//#region    ===========================		   	 Default properties		 		==============================
	perPixelTargetFind = false
	objectCaching = false
	//#endregion =====================================================================================================
}

registerSerializableConstructor(TextAsset)
