<script lang="ts">
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"

	let audioOutput = $derived(
		ProjectorPreferencesManager.pendingChanges.audioOutput ??
			ProjectorPreferencesManager.preferences.audioOutput
	)

	function oninputchange(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		const target = e.currentTarget
		setTimeout(() => {
			ProjectorPreferencesManager.stageChange(
				"audioOutput",
				target.value as typeof audioOutput
			)
		})
	}
</script>

<div class="select-container">
	<label for="audio-config-input">Audio Output: </label>
	<select
		name="audio-config"
		id="audio-config-input"
		value={audioOutput}
		onchange={oninputchange}
	>
		<option value="HDMI">Projector</option>
		<option value="HEADPHONES">3.5mm Jack</option>
	</select>
</div>

<style>
</style>
