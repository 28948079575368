<script lang="ts">
	import { run } from "svelte/legacy"

	import { DateTime } from "luxon"
	import CheckboxInput from "../../../reusable/input/CheckboxInput.svelte"
	import { ScheduleController } from "../ScheduleController"
	import {
		TimetableEventRepeat,
		type FrequencyType,
		type TimetableEvent,
	} from "luxedo-data/src/json-model/timetable/TimetableEvent"
	import { type Lightshow, type Scene, TimeZones } from "luxedo-data"
	import { onDestroy, onMount } from "svelte"

	interface Props {
		isValid?: boolean
	}

	let { isValid = $bindable() }: Props = $props()
	isValid = true

	let selectedShow: Scene | Lightshow

	let doLoop: boolean = $state(true)
	let doRepeat: boolean = $state(false)
	let doUseLocalTz: boolean = $state(true)

	let startDate: string = $state()
	let startTime: string = $state()
	let endTime: string = $state()
	let timezone

	let freqAmount = $state(1)
	let freqType: FrequencyType = $state("days")
	let repeatUntil: string = $state()

	const unsubscribe = ScheduleController.EventEditor.subscribe((ctx) => {
		if (ctx && ctx.selectedShow) selectedShow = ctx.selectedShow
		importTiming(ctx ? ctx.eventDraft : undefined)
	})

	/** Sets the time inputs to the default now + 5 min */
	function initializeDefaultTiming() {
		const now = DateTime.now()
		const end = now.plus({
			seconds: selectedShow?.duration >= 60 ? selectedShow.duration : 300,
		})
		startDate = now.toFormat("yyyy-MM-dd")
		startTime = now.toFormat("HH:mm")

		endTime = end.toFormat("HH:mm")

		const tomorrow = end.plus({ days: 1 })
		repeatUntil = `${tomorrow.toFormat("yyyy-MM-dd")}T${tomorrow.toFormat("HH:mm")}`
		doRepeat = false

		ScheduleController.EventEditor.updateTimingBlock({
			end: DateTime.fromISO(`${startDate}T${endTime}`).toISO(),
			start: DateTime.fromISO(`${startDate}T${startTime}`).toISO(),
		})
	}

	/** Called on store update, imports timing from the event if applicable, otherwise initializes default timing (see initializeDefaultTiming) */
	function importTiming(eventDraft?: TimetableEvent) {
		if (!eventDraft) return initializeDefaultTiming()

		const eventTiming = eventDraft.getTimingValues()
		if (eventDraft instanceof TimetableEventRepeat) {
			const start = DateTime.fromISO(eventTiming.start)
			const until = DateTime.fromISO(eventTiming.end)
			const duration = eventTiming.duration
			const frequencyType = eventTiming.frequencyType
			const frequencyAmount = eventTiming.frequencyAmount

			startDate = start.toFormat("yyyy-MM-dd")
			startTime = start.toFormat("HH:mm")
			endTime = start.plus({ millisecond: duration }).toFormat("HH:mm")

			repeatUntil = `${until.toFormat("yyyy-MM-dd")}T${until.toFormat("HH:mm")}`
			freqType = frequencyType
			freqAmount = frequencyAmount
			doRepeat = true

			ScheduleController.EventEditor.updateTimingBlock({
				frequencyAmount,
				frequencyType,
				doRepeat: true,
				duration,
				end: until.toISO(),
				start: start.toISO(),
			})
		} else {
			const start = DateTime.fromISO(eventTiming.start)
			const end = DateTime.fromISO(eventTiming.end)
			const tomorrow = end.plus({ days: 1 })

			startDate = start.toFormat("yyyy-MM-dd")
			startTime = start.toFormat("HH:mm")
			endTime = end.toFormat("HH:mm")
			doRepeat = false

			freqType = "days"
			freqAmount = 1
			repeatUntil = `${tomorrow.toFormat("yyyy-MM-dd")}T${tomorrow.toFormat("HH:mm")}`
			ScheduleController.EventEditor.updateTimingBlock({
				end: end.toISO(),
				start: start.toISO(),
			})
		}
	}

	/** Updates the ScheduleController with the new start time */
	function updateStartTime(date: string, time: string) {
		if (!(date && time)) return

		let end = DateTime.fromISO(`${startDate}T${endTime}`)
		const start = DateTime.fromISO(`${date}T${time}`)

		if (end <= start) {
			end = end.plus({ days: 1 })
		}
		if (!doRepeat)
			ScheduleController.EventEditor.updateTimingValue("end", end.toISO())
		ScheduleController.EventEditor.updateTimingValue("start", start.toISO())
	}

	/** Updates the ScheduleController with the new end time */
	function updateEndTime(date: string, time: string) {
		if (!(date && time)) return

		let end = DateTime.fromISO(`${date}T${time}`)
		const start = DateTime.fromISO(`${startDate}T${startTime}`)
		if (end <= start) end = end.plus({ days: 1 })

		ScheduleController.EventEditor.updateTimingValue("end", end.toISO())
		ScheduleController.EventEditor.updateTimingValue(
			"duration",
			end.diff(start, "milliseconds").as("milliseconds")
		)
	}

	/** Updates the ScheduleController with the new repeat until */
	function updateUntil(dateTime: string) {
		const untilDate = DateTime.fromISO(dateTime)
		const untilString = `${untilDate.toFormat("yyyy-MM-dd")}T${untilDate.toFormat("HH:mm")}`

		ScheduleController.EventEditor.updateTimingValue("end", untilString)
	}

	function updateDuration(date: string, time: string) {
		let end = DateTime.fromISO(`${date}T${time}`)
		const start = DateTime.fromISO(`${startDate}T${startTime}`)
		if (end <= start) end = end.plus({ days: 1 })

		ScheduleController.EventEditor.updateTimingValue(
			"duration",
			end.diff(start, "milliseconds").as("milliseconds")
		)
	}

	function onTimeZoneCheckChange(
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		if (e.currentTarget.checked) ScheduleController.EventEditor.clearTimeZone()
	}

	function onTimeZoneSelect(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		ScheduleController.EventEditor.setTimeZone(e.currentTarget.value)
	}

	onDestroy(unsubscribe)

	run(() => {
		updateStartTime(startDate, startTime)
	})

	run(() => {
		!doRepeat && updateEndTime(startDate, endTime)
	})

	run(() => {
		doRepeat && updateDuration(startDate, endTime)
	})
	run(() => {
		doRepeat && updateUntil(repeatUntil)
	})

	run(() => {
		doRepeat &&
			ScheduleController.EventEditor.updateTimingValue(
				"frequencyType",
				freqType
			)
	})
	run(() => {
		doRepeat &&
			ScheduleController.EventEditor.updateTimingValue(
				"frequencyAmount",
				freqAmount
			)
	})
</script>

<div class="date-inputs">
	<div class="time-inputs">
		<div class="input-container">
			<label for="start-date-input">Start Date:</label>
			<input id="start-date-input" type="date" bind:value={startDate} />
		</div>
		<div class="input-container">
			<CheckboxInput
				label="Loop"
				id="loop-checkbox"
				bind:isChecked={doLoop}
				disabled
			/>
		</div>
	</div>
	<div class="time-inputs">
		<div class="input-container">
			<label for="start-date-input">Start Time:</label>
			<input
				id="start-date-input"
				type="time"
				step="any"
				bind:value={startTime}
			/>
		</div>

		<div class="input-container">
			<label for="start-date-input">End Time:</label>
			<input
				id="start-date-input"
				type="time"
				step="any"
				bind:value={endTime}
			/>
		</div>
	</div>
	<div class="timezone-inputs">
		<CheckboxInput
			label="Use Local Time Zone"
			id="use-timezone-input"
			bind:isChecked={doUseLocalTz}
			onChange={onTimeZoneCheckChange}
		/>
		{#if !doUseLocalTz}
			<div class="select-container">
				<label for="timezone-input">Time Zone:</label>
				<select
					name="timezone_input"
					id="timezone-input"
					onchange={onTimeZoneSelect}
				>
					{#each TimeZones.default as zone}
						<option value={zone.utc[0]}>
							{zone.text}
						</option>
					{/each}
				</select>
			</div>
		{/if}
	</div>
</div>
<hr />
<div class="flex-column">
	<div class="repeat-inputs">
		<CheckboxInput
			label="Repeat"
			id="repeat-checkbox"
			bind:isChecked={doRepeat}
			onChange={(e) =>
				ScheduleController.EventEditor.updateTimingValue(
					"doRepeat",
					e.currentTarget.checked
				)}
		/>
		{#if doRepeat}
			<div
				id="repeat-interval-container"
				class="select-container input-container"
			>
				<label for="freq-input-number"> every </label>
				<input
					type="number"
					id="freq-input-number"
					step={1}
					disabled={!doRepeat}
					bind:value={freqAmount}
				/>
				<select id="freq-input" disabled={!doRepeat} bind:value={freqType}>
					<option value="minutes"> minutes </option>
					<option value="hours"> hours </option>
					<option value="days"> days </option>
					<option value="weeks"> weeks </option>
					<option value="months"> months </option>
				</select>
			</div>
		{/if}
	</div>
	{#if doRepeat}
		<div id="until-input" class="input-container">
			<label for="repeat-date-input">until</label>
			<input
				id="repeat-date-input"
				type="datetime-local"
				bind:value={repeatUntil}
			/>
		</div>
	{/if}
</div>

<style>
	.timezone-inputs,
	.time-inputs {
		display: flex;
		flex-direction: row;
	}

	.timezone-inputs {
		margin-top: 1rem;
		flex-direction: column;
		overflow: hidden;
		font-size: var(--h3);
	}

	.timezone-inputs .select-container {
		margin-top: 0.25rem;
	}

	.timezone-inputs .select-container label,
	.timezone-inputs .select-container select {
		font-size: var(--h3);
	}

	.input-container {
		width: 50%;
	}

	.repeat-inputs {
		display: flex;
		flex-direction: row;
		min-height: 2rem;
	}

	#repeat-interval-container {
		margin-left: 0.25rem;
	}

	#until-input {
		margin-left: 2.1rem;
	}

	#until-input input {
		width: fit-content;
	}

	#freq-input-number {
		margin-left: 0.25rem;
		background-color: var(--color-bg-dark);
		text-align: center;
	}

	#freq-input {
		padding-left: 0.25rem;
		color: var(--color-text-light);
	}

	hr {
		width: calc(100% - 3.5rem);
	}
</style>
