<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import NumberRange from "../../../input/NumberRange.svelte"
	import { GroupEditorController } from "../GroupEditorController"

	let isBlending: boolean = $state()
	let gridSize: number = $state()

	GroupEditorController.Grid.subscribe((ctx) => {
		gridSize = ctx.gridSize
	})

	GroupEditorController.Blend.subscribe((ctx) => {
		isBlending = ctx.isBlending
	})

	function toggleBlending(doBlend: boolean) {
		GroupEditorController.Blend.toggleBlend(doBlend)
		isBlending = doBlend
	}

	function updateGridSize(e: CustomEvent<number>) {
		GroupEditorController.Grid.setGridSize(e.detail)
	}
</script>

<div class="group-settings">
	<div class="flex-row">
		<h3>Group Settings</h3>
		<div class="input-container">
			<span class="label">Blending</span>
			<ToggleSwitch isEnabled={isBlending} onUpdate={toggleBlending} />
		</div>
	</div>
	<NumberRange
		label="Grid Size"
		min={8}
		max={400}
		bind:value={gridSize}
		on:change={updateGridSize}
	/>
</div>

<style>
	h3 {
		font-size: var(--h1);
		margin: 0 0 1rem 0;
	}

	.label {
		margin: 0 0.5rem 0 1rem;
		color: var(--color-text);
		font-size: var(--h3);
	}

	.input-container {
		margin-bottom: 0.5rem;
	}

	.group-settings :global(label) {
		color: var(--color-text);
		font-size: var(--h3);
	}
</style>
