<script lang="ts">
	import { NetworkTestController } from "./NetworkTestController"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import type { DeviceRPi } from "luxedo-data"

	let device: DeviceRPi

	SelectedDeviceStore.subscribe((dev) => {
		if (!dev.features.includes("NETWORK_TEST")) {
			return
		}

		device = dev as DeviceRPi
	})

	function openNetworkTestOverlay() {
		NetworkTestController.open(device)
	}
</script>

<button on:click={openNetworkTestOverlay} class="outline-button">
	Test Network Connection
</button>

<style>
</style>
