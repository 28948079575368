<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg
	class="icon"
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_501_50)">
		<rect
			class="svg-stroke"
			x="1.5"
			y="1.5"
			width="45"
			height="45"
			rx="3.5"
			stroke={strokeColor}
			stroke-width="3"
		/>
	</g>
</svg>
