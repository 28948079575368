<script lang="ts">
	import { getEditor } from "luxedo-canvas"
	import { ClippingMask, MaskBase } from "luxedo-canvas/asset"
	import { onMount } from "svelte"
	import { MaskSidebarController } from "./MaskEditorSidebarController"
	import { DataHandlerMask, type Snapshot } from "luxedo-data"
	import { MaskEditorController } from "../MaskEditorController"
	import { asyncConfirmOverlay } from "svelte-comps/overlay"

	import { MaskInstance } from "svelte-comps/luxedo"
	import { Toast } from "svelte-comps/toaster"

	let masks: Array<MaskBase> = $state([])
	let snapshot: Snapshot = $state()

	MaskEditorController.subscribe((ctx) => {
		snapshot = ctx.snapshot
	})

	getEditor().masks.on("*", updateList)

	function updateList() {
		// The children will always be a list of masks
		const children = getEditor().tracks.children

		// Get masks from each track
		masks = getEditor().masks.all()
	}

	async function onDelete(mask: MaskBase) {
		try {
			await asyncConfirmOverlay({
				title: "Delete Mask?",
				prompt: [`Are you sure you want to delete ${mask.name}?`, "This cannot be undone."],
				confirmText: "Yes",
				denyText: "No",
			})

			const entry = DataHandlerMask.get(mask.entryId)
			if (entry) {
				await DataHandlerMask.deleteEntry(entry)
			}

			mask.delete()

			const track = mask.track
			track?.clearMask()

			getEditor().tracks.delete(track)
			getEditor().canvas.requestRenderAll()

			Toast.success("Mask deleted.")
		} catch (e) {
			console.log("User canceled mask deletion")
		}
	}

	function onEdit(mask: MaskBase) {
		onHoverEnd(mask)
		MaskEditorController.editMask(mask)
	}

	function onHover(mask: MaskBase) {
		MaskEditorController.highlightMask(mask)
	}

	function onHoverEnd(mask: MaskBase) {
		MaskEditorController.clearMaskHighlight(mask)
	}

	function onClick(mask: MaskBase) {
		MaskEditorController.selectMask(mask)
	}

	onMount(updateList)
</script>

<div class="mask-list-menu">
	<div class="mask-list-container">
		<h3>Masks</h3>
		{#if masks.length}
			<p class="info">
				Select a mask below to adjust its position or click the "Edit" button to transform the shape
				or change the visibility and name.
			</p>
			<hr />
		{/if}
		{#if masks.length}
			<div class="mask-list">
				{#each masks as mask}
					<MaskInstance {mask} {snapshot} {onHover} {onHoverEnd} {onDelete} {onEdit} {onClick} />
				{/each}
			</div>
		{:else}
			<p class="info">
				There are no masks configured for this calibration, click "New Mask" to get started.
			</p>
		{/if}
	</div>
	<div class="button-container create-buttons">
		<button class="create-button outline-button" onclick={() => MaskSidebarController.goTo("new")}
			>New Mask</button
		>
	</div>
</div>

<style>
	h3 {
		font-size: var(--h0);
		margin: 0;
	}

	hr {
		margin-top: 0;
	}

	.mask-list-menu {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}

	.mask-list-container {
		flex-grow: 1;
		overflow: hidden;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: column;
	}

	.mask-list {
		height: 100%;
		padding-right: 0.5rem;
		overflow-y: auto;
	}

	.mask-list :global(.mask-instance) {
		width: 100%;
	}

	.mask-list :global(.mask-instance:last-of-type) {
		margin-bottom: 0;
	}
</style>
