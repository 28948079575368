<script lang="ts">
	import { DataHandlerDevice, type DeviceGroup, type Scene } from "luxedo-data"
	import CheckboxInput from "../../input/CheckboxInput.svelte"
	import ProjectorIcon from "../../icons/ProjectorIcon.svelte"
	import ProjectorGroupIcon from "../../icons/ProjectorGroupIcon.svelte"

	type Props = {
		scene: Scene
		slotSrc: { [index: number]: string }
		group: DeviceGroup
		onFinish: () => void
	}

	let { group, slotSrc, onFinish, scene }: Props = $props()

	let isCombinedSelected: boolean = $state()
	let selectedParts: { [index: number]: boolean } = $state({})

	function getDeviceInfo(slotId: number) {
		const slot = group.slots.find((slot) => slot.id == slotId)
		const devId = slot.device_id
		const device = DataHandlerDevice.get(devId)
		if (device) return { color: device.color, name: device.name }
		else return { color: "--color-main", name: "Unknown" }
	}

	const beginDownloads = async () => {
		for (const slotId of Object.keys(selectedParts)) {
			const deviceName = getDeviceInfo(Number(slotId)).name
			await triggerDownload(slotSrc[Number(slotId)], deviceName)
		}

		if (isCombinedSelected) await scene.triggerDownload()

		if (onFinish) onFinish()
	}

	const triggerDownload = async (src: string, deviceName: string) => {
		return new Promise<void>((res) => {
			const link = document.createElement("a")
			link.target = "_blank"
			link.href = src
			link.style.display = "none"
			link.download = `${deviceName}.mp4`

			document.body.appendChild(link)
			link.click()

			setTimeout(() => {
				link.parentNode?.removeChild(link)
				res()
			}, 250)
		})
	}
</script>

<div class="scene-chunk-selector">
	<p>
		Select which devices' perspectives to download, or select "whole scene" to
		download the entire show uncropped.
	</p>
	<CheckboxInput
		bind:isChecked={isCombinedSelected}
		label="Whole Scene"
		checkColor="var(--color-text-light)"
	>
		{#snippet LabelIcon()}
			<ProjectorGroupIcon {group} />
		{/snippet}
	</CheckboxInput>
	{#each Object.entries(slotSrc) as [id, src]}
		<CheckboxInput
			checkColor={getDeviceInfo(Number(id)).color}
			bind:isChecked={selectedParts[Number(id)]}
			id={`select-slot-${id}-input`}
			label={`${getDeviceInfo(Number(id)).name}'s view`}
		>
			{#snippet LabelIcon()}
				<ProjectorIcon strokeColor={getDeviceInfo(Number(id)).color} />
			{/snippet}
		</CheckboxInput>
	{/each}
</div>

<div class="button-container">
	<button onclick={beginDownloads}>Download</button>
</div>

<style>
	.button-container {
		width: fit-content;
		position: absolute;
		bottom: 0;
		right: 1rem;
	}

	.scene-chunk-selector p {
		max-width: 40dvw;
		color: var(--color-text);
		line-height: 1.25em;
	}

	.scene-chunk-selector {
		margin-bottom: 1rem;
	}

	.scene-chunk-selector :global(svg) {
		width: 2rem;
		margin-right: 0.5rem;
	}

	.scene-chunk-selector :global(label) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
