<script lang="ts">
	import {
		NetworkTestController,
		type NetworkTestState,
	} from "./NetworkTestController"
	import { onMount } from "svelte"

	let download_mbps = 0
	let upload_mbps = 0
	let packet_loss_percent = 0

	// Subscribe once to get final results
	const unsubscribe = NetworkTestController.subscribe(
		(ctx: NetworkTestState) => {
			if (ctx.state === "completed" && ctx.results) {
				download_mbps = ctx.results.download_mbps ?? 0
				upload_mbps = ctx.results.upload_mbps ?? 0
				packet_loss_percent = ctx.results.packet_loss_percent ?? 0
			}
		}
	)

	onMount(() => {
		return () => unsubscribe()
	})
</script>

<div class="network-test-body">
	<table class="network-results-table">
		<tbody>
			<tr>
				<td>Download</td>
				<td>{download_mbps.toFixed(2)} Mbps</td>
			</tr>
			<tr>
				<td>Upload</td>
				<td>{upload_mbps.toFixed(2)} Mbps</td>
			</tr>
			<tr>
				<td>Packet Loss</td>
				<td>{packet_loss_percent.toFixed(1)}%</td>
			</tr>
		</tbody>
	</table>
</div>

<div class="button-container">
	<button
		on:click={() => {
			NetworkTestController.retry()
		}}
	>
		Test Again
	</button>
</div>

<style>
	.network-test-body {
		padding: 2rem;
	}

	.network-results-table {
		width: 100%;
		border-collapse: collapse;
		margin-top: 1rem;
		justify-content: center;
	}
	.network-results-table td {
		padding: 0.5rem 1rem;
		border-bottom: 1px solid var(--color-bg-dark);
		color: var(--color-text-light);
	}
</style>
