<script lang="ts">
	import { run } from "svelte/legacy"

	import { ToggleSwitch } from "svelte-comps/inputs"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"
	import type { Device } from "luxedo-data"

	import QRCode from "qrcode"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { LoadingSpinner } from "svelte-comps/loading"
	import { InfoDialogue } from "svelte-comps/info-dialogue"
	import Guides from "luxedo-data/src/data/Guides"

	let isLoading = $state(false)
	let isActive = $state(false)
	let activeDevice: Device
	let audioCode: string = $state()
	let qrCodeURL: string = $state()

	SelectedDeviceStore.subscribe((dev) => {
		activeDevice = dev
		if (activeDevice && activeDevice.audioCode) {
			isActive = true
			audioCode = activeDevice.audioCode
			generateQRCode()
		} else if (!activeDevice?.audioCode) {
			isActive = false
			audioCode = undefined
			qrCodeURL = undefined
		}
	})

	async function generateQRCode() {
		qrCodeURL = await QRCode.toDataURL(`http://luxe.do/${audioCode}`, {
			color: {
				dark: "#141116",
				light: "faae1b",
			},
			margin: 2,
		})
	}

	async function initializeLuxedoRadio() {
		isLoading = true
		try {
			if (activeDevice.audioCode) {
				audioCode = activeDevice.audioCode
			} else {
				audioCode = (
					await LuxedoRPC.api.all.generate_audio_code(activeDevice.id)
				).audio_code
				activeDevice.audioCode = audioCode
			}

			await generateQRCode()
		} catch (e) {
			isActive = false
			Toast.error(
				"An error occurred when activating Luxedo Radio. Please try again later."
			)
			console.error("[ERROR] Requesting audio code. ", e)
		}

		isLoading = false
	}

	function clearLuxedoRadio() {
		audioCode = undefined
		activeDevice.audioCode = undefined
	}

	run(() => {
		isActive && initializeLuxedoRadio()
	})
	run(() => {
		!isActive && clearLuxedoRadio()
	})
</script>

<div id="luxedo-radio-settings">
	<span class="lux-radio-heading">
		<div class="flex-row">
			<h3>Luxedo Radio</h3>
			<InfoDialogue
				textContent="Luxedo Radio allows your viewers to hear your show live through a smartphone, tablet, or any other internet-connected device simply by scanning a QR code. "
			/>
		</div>
		<ToggleSwitch
			isEnabled={isActive}
			onUpdate={(newVal) => (isActive = newVal)}
			disabled={isLoading}
		/>
	</span>
	{#if isLoading}
		<div class="loading-container">
			<LoadingSpinner color="var(--color-main)" height={`4rem`} />
		</div>
	{:else if isActive}
		<div class="url-preview">
			<span class="url-label"> URL: </span>
			<a target="_blank" href="http://luxe.do/{audioCode}">
				luxe.do/<span class="url-radio-id">{audioCode}</span>
			</a>
		</div>
		<div class="lux-radio-qr-container">
			<p class="qr-description">
				Use Luxedo Radio to broadcast sound for your lightshow! Scan the QR code
				or visit the URL to listen. <a
					href={Guides["Luxedo Portal"]["Luxedo Radio"]}>Learn more</a
				>
			</p>
			<a class="qr-download-link" href={qrCodeURL} download="LuxedoRadioQR.png">
				<img
					class="qr-preview"
					src={qrCodeURL}
					alt="QR Code for Luxedo Radio"
				/>
			</a>
		</div>
	{:else}
		<div class="lux-radio-qr-container">
			<p class="qr-description">
				Use Luxedo Radio to broadcast audio! Click the toggle switch above to
				get started! <a href={Guides["Luxedo Portal"]["Luxedo Radio"]}
					>Learn more</a
				>
			</p>
		</div>
	{/if}
</div>

<style>
	#luxedo-radio-settings {
		min-height: 12rem;
		height: 14rem;
		position: relative;
		/* padding-right: 1rem; */

		border-radius: var(--br);
		background-color: var(--color-bg-dark);
		padding: 1rem;
	}

	h3 {
		font-size: var(--h1);
		font-family: var(--font-body);
		color: var(--color-text-light);
		margin: 0 0 0 0;
	}

	.lux-radio-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.lux-radio-heading .flex-row {
		align-items: center;
	}

	.loading-container {
		width: 100%;
		height: calc(100% - 5rem);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.url-preview a {
		border-bottom: none;
		text-decoration-color: transparent;
		color: inherit;
		text-decoration: none;
	}

	.url-label {
		color: var(--color-text);
	}

	.url-radio-id {
		color: var(--color-main);
	}

	.lux-radio-qr-container {
		/* width: calc(100% - 2rem); */
		min-height: 8rem;
		width: calc(100% - 2rem);
		position: absolute;
		bottom: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	p {
		margin: 0;
	}

	.qr-preview {
		border-radius: var(--br);
	}

	.qr-description {
		margin-top: auto;
		line-height: 1.25rem;
		margin-right: 2rem;
		max-width: 20rem;
		color: var(--color-main);
	}

	.qr-download-link {
		min-width: 6rem;
		border: none;
		width: unset;
		/* flex-grow: 1; */
	}
</style>
