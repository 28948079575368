<script lang="ts">
	import { DeviceGroup, type DeviceBrowser } from "luxedo-data"

	interface Props {
		device: DeviceBrowser
		strokeColor?: string
		onClick?: () => void
		deviceIconElem?: SVGElement
	}

	let {
		device,
		strokeColor = undefined,
		onClick = undefined,
		deviceIconElem = $bindable(undefined),
	}: Props = $props()
</script>

<svg
	bind:this={deviceIconElem}
	onclick={onClick}
	width="49"
	height="28"
	viewBox="0 0 49 28"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_123_39)">
		<g opacity="0.5">
			<path
				d="M35.84 19.83C38.9272 19.83 41.43 17.3272 41.43 14.24C41.43 11.1527 38.9272 8.65002 35.84 8.65002C32.7527 8.65002 30.25 11.1527 30.25 14.24C30.25 17.3272 32.7527 19.83 35.84 19.83Z"
				stroke="#817E84"
				stroke-width="4"
				stroke-miterlimit="10"
			/>
			<path
				d="M29 25.39H40.4C43.53 25.39 46.06 22.85 46.06 19.73C46.06 16.61 46.06 8.66 46.06 8.66C46.06 5.53 43.52 3 40.4 3H31.5H31.16C28.0341 3 25.5 5.53407 25.5 8.66V8.66V19V19C25.5 22.5291 22.6391 25.39 19.11 25.39H18.5H8.66C5.54 25.39 3 22.86 3 19.73V8.66C3 5.54 5.53 3 8.66 3H22"
				stroke="#817E84"
				stroke-width="4"
				stroke-miterlimit="10"
				stroke-linecap="round"
			/>
		</g>
		<g opacity="0.5">
			<path
				stroke={strokeColor ?? device?.color}
				d="M35.84 19.83C38.9272 19.83 41.43 17.3272 41.43 14.24C41.43 11.1527 38.9272 8.65002 35.84 8.65002C32.7527 8.65002 30.25 11.1527 30.25 14.24C30.25 17.3272 32.7527 19.83 35.84 19.83Z"
				stroke-width="4"
				stroke-miterlimit="10"
			/>
			<path
				stroke={strokeColor ?? device?.color}
				d="M29 25.39H40.4C43.53 25.39 46.06 22.85 46.06 19.73C46.06 16.61 46.06 8.66 46.06 8.66C46.06 5.53 43.52 3 40.4 3H31.5H31.16C28.0341 3 25.5 5.53407 25.5 8.66V8.66V19V19C25.5 22.5291 22.6391 25.39 19.11 25.39H18.5H8.66C5.54 25.39 3 22.86 3 19.73V8.66C3 5.54 5.53 3 8.66 3H22"
				stroke-width="4"
				stroke-miterlimit="10"
				stroke-linecap="round"
			/>
		</g>
	</g>
	<defs>
		<clipPath id="clip0_123_39">
			<rect width="49" height="28" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
