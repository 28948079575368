<script lang="ts">
	/**
	 * To promote a more dynamic interface, the sidemenu will need to be visible always when within the portal.
	 * To simplify the managing of client side routing contexts, use this component instead of "svelte-routing"/Link
	 */
	import { Link } from "svelte-routing"
	import { NavigationStore } from "../../../stores/NavigationContext"
	import type { ROUTES } from "../../routes/Routes"

	interface Props {
		to: keyof typeof ROUTES | string
		children?: import("svelte").Snippet
	}

	let { to, children }: Props = $props()

	function onClick() {
		NavigationStore.set(to as keyof typeof ROUTES)
	}
</script>

<Link {to} on:click={onClick}>
	{@render children?.()}
</Link>

<style>
</style>
