<script lang="ts">
	import { DeviceGroup, type Device } from "luxedo-data"
	import DeviceFilterInstance from "../../../reusable/device-filter/DeviceFilter_Instance.svelte"

	interface Props {
		allDevices: Array<Device>
		selected: Device
		onselect: (d: Device) => void
	}

	let { allDevices, selected, onselect }: Props = $props()

	let allGroups = $derived(allDevices.filter((d) => d instanceof DeviceGroup))
	let activeGroups = $derived(allGroups.filter((g) => !g.isDeactivated))
	let inactiveGroups = $derived(allGroups.filter((g) => g.isDeactivated))
</script>

{#each activeGroups as group (group.id)}
	<DeviceFilterInstance
		device={group}
		filterDevice={selected}
		onSetDeviceFilter={onselect}
		hideSettings
	/>
{/each}
{#if inactiveGroups.length}
	<span class="divider">
		<hr />
		<h4>Inactive Groups</h4>
		<hr />
	</span>
	{#each inactiveGroups as group (group.id)}
		<DeviceFilterInstance
			device={group}
			filterDevice={selected}
			onSetDeviceFilter={onselect}
			hideSettings
		/>
	{/each}
{/if}

<style>
	.divider {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 1rem 0;
	}

	.divider hr {
		flex-shrink: 1;
		margin: 0 0.5rem;
	}

	.divider h4 {
		white-space: nowrap;
		flex-grow: 1;
		margin: 0;
		color: var(--color-text);
		text-align: center;
		font-size: var(--h1);
	}
</style>
