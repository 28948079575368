<script lang="ts">
	import type { Device } from "luxedo-data"
	import ProjectorIcon from "../../icons/ProjectorIcon.svelte"
	import { closeOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { DeviceUpdateManager } from "../../../../modules/device-operation-managers/DeviceUpdateManager"

	interface Props {
		overlayID: string
		device: Device
		version: string
		isBeta: boolean
	}

	let { overlayID, device, version, isBeta }: Props = $props()

	async function update() {
		closeOverlay()
		if (!device.isOnline) return Toast.error("Device must be online.")

		try {
			Toast.success(`${device.name} is now updating...`)
			await DeviceUpdateManager.triggerUpdate(device)
			Toast.success(`${device.name} has succesfully updated!`)
		} catch (e) {
			console.error("Unable to update device.", e)
		}
	}
</script>

<div id="device-update-informer">
	<div class="flex-row update-heading">
		<h1>Device Update Available</h1>
		<span>v{version}{isBeta ? "-beta" : ""}</span>
	</div>
	<p>
		A new firmware update is now available for <ProjectorIcon {device} />
		{device.name}.
		<br />
		Click the button below to begin. This may take up to 10 minutes depending on
		your network strength.
	</p>
	<span class="warn">
		While updating:
		<ul>
			<li>Do not disconnect the projector from power.</li>
			<li>
				Make sure your projector remains connected to a stable Wi-Fi network.
			</li>
		</ul>
	</span>
	<div class="button-container">
		<button class="outline-button" onclick={update}>Update now</button>
		<button class="outline-button alt" onclick={() => closeOverlay(overlayID)}
			>Skip for now</button
		>
	</div>
</div>

<style>
	#device-update-informer {
		/* color: var(--color-text); */
		max-width: 40vw;
		padding-bottom: 1.5rem;
	}

	h1 {
		margin: 1rem 0 0 0;
		font-size: var(--h0);
	}

	.update-heading span {
		margin-left: 1rem;
		margin-bottom: 0.2rem;
		color: var(--color-main);
	}

	.update-heading {
		align-items: flex-end;
		margin-bottom: 1rem;
	}

	p :global(svg) {
		height: 1rem;
		width: auto;
	}

	.warn {
		color: var(--color-main);
	}

	ul {
		margin-left: 1.25rem;
		list-style: disc;
	}

	.button-container {
		width: fit-content;
		position: absolute;
		bottom: 0;
	}

	.button-container button {
		margin-right: 1rem;
	}
</style>
