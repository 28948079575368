<script lang="ts">
	import { DeviceGroup, type Device } from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		device: Device
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	async function stopPlayback() {
		Toast.text("Stopping playback...")
		setButtonsDisabled(true)
		try {
			await device.platoCall("clear_override", [])
		} catch {
			console.log("Failed to stop playback")
		}
		setButtonsDisabled(false)
	}
</script>

<div class="action-container">
	<button class="outline-button" onclick={stopPlayback} disabled={isDisabled}
		>Stop playback</button
	>
	<span class="action-info">
		Stop any current projection from this {device instanceof DeviceGroup
			? "group and each of its linked devices"
			: "device"}.
	</span>
</div>

<style>
</style>
