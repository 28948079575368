<script lang="ts">
	import YouTubeVideoTutorial from "../../../../YouTubeVideoTutorial.svelte"
	import { GroupEditorController } from "../../GroupEditorController"
	import { GroupOverlapController } from "./GroupOverlapController"
</script>

<YouTubeVideoTutorial
	title="Blend overlapping sections"
	description={`Adjust the gamma of each overlapping section created by aligning the snapshots in the previous step. This will adjust the fading between each projector within the overlapped sections resulting in a smooth transition between each projector.
	<br /><br />If you want to disable fading between devices, click the "Disable Blending" button below - this is usually not recommended unless the projectors have no overlap.
	<!--Watch this video before getting started: -->`}
/>
<!-- videoURL="https://www.youtube.com/embed/AjUkEHkYx0I" -->
<div id="step-buttons">
	<button id="back-step-button" class="link-button" onclick={() => GroupEditorController.back()}>
		Back
	</button>
	<button
		id="next-step-button"
		class="outline-button"
		onclick={() => GroupOverlapController.next()}
	>
		Next
	</button>
</div>

<style>
</style>
