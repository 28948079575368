<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M75 37.5L50 62.5L25 37.5"
		class="svg-stroke"
		stroke={strokeColor}
		stroke-width="8.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
</style>
