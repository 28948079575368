<script lang="ts">
	const animBarIdecies = [0, 1, 2, 3, 4, 5, 6]
</script>

<div id="calibration-animation">
	{#each animBarIdecies as index}
		<div class="anim-bar" style="animation-delay: {index * 150}ms;"></div>
	{/each}

	<svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_101_9)">
			<path
				d="M10.6638 12.2338C10.2438 12.2338 9.83376 12.0738 9.51376 11.7538L0.483757 2.79376C-0.156243 2.15376 -0.166243 1.12376 0.483757 0.483757C1.12376 -0.156243 2.15375 -0.166243 2.79375 0.483757L10.6738 8.30376L18.5538 0.483757C19.1938 -0.156243 20.2238 -0.146243 20.8638 0.483757C21.5038 1.12376 21.4938 2.16376 20.8638 2.79376L11.8338 11.7538C11.5138 12.0738 11.0938 12.2338 10.6838 12.2338H10.6638Z"
				fill="#FAAE1B"
			/>
			<path
				d="M19.7038 29.3637C19.2838 29.3637 18.8738 29.2037 18.5538 28.8837L10.6738 21.0637L2.79382 28.8837C2.15382 29.5237 1.11383 29.5137 0.483826 28.8837C-0.156174 28.2437 -0.146174 27.2037 0.483826 26.5737L9.51382 17.6037C10.1538 16.9737 11.1838 16.9737 11.8138 17.6037L20.8438 26.5737C21.4838 27.2137 21.4938 28.2437 20.8438 28.8837C20.5238 29.2037 20.1038 29.3637 19.6838 29.3637H19.7038Z"
				fill="#FAAE1B"
			/>
		</g>
		<defs>
			<clipPath id="clip0_101_9">
				<rect width="22" height="30" fill="white" />
			</clipPath>
		</defs>
	</svg>
</div>

<style>
	@keyframes animationBar {
		0% {
			transform: scaleY(0.25);
			/* height: 1rem; */
			opacity: 0;
		}

		25% {
			transform: scaleY(0.25);

			/* height: 1rem; */
			opacity: 0;
		}

		50% {
			transform: scaleY(2);

			/* height: 8rem; */
			opacity: 50%;
		}

		75% {
			transform: scaleY(0.25);

			/* height: 1rem; */
			opacity: 0;
		}

		100% {
			transform: scaleY(0.25);

			/* height: 1rem; */
			opacity: 0;
		}
	}

	@keyframes luxLogo {
		30% {
			opacity: 0%;
		}

		50% {
			opacity: 100%;
		}

		80% {
			opacity: 0%;
		}
	}

	#calibration-animation {
		position: relative;
		height: 8rem;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		will-change: contents;
	}

	#calibration-animation > svg {
		animation: luxLogo;
		animation-duration: 2s;
		animation-delay: 300ms;
		animation-iteration-count: infinite;

		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		height: 4rem;
		width: 4rem;
	}

	.anim-bar {
		opacity: 0;
		height: 4rem;
		background-color: var(--color-text-light);
		width: 0.5rem;
		border-radius: var(--br);
		animation: animationBar;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		margin: 0 0.5rem;
	}
</style>
