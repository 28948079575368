import type { Device } from "luxedo-data"
import { navigateTo } from "../../../stores/NavigationContext"
import {
	ProjectorMenuController,
	type ProjectorMenuView,
} from "./ProjectorMenuController.svelte"

export function openProjectorMenu(device: Device, view?: ProjectorMenuView) {
	ProjectorMenuController.setDevice(device)
	navigateTo("projector")
	if (view) ProjectorMenuController.setView(view)
}
