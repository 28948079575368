<script lang="ts">
	interface Props {
		strokeColor?: string;
		onClick?: (e: MouseEvent) => void;
	}

	let { strokeColor = "black", onClick = undefined }: Props = $props();
</script>

<svg
	onclick={onClick}
	width="34"
	height="34"
	viewBox="0 0 34 34"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_611_59)">
		<circle cx="12" cy="12" r="10" stroke={strokeColor} class="svg-stroke" stroke-width="4" />
		<path
			d="M20 20L32 32"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="4"
			stroke-linecap="round"
		/>
	</g>
	<defs>
		<clipPath id="clip0_611_59">
			<rect width="34" height="34" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
