import { ToastStore } from "./ToastStore"

let activeTimeout
const DEFAULT = 8000

export abstract class Toast {
	static error(textContent: string, durationMs = DEFAULT) {
		if (activeTimeout) clearTimeout(activeTimeout)

		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "error",
			})
		}, 200)
	}

	static text(textContent: string, durationMs = DEFAULT) {
		if (activeTimeout) clearTimeout(activeTimeout)
		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "text",
			})
		}, 200)
	}

	static success(textContent: string, durationMs = DEFAULT) {
		if (activeTimeout) clearTimeout(activeTimeout)
		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "success",
			})
		}, 200)
	}

	static action(
		textContent: string,
		actionString: string,
		action: Function,
		durationMs = DEFAULT
	) {
		if (activeTimeout) clearTimeout(activeTimeout)
		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({ isOpen: false })
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				action,
				actionString,
			})
		}, 200)
	}
}
