<script lang="ts">
	import { DataHandlerTag, Tag } from "luxedo-data"
	import NewTag from "./NewTag.svelte"
	import SceneTag from "./SceneTag.svelte"
	import TagColorPicker from "./tag-color-picker/TagColorPicker.svelte"

	let tags: Array<Tag> = $state(DataHandlerTag.getMany())

	interface Props {
		onToggle?: (tag: Tag) => void
		isLibraryContext?: boolean
		selectedTags?: Array<number>
		alignLeft?: boolean
	}

	let {
		onToggle = undefined,
		isLibraryContext = false,
		selectedTags = $bindable([]),
		alignLeft = false,
	}: Props = $props()

	DataHandlerTag.addListener((newIds) => {
		tags = [...DataHandlerTag.getMany()]
		selectedTags = selectedTags.filter((tagId) => {
			return !!tags.find((tag) => tag.id === tagId)
		})
	})

	function onTagClick(tag: Tag) {
		if (onToggle) return onToggle(tag)

		const tagIndex = selectedTags.findIndex((tagId) => {
			return tagId == tag.id
		})

		if (tagIndex !== -1) {
			selectedTags.splice(tagIndex, 1)
		} else {
			selectedTags.push(tag.id)
		}

		selectedTags = [...selectedTags]
	}

	function isTagSelected(thisTag: Tag, allSelectedTags: Array<number>) {
		return allSelectedTags.includes(thisTag.id)
	}
</script>

<div
	id="scene-tags"
	class={isLibraryContext ? "library-tags" : "scene-form-tags"}
>
	<div class="scene-tag-list {alignLeft ? 'align-left' : ''}">
		{#each tags as tag}
			<SceneTag
				{tag}
				color={tag.color}
				name={tag.title}
				onClick={() => onTagClick(tag)}
				isSelected={isTagSelected(tag, selectedTags)}
				{isLibraryContext}
			/>
		{/each}
	</div>
	<NewTag hasNoTags={!tags.length} />
	<TagColorPicker {isLibraryContext} />
</div>

<style>
	#scene-tags {
		margin: 0 1rem;
		overflow-x: auto;
		overflow-y: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;
		/* justify-content: flex-end; */
	}

	#scene-tags.scene-form-tags {
		margin-left: 0;
	}

	.scene-form-tags::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}

	/* Handle */
	#scene-tags::-webkit-scrollbar-thumb,
	.scene-form-tags::-webkit-scrollbar-thumb {
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
		background-color: var(--color-text-dark);
	}

	.scene-tag-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		/* margin-left: auto; */
	}

	.scene-tag-list.align-left {
		margin-left: unset;
	}

	#scene-tags > :global(.tag) {
		position: sticky;
		right: 0;
	}
</style>
