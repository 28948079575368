<script lang="ts">
	import { GroupEditorController } from "../../GroupEditorController"
	import { GroupCalibrationController } from "./GroupCalibrationController"
</script>

<h3>Calibrate each device</h3>
<p class="info">
	To simplify the alignment and scene creation process, calibrate each device to create a snapshot.
</p>
<button class="outline-button" onclick={GroupCalibrationController.calibrate}>Calibrate Now</button>

<div id="step-buttons">
	<button id="back-step-button" class="link-button" onclick={() => GroupEditorController.back()}>
		Back
	</button>
	<button id="next-step-button" onclick={() => GroupEditorController.next()}> Skip </button>
</div>

<style>
</style>
