<script lang="ts">
	import { DataHandlerDevice, Device, DeviceGroup } from "luxedo-data"
	import DeviceFilterInstance from "./DeviceFilter_Instance.svelte"
	import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"

	interface Props {
		selectedDevice?: Device
		onSetDeviceFilter?: (device: Device) => void
		heading?: string
	}

	let {
		selectedDevice = undefined,
		onSetDeviceFilter = SelectedDeviceStore.set,
		heading = "Filter by Projector",
	}: Props = $props()

	let projectors = $state(sortDevices(DataHandlerDevice.getMany()))

	DataHandlerDevice.addListener(() => {
		projectors = sortDevices(DataHandlerDevice.getMany())
	})

	function sortDevices(devices: Array<Device>) {
		const scoreDevice = (dev: Device) => {
			let score = 0
			if (dev instanceof DeviceGroup) {
				score = dev.deactivated ? 10 : -10
			}

			if (!dev.isOnline) score += 5

			return score
		}
		return devices.toSorted((a, b) => {
			let score = scoreDevice(a) - scoreDevice(b)
			if (score == 0) score = a.name.localeCompare(b.name)
			return score
		})
	}
</script>

<div class="device-filter-container">
	{#if heading}
		<h3>{heading}</h3>
	{/if}
	<div class="device-filter">
		{#each projectors as device}
			<DeviceFilterInstance
				{device}
				filterDevice={selectedDevice}
				{onSetDeviceFilter}
			/>
		{/each}
	</div>
</div>

<style>
	h3 {
		color: var(--color-text-light);
		margin: 0 1rem 1rem 0;
		font-size: var(--h1);
		text-align: center;
	}

	.device-filter-container {
		overflow: hidden;
		min-width: 18rem;
		width: 20rem;
		height: 100%;
	}

	.device-filter {
		--head-height: calc(var(--h1) + 1.5rem);
		/* height: calc(100% - var(--head-height)); */
		height: calc(100% - var(--head-height));
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 0.25rem;
	}
</style>
