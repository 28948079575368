<script lang="ts">
	import { DataHandlerDevice, Snapshot, type DeviceGroupSlot } from "luxedo-data"
	import type { OverlapInstance } from "../"
	import { BlendZoneEditorController } from "./BlendZoneEditorController"

	type EdgeType = "top" | "right" | "bottom" | "left"
	type Point = { x: number; y: number }

	let canvas: HTMLCanvasElement = $state()
	let context: CanvasRenderingContext2D

	let overlap: OverlapInstance = $state()
	let slot: DeviceGroupSlot

	let furthestPoints: Array<EdgeType> = []

	let isSizeHalved = false
	let width: number = $state()
	let height: number = $state()

	let otherSnapshot: Snapshot = $state()
	let otherSnapshotPosition: { x: number; y: number; width: number; height: number } = $state()

	BlendZoneEditorController.subscribe(async (ctx) => {
		overlap = ctx.overlap
		slot = overlap.slots.find((slot) => slot.id === ctx.mainSlotID)

		const otherSlot = overlap.slots.find((s) => s.id !== ctx.mainSlotID)
		otherSnapshot = await DataHandlerDevice.get(otherSlot.device_id).getSnapshot()
		otherSnapshotPosition = BlendZoneEditorController.Canvas.getSlotPosition(otherSlot)

		findFurthestPoints()
	})

	/**
	 * Determines which edges of the device are flush with the canvas.
	 * This is used to calculate which side of the user-drawn path needs to be filled.
	 */
	function findFurthestPoints() {
		furthestPoints = []

		const snapshotRect = {
			top: slot.pos_y,
			left: slot.pos_x,
			right: slot.pos_x + slot.width * slot.scale_x,
			bottom: slot.pos_y + slot.height * slot.scale_y,
		}

		if (snapshotRect.left === overlap.rect.left) furthestPoints.push("left")
		if (snapshotRect.right === overlap.rect.right) furthestPoints.push("right")
		if (snapshotRect.top === overlap.rect.top) furthestPoints.push("top")
		if (snapshotRect.bottom === overlap.rect.bottom) furthestPoints.push("bottom")
	}

	let isDrawing = false
	let pathPoints: Array<Point>
	let drawStartEdge: EdgeType
	let drawEndEdge: EdgeType

	/**
	 * Gets the mouse point on the canvas (removes the element padding and overlay offset).
	 */
	function getMousePos(e: MouseEvent) {
		const rect = canvas.getBoundingClientRect()
		return { x: e.clientX - rect.left, y: e.clientY - rect.top }
	}

	/**
	 * Draws a line where the user moves their mouse.
	 * This will only be active after the user clicked on a border to start drawing.
	 */
	function onMouseMove(
		e: MouseEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		const pos = getMousePos(e)
		context.lineTo(pos.x, pos.y)
		context.stroke()
		context.moveTo(pos.x, pos.y)
		pathPoints.push({ ...pos })
	}

	/**
	 * Returns the inverse of the initial cross points.
	 * Orders the points so they can be used to finish creating a clip path.
	 */
	function getInverseCrossPoints(
		points: Array<Point>,
		endEdge: EdgeType,
		endPoint: Point
	): Array<Point> {
		const cornerPoints = [
			{ x: -1, y: -1 },
			{ x: width + 1, y: -1 },
			{ x: -1, y: height + 1 },
			{ x: width + 1, y: height + 1 },
		]

		let remainingCornerPoints = [...cornerPoints]
		for (const point of points) {
			remainingCornerPoints = remainingCornerPoints.filter(
				(p) => !(p.x === point.x && p.y === point.y)
			)
		}

		let startIndex
		switch (endEdge) {
			case "top":
				startIndex = remainingCornerPoints.findIndex((pt) => pt.y === endPoint.y - 1)
				break
			case "right":
				startIndex = remainingCornerPoints.findIndex((pt) => pt.x === endPoint.x + 1)
				break
			case "bottom":
				startIndex = remainingCornerPoints.findIndex((pt) => pt.y === endPoint.y + 1)
				break
			case "left":
				startIndex = remainingCornerPoints.findIndex((pt) => pt.x === endPoint.x - 1)
				break
		}

		let ordered = remainingCornerPoints.splice(startIndex, 1)

		let loopIndex = 0
		while (remainingCornerPoints.length) {
			if (loopIndex > 3) throw new Error("Endless loop detected")

			let nextPointIndex = remainingCornerPoints.findIndex(
				(pt) => pt.x === ordered[loopIndex].x || pt.y === ordered[loopIndex].y
			)

			const nextPoint = remainingCornerPoints.splice(nextPointIndex, 1)
			ordered = ordered.concat(nextPoint)

			loopIndex++
		}

		return ordered
	}

	/**
	 * Returns the points needed to finish a path without crossing over the canvas.
	 */
	function getCanvasEdgeCrossPoint(
		edgeOrigin: EdgeType,
		edgeEnd: EdgeType,
		endPoint: Point
	): Array<Point> {
		if (
			(edgeOrigin === "left" && edgeEnd === "right") ||
			(edgeOrigin === "right" && edgeEnd === "left")
		) {
			const point1 = {
				x: -1,
				y: -1,
			}

			const point2 = {
				x: canvas.width + 1,
				y: -1,
			}

			if (endPoint.x === 0) return [point1, point2]
			if (endPoint.x === canvas.width) return [point2, point1]
		}

		if (
			(edgeOrigin === "top" && edgeEnd === "bottom") ||
			(edgeOrigin === "bottom" && edgeEnd === "top")
		) {
			const point1 = {
				x: -1,
				y: -1,
			}

			const point2 = {
				x: -1,
				y: canvas.height + 1,
			}

			if (endPoint.y === 0) return [point1, point2]
			if (endPoint.y === canvas.height) return [point2, point1]
		}

		let crossPoint = {
			x: 0,
			y: 0,
		}

		if (edgeOrigin === "top" || edgeEnd === "top") crossPoint.y = -1
		if (edgeOrigin === "bottom" || edgeEnd === "bottom") crossPoint.y = canvas.height + 1
		if (edgeOrigin === "left" || edgeEnd === "left") crossPoint.x = -1
		if (edgeOrigin === "right" || edgeEnd === "right") crossPoint.x = canvas.width + 1

		return [crossPoint]

		// ! Handle if both edges are the same
	}

	/**
	 * Returns the "edge point" based on the users start or end point.
	 * This is used to move the path start/end to the edge of the canvas.
	 */
	function getCanvasEdgePosition(edge: EdgeType, position: Point) {
		switch (edge) {
			case "top":
				return {
					x: position.x,
					y: 0,
				}
			case "bottom":
				return {
					x: position.x,
					y: canvas.height,
				}
			case "right":
				return {
					x: canvas.width,
					y: position.y,
				}
			case "left":
				return {
					x: 0,
					y: position.y,
				}
		}
	}

	/**
	 * Checks how much of the snapshot edge is covered by the initial path.
	 * If the initial path does not cover enough of the snapshot's edge (and the invert does), returns true. Otherwise, returns false.
	 */
	function checkForNeededInvert(edgePoints: Array<Point>) {
		const findTopPoints = () => edgePoints.filter((point) => point.y <= 0) as [Point, Point]
		const findRightPoints = () => edgePoints.filter((point) => point.x >= width) as [Point, Point]
		const findBottomPoints = () => edgePoints.filter((point) => point.y >= height) as [Point, Point]
		const findLeftPoints = () => edgePoints.filter((point) => point.x <= 0) as [Point, Point]

		const getWidth = (points: [Point, Point]) => {
			if (points.length < 2) return 0
			const sorted = points.sort((a, b) => b.x - a.x)
			return sorted[0].x - sorted[1].x
		}

		const getHeight = (points: [Point, Point]) => {
			if (points.length < 2) return 0
			const sorted = points.sort((a, b) => b.y - a.y)
			return sorted[0].y - sorted[1].y
		}

		const normalize = (value: number) => {
			const maxed = Math.max(value, 0)
			return Math.min(maxed, 1)
		}

		const initial = {
			top: normalize(getWidth(findTopPoints()) / width),
			right: normalize(getHeight(findRightPoints()) / height),
			bottom: normalize(getWidth(findBottomPoints()) / width),
			left: normalize(getHeight(findLeftPoints()) / height),
		}

		const inverted = {
			top: 1 - initial.top,
			right: 1 - initial.right,
			bottom: 1 - initial.bottom,
			left: 1 - initial.left,
		}

		let initialTotal = 0
		let invertedTotal = 0
		for (const edge of ["left", "top", "bottom", "right"] as Array<EdgeType>) {
			if (furthestPoints.includes(edge)) {
				initialTotal += initial[edge]
				invertedTotal += inverted[edge]
			} else {
				initialTotal -= initial[edge]
				invertedTotal -= inverted[edge]
			}
		}

		if (initialTotal < invertedTotal) return true
		else return false
	}

	/**
	 * Called when the user clicks
	 * @param e
	 */
	function onMouseDown(
		e: MouseEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		const pos = getMousePos(e)

		function startPath(
			e: MouseEvent & {
				currentTarget: EventTarget & HTMLDivElement
			}
		) {
			pathPoints = [{ ...pos }]
			drawStartEdge = e.currentTarget.id.split("canvas-")[1] as "top" | "right" | "bottom" | "left"

			context.moveTo(pos.x, pos.y)
			context.strokeStyle = "#faae1b"
		}

		function finalizePath(
			e: MouseEvent & {
				currentTarget: EventTarget & HTMLDivElement
			}
		) {
			pathPoints.push({ ...pos })
			drawEndEdge = e.currentTarget.id.split("canvas-")[1] as "top" | "right" | "bottom" | "left"

			context.lineTo(pos.x, pos.y)
			context.stroke()

			createClipPath()

			document.removeEventListener("mousemove", onMouseMove)
		}

		function createClipPath() {
			context.beginPath()

			const startPoint = getCanvasEdgePosition(drawStartEdge, pathPoints[0])
			context.moveTo(startPoint.x, startPoint.y)

			context.strokeStyle = "#000"

			for (const point of pathPoints) {
				context.lineTo(point.x, point.y)
			}

			const endPoint = getCanvasEdgePosition(drawEndEdge, pathPoints[pathPoints.length - 1])
			context.lineTo(endPoint.x, endPoint.y)

			let crossPoints = getCanvasEdgeCrossPoint(drawStartEdge, drawEndEdge, endPoint)
			if (checkForNeededInvert([startPoint, endPoint, ...crossPoints]))
				crossPoints = getInverseCrossPoints(crossPoints, drawEndEdge, endPoint)

			for (const point of crossPoints) {
				context.lineTo(point.x, point.y)
			}

			context.closePath()
			context.stroke()
			context.fill()
			context.clip()

			context.clearRect(0, 0, width, height)

			// let allPoints = [startPoint, ...pathPoints, endPoint, ...crossPoints]
			BlendZoneEditorController.setPathPoints([startPoint, ...pathPoints, endPoint, ...crossPoints])
		}

		function drawEnd() {
			isDrawing = false
			finalizePath(e)
			document.removeEventListener("mousemove", onMouseMove)
		}

		function drawStart() {
			isDrawing = true
			startPath(e)
			document.addEventListener("mousemove", onMouseMove)
		}

		if (isDrawing) drawEnd()
		else drawStart()
	}

	let hasInitialized = false
	function initialize() {
		if (hasInitialized) return
		hasInitialized = true
		const size = BlendZoneEditorController.Canvas.initializeCanvas(canvas)
		width = size.width
		height = size.height
		isSizeHalved = size.isSizeHalved

		context = canvas.getContext("2d")
	}

	$effect(() => {
		if (overlap && canvas) initialize()
	})
</script>

<div id="canvas-wrapper" style="width: {width}px; height: {height}px;">
	<canvas {width} {height} bind:this={canvas}> </canvas>
	{#if otherSnapshot}
		<img
			id="other-snapshot"
			src={otherSnapshot.src}
			alt=""
			style="left: {otherSnapshotPosition.x}px; top: {otherSnapshotPosition.y}px; width: {otherSnapshotPosition.width}px; height: {otherSnapshotPosition.height}px;"
		/>
	{/if}
	<div id="canvas-top" class="click-start" onmousedown={onMouseDown}></div>
	<div id="canvas-left" class="click-start" onmousedown={onMouseDown}></div>
	<div id="canvas-right" class="click-start" onmousedown={onMouseDown}></div>
	<div id="canvas-bottom" class="click-start" onmousedown={onMouseDown}></div>
</div>

<style>
	canvas {
		border: 1px solid var(--color-border);
		z-index: 1;
		position: absolute;
		background-color: transparent;
	}

	#canvas-wrapper {
		position: relative;
		overflow: hidden;
		margin-bottom: 2rem;
	}

	.click-start {
		cursor: crosshair;
		z-index: 2;
	}

	#other-snapshot {
		position: absolute;
		z-index: 0;
		max-inline-size: unset;
		max-block-size: unset;
	}

	#canvas-top,
	#canvas-bottom {
		background-color: var(--color-main);
		opacity: 0.25;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		height: 15px;
		width: calc(100% - 30px);
	}

	#canvas-left,
	#canvas-right {
		background-color: var(--color-main);
		opacity: 0.25;
		position: absolute;
		width: 15px;
		height: 100%;
		top: 0;
	}

	#canvas-left {
		left: 0;
	}
	#canvas-right {
		right: 0;
	}
	#canvas-top {
		top: 0;
	}
	#canvas-bottom {
		bottom: 0;
	}
</style>
