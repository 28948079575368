<script lang="ts">
	import { DeviceGroup, DeviceRPi, type Device } from "luxedo-data"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import NetworkMenuDevice from "./NetworkMenu_Device.svelte"
	import NetworkMenuGroup from "./NetworkMenu_Group.svelte"

	let device: Device = $derived(ProjectorMenuController.ctx.device)
</script>

<div class="projector-menu">
	{#if device instanceof DeviceGroup}
		<NetworkMenuGroup group={device} />
	{:else if device instanceof DeviceRPi}
		<NetworkMenuDevice {device} />
	{/if}
</div>

<style>
	.projector-menu {
		justify-content: space-between;
	}
</style>
