<script lang="ts">
	import { DataHandlerDevice, DeviceGroup, type Scene } from "luxedo-data"
	import { DateTime } from "luxon"
	import DownloadIcon from "../../../icons/DownloadIcon.svelte"
	import { openOverlay } from "svelte-comps/overlay"
	import SceneDownloader from "../../scene-download/SceneDownloader.svelte"
	import { openSceneDownloader } from "../../scene-download"

	interface Props {
		scene: Scene
	}

	let { scene }: Props = $props()

	/**
	 * This function would be responsible for displaying the duration of the show in the appropriate format,
	 * i.e, XXh, YYm, ZZs.
	 * @param duration
	 */
	function formatDuration(duration: number) {
		if (!duration) {
			return "N/A"
		}

		const hours = Math.floor(duration / 3600)
		const remainder = duration % 3600
		const minutes = Math.floor(remainder / 60)
		const seconds = remainder % 60

		// This is for making the the time to be displayed in the readable format, for example,
		// just 29s instead of 00h 00m 29s.
		let result = `${Math.round(seconds)}s`

		if (minutes > 0 || hours > 0) {
			result = `${minutes}m ` + result
		}
		if (hours > 0) {
			result = `${hours}h ` + result
		}

		return result
	}

	const initializeDownload = () => {
		const dev = DataHandlerDevice.get(scene.target_device_id)
		if (dev && dev instanceof DeviceGroup) {
			openSceneDownloader(scene)
		} else scene.triggerDownload()
	}
</script>

<div>
	<h3>Scene Details</h3>
	<div class="details">
		<ul>
			<li>
				<span class="label">Duration:</span>
				{formatDuration(scene.duration ?? 0)}
			</li>
			<li>
				<span class="label">Resolution:</span>
				{scene.res_x} x {scene.res_y}
			</li>
			<li>
				{#if scene.isDirectUpload}
					<span class="label">Uploaded on:</span>
				{:else}
					<span class="label">Last render:</span>
				{/if}
				{#if scene.render_ver}
					{DateTime.fromJSDate(scene.render_ver).toFormat("ff")}
					<button
						class="icon small"
						id="download-button"
						onclick={() => initializeDownload()}
					>
						<DownloadIcon />
					</button>
				{:else}
					Never
				{/if}
			</li>
		</ul>
	</div>
</div>

<style>
	li {
		display: flex;
		align-items: center;
	}

	li > span {
		margin-right: 0.25rem;
	}

	#download-button {
		height: 1rem;
		margin-left: 0.5rem;
	}

	#download-button :global(svg) {
		height: 1.25rem;
	}
</style>
