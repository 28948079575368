<script lang="ts">
	import type { Media } from "luxedo-data"
	import MediaEditorContainer from "../base/MediaTool_Container.svelte"
	import MediaTrimmerTimeline from "./MediaTrimmer_Timeline.svelte"
	import MediaToolVideo from "../base/MediaTool_Video.svelte"
	import { MediaTrimController } from "../MediaToolController"

	interface Props {
		media: Media
	}

	let { media }: Props = $props()
	let tool: "trim" = "trim"
</script>

<MediaEditorContainer {tool}>
	<MediaToolVideo {media} videoController={MediaTrimController} />
	<div id="media-trimmer-controls">
		<MediaTrimmerTimeline {media} />
	</div>
</MediaEditorContainer>

<style>
	#media-trimmer-controls {
		display: flex;
		flex-direction: column;
	}
</style>
