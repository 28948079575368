import type { BundleOf, DataHandler, Entry, EntryRawData } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import { RPCError } from "../../../rpc-client"
import {
	DataHandlerCalibration,
	DataHandlerDevice,
	DataHandlerFolder,
	DataHandlerLightshow,
	DataHandlerMask,
	DataHandlerMedia,
	DataHandlerScene,
	DataHandlerSnapshot,
	DataHandlerTag,
	DataHandlerUser,
	type User,
} from "luxedo-data"

export class PageInit {
	declare datahandlers: Array<DataHandler<any>>
	declare userDatahandler: DataHandler<User>
	declare isInitRPC: boolean
	declare isDatahandlersRefreshsed: boolean
	declare apiURL: string
	declare setUser: (user: User) => void

	declare onLoadCallbacks: Array<Function>

	constructor(
		datahandlers: Array<DataHandler<any>>,
		userDatahandler: DataHandler<User>,
		apiURL: string,
		setUserFn: (user: User) => void
	) {
		this.datahandlers = datahandlers
		this.userDatahandler = userDatahandler
		this.isInitRPC = false
		this.apiURL = apiURL
		this.setUser = setUserFn
		this.onLoadCallbacks = []
	}

	async postInit(fn: () => Promise<void> | void) {
		this.onLoadCallbacks.push(fn)
	}

	private async onLoadFinished() {
		for (const cb of this.onLoadCallbacks) {
			await cb()
		}
	}

	async initPageLoad(skipPull?: boolean) {
		if (!this.isInitRPC) await this.initRPC()
		try {
			await this.setCurrentUser()
			if (!skipPull && !this.isDatahandlersRefreshsed) await this.refreshData()
			await this.onLoadFinished()
			return true
		} catch (e) {
			if (e instanceof RPCError && e.statusCode == 403) {
				console.error("Invalid login")
				return false
			} else {
				throw e
			}
		}
	}

	async onUserLogin() {
		await this.setCurrentUser()
	}

	async onUserLogout() {
		this.isDatahandlersRefreshsed = false
		await LuxedoRPC.api.login.logout_logout()
	}

	public async initRPC() {
		await LuxedoRPC.connect({
			apiUrl: this.apiURL,
		})
		this.isInitRPC = true
	}

	private async setCurrentUser() {
		const id = await LuxedoRPC.api.user.account_get_current_user()
		await this.refreshUserDataHandler()
		const user = this.userDatahandler.get(id)
		this.setUser(user)
		this.isDatahandlersRefreshsed = false
	}

	// #region DATAHANDLERS

	private async refreshUserDataHandler() {
		await this.userDatahandler.pull()
	}

	private async refreshData(): Promise<void> {
		return new Promise((res, rej) => {
			const promises = this.datahandlers.map((dh) => {
				return dh.pull()
			})
			Promise.all(promises)
				.then(() => {
					this.isDatahandlersRefreshsed = true
					res()
				})
				.catch(rej)
		})
	}

	// #endregion
}

/**
 * This class replaces PageInit to switch the initial load process from multiple requests to one
 */
export class InitPageLoader {
	declare isInitRPC: boolean
	declare isDatahandlersRefreshed: boolean
	declare apiURL: string
	declare setUser: (user: User) => void

	declare onLoadCallbacks: Array<Function>

	constructor(apiUrl: string, setUserFn: (user: User) => void) {
		this.isInitRPC = false
		this.apiURL = apiUrl
		this.setUser = setUserFn
		this.onLoadCallbacks = []
	}

	public async initRPC() {
		await LuxedoRPC.connect({
			apiUrl: this.apiURL,
		})
		this.isInitRPC = true
	}

	async onUserLogout() {
		this.isDatahandlersRefreshed = false
		await LuxedoRPC.api.login.logout_logout()
	}

	/**
	 * Add a method to be called after initial data load
	 * @param fn a function to call
	 */
	async postInit(fn: () => Promise<void> | void) {
		this.onLoadCallbacks.push(fn)
	}

	/**
	 * Called once all data is loaded, triggers callbacks added in postInit
	 */
	private async onLoadFinished() {
		for (const fn of this.onLoadCallbacks) {
			await fn()
		}
	}

	/**
	 * Pulls all user data and resolves after all data is pulled
	 * @param skipPull if true, don't pull fresh data
	 */
	async initPageLoad(skipPull?: boolean) {
		if (!this.isInitRPC) await this.initRPC()

		try {
			const initData = await LuxedoRPC.api.user.account_fetch_startup_data()

			console.log({ initData })

			DataHandlerUser.handlePulledData(initData["user"])
			this.setUser(DataHandlerUser.get(Object.values(initData["user"])[0].id))

			DataHandlerDevice.handlePulledData(initData["device"])
			DataHandlerCalibration.handlePulledData(initData["calibration"])
			DataHandlerScene.handlePulledData(initData["scene"])
			DataHandlerLightshow.handlePulledData(initData["lightshow"])
			DataHandlerMedia.handlePulledData(initData["media"])
			DataHandlerFolder.handlePulledData(initData["folder"])
			DataHandlerTag.handlePulledData(initData["tag"])
			DataHandlerSnapshot.handlePulledData(initData["snapshot"])
			DataHandlerMask.handlePulledData(initData["mask"])

			await this.onLoadFinished()
			return true
		} catch (e) {
			if (e instanceof RPCError && e.statusCode == 403) {
				console.error("Invalid login")
				return false
			} else {
				throw e
			}
		}
	}
}
