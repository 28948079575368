import { fabric } from "fabric"
import { AudioAsset, CanvasAsset, Text, TrackMask } from "../asset"
import { EditorClass } from "../Editor"
import { KeyframeEvent, TrackEvent } from "../events"
import { Track, TrackLike } from "../tracks"
import { TrackRenderManager, TrackRenderObject, TrackRendererType } from "../tracks/Renderer"

import { ClippingMask } from "../asset"

export interface CanvasOptions {
	container: HTMLElement
	canvasWidth: number
	canvasHeight: number
	backgroundColor: string
	canvasColor: string
}

export interface Canvas extends fabric.Canvas, CanvasOptions {
	backgroundColor: string
	canvasWidth: number
	canvasHeight: number

	getActiveObjects(): CanvasAsset[]
}

export class Canvas extends fabric.Canvas {
	//#region    ===========================		  		Construction				==============================

	editor: EditorClass
	constructor(editor: EditorClass, options: CanvasOptions) {
		const canvasElement = Canvas.initElement(options.container)
		super(canvasElement, {
			...CANVAS_DEFAULTS,
			...options,
			width: options.container.clientWidth,
			height: options.container.clientHeight,
		})

		this.editor = editor
		this.resetBackground()
		this.initEvents()

		this.setTrackRenderer(TrackRenderObject)
	}

	static initElement(container: HTMLElement) {
		container.replaceChildren()
		const newCanvas = document.createElement("canvas")
		newCanvas.setAttribute("id", `_luxcanvas`)
		container.appendChild(newCanvas)

		return newCanvas
	}

	private initEvents() {
		this.on("mouse:up", (e: fabric.IEvent<MouseEvent>) => {
			if (e.button != 3) return
			if (this.editor.viewport.panning) return
			// e.e.preventDefault()

			const target = e.target
			this.editor.contextMenu.activate(target, e.e, e)
		})

		this.on("mouse:down", (e) => {
			// e.e.preventDefault()
			this.editor.contextMenu.clear()
		})

		this.initTrackEvents()
		this.initSelectionEvents()
		this.initTimelineEvents()
	}

	private initTrackEvents() {
		// Handle timestamp changes
		this.editor.on(TrackEvent, "tracklist:add", (e) => {
			this.requestRenderAll()
		})

		this.editor.on(TrackEvent, "tracklist:delete", (e) => {
			this.requestRenderAll()
		})

		// Make sure new objects are in the correct layer when added
		this.editor.on(TrackEvent, "tracklist:move", (e) => {
			this.requestRenderAll()
		})

		this.editor.on(KeyframeEvent, "*", (e) => {
			this.requestRenderAll()
		})
	}

	private initSelectionEvents() {
		/* Very important canvas event forwarding below this point */

		this.editor.selection.on("*", (e) => {
			// Check if the selected assets are hidden by a mask and update perPixelTargetFind
			if (e.eventName === "selection:cleared") {
				for (const asset of e.previousSelection) {
					if (
						asset instanceof CanvasAsset &&
						(asset.clipPath instanceof ClippingMask || asset.clipPath instanceof TrackMask)
					) {
						// Text will never have per pixel target find
						if (asset instanceof Text) continue
						asset.perPixelTargetFind = true
					}
				}
			} else if (e.eventName === "selection:created") {
				for (const asset of e.selected) {
					if (
						asset instanceof CanvasAsset &&
						(asset.clipPath instanceof ClippingMask || asset.clipPath instanceof TrackMask)
					) {
						asset.perPixelTargetFind = false
					}
				}
				for (const asset of e.previousSelection) {
					if (e.selected.includes(asset)) continue
					if (asset instanceof CanvasAsset && !(asset instanceof Text))
						asset.perPixelTargetFind = true
				}
			}

			if (e.origin != "canvas") {
				this.requestRenderAll()
			}
		})
	}

	private initTimelineEvents() {
		this.editor.timeline.on("preview:*", () => {
			this.updateAnimatedAssets()
		})
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  	    Rendering API				==============================

	private tempObjects: fabric.Object[] = []
	addTemporaryObject(object: fabric.Object) {
		this.tempObjects.push(object)

		this.requestRenderAll()
	}
	clearTemporaryObjects() {
		for (const obj of this.tempObjects) {
			this.remove(obj)
		}
		this.tempObjects = []
		this.requestRenderAll()
	}

	updateAnimatedAssets() {
		for (const asset of this._objects) {
			if (
				!asset.visible ||
				!(asset instanceof CanvasAsset) ||
				!asset.track ||
				!asset.track.isAnimated()
			)
				continue
			asset.setCoords()
		}
		this.requestRenderAll()
	}

	trackRenderManager: TrackRenderManager
	setTrackRenderer(trackRendererClass: TrackRendererType) {
		this.trackRenderManager = new TrackRenderManager(this.editor, trackRendererClass)
	}

	/**
	 * @private
	 * @param {CanvasRenderingContext2D} ctx Context to render on
	 * @param {Array} objects to render
	 */
	_renderObjects(ctx, objects) {
		let i
		for (i = 0; i < objects.length; ++i) {
			objects[i] && objects[i].render(ctx)
		}

		for (const tmp of this.tempObjects) {
			tmp.render(ctx)
		}
	}

	/**
	 * Divides objects in two groups, one to render immediately
	 * and one to render as activeGroup.
	 * @return {Array} objects to render immediately and pushes the other in the activeGroup.
	 */
	_chooseObjectsToRender() {
		const activeObjects = this.getActiveObjects()
		let objsToRender = []

		// Add new mask types to the renderer
		this.editor.masks
			.all()
			.forEach((mask) => (objsToRender = objsToRender.concat([mask, mask.path])))

		this.editor.tracks.traverse((track: TrackLike<CanvasAsset>) => {
			if (track.hidden) return

			if (track instanceof Track) {
				const asset = track.asset
				if (asset instanceof AudioAsset) return
				if (!activeObjects.includes(asset) || this.preserveObjectStacking) objsToRender.push(asset)
			}

			const renderTrack = this.trackRenderManager && this.trackRenderManager.shouldRender(track)
			if (renderTrack) objsToRender.push(this.trackRenderManager.getRenderer(track))

			if (track.trackMask) {
				// objsToRender = objsToRender.concat(track.trackMask.masks)
			}

			// if (track.hasOwnMask()) {
			// 	if (track.clippingMask instanceof ClippingMask) {
			// 		objsToRender.push(track.clippingMask.selectionPath)
			// 	} else if (track.clippingMask instanceof MultiMask) {
			// 		objsToRender.push(...track.clippingMask.masks.map((m) => m.selectionPath))
			// 	}
			// }
		})

		//objsToRender = objsToRender.concat(this.tempObjects)
		if (!this.preserveObjectStacking) objsToRender.push(...activeObjects)
		objsToRender.unshift(this.canvasBackground)

		for (const obj of objsToRender) {
			if (!this._objects.includes(obj)) this.add(obj)
		}
		for (const obj of this._objects) {
			if (!objsToRender.includes(obj)) this.remove(obj)
		}

		this._objects = objsToRender.slice()

		return objsToRender
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  	  Scene Background				==============================

	private canvasBackground: fabric.Image

	resize(width: number, height: number) {
		const elem = this.getElement()

		this.editor.canvas = new Canvas(this.editor, {
			backgroundColor: this.backgroundColor,
			canvasColor: this.canvasColor,
			canvasWidth: width,
			canvasHeight: height,
			container: elem.parentElement,
		})

		this.editor.canvas.initialize(elem, {
			width,
			height,
		})

		this.requestRenderAll()
	}

	resetBackground() {
		// Remove the old background
		if (this.canvasBackground) this.remove(this.canvasBackground)

		const backGroundElement = new Image(this.canvasWidth, this.canvasHeight)

		this.canvasBackground = new fabric.Image(backGroundElement, {
			width: this.canvasWidth,
			height: this.canvasHeight,
			left: 0,
			top: 0,
			backgroundColor: this.canvasColor as string,
			selectable: false,
			evented: false,
			originX: "left",
			originY: "top",
		})

		// Add the new one
		this.add(this.canvasBackground)
		this.sendToBack(this.canvasBackground)
	}

	/**
	 * Set the canvas background
	 * Note that it is important for the canvasWidth and canvasHeight
	 * @param imageSrc
	 * @returns
	 */
	async setBackground(imageSrc: string) {
		const element: HTMLImageElement = this.canvasBackground.getElement() as HTMLImageElement
		await new Promise((res, rej) => {
			element.onload = res
			element.onerror = rej
			element.src = imageSrc
			console.warn(`Loading ${imageSrc}`)
		})

		if (element.naturalWidth != this.canvasWidth || element.naturalHeight != this.canvasHeight) {
			throw TypeError(
				`Background dimensions ${element.width}x${element.height} do not match configured canvas size ${this.canvasWidth}x${this.canvasHeight}. Make sure your canvas is initialized correctly.`
			)
		}

		this.renderAll()
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  	   Import/Export API			==============================

	/**
	 * Exports the current editor's canvas as a dataurl image in the specified format
	 * @param format The format of the image to be exported (e.g. "jpeg", "png")
	 */
	public exportImage(format: "png" | "jpeg" = "png"): string | undefined {
		const bounds = this.calcViewportBoundaries()

		const backgroundColor = this.backgroundColor

		let img: string | undefined
		try {
			this.canvasBackground.visible = false
			this.setBackgroundColor("#000", () => {})

			img = this.toDataURL({
				format: format,
				top: this.canvasBackground.top! - bounds.tl.y,
				left: this.canvasBackground.left! - bounds.tl.x,
				width: this.canvasWidth,
				height: this.canvasHeight,
			})
		} catch (e) {
			console.error(e)
		} finally {
			this.canvasBackground.visible = true
			this.setBackgroundColor(backgroundColor, () => {})
			return img
		}
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Selection Intercept				==============================

	setActiveObject(object: CanvasAsset | fabric.ActiveSelection, canvasEvent?: Event) {
		if (object instanceof fabric.ActiveSelection) {
			if (this.editor.selection.locked) {
				object.destroy()
				return this as fabric.Canvas
			}
			super.setActiveObject(object, canvasEvent)
			this.editor.selection.set(object._objects, {
				origin: "canvas",
			})

			return this
		}

		if (this.editor.selection.locked) return this as fabric.Canvas

		this.editor.selection.set(object as CanvasAsset, {
			origin: "canvas",
		})

		return this as fabric.Canvas
	}

	refreshSelection(canvasEvent?: Event) {
		const selection = this.editor.selection.getCanvas()
		const currentState = this.getActiveObject()

		if (selection.length === 0) return super.discardActiveObject(canvasEvent)
		if (selection.length === 1) {
			if (selection[0] === currentState) return
			return super.setActiveObject(selection[0], canvasEvent)
		}

		if (!(currentState instanceof fabric.ActiveSelection)) {
			const group = new fabric.ActiveSelection(selection, {
				canvas: this.editor.canvas,
			})
			super.setActiveObject(group, canvasEvent)
			return
		}

		currentState.forEachObject((obj) => {
			if (!selection.includes(obj)) currentState.removeWithUpdate(obj)
		})

		selection.forEach((obj) => {
			if (!currentState.contains(obj)) currentState.addWithUpdate(obj)
		})
		return
	}

	getActiveObjects(): fabric.Object[] {
		return this.editor.selection.getCanvas()
	}

	discardActiveObject(fabricEvent?: Event) {
		this.editor.selection.clear()
		return this as fabric.Canvas
	}
	//#endregion =====================================================================================================
}

const CANVAS_DEFAULTS: Partial<fabric.ICanvasOptions> = {
	stopContextMenu: true,
	fireRightClick: true,
	selection: true,
	skipOffscreen: false,
}
