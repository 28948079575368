<script lang="ts">
	import { DataHandlerMedia, type Media } from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import { openMediaPreview, RenderProgressIndicator, TileLabel } from "svelte-comps/luxedo"
	import { DragController } from "../../../../stores/DragContext"
	import {
		type ContextMenuOptions,
		triggerContextMenuWithGenerator,
	} from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../../modules/ctx-menu/ContextMenuOptions"
	import { UploadProcessManager } from "svelte-comps/stores"

	interface Props {
		media: Media;
	}

	let { media }: Props = $props();

	let isDragging = $state(false)
	let isProcessing = $state(false)
	let progress: number = $state(0)

	let triggerRename: () => void = $state()

	UploadProcessManager.subscribeTo(media.id, ({ progress: prog, didFail, didComplete }) => {
		if (prog !== 1) isProcessing = true
		progress = prog
		if (didFail || didComplete)
			setTimeout(() => {
				isProcessing = false
			})
	})

	const generateCtxMenuOpts = (): ContextMenuOptions => {
		return CTXOptions.Media.createOptions(media)
	}

	function onClick() {
		openMediaPreview(media, triggerContextMenuWithGenerator(generateCtxMenuOpts))
	}

	async function onRename(newName: string) {
		try {
			await DataHandlerMedia.rename(media, newName)
			Toast.success("Media renamed.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error("There was an error renaming this media, please refresh and try again.")
		}
	}

	function onDragStart() {
		isDragging = true
	}

	function onDragEnd() {
		isDragging = false
	}
</script>

<div>
	<div
		class="tile-container {isDragging ? 'is-dragging' : ''}"
		id="media-tile-{media.id}"
		role="application"
		onclick={onClick}
		oncontextmenu={triggerContextMenuWithGenerator(generateCtxMenuOpts)}
		ondragend={DragController.onDragEnd(onDragEnd)}
		ondragstart={DragController.onDragStart(media, onDragStart)}
		ontouchstart={DragController.onDragStart(media, onDragStart)}
		draggable={true}
	>
		<div class="tile-icon-container">
			<div class="tile">
				{#if isProcessing}
					<RenderProgressIndicator {progress} />
				{:else}
					<img src={media.src.thumbnail} alt="{media.name} Thumbnail" />
				{/if}
				<!-- {#if thumbnailURL} -->
				<!-- {:else} -->
				<!-- <LoadingSpinner height="4rem" color="var(--color-main)" /> -->
				<!-- {/if} -->
			</div>
		</div>
		<div class="tile-label-container">
			<TileLabel file={media} {onRename} bind:triggerRename />
		</div>
	</div>
</div>

<style>
	.tile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
