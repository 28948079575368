<script lang="ts">
	import type { DeviceRPi } from "luxedo-data"
	import ConnectedNetworkPreview from "./network-preview/ConnectedNetworkPreview.svelte"
	import DeviceMenuBlock from "../../DeviceMenuBlock.svelte"
	import EnableHotspotInput from "../settings/inputs/EnableHotspotInput.svelte"
	import WifiInfoInput from "../settings/inputs/WifiInfoInput.svelte"
	import { Reactivity } from "svelte-comps/reactivity"
	import NetworkMenuDeviceGroupStatus from "./NetworkMenu_DeviceGroupStatus.svelte"

	interface Props {
		device: DeviceRPi
	}

	let { device }: Props = $props()

	$effect.pre(() => {
		Reactivity.useProps(device, ["parent", "isOnline"])
	})

	let group = $derived(Reactivity.propOf(device, "parent"))
	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))
</script>

<div class="top-blocks">
	<DeviceMenuBlock heading="Network Settings">
		{#if isOnline}
			<div class="grid-columns">
				<EnableHotspotInput />
				<WifiInfoInput />
			</div>
		{:else}
			<div class="warn-text">
				Your device must be connected to the internet in order to access or
				modify its settings. Please power it on and connect it to your network
				and try again.
			</div>
		{/if}
		{#if group}
			<hr />
			<NetworkMenuDeviceGroupStatus {device} />
		{/if}
	</DeviceMenuBlock>
</div>

<div class="network-preview-container">
	<ConnectedNetworkPreview {device} />
</div>

<style>
	.network-preview-container {
		background-color: var(--color-bg-dark);
		padding: 1rem;
		border-radius: var(--br);
	}

	.warn-text {
		margin-top: 0.5rem;
	}
</style>
