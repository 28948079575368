import { Entry } from "data-handler"
import { Device } from "../entries/Device"
import { DeviceFeature } from "../entries/Device/features"

export class EntryCreationError<T extends typeof Entry<any>> extends Error {
	name = "EntryCreationError"
	entryType?: T
	entryData?: any
	constructor(
		message: string,
		info?: {
			entryType: T
			entryData: any
		}
	) {
		super(message)
		this.entryType = info?.entryType
		this.entryData = info?.entryData
	}
}

export class FeatureNotAvailableError extends Error {
	constructor(device: Device, feature: DeviceFeature) {
		super(`Device${device.id} cannot use this feature ${feature}.`)
	}
}
