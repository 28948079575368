<script lang="ts">
	import type { Snippet } from "svelte"

	interface Props {
		isChecked: boolean
		LabelIcon?: Snippet
		checkColor?: string
		label?: string
		id?: string
		disabled?: boolean
		onChange?: (
			e: Event & {
				currentTarget: EventTarget & HTMLInputElement
			}
		) => void
	}

	let {
		isChecked = $bindable(),
		checkColor = "var(--color-main)",
		label = "Repeat?",
		id = "repeat-input",
		disabled = false,
		onChange = undefined,
		LabelIcon = undefined,
	}: Props = $props()
</script>

<div class="input-container checkbox-input" style="--color-check: {checkColor}">
	<input
		type="checkbox"
		{id}
		bind:checked={isChecked}
		{disabled}
		onchange={onChange}
	/>
	<label for={id}>
		{#if LabelIcon}
			{@render LabelIcon()}
		{/if}

		{label}
	</label>
</div>

<style>
	.input-container:has(:global(input:disabled)) {
		opacity: 50%;
	}

	/* Hide the default checkbox input */
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* Style the custom checkbox */
	label {
		position: relative;
		padding-left: 2rem; /* Add space for the custom checkbox */
		cursor: pointer;
		user-select: none;
		width: auto;
	}

	/* Style the custom checkbox appearance */
	label:before {
		content: "";
		position: absolute;
		left: 0.25rem;
		top: 0.25rem;
		width: 1rem; /* Set the width of the custom checkbox */
		height: 1rem; /* Set the height of the custom checkbox */
		border: 1px solid var(--color-border); /* Border color of the checkbox */
		border-radius: 0.25rem;
		background-color: transparent; /* Background color of the checkbox */
	}

	/* Style the custom checkmark */
	label:after {
		content: "";
		position: absolute;
		left: 0.5rem; /* Adjust the position of the checkmark */
		top: 0.15rem; /* Adjust the position of the checkmark */
		width: 0.5rem; /* Set the width of the checkmark */
		height: 1rem; /* Set the height of the checkmark */
		border: solid var(--color-check); /* Checkmark color */
		border-width: 0 2px 2px 0; /* Style the checkmark as a 45-degree angle */
		transform: rotate(
			45deg
		); /* Rotate the checkmark to create a checkmark shape */
		opacity: 0; /* Hide the checkmark by default */
		transition: opacity 150ms ease;
	}

	/* Show the custom checkmark when the checkbox is checked */
	input[type="checkbox"]:checked + label:after {
		opacity: 1;
	}
</style>
