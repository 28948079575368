<script lang="ts">
	/**
	 * The page router is simply for breaking up the unprotected routes (e.g., login from the protected routes (all of the others))
	 */

	// Some evil package updates made the svelte component type break, which results in errors on the component prop - ignore this as this is just a typescript issue.

	import { navigate, Route, Router } from "svelte-routing"

	import ProtectedRoute from "../reusable/auth/ProtectedRoute.svelte"
	import Login from "./login/Login.svelte"
	import AppRouter from "./AppRouter.svelte"
	import PasswordReset from "./login/RequestPasswordReset.svelte"
	import { onMount } from "svelte"
	import WifiHelp from "./wifi-help/WifiHelp.svelte"

	onMount(() => {
		if (window.location.hostname === "connect.luxedo.com")
			navigate("/wifi-help")
	})
</script>

<Router>
	<Route path="login">
		<Login />
	</Route>
	<Route path="wifi-help">
		<WifiHelp />
	</Route>
	<Route path="password-reset">
		<PasswordReset />
	</Route>

	<ProtectedRoute path="*" component={AppRouter} />
</Router>

<style>
</style>
