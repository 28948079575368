<script lang="ts">
	// Same arc radii
	const arcs = [4, 7, 10, 13]

	const centerX = 16
	const centerY = 20

	function wifiPath(r: number) {
		const startX = centerX - r
		const startY = centerY
		const endX = centerX
		const endY = centerY - r
		return `
      M ${startX} ${startY}
      A ${r} ${r} 0 0 1 ${endX} ${endY}
    `.trim()
	}
</script>

<!-- 
  We apply a rotation transform to the entire <svg> so the arcs 
  (which used to sweep left->top) now appear upright.
-->
<svg
	width="32"
	height="24"
	viewBox="0 0 32 24"
	xmlns="http://www.w3.org/2000/svg"
	style="
		transform: rotate(45deg);
		transform-origin: center;
		transform-box: fill-box;
	"
>
	<!-- The small circle at the bottom center -->
	<circle cx="16" cy="20" r="2" class="wifi-center" />

	<!-- Each arc, with staggered animation delays -->
	{#each arcs as r, i}
		<path
			d={wifiPath(r)}
			class="wifi-arc"
			style="animation-delay: {i * 200}ms;"
		/>
	{/each}
</svg>

<style>
	svg {
		width: 4rem;
		height: auto;
	}

	/* The center dot */
	.wifi-center {
		fill: var(--color-text-light, #888);
	}

	/* The arcs */
	.wifi-arc {
		fill: none;
		stroke: var(--color-text-light, #888);
		stroke-width: 2;
		animation: wifiPulse 2s infinite;
		transform-box: fill-box;
		transform-origin: center;
	}

	@keyframes wifiPulse {
		0% {
			transform: scale(0.8);
			opacity: 0;
		}
		40% {
			transform: scale(1);
			opacity: 1;
		}
		100% {
			transform: scale(0.8);
			opacity: 0;
		}
	}
</style>
