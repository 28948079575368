<script lang="ts">
	import type { DeviceGroup } from "luxedo-data"
	import { GroupGridManager } from "../../../../../../modules/device-operation-managers/GroupGridManager"

	interface Props {
		device: DeviceGroup
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	let isGridActive = $state(false)
	let isGridToggling = $state(false)

	$effect(() => {
		onDeviceChange(device)
	})

	let unsubscriber
	function onDeviceChange(device: DeviceGroup) {
		if (unsubscriber) unsubscriber()
		unsubscriber = GroupGridManager.subscribe((ctx) => {
			if (!device) isGridActive = false
			else isGridActive = device.id in ctx
		})
	}

	async function toggleGrid() {
		isGridToggling = true
		setButtonsDisabled(true)

		try {
			if (isGridActive) await GroupGridManager.deactivateGrid(device)
			else await GroupGridManager.activateGrid(device)
		} catch {
			console.error("Failed to toggle spotlight")
		}

		isGridToggling = false
		setButtonsDisabled(false)
	}
</script>

<div class="action-container">
	<button
		id="show-grid-button"
		class="outline-button alt"
		onclick={toggleGrid}
		disabled={isGridToggling || isDisabled}
	>
		{#if isGridActive}
			Hide
		{:else}
			Show
		{/if}
		Grid
	</button>
	<span class="action-info">
		Project a dynamic grid from each linked device allowing you to better
		visualize and adjust your group's position and alignment.
	</span>
</div>

<style>
</style>
