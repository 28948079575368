<script lang="ts">
	import { DeviceRPi } from "luxedo-data"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import ProjectorSettingsBlock from "../../DeviceMenuBlock.svelte"
	import AudioOutputInput from "./inputs/AudioOutputInput.svelte"
	import CameraExposureInput from "./inputs/CameraExposureInput.svelte"
	import DeviceSettingsProjector from "./DeviceSettings_Projector.svelte"
	import EnableHotspotInput from "./inputs/EnableHotspotInput.svelte"
	import WifiInfoInput from "./inputs/WifiInfoInput.svelte"
	import { Reactivity } from "svelte-comps/reactivity"

	let device = $derived(ProjectorMenuController.ctx.device)

	let isRPi = $derived(device instanceof DeviceRPi)
	let canInvertCamera = $derived("orientation" in device)
	let canToggleHotspot = $derived(device.features.includes("TOGGLE_HOTSPOT"))

	$effect.pre(() => Reactivity.useProps(device, ["isOnline"]))

	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))
</script>

<div class="projector-menu">
	{#if isOnline}
		<ProjectorSettingsBlock>
			<div class="grid-columns">
				{#if isRPi}
					<div class="settings-block">
						<h2 class="setting-heading">Audio</h2>
						<AudioOutputInput />
					</div>
				{/if}

				{#if canInvertCamera}
					<div class="settings-block">
						<h2 class="setting-heading">Camera</h2>
						<CameraExposureInput />
					</div>
				{/if}

				{#if isRPi}
					<DeviceSettingsProjector />
				{/if}
				<div class="settings-block">
					<h2 class="setting-heading">Network</h2>
					{#if canToggleHotspot}
						<EnableHotspotInput />
					{/if}
					<WifiInfoInput />
				</div>
			</div>
		</ProjectorSettingsBlock>
	{:else}
		<ProjectorSettingsBlock heading="Settings">
			<div class="warn-text">
				Your device must be connected to the internet in order to access or
				modify its settings. Please power it on and connect it to your network
				and try again.
			</div>
		</ProjectorSettingsBlock>
	{/if}
</div>

<style>
	.grid-columns {
		gap: 1rem;
		grid-auto-rows: 1fr;
	}

	.warn-text {
		margin-top: 0.5rem;
	}
</style>
