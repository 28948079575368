/**
 * 
 * Desired flow:
1. User clicks on "Calibrate" button
2. Checks if it is between the hours of sunset and sunrise
3. If it is daytime, open a popup
	If it is not, the flow continues as normal
4. The popup prompts the user with: 

	Warning: We detected that it might be daylight at your location.

	Calibration will not work if it is too bright in your location. 

	(My location is dark)  (I will try again when it is dark)

5. The user must click one of the two buttons before anything happens
	If the user clicks the button "My location is dark", it will continue as normal
	If they click "I will try again when it is dark", the popup will close, along with the calibration overlay

 */

/**
 * Implementation requirements
1. Create a file apps/portal-client/src/modules/LocationUtils.ts which exports the namespace LocationUtils (check ./TimeUtils.ts for reference) which includes the functions:
	- async getLocation() : Promise<GeolocationPosition> to try to get user location using the browser's geolocation functionality. MUST use promises.
	- getDaylightTimes(location) which takes location data and returns the object { sunriseTime: <time>, sunsetTime: <time> }
	- isDaylight(location, time : Date) : boolean which checks if the specified time is between the sunrise and sunset times for the given location
	- Note: I don't know how the return type of location data, or what the best return type for the sunrise and sunset times are, 
	so I will leave you to figure out how to do those.
2. The popup should use the initConfirmOverlay function, imported from svelte-comps/overlay. Search the frontend codebase for "initConfirmOverlay" to find examples of this.
3. This should be able to handle cases where the user does not allow location data. If location data isn't allowed, use a default location on the equator.
4. Run some tests on the isDaylight function with different hard-coded locations and times to make sure that the function returns the expected values
 */

export namespace LocationUtils
{

	export interface Location
	{

		latitude: number;
		longitude: number;

	}

	export interface DaylightTimes
	{

		sunriseTime: Date;
		sunsetTime: Date;
	}
	
	/**
	 * This function will get the user's current geographic location using the browser's
	 * Geolocation API. 
	 * 
	 * Question -  What to do if the user denies permission to access the location, if we are 
	 * going to prompt to ask them permission for location?
	 */

	export async function getLocation(): Promise< Location | null >
	{

		return new Promise< Location | null >((resolve) => {

			if(!navigator.geolocation){ 
				return resolve(null) 
			}

			navigator.geolocation.getCurrentPosition(
				(position) => {
					resolve({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude
					});
				},

				(error) => {
					console.warn("Geolocation not available or denied: ", error)

					resolve(null)
				}
			);
		});
		
	}

	/**
	 * This function fetches the sunrise and sunset times for the given location from https://sunrise-sunset.org/api \
	 * For now, we'll be returning the times as Date objects in UTC.
	 * 
	 * @param location - The location {latitude, longitude}
	 * @returns { sunriseTime: Date, sunsetTime: Date} 
	 */

	export async function getDaylightTimes(location: Location): Promise<DaylightTimes> 
	{
		const { latitude, longitude } = location
		const url = `https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&formatted=0`

		const response = await fetch(url);

		if (!response.ok)
		{
			throw new Error(`Failed to fetch sunrise/sunset times: ${response.statusText}`)
		}

		const data = await response.json()
		const sunriseTime = new Date(data.results.sunrise)
		const sunsetTime =  new Date(data.results.sunset)

		return { sunriseTime, sunsetTime }
	}

	/**
	 * This function checks if a given time is between sunrise and sunset for the specified location.
	 * 
	 * @param location - The location { latitude, longitude }
	 * @param time - The Date object representing the time you want to check
	 * @returns boolean - true if it's daylight, false otherwise
	 */

	export async function isDaylight(location: Location, time: Date): Promise<boolean> 
	{
		const { sunriseTime, sunsetTime } = await getDaylightTimes(location)
		return time >= sunriseTime && time < sunsetTime
		
	}

}
