import {
	DataHandlerDevice,
	Device,
	DeviceRPi,
	ThirdPartyProjectorManager,
} from "luxedo-data"
import { ProjectorMenuController } from "./ProjectorMenuController.svelte"
import { LuxedoRPC } from "luxedo-rpc"
import { DataSaveError } from "../../../types/ErrorVariants"

export type ProjectorPreferenceTypes = {
	deviceName?: string

	// General projector settings
	resolution?: string

	// Projector timeout settings
	timeoutDuration?: number

	// Camera settings
	cameraExposure?: number
	invertCamera?: boolean

	// Audio settings
	audioOutput?: "HDMI" | "HEADPHONES"

	// Network
	enableHotspot?: boolean
	showWifiInfo?: boolean
}

class PreferenceManager {
	device: Device
	preferences: ProjectorPreferenceTypes = $state({})
	pendingChanges: ProjectorPreferenceTypes = $state({})

	reset() {
		this.preferences = {}
		this.pendingChanges = {}
	}

	/**
	 * Specifies a preference changed by the user, but not yet saved to the device
	 * @param pref the preference name
	 * @param val the new preference value
	 */
	stageChange = <T extends keyof ProjectorPreferenceTypes>(
		pref: T,
		val: ProjectorPreferenceTypes[T]
	) => {
		this.pendingChanges[pref] = val
		// if the value is the same as the device preference, just remove the staged change
		if (this.preferences[pref] === val) delete this.pendingChanges[pref]
	}

	/**
	 *
	 */
	applyChanges = async () => {
		if (Object.keys(this.pendingChanges).length === 0) return

		const device = ProjectorMenuController.ctx.device as DeviceRPi
		const configUpdate: typeof device.eidos.config.fw_config = {}
		const promises: Array<Promise<any>> = []

		for (const [name, value] of Object.entries(this.pendingChanges)) {
			switch (name) {
				case "deviceName":
					device.name = value as string
					promises.push(DataHandlerDevice.save(device as Device))
					break
				case "resolution":
					promises.push(
						PreferenceManager.saveResolution(
							device,
							value as string
						)
					)
					break
				case "timeoutDuration":
					configUpdate["projector_keep_alive_duration"] =
						Number(value)
					break
				case "cameraExposure":
					promises.push(
						PreferenceManager.saveCameraExposure(
							device,
							value as number
						)
					)
					break
				case "invertCamera":
					promises.push(
						PreferenceManager.saveCameraInverted(
							device,
							value as boolean
						)
					)
					break
				case "audioOutput":
					configUpdate["audio_device"] = value as
						| "HDMI"
						| "HEADPHONES"
					break
				case "enableHotspot":
					configUpdate["hotspot_enabled"] = value as boolean
					break
				case "showWifiInfo":
					configUpdate["show_wifi_info"] = value as boolean
					break
			}
		}

		if (Object.keys(configUpdate).length) {
			promises.push(
				LuxedoRPC.api.plato.plato_call(
					"config_update",
					[configUpdate],
					device?.id!
				)
			)
		}

		await Promise.all(promises)

		await DataHandlerDevice.pull([device.id])

		// if updating resolution, wait for the device to go offline before attempting to verify modified properties were saved
		if ("resolution" in this.pendingChanges) {
			await device.awaitPower(false)
			await device.awaitPower(true)
		}

		// if ONLY UPDATING THE DEVICE NAME, skip the eidos condition listener as it will cause an error when offline
		const onlyUpdatingDeviceName =
			"deviceName" in this.pendingChanges &&
			Object.keys(this.pendingChanges).length === 1
		if (!onlyUpdatingDeviceName) {
			// Make sure each modified value actually updates
			await device.listenEidosCondition(() => {
				const updatedPrefs =
					PreferenceManager.getDevicePreferences(device)

				let allFinished = true
				for (const [key, value] of Object.entries(
					this.pendingChanges
				)) {
					if (updatedPrefs[key] != value) allFinished = false
				}
				return allFinished
			}, 180)
		}
		this.pendingChanges = {}
		this.refresh()
	}

	refresh(device?: Device) {
		this.reset()
		if (device) this.device = device
		this.preferences = PreferenceManager.getDevicePreferences(this.device)

		this.pendingChanges = {}
	}

	/**
	 * Gets the preferences of the specififed device
	 * @param device the device to get the preferences from
	 * @returns the device's preferences
	 */
	static getDevicePreferences(device?: Device) {
		if (!device) return {}

		const pref: ProjectorPreferenceTypes = {
			deviceName: device.name,
		}

		// janky shit from passing just the properties sometimes
		// this should have a better solution
		if (device.hasConnectedProjector || device instanceof DeviceRPi) {
			pref["audioOutput"] = (
				device as DeviceRPi
			)?.eidos?.config?.fw_config?.audio_device
			pref["timeoutDuration"] = (
				device as DeviceRPi
			)?.eidos?.config?.fw_config?.projector_keep_alive_duration
			pref["invertCamera"] = (device as DeviceRPi)?.orientation
			pref["enableHotspot"] = (
				device as DeviceRPi
			)?.eidos?.config?.fw_config?.hotspot_enabled
			pref["showWifiInfo"] = (
				device as DeviceRPi
			)?.eidos?.config?.fw_config?.show_wifi_info
			pref["cameraExposure"] =
				(device as DeviceRPi)?._rawData?.recommended_exposure ?? -1

			// Parse resolution depending
			if (
				!device.resX &&
				"eidos" in device &&
				"display_config" in device.eidos
			) {
				pref["resolution"] =
					ThirdPartyProjectorManager.resolutionManager.getByResolution(
						device.eidos.display_config[0],
						device.eidos.display_config[1]
					)
			} else {
				pref["resolution"] =
					ThirdPartyProjectorManager.resolutionManager.getByResolution(
						device.resX,
						device.resY
					)
			}
		}

		return pref
	}

	private static async saveResolution(device: DeviceRPi, newRes: string) {
		const newResolution =
			ThirdPartyProjectorManager.resolutionManager.resolutions[newRes]

		if (!device?.isReady)
			throw new DataSaveError(
				"Device is offline or busy - please wait and try again"
			)
		if (!(device instanceof DeviceRPi) || !device?.hasConnectedProjector)
			throw new DataSaveError("Cannot change resolution of this device?.")
		if (device?.isResolutionChanging)
			throw new DataSaveError(
				"Cannot change resolution while awaiting response."
			)

		if (
			device?.resX === newResolution.width &&
			device?.resY === newResolution.height
		)
			return true
		device.isResolutionChanging = true

		device.resX = newResolution.width
		device.resY = newResolution.height

		await LuxedoRPC.api.plato.plato_call(
			"display_set_resolution",
			[
				newResolution.width,
				newResolution.height,
				60,
				"_all_other_projectors",
			],
			device?.id!
		)

		device.isResolutionChanging = false
	}

	private static async saveCameraInverted(
		device: DeviceRPi,
		isInverted: boolean
	) {
		await LuxedoRPC.api.deviceControl.device_set_camera_flipped(
			device?.id,
			isInverted ? 1 : 0
		)
	}

	private static async saveCameraExposure(
		device: DeviceRPi,
		cameraExposure: number
	) {
		await LuxedoRPC.api.plato.plato_call(
			"set_camera_exposure",
			[cameraExposure],
			device?.id!
		)
	}
}

export const ProjectorPreferencesManager = new PreferenceManager()
