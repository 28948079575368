<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"

	let showWifiInfo = $derived(
		ProjectorPreferencesManager.pendingChanges.showWifiInfo ??
			ProjectorPreferencesManager.preferences.showWifiInfo
	)

	function onInfoToggle() {
		ProjectorPreferencesManager.stageChange("showWifiInfo", !showWifiInfo)
	}
</script>

<div class="flex-row switch-container">
	<span class="label">Show Wi-Fi Info: </span>
	<ToggleSwitch
		info={`If disabled, your device won't project any Wi-Fi status details, including connectivity or signal strength.`}
		isEnabled={showWifiInfo}
		onUpdate={onInfoToggle}
	/>
</div>

<style>
</style>
