<script lang="ts">
	import { DeviceGroup, type Device } from "luxedo-data"
	import DeviceFilterInstance from "../../../reusable/device-filter/DeviceFilter_Instance.svelte"

	interface Props {
		allDevices: Array<Device>
		selected: Device
		onselect: (d: Device) => void
	}

	let { allDevices, selected, onselect }: Props = $props()
	let devices = $derived(allDevices.filter((d) => !(d instanceof DeviceGroup)))
</script>

{#each devices as device (device.id)}
	<DeviceFilterInstance
		{device}
		filterDevice={selected}
		onSetDeviceFilter={onselect}
		hideSettings
	/>
{/each}

<style>
</style>
