<script lang="ts">
	import { getRandomNumberInRange } from "../../modules/NumberUtils"

	type Position = {
		x: number
		y: number
	}

	interface Props {
		colors?: [string, string]
	}

	let { colors = ["var(--color-main)", "var(--color-alt)"] }: Props = $props()
	let position: Array<Position> = $state(getInitialPosition())

	function getInitialPosition() {
		const pos = []

		const width = window.innerWidth
		const height = window.innerHeight

		pos.push({
			x: getRandomNumberInRange(-50, width / 8),
			y: getRandomNumberInRange(height / 2, (height / 3) * 2),
		})

		pos.push({
			x: getRandomNumberInRange(width / 2, (width / 5) * 4),
			y: getRandomNumberInRange(height / 16, height / 2),
		})

		return pos
	}
</script>

<div class="animated-background">
	{#each colors as color, i}
		<div
			class="color-block block-{i}"
			style="--color-block: {color}; left: {position[i].x}px; top: {position[i]
				.y}px;"
		></div>
	{/each}
	<div class="animated-background-foreground"></div>
</div>

<style>
	.animated-background {
		overflow: hidden;
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.animated-background-foreground {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		backdrop-filter: blur(4rem);
	}

	@keyframes block-move-0 {
		0% {
			transform: translateX(0) translateY(0);
		}

		33% {
			transform: translateX(60vw) translateY(-80vh);
		}

		66% {
			transform: translateX(80vw) translateY(-20vh);
		}

		100% {
			transform: translateX(0) translateY(0);
		}
	}

	@keyframes block-move-1 {
		0% {
			transform: translateX(0) translateY(0);
		}

		25% {
			transform: translateX(-60vw) translateY(-60vh);
		}

		50% {
			transform: translateX(-80vw) translateY(-20vh);
		}

		75% {
			transform: translateX(-70vw) translateY(-45vh);
		}

		100% {
			transform: translateX(0) translateY(0);
		}
	}

	.color-block {
		position: absolute;
		opacity: 0.4;
		width: 35vw;
		height: 35vw;
		border-radius: 100%;
		background-color: var(--color-block);
		animation-iteration-count: infinite;
	}

	.color-block:global(.block-0) {
		animation-name: block-move-0;
		animation-duration: 180s;
	}

	.color-block:global(.block-1) {
		animation-name: block-move-1;
		animation-duration: 190s;
	}
</style>
