<script lang="ts">
	import type { Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"

	let device: Device = $state()
	SelectedDeviceStore.subscribe((dev) => (device = dev))
</script>

<div class="next-steps-heading">
	<h1>Device Updating</h1>
</div>
<p>Your device is currently updating...</p>

<div class="info">
	This may take up to 10 minutes, depending on the network strength of {device?.name ??
		"your device"}.
</div>

<p class="help">
	Having trouble? check out our
	<a target="_blank" href="https://www.luxedo.com/pages/help">help center</a> for assistance.
</p>

<style>
</style>
