<script lang="ts">
	import { Scene, type Lightshow } from "luxedo-data"
	import { ScheduleController } from "../ScheduleController"
	import { TileLabel } from "svelte-comps/luxedo"
	import LightshowThumbnail from "../../../reusable/file-system/tiles/LightshowThumbnail.svelte"

	interface Props {
		show: Scene | Lightshow;
	}

	let { show }: Props = $props();
	let isSelected: boolean = $state(false)
	ScheduleController.EventEditor.subscribe((ctx) => (isSelected = ctx && ctx.selectedShow === show))

	function select() {
		ScheduleController.EventEditor.selectShow(show)
	}

	let onHover: () => void = $state()
	let onLeave: () => void = $state()
</script>

<div
	role="button"
	class="tile-container {isSelected ? 'is-selected' : ''}"
	tabindex="0"
	title={show.name}
	onmouseover={() => {
		if (onHover) onHover()
	}}
	onmouseleave={() => {
		if (onLeave) onLeave()
	}}
	onclick={select}
	onkeydown={(e) => (e.key === "Enter" ? select() : undefined)}
	onfocus={undefined}
>
	<div class="tile-icon-container">
		<div class="tile">
			{#if show instanceof Scene}
				<img src={show.src.thumbnail} alt="{show.name} Thumbnail" />
			{:else}
				<LightshowThumbnail lightshow={show} bind:onHover bind:onLeave />
			{/if}
		</div>
	</div>
	<div class="tile-label-container">
		<TileLabel file={show} onRename={undefined} />
	</div>
</div>

<style>
	.tile-container.is-selected .tile {
		border: 2px solid var(--color-main);
	}
</style>
