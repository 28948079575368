<script lang="ts">
	import {
		DataHandlerDevice,
		type Device,
		Scene,
		type Lightshow,
		LightshowBreak,
	} from "luxedo-data"
	import { DateTime } from "luxon"
	import { TimeUtils } from "../../../../../modules/TimeUtils"
	import { ShowOverviewController } from "../ShowOverviewController"

	interface Props {
		lightshow: Lightshow;
	}

	let { lightshow }: Props = $props();
	let scenes: Array<Scene | LightshowBreak> = lightshow.getScenesInOrder()

	function createClickHandler(block: Scene | LightshowBreak) {
		return (
			e: MouseEvent & {
				currentTarget: EventTarget & HTMLTableRowElement
			}
		) => {
			if (block instanceof LightshowBreak) return

			ShowOverviewController.close()
			ShowOverviewController.open(block)
		}
	}
</script>

<div class="lightshow-info-container">
	<h3>Scenes</h3>
	<table class="lightshow-scene-list">
		<tbody>
			{#each scenes as scene}
				{#if scene instanceof Scene}
					<tr onclick={createClickHandler(scene)}>
						<td>{scene.name}</td>
						<td>Last Edit: {DateTime.fromJSDate(scene.updated_at).toFormat("LLL dd, yy")}</td>
						<td>{TimeUtils.renderTime(scene.duration)}</td>
					</tr>
				{:else}
					<tr class="break">
						<td> Break </td>
						<td></td>
						<td>{scene ? TimeUtils.renderTime(scene.duration) : ""}</td>
					</tr>
				{/if}
			{/each}
		</tbody>
	</table>
</div>

<style>
	.lightshow-info-container {
		width: 100%;
	}

	.lightshow-scene-list {
		width: calc(100% - 3rem);
		margin: 0.5rem 1rem;
	}

	.lightshow-scene-list tr:not(.break) {
		cursor: pointer;
	}

	.lightshow-scene-list tr {
		border-bottom: 1px solid var(--color-border);
		transition: background-color 150ms;
	}

	.lightshow-scene-list tr td {
		color: var(--color-text);
		transition: color 150ms;
	}

	.lightshow-scene-list tr td:first-of-type {
		width: 30%;
		padding-left: 1rem;
		color: var(--color-text-light);
		border-top-left-radius: var(--br);
		border-bottom-left-radius: var(--br);
	}

	.lightshow-scene-list tr td:last-of-type {
		border-top-right-radius: var(--br);
		border-bottom-right-radius: var(--br);
		text-align: right;
		padding-right: 1rem;
	}

	.lightshow-scene-list tr.break td {
		color: var(--color-text);
	}

	.lightshow-scene-list tr:not(.break):hover {
		background-color: var(--color-hover);
	}

	.lightshow-scene-list tr:not(.break):hover td {
		color: var(--color-text-light);
	}
</style>
