<script lang="ts">
	import { run } from "svelte/legacy"

	import { ShowLibraryController, type ShowLibraryViewOption } from "./ShowLibraryController"
	import ShowLibraryHeading from "./show-library-heading/ShowLibraryHeading.svelte"
	import {
		DataHandlerDevice,
		DataHandlerLightshow,
		DataHandlerScene,
		type Device,
		type Lightshow,
		type Scene,
	} from "luxedo-data"
	import DeviceFilter from "../../reusable/device-filter/DeviceFilter.svelte"
	import LightshowEditor from "./lightshow-editor/LightshowEditor.svelte"
	import { onMount } from "svelte"
	import ShowList from "./ShowList.svelte"

	let viewType: ShowLibraryViewOption = $state("Scenes")
	let isEditingLightshow: boolean = $state(false)
	let shows: Array<Scene | Lightshow> = $state([])

	let searchInput: string = $state()
	let selectedTags: Array<number> = $state([])

	let libraryWidth: number = $state()
	let tileWidth: number = $state() // used to set css var to make scene tiles appear aligned

	let deviceFilter: Device = $state()

	let runFilter: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow> = (content) =>
		content
	let runOrder: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow> = (content) =>
		content

	const getDataHandler = (viewType: ShowLibraryViewOption) =>
		viewType === "Scenes" ? DataHandlerScene : DataHandlerLightshow

	ShowLibraryController.subscribe((ctx) => {
		viewType = ctx.view
		isEditingLightshow = ctx.isEditingLightshow
		deviceFilter = ctx.deviceFilter
		onFilterSelectionUpdate(ctx.filterSelection.filter, ctx.filterSelection.order)
	})


	function getRelevantScenes(viewType: ShowLibraryViewOption) {
		let shows = []

		const DATAHANDLER = getDataHandler(viewType)

		if (deviceFilter) shows = DATAHANDLER.getByDevice(deviceFilter)
		else shows = DATAHANDLER.getMany()

		return shows
	}

	function onDataHandlerUpdate(updatedIDs: Array<number>) {
		onViewUpdate(searchInput, selectedTags, viewType)
	}

	let datahandler: typeof DataHandlerLightshow | typeof DataHandlerScene

	function updateDataHandlerListener(viewType: ShowLibraryViewOption) {
		if (datahandler) datahandler.removeListener(onDataHandlerUpdate)
		datahandler = getDataHandler(viewType)
		datahandler.addListener(onDataHandlerUpdate)
	}

	function onViewUpdate(search: string, tags: Array<number>, view?: ShowLibraryViewOption) {
		view = view ?? viewType
		let filterShows = getRelevantScenes(view)
		if (search) {
			filterShows = filterShows.filter((scene) =>
				scene.name.toLowerCase().includes(search.toLowerCase())
			)
		}
		if (tags.length) {
			filterShows = filterShows.filter((scene) => {
				let doesMatch = true
				for (const tagId of tags) {
					if (!scene.tags.includes(tagId)) {
						doesMatch = false
						return
					}
				}
				return doesMatch
			})
		}

		filterShows = filterOperation(filterShows) as Array<Lightshow>
		filterShows = filterShows.filter((show) => show.name !== "__temp_ls__")

		shows = filterShows
	}

	/** Runs the filter methods, which are updated in onFilterSelectionUpdate */
	function filterOperation(content: Array<Scene | Lightshow>) {
		let filteredContent = runFilter(content)
		let orderedContent = runOrder(filteredContent)
		return orderedContent
	}

	/** Set the filter function according to the new filter selection. */
	function onFilterSelectionUpdate(filter: string, order: string) {
		type Content = Array<Scene | Lightshow>

		switch (filter) {
			case "Name":
				runFilter = (content: Content) => content.sort((a, b) => a.name.localeCompare(b.name))
				break
			case "Last Modified":
				runFilter = (content: Content) =>
					content.sort((a, b) => b.updated_at.getUTCSeconds() - a.updated_at.getUTCSeconds())
				break
			case "Projector":
				runFilter = (content: Content) =>
					content.sort((a, b) => a.target_device_id - b.target_device_id)
				break
			case "Tags":
				runFilter = (content: Content) => content.sort((a, b) => a.tags.length - b.tags.length)
				break
			default:
				runFilter = (content) => content
				break
		}

		switch (order) {
			case "Descending":
				runOrder = (content: Content) => content
				break
			case "Ascending":
			default:
				runOrder = (content: Content) => content.reverse()
				break
		}

		onViewUpdate(searchInput, selectedTags)
	}

	function calculateTileWidth(width: number) {
		const TILE_WIDTH_PREFFERED = 128
		const TILE_MARGIN = 33

		const tileAmountPerRow = Math.floor(width / (TILE_WIDTH_PREFFERED + TILE_MARGIN))
		const newTileWidth = Math.floor(width / tileAmountPerRow) - TILE_MARGIN

		tileWidth = newTileWidth
	}

	$effect(() => {
		calculateTileWidth(libraryWidth)
	})

	$effect(() => {
		onViewUpdate(searchInput, selectedTags, viewType)
	})

	$effect(() => {
		updateDataHandlerListener(viewType)
	})

	onMount(() => {
		ShowLibraryController.setDeviceFilter(undefined)
		ShowLibraryController.changeView("Scenes")
	})
</script>

<div id="show-library-container">
	{#if isEditingLightshow}
		<LightshowEditor />
	{/if}
	<div id="library-wrapper">
		<div id="show-library" bind:clientWidth={libraryWidth} style="--tile-width: {tileWidth}px;">
			<ShowLibraryHeading bind:searchInput bind:selectedTags />
			<ShowList {viewType} {shows} />
		</div>
		<div id="library-right">
			{#if DataHandlerDevice.getMany().length}
				<DeviceFilter
					selectedDevice={deviceFilter}
					onSetDeviceFilter={ShowLibraryController.setDeviceFilter}
				/>
			{/if}
		</div>
	</div>
</div>

<style>
	#show-library :global(.empty-indicator) {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--color-text);
	}

	#show-library :global(.empty-text) {
		/* width: 60%; */
		text-align: center;
	}

	#show-library :global(.empty-indicator svg) {
		width: 8rem;
		height: 8rem;
	}

	#show-library :global(.empty-indicator .svg-fill) {
		fill: var(--color-text);
	}

	#show-library :global(.create-quick-action) {
		background-color: unset;
		box-shadow: unset;
		padding: unset;
		color: var(--color-main);
		transform: unset;
	}

	#show-library :global(.create-quick-action:hover),
	#show-library :global(.create-quick-action:focus-visible) {
		color: var(--color-text-light);
	}

	#show-library-container {
		height: calc(100vh - var(--header-height));
		display: flex;
		flex-direction: column;
	}

	#library-wrapper {
		display: flex;
		flex-direction: row;
		overflow: hidden;
		height: 100%;
	}

	#show-library {
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		height: 100%;
		flex-grow: 1;
		padding-right: 0.25rem;
		margin-right: 0.5rem;
	}

	#show-library :global(#library-list) {
		flex-grow: 1;
	}

	#show-library :global(.library-list) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	#show-library :global(.library-list .tile-container) {
		margin: 1rem;
	}
</style>
