<script lang="ts">
	import type { Component } from "svelte"
	import { LUXLINK_MENUS, LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"

	let currentMenu: Component = $state()
	LuxLinkSubscriptionManager.subscribe(({ route }) => (currentMenu = LUXLINK_MENUS[route]))
</script>

{#if currentMenu}
	{@const SvelteComponent = currentMenu}
	<SvelteComponent />
{/if}

<style>
</style>
