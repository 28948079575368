import { writable } from "svelte/store"

export namespace TooltipController {
	type ContextType = {
		content: string
		position: {
			top: number
			left: number
		}
		pointing: "left" | "top"
		style: string
	}

	const store = writable<ContextType>({
		content: undefined,
		position: undefined,
		pointing: undefined,
		style: undefined,
	})

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function useTooltip(
		elem: HTMLElement,
		props: {
			content: string
			style?: string
			pointing?: "left" | "top"
		}
	) {
		const onMouseOut = (e?: MouseEvent) => {
			store.set({
				content: undefined,
				position: undefined,
				pointing: undefined,
				style: undefined,
			})
			elem.removeEventListener("mouseout", onMouseOut)
		}

		const onMouseOver = (e: MouseEvent) => {
			const rect = elem.getBoundingClientRect()
			let position: {
				left: number
				top: number
			}

			if (props.pointing === "top") {
				position = {
					left: rect.left + rect.width / 2,
					top: rect.bottom,
				}
			} else {
				position = {
					left: rect.right,
					top: rect.bottom - rect.height / 2,
				}
			}

			store.set({
				content: props.content,
				position,
				pointing: props.pointing ?? "left",
				style: props.style,
			})

			elem.addEventListener("mouseout", onMouseOut)
		}

		elem.addEventListener("mouseover", onMouseOver)

		const mutationObserver = new MutationObserver(
			(mutationList, observer) => {
				for (const mutation of mutationList) {
					if (mutation.type == "childList") {
						for (const node of mutation.removedNodes) {
							if (node == elem) {
								onMouseOut()
							}
						}
					}
				}
			}
		)

		mutationObserver.observe(elem.parentNode, { childList: true })
	}
}
