<script lang="ts">
	import { DataHandlerScene, Device, DeviceGroup, DeviceRPi } from "luxedo-data"
	import PasswordInput from "../../../../reusable/input/PasswordInput.svelte"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import { ProjectorPreferencesManager } from "../../ProjectorPreferenceManager.svelte"
	import { openUpdateInformerOverlay } from "../../../../reusable/overlays/device-update"
	import { DeviceUpdateManager } from "../../../../../modules/device-operation-managers/DeviceUpdateManager"
	import { Reactivity } from "svelte-comps/reactivity"
	import {
		ContextMenuButton,
		triggerContextMenuWithGenerator,
	} from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../../../modules/ctx-menu/ContextMenuOptions"
	import DeviceDownloads from "./DeviceDownloads.svelte"
	import { getCurrentUser } from "../../../../../stores/UserStore"

	let device = $derived(ProjectorMenuController.ctx.device)

	$effect.pre(() => {
		Reactivity.useProps(device, [
			"password",
			"name",
			"eidos",
			"firmwareVersion",
			"isUpdating",
			"isOnline",
		])
	})

	let devName = $derived(
		ProjectorPreferencesManager.pendingChanges.deviceName ??
			ProjectorPreferencesManager.preferences.deviceName
	)

	let firmwareVersion = $derived(Reactivity.propOf(device, "firmwareVersion"))
	let isUpdating = $derived(Reactivity.propOf(device, "isUpdating"))
	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))

	let devPass = $derived(Reactivity.propOf(device, "password"))

	function onNameChange(
		e: KeyboardEvent & { currentTarget: HTMLInputElement }
	) {
		const target = e.currentTarget
		setTimeout(() => {
			ProjectorPreferencesManager.stageChange("deviceName", target.value)
		})
	}

	/**
	 * Opens the update overlay, providing it with information relevant to the currently selected device
	 */
	async function triggerUpdate() {
		const [updateVer, isUpdateInBeta] = DeviceUpdateManager.getAvailableUpdate(
			device as DeviceRPi
		)
		openUpdateInformerOverlay(device, updateVer, isUpdateInBeta)
	}
</script>

<div class="projector-menu">
	<div class="projector-section">
		<div class="flex-row">
			<h3 class="setting-heading">About</h3>
			<ContextMenuButton
				onClick={triggerContextMenuWithGenerator(
					CTXOptions.Device.createOptions,
					[device]
				)}
			/>
		</div>
		<div class="grid-columns">
			<div class="input-container">
				<label for="name-input">Name: </label>
				<input
					id="name-input"
					type="text"
					value={devName}
					onkeydown={onNameChange}
				/>
			</div>
			{#if devPass}
				<div class="input-container">
					<label for="password-input">Password: </label>
					<PasswordInput
						value={devPass}
						id="device-password-input"
						disabled={true}
						readonly={true}
					/>
				</div>
			{/if}
			<div class="input-container">
				<label for="id-input">Device ID: </label>

				<input id="id-input" type="text" disabled value={device.id} />
			</div>

			{#if firmwareVersion}
				<div class="flex-row">
					<div class="input-container">
						<label for="version-input">Firmware Version: </label>

						<input
							id="version-input"
							type="text"
							disabled
							value={firmwareVersion}
						/>
					</div>
					{#if device.isUpdateAvailable(getCurrentUser().hasPriv("beta_opt_in")) && device instanceof DeviceRPi && isOnline && !isUpdating}
						<button class="small" onclick={triggerUpdate} disabled={isUpdating}
							>Update</button
						>
					{/if}
				</div>
			{/if}
		</div>
	</div>

	{#if device instanceof DeviceRPi}
		<DeviceDownloads {device} />
	{/if}
</div>

<style>
	.projector-menu {
		justify-content: space-between;
		align-items: center;
	}

	.projector-section > .flex-row {
		justify-content: space-between;
		margin-right: 0.1rem;
	}
</style>
