<script lang="ts">
	import { PlusIcon, SmallPlusIcon } from "svelte-comps/icons"

	interface Props {
		strength: number;
	}

	let { strength }: Props = $props();
</script>

<div class="signal-strength-indicator">
	{#each new Array(5) as _, i}
		<div
			class="bar {strength > 0.2 * i ? 'powered' : 'off'} "
			style="height: {((i + 1) / 5) * 100}%;"
		></div>
	{/each}
	{#if !strength}
		<div class="no-signal-indicator">
			<SmallPlusIcon />
		</div>
	{/if}
</div>

<style>
	.signal-strength-indicator {
		height: 3rem;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 0.25rem;
		padding: 0.2rem 0;
	}

	.bar {
		width: 0.5rem;
		border-radius: calc(var(--br) / 4);
		background-color: var(--color-text);
	}

	.powered {
		background-color: var(--color-main);
	}

	.no-signal-indicator {
		position: absolute;
		width: fit-content;
		height: fit-content;
		transform: rotate(45deg);
		left: 0;
		top: 0;
	}

	.no-signal-indicator :global(svg) {
		width: 1rem;
		height: 1rem;
	}

	.no-signal-indicator :global(.svg-stroke) {
		stroke: var(--ct-red);
	}
</style>
