<script lang="ts">
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowDown } from "svelte-comps/icons"
	import { tooltip } from "svelte-comps/tooltip"
	import { Reactivity } from "svelte-comps/reactivity"
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		DeviceRPi,
		Lightshow,
		Scene,
	} from "luxedo-data"
	import DesignTile from "../../../../reusable/file-system/tiles/DesignTile.svelte"
	import LightshowTile from "../../../../reusable/file-system/tiles/LightshowTile.svelte"
	import { openShowOverview } from "../../../../reusable/overlays"

	type Props = {
		device: DeviceRPi
	}

	let { device }: Props = $props()

	$effect.pre(() => {
		Reactivity.useProps(device, ["eidos", "isOnline"])
	})

	let deviceEidos = $derived(Reactivity.propOf(device, "eidos"))
	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))

	let emptyDownloadsText = $derived(
		!isOnline
			? "This device is offline, and the list of downloaded shows is inaccessible."
			: "There are no scenes downloaded to this device."
	)

	const getShow = (id: number | string) => {
		if (!id) return undefined
		return (
			DataHandlerScene.get(Number(id)) ?? DataHandlerLightshow.get(Number(id))
		)
	}

	let showsDownloaded = $derived(
		Object.keys(deviceEidos?.cache?.shows ?? {})
			.map(getShow)
			.filter((show) => !!show)
	)

	let showsDownloading = $derived(
		Object.keys(
			deviceEidos?.cache?.download_queue.concat([
				deviceEidos?.cache?.currently_downloading,
			]) ?? {}
		)
			.map(getShow)
			.filter((show) => !!show)
	)

	let isExpanded: boolean = $state(false)
</script>

{#snippet downloadBlock(shows: Array<Scene | Lightshow>, heading?: string)}
	<div class="download-block">
		{#if heading}
			<h3 class="download-heading">{heading}</h3>
		{/if}
		<div class="flex-row show-list">
			{#if shows.length}
				{#each shows as show}
					{#if show instanceof Scene}
						<DesignTile design={show} onClick={() => openShowOverview(show)} />
					{:else}
						<LightshowTile
							lightshow={show}
							onClick={() => openShowOverview(show)}
						/>
					{/if}
				{/each}
			{:else}
				<span class="info">{emptyDownloadsText}</span>
			{/if}
		</div>
	</div>
{/snippet}

<CollapsibleSection className="download-list" bind:expanded={isExpanded}>
	{#snippet header()}
		<h2
			class="setting-heading"
			use:tooltip={{
				pointing: "top",
				content:
					"View all shows that are currently downloading or have been downloaded to this device.",
			}}
		>
			Downloads
			<button class="small icon {isExpanded ? 'open' : ''}"
				><ArrowDown /></button
			>
		</h2>
	{/snippet}
	{#snippet content()}
		{@render downloadBlock(showsDownloaded)}
		{#if showsDownloading.length}
			{@render downloadBlock(showsDownloading, "Queued")}
		{/if}
	{/snippet}
</CollapsibleSection>

<style>
	.setting-heading {
		margin: 0;
		display: flex;
		flex-direction: row;
		font-size: var(--h1);
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	.download-heading {
		margin: 2rem 0 0 0;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	.info {
		color: var(--color-text);
	}

	.show-list {
		overflow: hidden;
		overflow-x: auto;
		gap: 1rem;
		padding-top: 0.5rem;
	}

	.show-list:not(:has(.info)) {
		padding-bottom: 1rem;
	}

	.setting-heading {
		margin: 0;
		display: flex;
		flex-direction: row;
		font-size: var(--h1);
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	.info {
		color: var(--color-text);
	}

	.show-list {
		overflow: hidden;
		overflow-x: auto;
		gap: 1rem;
		padding-top: 0.5rem;
	}

	.show-list:not(:has(.info)) {
		padding-bottom: 1rem;
	}
</style>
