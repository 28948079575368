import { DataHandlerDevice, type Scene } from "luxedo-data"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import SceneDownloader from "./SceneDownloader.svelte"

export function openSceneDownloader(scene: Scene) {
	const slots = scene.slotSrc
	if (!slots) {
		console.error("Trying to open scene downloader for scene which is not providing slot data")
		return
	}

	const group = DataHandlerDevice.get(scene.target_device_id)

	let overlayId = openOverlay(SceneDownloader, {
		heading: "Download Scene",
		classHeading: "no-underline",
		props: {
			slotSrc: slots,
			group,
			scene,
			onFinish: () => closeOverlay(overlayId),
		},
	})
}
