<script lang="ts">
	interface Props {
		strokeColor?: string
	}

	let { strokeColor = "black" }: Props = $props()
</script>

<svg
	data-name="Layer 50"
	id="Layer_50"
	width={32}
	height={32}
	viewBox="0 0 250 250"
	xmlns="http://www.w3.org/2000/svg"
>
	<defs>
		<style>
			.Fit-canvasicon-cls-1 {
				fill: none;
				stroke: #000;
				stroke-linecap: round;
				stroke-miterlimit: 10;
				stroke-width: 24px;
			}
		</style>
	</defs>
	<rect
		class="Fit-canvasicon-cls-1 svg-stroke"
		height="125"
		rx="12"
		ry="12"
		stroke={strokeColor}
		width="175"
		x="37.5"
		y="62.5"
	/>
	<path
		class="Fit-canvasicon-cls-1 svg-stroke"
		d="m62.5,37.5H24.5c-6.63,0-12,5.37-12,12v38"
		stroke={strokeColor}
	/>
	<path
		class="Fit-canvasicon-cls-1 svg-stroke"
		d="m237.5,87.5v-38c0-6.63-5.37-12-12-12h-38"
		stroke={strokeColor}
	/>
	<path
		class="Fit-canvasicon-cls-1 svg-stroke"
		d="m187.5,212.5h38c6.63,0,12-5.37,12-12v-38"
		stroke={strokeColor}
	/>
	<path
		class="Fit-canvasicon-cls-1 svg-stroke"
		d="m12.5,162.5v38c0,6.63,5.37,12,12,12h38"
		stroke={strokeColor}
	/>
</svg>
