<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerLightshow,
		type Device,
		type Lightshow,
	} from "luxedo-data"
	import ProjectorIcon from "../../icons/ProjectorIcon.svelte"
	import {
		initContextMenu,
		type ContextMenuOptions,
	} from "svelte-comps/context-menu"
	import { Toast } from "svelte-comps/toaster"
	import { onDestroy, onMount } from "svelte"
	import { LightshowRenderContext } from "../../../../stores/LightshowRenderContext"
	import { RenderProgressIndicator } from "svelte-comps/luxedo"
	import LightshowThumbnail from "./LightshowThumbnail.svelte"
	import { TileLabel } from "svelte-comps/luxedo"
	import { CTXOptions } from "../../../../modules/ctx-menu/ContextMenuOptions"
	import WarningIcon from "../../icons/WarningIcon.svelte"

	interface Props {
		lightshow: Lightshow
		isSelected?: boolean
		onClick?: () => void
	}

	let { lightshow, isSelected = false, onClick = undefined }: Props = $props()

	let device: Device = $state()
	let callbackId: number

	let renderProgress = $state(undefined)
	let isRendering = $state(false)

	let onHover: () => void = $state()
	let onLeave: () => void = $state()

	let triggerRename: () => void = $state()

	let sceneThumbnails: Array<string> = undefined

	function getDevice() {
		return DataHandlerDevice.get(lightshow.target_device_id)
	}

	function updateRenderStatus() {
		if (renderProgress > 0) isRendering = true
	}

	function getThumbnails() {
		const scenes = lightshow.getUniqueScenes()
		let thumbnails = scenes.map((scene) => scene.src.thumbnail)
		return thumbnails
	}

	const contextMenuOptions: ContextMenuOptions =
		CTXOptions.Lightshow.createOptions(lightshow, {})

	async function onRename(newName: string) {
		try {
			await DataHandlerLightshow.rename(lightshow, newName)
			Toast.success("Lightshow renamed.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error(
				"There was an error renaming this lightshow, please try again."
			)
		}
	}

	onMount(async () => {
		device = getDevice()
		sceneThumbnails = getThumbnails()
		if (device?.features.includes("RENDERLESS_LIGHTSHOWS")) return

		callbackId = LightshowRenderContext.subscribeTo(
			lightshow.id,
			(progress) => {
				renderProgress = progress

				if (progress >= 1) {
					setTimeout(() => {
						renderProgress = 0
						isRendering = false
					}, 5000)
				}
			}
		)
	})

	onDestroy(() => {
		LightshowRenderContext.unsubscribe(lightshow.id, callbackId)
	})

	$effect(() => {
		if (lightshow) device = getDevice()
	})

	$effect(() => {
		if (renderProgress) updateRenderStatus()
	})
</script>

<div
	class="tile-container {isSelected ? 'is-selected' : ''}"
	role="button"
	tabindex="0"
	oncontextmenu={initContextMenu(contextMenuOptions)}
	onmouseover={onHover}
	onmouseleave={onLeave}
	onkeydown={undefined}
	onclick={onClick}
	title={lightshow.name}
>
	<div class="tile-icon-container">
		<div class="tile">
			{#if isRendering}
				<RenderProgressIndicator progress={renderProgress} />
			{/if}
			<LightshowThumbnail {lightshow} bind:onHover bind:onLeave />
		</div>
		<div class="projector-icon {!device ? 'warn' : ''}">
			{#if device}
				<ProjectorIcon {device} />
			{:else}
				<WarningIcon strokeColor="var(--color-error)" />
			{/if}
		</div>
	</div>
	<div class="tile-label-container">
		<TileLabel file={lightshow} {onRename} bind:triggerRename />
		<!-- {#if lightshow.duration} -->
		<!-- <span class="tile-sublabel"> {lightshow.duration} </span> -->
		<!-- {/if} -->
	</div>
</div>

<style>
	.tile :global(.progress-bar) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 3rem;
		height: 3rem;
	}

	.tile :global(.progress-bar span) {
		font-size: var(--h3);
	}

	.thumbnail-wrapper {
		display: flex;
	}
</style>
