<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg
	class="icon"
	width="48"
	height="48"
	viewBox="0 0 48 48"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_501_51)">
		<circle class="svg-stroke" cx="24" cy="24" r="22.5" stroke={strokeColor} stroke-width="3" />
	</g>
</svg>

<style>
</style>
