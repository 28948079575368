import "./assets/css/app.css"

import "luxedo-canvas"
import App from "./comps/routes/PageRouter.svelte"
import * as Sentry from "@sentry/svelte"
import { UserStore, getCurrentUser } from "./stores/UserStore"
import { Device, User } from "luxedo-data"
import { DevelopmentMode } from "./modules/development-mode/DevelopmentMode"
import { mount } from "svelte"

let user: User
UserStore.subscribe((u) => (user = u))

// Initialize the Sentry SDK here
Sentry.init({
	dsn: "https://e4c2ec50757b81669b392c6ce330bccd@o434314.ingest.sentry.io/4505783608672256",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
			block: ["#open-account-menu input"],
		}),
		// new Sentry.Feedback({
		// 	// Additional SDK configuration goes in here, for example:
		// 	colorScheme: "dark",
		// }),
	],
	environment: import.meta.env.MODE,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		"localhost:5173",
		"devapp.myluxedo.com",
		"app.myluxedo.com",
	],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: import.meta.env.PROD ? 0.0 : 1.0,
	replaysOnErrorSampleRate: 1.0,
})

// if (!import.meta.env.PROD) {
// 	DevelopmentMode.init()
// }

const app = mount(App, {
	target: document.getElementById("app"),
})

export default app
