import { get, writable } from "svelte/store"
import type { DeviceGroupSlot } from "luxedo-data"

export namespace GroupOverlapController {
	export type OverlapInstance = {
		id: string
		slots: [DeviceGroupSlot, DeviceGroupSlot]
		rect: {
			top: number
			left: number
			right: number
			bottom: number
		}
	}

	type ContextType = {
		step: number
		selectedOverlapInstance: string
		overlapInstances: Array<OverlapInstance>
	}

	const CONTEXT_DEFAULT: ContextType = {
		step: 0,
		overlapInstances: [],
		selectedOverlapInstance: undefined,
	}

	const store = writable<ContextType>(CONTEXT_DEFAULT)
	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function setStep(stepIndex: number) {
		if (stepIndex < 0 || stepIndex > 1) return
		store.update((ctx) => ({ ...ctx, step: stepIndex }))
	}

	export function getContext() {
		return get(store)
	}

	export function back() {
		store.update((ctx) => ({ ...ctx, step: Math.max(0, ctx.step - 1) }))
	}

	export function next() {
		store.update((ctx) => ({ ...ctx, step: ctx.step + 1 }))
	}

	export function reset() {
		store.set(CONTEXT_DEFAULT)
	}

	export function selectOverlap(id: string) {
		store.update((ctx) => ({ ...ctx, selectedOverlapInstance: id }))
	}

	export function updateOverlapInstances(slots: Array<DeviceGroupSlot>) {
		if (!slots) return

		let newOverlapInstances: Array<OverlapInstance> = []

		const getSlotRect = (slot: DeviceGroupSlot) => {
			const width = slot.width * slot.scale_x
			const height = slot.height * slot.scale_y
			const left = slot.pos_x
			const right = slot.pos_x + width
			const top = slot.pos_y
			const bottom = slot.pos_y + height
			return {
				width,
				height,
				left,
				right,
				top,
				bottom,
			}
		}

		for (const slotA of slots) {
			const aRect = getSlotRect(slotA)
			for (const slotB of slots) {
				if (slotB.id === slotA.id) continue // if slotB and slotA are the same slot, skip this interval
				// If there is an existing overlap for BOTH slots already, skip this interval
				const existingSlotOverlap = newOverlapInstances.find(
					(instance) =>
						instance.slots.includes(slotA) &&
						instance.slots.includes(slotB)
				)
				if (existingSlotOverlap) continue

				const bRect = getSlotRect(slotB)

				const doesLeftOverlap =
					aRect.left >= bRect.left && aRect.left < bRect.right
				const doesRightOverlap =
					aRect.right <= bRect.right && aRect.right > bRect.left
				const doesTopOverlap =
					aRect.top >= bRect.top && aRect.top < bRect.bottom
				const doesBottomOverlap =
					aRect.bottom <= bRect.bottom && aRect.bottom > bRect.top

				if (
					(doesLeftOverlap || doesRightOverlap) &&
					(doesTopOverlap || doesBottomOverlap)
				) {
					let overlapBounds: {
						top: number
						left: number
						right: number
						bottom: number
					} = {
						top: undefined,
						left: undefined,
						right: undefined,
						bottom: undefined,
					}

					if (doesLeftOverlap) overlapBounds.left = aRect.left
					else overlapBounds.left = bRect.left

					if (doesRightOverlap) overlapBounds.right = aRect.right
					else overlapBounds.right = bRect.right

					if (doesTopOverlap) overlapBounds.top = aRect.top
					else overlapBounds.top = bRect.top

					if (doesBottomOverlap) overlapBounds.bottom = aRect.bottom
					else overlapBounds.bottom = bRect.bottom

					newOverlapInstances.push({
						id: `${slotA.id}-${slotB.id}`,
						slots: [slotA, slotB],
						rect: overlapBounds,
					})
				}
			}
		}

		store.update((ctx) => ({
			...ctx,
			overlapInstances: [...newOverlapInstances],
		}))
	}
}
