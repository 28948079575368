<script lang="ts">
	interface Props {
		strokeColor?: string
	}

	let { strokeColor = "black" }: Props = $props()
</script>

<svg
	class="hamburger"
	width="30"
	height="20"
	viewBox="0 0 30 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M2 10H28"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
		stroke-linecap="round"
	/>
	<path
		d="M2 2H28"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
		stroke-linecap="round"
	/>
	<path
		d="M2 18H28"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
		stroke-linecap="round"
	/>
</svg>

<style>
</style>
