<script lang="ts">
	import { PlusIcon } from "svelte-comps/icons"
	import { ProjectorMenuController } from "../ProjectorMenuController.svelte"
	import { ProjectorRegistrationContext } from "../view/registration/DeviceRegistrationContext"
	import { DataHandlerDevice, type Device } from "luxedo-data"

	type Props = {
		view: "My Devices" | "My Groups"
		isExpanded: boolean
	}

	let { view, isExpanded = $bindable() }: Props = $props()

	let device = $derived(ProjectorMenuController.ctx.device)

	function registerNew() {
		ProjectorMenuController.setView("Registration")
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: DataHandlerDevice.getNextUIColor(),
			type: "Cast",
		})
	}

	function createNewLuxLink() {
		ProjectorMenuController.setView("Registration")
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: undefined,
			type: "LuxLink",
		})
	}

	function openOptions() {
		isExpanded = true
	}

	function cancelRegister() {
		isExpanded = false
	}

	function createNewGroup() {
		ProjectorMenuController.setView("Registration")
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: undefined,
			type: "Group",
		})
	}
	isExpanded = false
</script>

<div class="new-projector-create-wrapper">
	{#if isExpanded && view === "My Devices"}
		<button class="new-projector-select" onclick={registerNew}
			>Register New Luxedo</button
		>

		<button class="new-projector-select" onclick={createNewLuxLink}
			>Create LuxLink Device</button
		>
		<button class="new-projector-select" onclick={cancelRegister}>Cancel</button
		>
	{:else if view === "My Devices"}
		<button
			id="begin-registration-button"
			class="new-projector-select"
			onclick={openOptions}
		>
			<PlusIcon /> <span>Register New Device</span></button
		>
	{:else}
		<button
			id="begin-registration-button"
			class="new-projector-select"
			onclick={createNewGroup}
		>
			<PlusIcon /> <span>New Projector Group</span></button
		>
	{/if}
</div>

<style>
	button.new-projector-select {
		transform: none;
		background-color: var(--color-bg);
		width: 100%;
		border-radius: 0.5rem;
		margin-bottom: 0.5rem;
		padding: 1rem;
		display: flex;
		align-items: center;

		transition:
			filter 250ms,
			color 250ms,
			box-shadow 250ms;
	}

	button.new-projector-select.selected,
	button.new-projector-select:hover,
	button.new-projector-select:focus-visible {
		/* filter: brightness(1.1); */
		color: var(--color-main);
	}

	button.new-projector-select.selected :global(.svg-stroke),
	button.new-projector-select:hover :global(.svg-stroke),
	button.new-projector-select:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.new-projector-select :global(svg) {
		width: 1rem;
		height: 1rem;
		margin-left: 0.5rem;
		margin-right: 1.5rem;
	}
</style>
