<script lang="ts">
	import {
		Device,
		DataHandlerDevice,
		DeviceRPi,
		DeviceBrowser,
		DeviceGroup,
		DataHandlerCalibration,
		DataHandlerSnapshot,
	} from "luxedo-data"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"
	import { ProjectorRegistrationContext } from "./DeviceRegistrationContext"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { openPopupOverlay } from "svelte-comps/overlay"
	import { openGroupEditorOverlay } from "../../../../reusable/overlays/group-editor"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import { launchLuxLink } from "../../LuxLinkLauncher"
	import DeviceColorPicker from "../../device-color-picker/DeviceColorPicker.svelte"

	let prevSelectedDevice: Device
	let deviceName: string = $state()

	let deviceType: "Cast" | "Group" | "LuxLink" = $state()

	let deviceId: string = $state()
	let devicePassword: string = $state()

	ProjectorRegistrationContext.subscribe((ctx) => {
		if (ctx.isRegistering) {
			prevSelectedDevice = ctx.prevSelectedDevice
			deviceType = ctx.type
		} else {
			prevSelectedDevice = undefined
			deviceType = "Cast"
		}
	})

	async function registerNewDevice() {
		try {
			await LuxedoRPC.api.device.device_register(
				Number(deviceId),
				devicePassword,
				deviceName ?? "My Luxedo"
			)

			Toast.success(`${deviceName ?? "My Luxedo"} successfully registered.`)

			await DataHandlerDevice.pull([Number(deviceId)])
			const dev = DataHandlerDevice.get(Number(deviceId)) as DeviceRPi
			await DataHandlerCalibration.pull()
			await DataHandlerSnapshot.pull()

			if (dev._rawData.product_id && dev._rawData.product_id < 5) {
				openPopupOverlay(
					{
						buttonText: "I understand",
						prompt: [
							"Our systems detected that this may be a second-hand device!",
							"Please note that Luxedo does not offer support, warranties, or returns for second-hand devices, and does not take responsibility for any damage or malfunction of devices which have had a previous owner.",
							"If you experience issues with a second-hand device, please take it up with the person who sold you the device.\n",
							"(If you purchased this device directly from Luxedo, you may ignore this message)",
						],
					},
					{
						heading: "Resale Device Disclaimer",
						classHeading: "no-unerline",
					}
				)
			}

			onRegisterComplete(dev)
		} catch (e) {
			console.error("ERROR registering projector", e)
			Toast.error(
				"Failed to register projector, please verify the id and password then try again."
			)
		}
	}

	async function registerNewLuxLink() {
		try {
			const { dev_id } =
				await LuxedoRPC.api.device.device_synth_create(deviceName)
			await DataHandlerDevice.pull([dev_id])
			const dev = DataHandlerDevice.get(dev_id) as DeviceBrowser
			onRegisterComplete(dev)

			Toast.success(
				`${deviceName ?? "LuxLink"} successfully created - launching setup!`
			)

			launchLuxLink(dev)
		} catch (e) {
			console.error("ERROR creating device group", e)
			Toast.error("Unable to create LuxLink.")
		}
	}

	async function registerNewGroup() {
		try {
			const { dev_id } =
				await LuxedoRPC.api.device.device_group_create(deviceName)
			await DataHandlerDevice.pull([dev_id])
			const dev = DataHandlerDevice.get(
				Number(dev_id)
			) as unknown as DeviceGroup
			onRegisterComplete(dev)

			Toast.success(`${deviceName ?? "Device group"} successfully registered!`)
			openGroupEditorOverlay(dev)
		} catch (e) {
			console.error("ERROR creating device group", e)
			Toast.error(
				"Failed to create device group, please refresh and try again."
			)
		}
	}

	function onRegisterComplete(dev: Device) {
		SelectedDeviceStore.set(dev)
		ProjectorMenuController.setDevice(dev)

		jumpToFirstDeviceMenu(dev)
	}

	async function onRegister() {
		const device = DataHandlerDevice.get(Number(deviceId))
		if (device) {
			return Toast.error("Device already registered! ")
		}

		if (deviceType === "Group") return registerNewGroup()
		else if (deviceType === "LuxLink") return registerNewLuxLink()
		else return registerNewDevice()
	}

	function jumpToFirstDeviceMenu(dev: Device) {
		const selectableViews =
			ProjectorMenuController.getSelectableViewsFromDevice(dev)
		const viewToSet = selectableViews.find((v) => v === "Calibration")
		ProjectorMenuController.setView(viewToSet ?? selectableViews[0])
	}

	function onCancel() {
		if (!prevSelectedDevice) {
			const devices = DataHandlerDevice.getMany()
			prevSelectedDevice = devices[devices.length - 1]
		}

		ProjectorMenuController.setDevice(prevSelectedDevice)
		jumpToFirstDeviceMenu(prevSelectedDevice)

		ProjectorRegistrationContext.set({
			isRegistering: false,
		})
	}

	function checkEnter(e: KeyboardEvent) {
		if (e.key === "Enter") {
			if (deviceType === "Cast" && deviceName && devicePassword && deviceId)
				onRegister()
			else if (deviceName) onRegister()
		}
	}

	function switchToLuxLink() {
		ProjectorRegistrationContext.update((ctx) => ({
			...ctx,
			type: "LuxLink",
			isRegistering: true,
			prevSelectedDevice: undefined,
			colorIndex: DataHandlerDevice.getNextUIColor(),
		}))
	}

	function switchToLPS() {
		ProjectorRegistrationContext.update((ctx) => ({
			...ctx,
			type: "Cast",
			isRegistering: true,
			prevSelectedDevice: undefined,
			colorIndex: DataHandlerDevice.getNextUIColor(),
		}))
	}
</script>

<div class="flex-row">
	<DeviceColorPicker />
	<div class="flex-column">
		<div class="device-registration-inputs">
			{#if deviceType === "Group"}
				<span class="device-group-info"
					>A projector group is a combination of multiple projectors which can
					be used to create scenes and lightshows to cover larger projection
					spaces.
				</span>
			{:else if deviceType === "LuxLink"}
				<span class="device-group-info"
					>LuxLink allows you to use your own projector as a Luxedo device as
					long as it remains plugged into your computer.
				</span>
			{/if}
			<input
				type="text"
				placeholder={deviceType === "Group" ? "Group Name" : "Device Name"}
				bind:value={deviceName}
			/>
			{#if deviceType !== "Group" && deviceType !== "LuxLink"}
				<div class="flex-row">
					<input type="text" placeholder="Device ID" bind:value={deviceId} />
					<input
						type="text"
						placeholder="Device Password"
						bind:value={devicePassword}
					/>
				</div>
			{/if}
		</div>
	</div>
</div>
<div class="space-between">
	{#if deviceType === "Cast"}
		<button class="link-button flip-reg-btn" onclick={switchToLuxLink}>
			Registering LuxLink?
		</button>
	{:else if deviceType === "LuxLink"}
		<button class="link-button flip-reg-btn" onclick={switchToLPS}>
			Registering Luxedo Device?
		</button>
	{/if}
	<div class="button-container">
		<button class="link-button" onclick={onCancel}>Cancel</button>
		<button
			class=""
			onclick={onRegister}
			disabled={deviceType === "Group" || deviceType === "LuxLink"
				? !!!deviceName
				: !(!!deviceId && !!devicePassword && !!deviceName)}>Register</button
		>
	</div>
</div>
<div class="finish-reg-text">
	Complete device registration to adjust settings or view device information.
</div>

<svelte:document onkeydown={checkEnter} />

<style>
	.device-registration-inputs {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.flex-column {
		width: 100%;
	}

	.finish-reg-text {
		font-size: var(--h1);
		color: var(--color-text);
		display: flex;
		flex-grow: 1;
		text-align: center;
		align-items: center;
		justify-content: center;
	}

	.device-group-info {
		color: var(--color-text);
		margin-bottom: 0.5rem;
	}

	input {
		width: 100%;
		margin-bottom: 1rem;
		background-color: transparent;
	}

	input::placeholder {
		color: var(--color-text);
	}

	.button-container {
		justify-content: flex-end;
		width: fit-content;
		margin-bottom: 0;
	}

	.button-container button {
		margin-left: 0.5rem;
		padding: 0rem 0.5rem;
	}

	.flex-row input:first-of-type {
		margin-right: 1rem;
	}

	.space-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.flip-reg-btn {
		padding: 0;
		width: 14rem;
		color: var(--color-text);
	}
</style>
