<script lang="ts">
	import { AccountNavigator } from "../../AccountStore"
	import SubmenuHeading from "../SubmenuHeading.svelte"

	function goBack() {
		AccountNavigator.closeSubscription()
	}
</script>

<SubmenuHeading onBack={goBack}>
	<div class="heading-content">
		<h2>Cancel LuxLink Subscription</h2>
		<span>Click the "Cancel Subscription" button below to cancel your subscription.</span>
	</div>
</SubmenuHeading>

<div class="cancel-text-container">
	<p>
		Are you sure you want to cancel your subscription?
		<br />
		If so, you will be able to continue using LuxLink until the end of your current billing cycle, after
		which you will not be charged and the subscription will be terminated.
	</p>
</div>

<div class="button-container">
	<button class="link-button" onclick={goBack}>Go Back</button>
	<button class="warn">Cancel Subscription</button>
</div>

<style>
	h2 {
		font-size: var(--h1);
		margin: 0;
	}

	.heading-content {
		display: flex;
		flex-direction: column;
	}

	.heading-content span {
		font-size: var(--h3);
	}

	.cancel-text-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p {
		color: var(--color-text);
	}

	.button-container {
		width: fit-content;
		position: absolute;
		bottom: 1rem;
		right: 2rem;
	}
</style>
