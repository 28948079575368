<script lang="ts">
	import { createEventDispatcher } from "svelte"

	const dispatch = createEventDispatcher()

	interface Props {
		value: number
		max: number
		min: number
		label: string
		step?: number
		disabled?: boolean
		labelUnit?: string
		containerClass?: string
		id?: any
	}

	let {
		value = $bindable(),
		max,
		min,
		label,
		step = 1,
		disabled = false,
		labelUnit = undefined,
		containerClass = "",
		id = undefined,
	}: Props = $props()

	function onValueChange(
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		const target = e.currentTarget
		if (Number(target.value) > max) {
			value = max
			setTimeout(() => {
				target.value = value.toString()
			})
		} else if (Number(target.value) < min) {
			value = min
			setTimeout(() => {
				target.value = value.toString()
			})
		} else value = Number(target.value)

		dispatch("change", value)
	}

	/**
	 * Clears the 0 at the start of a string if the value is not 0
	 * @param e
	 */
	function ensureClearZero(
		e: KeyboardEvent & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		const target = e.currentTarget
		setTimeout(() => {
			if (target.value.charAt(0) === "0" && Number(target.value)) {
				target.value = target.value.slice(1)
			}
		})
	}
</script>

<div {id} class="input-container {containerClass}">
	<label for="{label.replaceAll(' ', '-')}-input-number" class={disabled ? "disabled" : ""}
		>{label}
	</label>
	<span class="number-container">
		<input
			id="{label.replaceAll(' ', '-')}-input-number"
			type="number"
			{step}
			{min}
			{max}
			{value}
			onblur={onValueChange}
			onchange={onValueChange}
			onkeydown={ensureClearZero}
			{disabled}
		/>
		{#if labelUnit}
			<span class="label-unit">
				{labelUnit}
			</span>
		{/if}
	</span>
	<input
		id="{label.replaceAll(' ', '-')}-input-range"
		type="range"
		{step}
		{min}
		{max}
		{value}
		onblur={onValueChange}
		onchange={onValueChange}
		onkeydown={ensureClearZero}
		{disabled}
	/>
</div>

<style>
	label.disabled {
		color: var(--color-text);
	}

	input[type="number"] {
		width: 3em;
		text-align: right;
		background-color: var(--color-bg);
		padding: 0.2em 0.5em;
		margin: 0 0.5rem;
		line-height: 1em;
		font-size: var(--h3);
		text-align: center;
		cursor: text;
	}

	.number-container {
		font-size: var(--h3);
		margin-right: 0.5rem;
	}

	.number-container:has(:global(.label-unit)) input {
		padding-right: 0;
		text-align: right;
	}

	input[type="number"]:disabled {
		opacity: 0.5;
	}

	input[type="range"] {
		padding: 0;
		border: 1px solid var(--color-main);
		width: unset;
		flex-grow: 1;
	}

	input[type="range"]:disabled {
		opacity: 0.25;
	}
</style>
