<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M6 5L6 19"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
		stroke-linecap="round"
	/>
	<path
		d="M18 5V19"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="4"
		stroke-linecap="round"
	/>
</svg>

<style>
</style>
