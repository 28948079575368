<script lang="ts">
	import { Lightshow, type Scene } from "luxedo-data"
	import LightshowTile from "../../reusable/file-system/tiles/LightshowTile.svelte"
	import SceneTile from "../../reusable/file-system/tiles/scene/SceneTile.svelte"
	import {
		openNewSceneOverlay,
		openShowOverview,
	} from "../../reusable/overlays"
	import DesignTile from "../../reusable/file-system/tiles/DesignTile.svelte"
	import { ShowLibraryController } from "./ShowLibraryController"
	import { GridIcon } from "svelte-comps/icons"

	interface Props {
		viewType: "Scenes" | "Lightshows"
		shows: Array<Lightshow | Scene>
	}

	let { shows, viewType }: Props = $props()

	let isCreatingLightshow = $state(false)
	ShowLibraryController.subscribe(
		(ctx) => (isCreatingLightshow = ctx.isEditingLightshow)
	)
</script>

<div id="library-list">
	{#if shows.length}
		<div class="library-list">
			{#each shows as show (show.id)}
				{#if show instanceof Lightshow}
					<LightshowTile
						lightshow={show}
						onClick={() => openShowOverview(show)}
					/>
				{:else}
					<DesignTile
						design={show}
						showSelectOutline={isCreatingLightshow}
						onClick={() => openShowOverview(show)}
					/>
				{/if}
			{/each}
		</div>
	{:else}
		<div class="empty-indicator">
			<GridIcon />
			<div class="empty-text">
				{#if viewType === "Scenes"}
					Scenes are rendered videos created using your calibrated snapshot as a
					guide, allowing you to map videos and images onto your projection
					space. <br />
					Click
					<button
						onclick={() => openNewSceneOverlay()}
						class="create-quick-action">Create Scene</button
					>
					to create your first Scene!
				{:else}
					Lightshows are rendered playlists of scenes you can schedule, allowing
					you to create a seamless masterpiece from your individual scenes. <br
					/>Click
					<button
						class="create-quick-action"
						onclick={() =>
							ShowLibraryController.LightshowEditor.createLightshow()}
						>Create Lightshow</button
					> to create your first lightshow!
				{/if}
			</div>
		</div>
	{/if}
</div>

<style>
</style>
