<script lang="ts">
	import DirectUploadForm from "./DirectUploadForm.svelte"



	interface Props {
		triggerUpload: () => void;
		postSubmit: () => void;
		sceneName: string;
		projectFolder: number;
		selectedSnapshotId: number;
		selectedDeviceId: number;
		newSceneId: number;
		deepFry?: "off" | "low" | "med" | "high";
	}

	let {
		triggerUpload = $bindable(),
		postSubmit,
		sceneName,
		projectFolder,
		selectedSnapshotId,
		selectedDeviceId,
		newSceneId = $bindable(),
		deepFry = $bindable("off")
	}: Props = $props();

	let uploadState: "upload" | "process" | "error" = $state()
</script>

<div class="flex-column">
	{#if uploadState === "process"}
		<h3>Processing Video...</h3>
	{:else if uploadState === "error"}
		<h3>Error Uploading Project</h3>
	{:else}
		<div class="flex-row">
			<h3>Upload your Project</h3>

			<div class="input-container select-container editor-input">
				<label for="brightness-modifier">Brightness Booster: </label>
				<!-- disabled={!allowEdit} -->
				<select id="brightness-modifier" name="brightness_modifier" bind:value={deepFry}>
					<option value="off">Off</option>
					<option value="low">Bright</option>
					<option value="med">Brighter</option>
					<option value="high">Brightest</option>
				</select>
			</div>
		</div>
	{/if}
	<DirectUploadForm
		{deepFry}
		{sceneName}
		{projectFolder}
		{selectedSnapshotId}
		{selectedDeviceId}
		bind:currentState={uploadState}
		bind:triggerUpload
		bind:newSceneId
		onComplete={postSubmit}
	/>
</div>

<style>
	.flex-column > h3 {
		margin-bottom: 1rem;
	}

	.flex-row {
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}
</style>
