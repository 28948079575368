<script lang="ts">
	import {
		DataHandlerDevice,
		DeviceGroup,
		DeviceRPi,
		Guides,
	} from "luxedo-data"
	import GroupNetworkDiagram from "./GroupNetworkDiagram.svelte"
	import GroupNetworkIssue from "./GroupNetworkIssue.svelte"
	import { Reactivity } from "svelte-comps/reactivity"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"

	type Props = {
		group: DeviceGroup
	}

	let { group }: Props = $props()
	let isRestarting: boolean = $state()

	$effect.pre(() => {
		Reactivity.useProps(group, ["children"])
	})

	let children = $derived(
		Reactivity.propOf(group, "children")?.map((slot) =>
			DataHandlerDevice.get(slot.device_id)
		) ?? []
	)

	$effect.pre(() => {
		for (const child of children) {
			Reactivity.useProps(child, ["eidos", "isOnline"])
		}
	})

	let childEidos = $derived(Reactivity.propOfMany(children, "eidos"))
	let childOnlineStatus = $derived(Reactivity.propOfMany(children, "isOnline"))

	let devicesAreAllOnline = $derived(
		// filter all the offline statuses, returning true if any status are offline
		Object.values(childOnlineStatus).filter((isOnline) => {
			return !isOnline
		}).length === 0
	)

	let devicesAreOnSameNetwork = $derived.by(() => {
		if (!devicesAreAllOnline) return false

		const networks: Array<string> = []

		Object.values(childEidos).forEach((eidos: DeviceRPi["eidos"]) => {
			networks.push(eidos.network?.ssid)
		})

		return new Set(networks).size <= 1
	})

	let devicesCanCommunicate = $derived.by(() => {
		const connectedStatuses = Object.values(childEidos).map(
			(eidos: DeviceRPi["eidos"]) => {
				return eidos?.lux2lux?.connected
			}
		)
		return !connectedStatuses.some((connected) => !connected)
	})

	let devicesAreUpdated = $derived(
		!children.some((child) => {
			return child.isUpdateAvailable(false)
		})
	)

	async function awaitDevicePowerCycle(device: DeviceRPi) {
		await device.listenEidosCondition(() => !device.isOnline)
		await device.listenEidosCondition(() => device.isOnline)
	}

	async function restartGroup() {
		isRestarting = true
		Toast.text("Restarting devices... This may take a few minutes.")

		await LuxedoRPC.api.device.device_group_restart_children(group.id)

		const promises = group.getChildDevices().map(awaitDevicePowerCycle)
		await Promise.all(promises)

		Toast.success("Devices successfully restarted.")

		isRestarting = false
	}
</script>

<div class="group-network-menu projector-section">
	<h3 class="setting-heading">Group Connection Status</h3>

	<p class="info">
		See the current connection status of the devices within this group. If any
		issues arise, verifying the connection status here can help troubleshoot and
		ensure seamless operation across all grouped projectors.
	</p>

	<div class="network-issues">
		<GroupNetworkIssue
			groupConnectionIssue="Devices are all online"
			status={devicesAreAllOnline}
		/>
		<GroupNetworkIssue
			groupConnectionIssue="Devices are all updated"
			status={devicesAreUpdated}
		/>
		<GroupNetworkIssue
			groupConnectionIssue="Devices are on the same network"
			status={devicesAreOnSameNetwork}
		/>
		<GroupNetworkIssue
			groupConnectionIssue="Devices can communicate with each other"
			status={devicesCanCommunicate}
		/>
	</div>

	<GroupNetworkDiagram device={group} />

	{#if (devicesAreOnSameNetwork && devicesAreUpdated && devicesAreAllOnline) || isRestarting}
		<div class="last-step">
			<div class="restart-prompt">
				Shows failing to play? Ensure the network is configured to follow the <a
					target="_blank"
					href={Guides["default"]["Other"]["Group Network Requirements"]}
					>group network requirements</a
				>, then restart the devices in the group.
			</div>
			<button class="small warn" onclick={restartGroup} disabled={isRestarting}
				>{isRestarting ? "Restarting..." : "Restart Devices"}</button
			>
		</div>
	{/if}
</div>

<style>
	.group-network-menu {
		display: flex;
		flex-direction: column;
		/* align-items: center; */
	}

	.group-network-menu :global(#group-diagram) {
		width: 100%;
	}

	.network-issues {
		margin-bottom: 1rem;
	}

	.info {
		line-height: 1.25rem;
		color: var(--color-text);
		margin-bottom: 1rem;
		margin-top: 0.5rem;
	}

	.restart-prompt {
		display: inline;
		color: var(--color-text);
		margin-right: 0.25rem;
		line-height: 1.25rem;
	}

	.last-step {
		line-height: 1.25rem;
	}

	.last-step button {
		line-height: 1em;
		padding: 0.25rem 0.5rem;
	}
</style>
