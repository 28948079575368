<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { tooltip } from "svelte-comps/tooltip"
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"
	import { ProjectorMenuController } from "../../../ProjectorMenuController.svelte"
	import type { DeviceRPi } from "luxedo-data"

	let device = $derived(ProjectorMenuController.ctx.device) as DeviceRPi

	let timeoutDuration = $derived(
		ProjectorPreferencesManager.pendingChanges.timeoutDuration ??
			ProjectorPreferencesManager.preferences.timeoutDuration
	)

	let timeoutDurationMinutes = $derived(Math.round(timeoutDuration / 60))
	let isTimeoutActive = $derived(timeoutDuration > 0)

	function onToggleProjectorTimeout() {
		if (isTimeoutActive)
			ProjectorPreferencesManager.stageChange("timeoutDuration", 0)
		else
			ProjectorPreferencesManager.stageChange(
				"timeoutDuration",
				device?.eidos?.config?.fw_config?.projector_keep_alive_duration ||
					10 * 60
			)
	}

	function onProjectorTimeoutUpdate(
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		const target = e.currentTarget

		setTimeout(() => {
			ProjectorPreferencesManager.stageChange(
				"timeoutDuration",
				Number(target.value) * 60
			)
		})
	}
</script>

<div class="flex-row projector-timeout-container">
	<div class="switch-container">
		<span
			class="label projector-timeout"
			use:tooltip={{
				content:
					"The duration between the last active projection and the projector's automatic shutdown to conserve energy.",
				pointing: "top",
			}}
			>Sleep Timer:
		</span>
		<ToggleSwitch
			isEnabled={isTimeoutActive}
			onUpdate={onToggleProjectorTimeout}
			info={"Toggle the projector timeout. If it is disabled, the internal projector will remain powered on."}
		/>
	</div>
	{#if isTimeoutActive}
		<input
			type="number"
			name=""
			id="timeout-duration-input"
			value={timeoutDurationMinutes}
			onkeydown={onProjectorTimeoutUpdate}
		/>
		min
	{/if}
</div>

<style>
	#timeout-duration-input {
		width: 2rem;
		flex-grow: 0;
	}
</style>
