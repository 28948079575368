import { DeviceGroup, DeviceRPi, type Device } from "luxedo-data"
import { ComponentController } from "svelte-comps/stores"
import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"
import { ProjectorPreferencesManager } from "./ProjectorPreferenceManager.svelte"
import { updateDeviceTypeView } from "./device-selector"

export type ProjectorMenuView =
	| "Calibration"
	| "Config"
	| "Network"
	| "Controls"
	| "Audio"
	| "Settings"
	| "About"
	| "Registration"

export const PROJECTOR_MENU_VIEWS: Array<ProjectorMenuView> = [
	"Calibration",
	"Config",
	"Network",
	"Controls",
	"Audio",
	"Settings",
	"About",
	"Registration",
]

interface ProjectorMenuContext {
	view: ProjectorMenuView
	device: Device
}

class ProjMenuCntrl extends ComponentController<ProjectorMenuContext> {
	constructor() {
		super({
			view: "Registration",
			device: undefined,
		})
	}

	/**
	 * Sets the current view of the projector menu
	 * @param view
	 */
	setView = (view: ProjectorMenuView) => {
		this.ctx.view = view
	}

	/**
	 * Updates the selected device
	 * @param device
	 */
	setDevice = (device: Device) => {
		// the DeviceFilterInstance will trigger this with an undefined value if clicking the same device twice.
		if (!device) return

		this.ctx.device = device

		ProjectorPreferencesManager.refresh(device)
		SelectedDeviceStore.set(device) // for backwards compatibility
		const selectableViews = this.getSelectableViewsFromDevice(device)

		if (device instanceof DeviceGroup) {
			updateDeviceTypeView("My Groups")
		} else {
			updateDeviceTypeView("My Devices")
		}

		if (!selectableViews.includes(this.ctx.view))
			this.setView(selectableViews[0])
	}

	/**
	 * Determines which menus will be available to selected based upon the provided device.
	 */
	getSelectableViewsFromDevice(
		device: Device
	): Partial<typeof PROJECTOR_MENU_VIEWS> {
		if (device instanceof DeviceGroup) {
			return ["Config", "Controls", "Network", "About"]
		} else if (device instanceof DeviceRPi) {
			return [
				"Calibration",
				"Audio",
				"Controls",
				"About",
				"Network",
				"Settings",
			]
		} else {
			return ["Calibration", "Controls", "Audio", "About"]
		}
	}
}

export const ProjectorMenuController = new ProjMenuCntrl()
