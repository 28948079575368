import { OverlayController } from "./OverlayController"

import Overlay from "./Overlay.svelte"
import OverlaySideMenu from "./OverlaySideMenu.svelte"

const openOverlay = OverlayController.openOverlay
const closeOverlay = OverlayController.closeOverlay

export { openOverlay, closeOverlay, Overlay, OverlaySideMenu }
export * from "./variants"
export * from "./variants/types"
export type { SideMenuOptions } from "./OverlayController"
