<script lang="ts">
	import { type User } from "luxedo-data"
	import { UserStore } from "../../../../../stores/UserStore"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import { AccountNavigator } from "../../AccountStore"
	import { StoragSubscriptionController, getUserStorage } from "./StorageSubscriptionController"
	import CancelButton from "../CancelButton.svelte"

	let user: User
	let sceneStorage: number = $state()
	let mediaStorage: number = $state()

	let spaceUsed: number = $state()
	let spaceAvailable: number = $state()
	let spaceFree: number

	let percentScene: number = $state()
	let percentMedia: number = $state()
	let percentConsumed: number

	let userHasSubscription: boolean = $state()
	let subscriptionPlanQuantity: number = $state()

	let isSubscriptionCancelled: boolean
	let subscriptionCancelDate: string

	UserStore.subscribe((u) => {
		user = u
		if (u.chargebee.storagePlan) {
			userHasSubscription = true
			subscriptionPlanQuantity = u.chargebee.storagePlan.quantity
		}

		getStorage()
		getUserSubscription()
	})

	/**
	 * Gets the user's storage info (capacity and used)
	 */
	function getStorage() {
		const storageData = getUserStorage()

		percentConsumed = storageData.percentUsed
		percentMedia = storageData.percentMedia
		percentScene = storageData.percentScene

		sceneStorage = storageData.sceneStorage
		mediaStorage = storageData.mediaStorage

		spaceUsed = storageData.spaceUsed
		spaceAvailable = storageData.spaceAvailable
		spaceFree = storageData.spaceFree
	}

	function getUserSubscription() {
		const cancelStatus = ChargebeeManager.getCancelStatus(user.chargebee.storagePlan)

		if (!cancelStatus) {
			isSubscriptionCancelled = false
			subscriptionCancelDate = undefined
			return
		}

		if (cancelStatus.cancelled) isSubscriptionCancelled = true
		if (cancelStatus.endDateString) subscriptionCancelDate = cancelStatus.endDateString
	}

	function onCancelClick() {
		AccountNavigator.openSubscription()
		StoragSubscriptionController.navigateTo("Cancel")
	}

	function onUpgradeClick() {
		AccountNavigator.openSubscription()
		StoragSubscriptionController.navigateTo("Subscriber")
	}
</script>

<h3>Account Storage</h3>
<span id="space-used">
	{spaceUsed.toFixed(2)}GB <span>of {spaceAvailable / (1024 * 1024)} GB</span>
</span>

<div id="storage-preview">
	<div id="media-storage" style="width: {100 * percentMedia}%;"></div>
	<div id="scene-storage" style="width: calc({100 * percentScene}% + 1rem);"></div>
</div>
<div class="space-used-breakdown">
	<button class="upgrade-button outline-button" onclick={onUpgradeClick}> Upgrade </button>

	<div class="flex-column">
		<span id="media-used"
			>Media - {(mediaStorage / (1024 * 1024)).toFixed(2)} GB
			<div></div></span
		>
		<span id="scenes-used"
			>Scenes - {(sceneStorage / (1024 * 1024)).toFixed(2)} GB
			<div></div></span
		>
	</div>
</div>
<div class="subscription-info">
	<div class="flex-row">
		<span class="current-plan-label">Current Plan: </span>
		{#if userHasSubscription}
			{subscriptionPlanQuantity} x Additional 50GB
		{/if}
		<CancelButton title="Cancel Plan" onClick={() => onCancelClick()} />
	</div>
	<div class="flex-row">
		<span class="current-plan-label">Maximum Storage Capacity: </span>
		{spaceAvailable / (1024 * 1024)} GB
	</div>
</div>

<style>
	#storage-preview {
		width: 100%;
		border-radius: 99px;
		background-color: var(--color-bg-darker);
		overflow: hidden;

		height: 1.5rem;
		display: flex;
		flex-direction: row;
		padding: 0.25rem;
	}

	#media-storage {
		background-color: var(--color-main);
		z-index: 1;
		box-shadow: var(--shadow-large);
	}

	#scene-storage {
		position: relative;
		left: -1rem;
		background-color: var(--color-alt);
		/* border-top-right-radius: 99px;
		border-bottom-right-radius: 99px; */
	}

	#space-used {
		display: block;
		margin-bottom: 0.5rem;
		margin-top: 3rem;
		font-family: var(--font-heading);
		font-size: 32px;
		font-weight: 800;
	}

	#space-used span {
		font-size: var(--h2);
		color: var(--color-text);
	}

	#storage-preview div {
		height: 100%;
		border-radius: 99px;
	}

	.space-used-breakdown {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: var(--color-text);
		margin-top: 1rem;
	}

	.space-used-breakdown .flex-column span {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		font-size: var(--h3);
	}

	.space-used-breakdown span div {
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 99px;
		margin-left: 1rem;
	}

	#media-used div {
		background-color: var(--color-main);
	}

	#scenes-used div {
		background-color: var(--color-alt);
	}

	.subscription-info {
		position: absolute;
		bottom: 1rem;
	}

	.current-plan-label {
		color: var(--color-text);
		margin-right: 1rem;
	}

	.upgrade-button {
	}
</style>
