<script lang="ts">
	import type { Media } from "luxedo-data"

	interface Props {
		media: Media
	}

	let { media }: Props = $props()
</script>

<audio controls src={media.src.audio}></audio>

<style>
	audio {
		width: 50vw;
		margin-top: 5rem;
	}
</style>
