<script lang="ts">
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		type FullCalendarEvent,
		type Lightshow,
		type Scene,
	} from "luxedo-data"
	import { onMount } from "svelte"
	import { closeOverlay } from "svelte-comps/overlay"
	import ShowOverviewHeader from "./header/ShowOverview_Header.svelte"
	import ShowOverviewInfo from "./info/ShowOverview_Info.svelte"
	import { ShowOverviewController } from "./ShowOverviewController"

	let show: Scene | Lightshow = $state()
	let event: FullCalendarEvent

	let isPreviewing: boolean = $state(false)

	ShowOverviewController.subscribe((ctx) => {
		isPreviewing = ctx.isPreviewing
		show = ctx.show
		event = ctx.event
	})

	/**
	 * Hooks into DataHandlers for scenes and lightshows - if the scene cannot be pulled it will close the overlay.
	 * This handles instances where the scene is deleted while the overlay is open.
	 */
	function listenForShowDeletion() {
		DataHandlerScene.addListener((ids) => {
			if (!show) return closeOverlay()
			if (ids.includes(show.id)) show = DataHandlerScene.get(show.id)
			if (!show) return closeOverlay()
		})

		DataHandlerLightshow.addListener((ids) => {
			if (!show) return closeOverlay()
			if (ids.includes(show.id)) show = DataHandlerLightshow.get(show.id)
			if (!show) return closeOverlay()
		})
	}

	onMount(listenForShowDeletion)
</script>

<div id="show-preview" class="{isPreviewing ? 'showing-preview' : 'not-preview'} show-{show?.id}">
	<ShowOverviewHeader />
	<ShowOverviewInfo />
</div>

<style>
	#show-preview :global(.ctx-menu-button) {
		margin-right: 0.5rem;
	}

	#show-preview :global(#direct-upload-form-container > div) {
		padding: 2rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#show-preview :global(.scene-scheduler) {
		padding: 0;
	}

	#show-preview :global(form.dropzone) {
		margin-bottom: 0;
	}

	.heading-container {
		background-color: var(--color-bg-dark);
		backdrop-filter: blur(3rem);
		padding: 2rem;
		--tile-width: 12rem;
	}

	.internal-heading-container {
		display: flex;
		flex-direction: row;
		transition: transform;
		transition-duration: 500ms;
		transition-delay: 250ms;
	}

	.heading {
		display: flex;
		flex-direction: column;
		width: 50%;
		justify-content: flex-end;
	}

	.show-information {
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 70% 30%;
		/* grid-auto-columns: 70% 30%; */
	}

	.show-information > :global(*) {
		flex-basis: 50%;
	}

	.tile {
		min-width: var(--tile-width);
		width: var(--tile-width);
		height: var(--tile-width);
	}

	.lightshow-info {
		height: fit-content;
		margin-left: 1rem;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	.left {
		width: 100%;
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		padding-top: 3rem;
		margin-bottom: 1rem;
		width: 100%;
	}

	.color-block {
		width: 3rem;
		height: 3rem;
		right: 2.5rem;
		top: 11rem;
		position: absolute;
		border-radius: 100%;
		z-index: -1;
		filter: blur(3rem);
	}

	.duration-info {
		color: var(--color-text);
		margin-bottom: 0;
	}

	.tag-container {
		margin: 1rem 0 0 1rem;
		padding-right: 1rem;
	}

	.lightshow-actions {
		justify-content: flex-end;
	}

	#show-preview {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}

	.show-information {
		padding: 1rem 2rem;

		color: var(--color-text-light);
	}

	.show-information :global(h3) {
		font-size: var(--h1);
		margin: 0;
	}

	.show-information :global(.details) {
		margin-left: 1rem;
	}

	.show-information :global(.details .label) {
		color: var(--color-text);
	}

	.overlay-button-container {
		font-size: var(--h2);
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}

	.select-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
</style>
