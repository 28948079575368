<script lang="ts">
	import { Device, DeviceSpotlightManager } from "luxedo-data"

	interface Props {
		device: Device
		isDisabled: boolean
		setButtonsDisabled: (doDisable?: boolean) => void
	}

	let { device, isDisabled, setButtonsDisabled }: Props = $props()

	let isSpotlightToggling = $state(false)
	let isSpotlightActive = $state(false)

	DeviceSpotlightManager.subscribe((ctx) => {
		if (!device) isSpotlightActive = false
		else isSpotlightActive = ctx[device.id] ?? false
	})

	async function toggleSpotlight() {
		isSpotlightToggling = true
		setButtonsDisabled(true)
		try {
			if (isSpotlightActive)
				await DeviceSpotlightManager.deactivateSpotlight(device)
			else await DeviceSpotlightManager.activateSpotlight(device)
		} catch {
			console.error("Failed to toggle spotlight")
		}
		isSpotlightToggling = false
		setButtonsDisabled(false)
	}
</script>

<div class="action-container">
	<button
		class="outline-button"
		onclick={toggleSpotlight}
		disabled={isSpotlightToggling || isDisabled}
	>
		{#if isSpotlightToggling}
			{isSpotlightActive ? "Deactivating..." : "Activating..."}
		{:else}
			{isSpotlightActive ? "Deactivate" : "Activate"} Spotlight
		{/if}
	</button>
	<span class="action-info">
		Enable the projector spotlight to trigger your device to project white light
		allowing you to better visualize the bounds of the projection and adjust
		your device's position.
	</span>
</div>

<style>
</style>
