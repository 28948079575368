<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { ProjectorMenuController } from "../../../ProjectorMenuController.svelte"
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"

	let device = $derived(ProjectorMenuController.ctx.device)

	let isHotspotEnabled = $derived(
		ProjectorPreferencesManager.pendingChanges.enableHotspot ??
			ProjectorPreferencesManager.preferences.enableHotspot
	)

	function onHotspotToggle() {
		ProjectorPreferencesManager.stageChange("enableHotspot", !isHotspotEnabled)
	}
</script>

<div class="flex-row switch-container">
	<span class="label">Hotspot Enabled: </span>
	<ToggleSwitch
		info={`If disabled, the network used to connect this device to Wi-Fi (Luxedo${device.id}) will not be available while this device remains connected to the internet.`}
		isEnabled={isHotspotEnabled}
		onUpdate={onHotspotToggle}
	/>
</div>

<style>
</style>
