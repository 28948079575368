<script lang="ts">
	import { onMount } from "svelte"
	import {
		NetworkTestController,
		type NetworkTestState,
	} from "./NetworkTestController"

	import NetworkTest_Initial from "./NetworkTest_Initial.svelte"
	import NetworkTest_Loading from "./NetworkTest_Loading.svelte"
	import NetworkTest_Completed from "./NetworkTest_Completed.svelte"
	import NetworkTest_Error from "./NetworkTest_Error.svelte"

	let currentState: NetworkTestState["state"] = "initial"

	const unsubscribe = NetworkTestController.subscribe(
		(ctx: NetworkTestState) => (currentState = ctx.state)
	)

	onMount(() => {
		NetworkTestController.reset()
		return () => unsubscribe()
	})

	const ROUTES = {
		initial: NetworkTest_Initial,
		loading: NetworkTest_Loading,
		completed: NetworkTest_Completed,
		error: NetworkTest_Error,
	}
</script>

<div class="network-test">
	<svelte:component this={ROUTES[currentState]} />
</div>

<style>
	.network-test {
		--network-test-width: 35rem;

		display: flex;
		flex-direction: column;

		min-width: var(--network-test-width);
		min-height: 16rem;
	}

	.network-test :global(.button-container) {
		width: fit-content;
		gap: 1rem;
		position: absolute;
		bottom: 0;
		right: 1rem;
	}
</style>
