<script lang="ts">
	import type { Folder, Media } from "luxedo-data"
	import { MediaLibraryController } from "./MediaLibraryController"
	import { UserStore } from "../../../stores/UserStore"
	import MediaList from "./MediaList.svelte"
	import MediaLibraryHeading from "./media-library-heading/MediaLibraryHeading.svelte"

	let media: Array<Media | Folder> = $state([])
	let dirMedia: Array<Media | Folder> = $state([])
	let libraryWidth: number = $state()
	let tileWidth: number = $state()

	let searchInput: string = $state(undefined)
	let activeFolder: Folder

	let runFilter: (content: Array<Media>) => Array<Media> = (content) => content
	let runOrder: (content: Array<Media>) => Array<Media> = (content) => content

	UserStore.subscribe((user) => {
		MediaLibraryController.updateActiveDirectory(user.directories.media)
	})

	MediaLibraryController.subscribe((ctx) => {
		activeFolder = ctx.activeFolder
		onFilterSelectionUpdate(
			ctx.filterSelection.filter,
			ctx.filterSelection.order
		)
	})

	MediaLibraryController.setDirectoryUpdateListener(
		(newMedia) => (dirMedia = newMedia)
	)

	function filterMedia(dirMedia: Array<Media | Folder>, searchInput: string) {
		let media
		if (searchInput) {
			media = [
				...dirMedia.filter((media) =>
					media.name.toLowerCase().includes(searchInput.toLowerCase())
				),
			]
		} else media = [...dirMedia]

		media = filterOperation(media)
		return [...media]
	}

	function filterOperation(content: Array<Media>) {
		let filteredContent = runFilter(content)
		let orderedContent = runOrder(filteredContent)
		return orderedContent
	}

	/** Set the filter function according to the new filter selection. */
	function onFilterSelectionUpdate(filter: string, order: string) {
		type Content = Array<Media>

		switch (filter) {
			case "Name":
				runFilter = (content: Content) =>
					content.sort((a, b) => a.name.localeCompare(b.name))
				break
			case "Last Modified":
				runFilter = (content: Content) =>
					content.sort(
						(a, b) =>
							b.updated_at.getUTCSeconds() - a.updated_at.getUTCSeconds()
					)
				break
			default:
				runFilter = (content) => content
				break
		}

		switch (order) {
			case "Descending":
				runOrder = (content: Content) => content
				break
			case "Ascending":
			default:
				runOrder = (content: Content) => content.reverse()
				break
		}

		media = filterMedia(dirMedia, searchInput)
	}
	function calculateTileWidth(width: number) {
		const TILE_WIDTH_PREFFERED = 128
		const TILE_MARGIN = 32.5

		const tileAmountPerRow = Math.floor(
			width / (TILE_WIDTH_PREFFERED + TILE_MARGIN)
		)
		const newTileWidth = Math.floor(width / tileAmountPerRow) - TILE_MARGIN

		tileWidth = newTileWidth
	}

	$effect(() => {
		calculateTileWidth(libraryWidth)
	})

	$effect(() => {
		media = filterMedia(dirMedia, searchInput)
	})
</script>

<div id="media-library-container">
	<div id="library-wrapper">
		<div
			id="media-library"
			bind:clientWidth={libraryWidth}
			style="--tile-width: {tileWidth}px;"
		>
			<MediaLibraryHeading bind:searchInput />
			<MediaList {media} />
		</div>
	</div>
</div>

<style>
	#media-library-container {
		height: calc(100vh - var(--header-height));
		display: flex;
		flex-direction: column;
	}

	#media-library :global(.empty-indicator) {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--color-text);
	}

	#media-library :global(.empty-text) {
		/* width: 60%; */
		text-align: center;
	}

	#media-library :global(.empty-indicator svg) {
		width: 8rem;
		height: 8rem;
	}

	#media-library :global(.empty-indicator .svg-fill) {
		fill: var(--color-text);
	}

	#media-library :global(.create-quick-action) {
		background-color: unset;
		box-shadow: unset;
		padding: unset;
		color: var(--color-main);
		transform: unset;
	}

	#media-library :global(.create-quick-action:hover),
	#media-library :global(.create-quick-action:focus-visible) {
		color: var(--color-text-light);
	}

	#media-library {
		overflow-y: scroll;
		padding-right: 0.25rem;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		height: 100%;
		flex-grow: 1;
	}

	#library-wrapper {
		display: flex;
		flex-direction: row;
		overflow: hidden;
		height: 100%;
	}

	#media-library :global(#library-list) {
		flex-grow: 1;
	}

	#media-library :global(.library-list) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	#media-library :global(.library-list .tile-container) {
		margin: 1rem;
	}
</style>
