<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect x="2" y="2" width="20" height="20" rx="2" fill={strokeColor} class="svg-fill" />
</svg>

<style>
</style>
