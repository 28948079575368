<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerSnapshot,
		type Snapshot,
	} from "luxedo-data"
	import { TrashIcon } from "svelte-comps/icons"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import DownloadIcon from "../../icons/DownloadIcon.svelte"
	import { Reactivity } from "svelte-comps/reactivity"

	interface Props {
		snapshot: Snapshot
	}

	let { snapshot }: Props = $props()

	$effect.pre(() => {
		Reactivity.useProps(snapshot, ["is_default"])
	})

	let isDefault = $derived(Reactivity.propOf(snapshot, "is_default"))
	let isSettingsAsDefault = $state(false)

	function verifyDeleteSnapshot() {
		openConfirmOverlay({
			prompt: [
				"Are you sure you want to delete this snapshot?",
				"This cannot be undone.",
			],
			buttons: {
				confirm: {
					text: "Delete",
					onClick: deleteSnapshot,
				},
			},
		})
	}

	async function deleteSnapshot() {
		try {
			await DataHandlerSnapshot.deleteEntry(snapshot)
			Toast.success("Snapshot successfully deleted.")
		} catch (e) {
			console.error("ERROR deleting snapshot", e)
			Toast.error("Unable to delete snapshot. Please try again...")
		}
	}

	function downloadSnapshot() {
		snapshot.triggerDownload()
	}

	async function setAsDefault() {
		const device = DataHandlerDevice.get(snapshot.dev_id)
		if (!device)
			return console.error(
				"Device associated with this snapshot cannot be found."
			)

		isSettingsAsDefault = true
		try {
			await device.setDefaultSnapshot(snapshot.id)
			Toast.success("Default snapshot updated!")
		} catch (e) {
			Toast.error("Unable to update default snapshot")
		}
		isSettingsAsDefault = false
	}
</script>

<div class="button-container">
	<button
		id="download-snapshot-button"
		class="icon"
		title="Download Snapshot"
		onclick={downloadSnapshot}
	>
		<DownloadIcon />
	</button>
	{#if snapshot.id > -1}
		<button
			id="delete-snapshot-button"
			class="icon"
			title="Delete Snapshot"
			onclick={verifyDeleteSnapshot}
		>
			<TrashIcon />
		</button>
	{/if}
</div>

<img src={snapshot.src} alt="" />
{#if !isDefault}
	<button
		id="set-default-button"
		onclick={setAsDefault}
		disabled={isSettingsAsDefault}>Set as Default</button
	>
{/if}

<style>
	img {
		margin-top: 0.5rem;
		max-height: calc(80vh - 2rem);
		max-width: 80vw;
	}

	.button-container {
		width: fit-content;
		position: absolute;
		margin: 0;
		top: 1.2rem;
		right: 3.25rem;
		padding: 0.25rem;
		z-index: 150;
	}

	.button-container button {
		height: 2rem;
	}

	.button-container button :global(svg) {
		height: 2rem;
	}

	#download-snapshot-button :global(svg) {
		height: 1.75rem;
	}

	#delete-snapshot-button :global(.svg-stroke) {
		stroke: var(--color-error);
	}

	#delete-snapshot-button:hover :global(.svg-stroke),
	#delete-snapshot-button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#set-default-button {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
</style>
