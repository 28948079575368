<script lang="ts">
	import { run, stopPropagation } from 'svelte/legacy';

	import { DataHandlerTag, DataHandlerUser, Tag, User } from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import { UserStore } from "../../../stores/UserStore"

	interface Props {
		hasNoTags?: boolean; // if the user does not have any tags, make the tag creation button more explicit
	}

	let { hasNoTags = false }: Props = $props();

	let isCreating = $state(false)
	let tagElem: HTMLDivElement = $state()
	let title: string = ""

	let user: User
	UserStore.subscribe((u) => (user = u))

	function onKeydown(
		e: KeyboardEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.key === "Escape") {
			isCreating = false
		} else if (e.key === "Enter") {
			isCreating = false
			createTag()
		} else {
			const target = e.currentTarget
			setTimeout(() => {
				title = target.textContent
			})
		}
	}

	function onClick() {
		if (!isCreating) {
			isCreating = true

			setTimeout(() => {
				const range = document.createRange()
				range.selectNodeContents(tagElem)
				const windowSelection = window.getSelection()
				windowSelection.removeAllRanges()
				windowSelection.addRange(range)
			})
		}
	}

	function bgClick() {
		if (!title) return (isCreating = false)

		createTag()

		isCreating = false
	}

	async function createTag() {
		if (!title) return Toast.error("You must name your tag.")
		const color = Tag.colors[user.tag_color_index % Tag.colors.length]
		await DataHandlerTag.createEntry({ title, color })
		user.tag_color_index++
		await DataHandlerUser.save(user)

		title = ""
	}

	function initiateClickListener() {
		window.addEventListener("click", bgClick)
	}

	function removeClickListener() {
		window.removeEventListener("click", bgClick)
	}

	run(() => {
		isCreating && initiateClickListener()
	});
	run(() => {
		!isCreating && removeClickListener()
	});
</script>

<div
	class="tag"
	onclick={stopPropagation(onClick)}
	onkeydown={stopPropagation(onKeydown)}
	bind:this={tagElem}
	contenteditable={isCreating}
>
	{#if isCreating}
		New Tag
	{:else}
		{#if hasNoTags}Create Tag
		{/if}
		+
	{/if}
</div>

<style>
	.tag {
		background-color: #8b8b8b;
		z-index: 99;
		flex-shrink: 0;
		transition: width 250ms;
	}
</style>
