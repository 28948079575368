<script lang="ts">
	import { DEVICE_SETUP_STEPS, NextStepsController } from "./NextStepsController"

	let step: string = $state()

	NextStepsController.subscribe(({ currentStep }) => (step = currentStep))
</script>

<div id="next-steps">
	{#if step}
		{@const SvelteComponent = DEVICE_SETUP_STEPS[step]}
		<SvelteComponent />
	{/if}
</div>

<style>
	#next-steps {
		height: 100%;
		background-color: var(--color-bg-dark);
		padding: 1rem;
		border-radius: var(--br);
		box-shadow: var(--shadow-medium);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-x: hidden;
		overflow-y: auto;
		text-align: left;
	}

	#next-steps :global(h1) {
		font-size: var(--h1);
		margin: 0 0 1rem 0;
		text-align: center;
		color: var(--color-main);
	}

	#next-steps :global(p) {
		color: var(--color-text-light);
		line-height: 1.25em;
		margin-bottom: 0.5rem;
	}

	#next-steps :global(a:not(.name)) {
		color: var(--color-main);
		border-color: var(--color-main);
		transition:
			color 250ms,
			border-color 250ms;
	}

	#next-steps :global(a:not(.name):hover),
	#next-steps :global(a:not(.name):focus-visible) {
		color: var(--color-alt);
		border-color: var(--color-alt);
	}

	#next-steps :global(.help) {
		margin-top: auto;
		margin-bottom: 0;
	}

	#next-steps :global(.warn) {
		color: var(--color-main);
	}

	#next-steps :global(.info) {
		background-color: var(--color-bg);
		border-radius: var(--br);
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: var(--h3);
		/* line-height: 1.5em; */
	}

	#next-steps :global(.button-container) {
		margin-bottom: 0;
	}

	#next-steps
		:global(
			button:not(.ctx-menu-button):not(#edit-lightshow-button):not(.now-playing):not(.mask-button)
		) {
		margin: 0;
		width: 100%;

		/* dev device back to production */
	}

	#next-steps :global(.now-playing-instance .button-container button) {
		margin-bottom: 0;
		width: unset;
	}

	#next-steps :global(.button-container button) {
		margin-bottom: 0.5rem;
	}
</style>
