import type { Entry } from "data-handler"

class ReactiveData {
	public properties = $state({})

	private entryListenerIDs: { [hash: string]: string } // id of update listener for each loaded entry, keyed by the entry hash
	private cachedPropertyList: { [hash: string]: Array<string> } // list of properties being listened to, keyed by the entry hash

	constructor() {
		this.entryListenerIDs = {}
		this.cachedPropertyList = {}
	}

	propOf<T extends Entry<any>, K extends keyof T>(entry: T, prop: K): T[K] {
		if (!entry) {
			console.error("Skipping propOf due to no passed entry")
			return null
		}
		const propHash = ReactiveData.createHashKey(entry, prop)

		if (!(propHash in this.properties))
			console.error(
				"Prop has not been initialized - all props must be explicity used (see useProp)."
			)

		return this.properties[propHash]
	}

	propOfMany<T extends Entry<any>, K extends keyof T>(
		entries: Array<T>,
		prop: K
	): { [index: number]: T[K] } {
		if (!entries) {
			console.error("Skipping propOfMany due to no passed entry")
			return null
		}
		const reactiveValues = {}

		for (const entry of entries) {
			reactiveValues[entry.id] = this.propOf(entry, prop)
		}

		return reactiveValues
	}

	/**
	 * Call within an $effect.pre block to "mark" props of a specific entry as Reactive.
	 * This will add them to this.properties, allowing them to be used via propOf or propOfMany.
	 * @param entry The entry to use the data of
	 * @param props the props to use from said entry
	 */
	useProps<T extends Entry<any>, K extends keyof T>(
		entry: T,
		props: Array<K>
	) {
		if (!entry) {
			console.error("Skipping useProps due to no passed entry")
			return null
		}
		const entryHash = ReactiveData.createHashKey(entry)
		if (!(entryHash in this.cachedPropertyList))
			this.cachedPropertyList[entryHash] = []
		if (!(entryHash in this.entryListenerIDs)) {
			this.entryListenerIDs[entryHash] = entry.addUpdateListener(
				this.onEntryUpdate
			)
		}

		for (const prop of props) {
			const propHash = ReactiveData.createHashKey(entry, prop)
			this.properties[propHash] = entry[prop]
			this.cachedPropertyList[entryHash].push(prop as string)
		}
	}

	/**
	 * Called by each entry's onupdate
	 * @param entry
	 */
	onEntryUpdate = <T extends Entry<any>>(entry: T) => {
		if (!entry) {
			console.error("Skipping onEntryUpdate due to no passed entry")
			return null
		}

		const entryHash = ReactiveData.createHashKey(entry)
		for (const prop of this.cachedPropertyList[entryHash]) {
			const propHash = ReactiveData.createHashKey(
				entry,
				prop as keyof typeof entry
			)
			this.properties[propHash] = entry[prop]
		}
	}

	static createHashKey<T extends Entry<any>, K extends keyof T>(
		entry: T,
		prop?: K
	) {
		if (!entry)
			throw new Error("Cannot create hash key for non existent entry.")

		let hashKey = `${entry.constructor.name}-${entry.id}`
		if (prop) hashKey = `${hashKey}-${prop as string}`

		return hashKey
	}
}

export const Reactivity = new ReactiveData()
