<script lang="ts">
	import type { Device } from "luxedo-data"
	import { ProjectorMenuController } from "../../../ProjectorMenuController.svelte"
	import { Reactivity } from "svelte-comps/reactivity"
	import SignalStrengthIndicator from "./SignalStrengthIndicator.svelte"
	import NetworkTestInitiator from "../network-test/NetworkTestInitiator.svelte"

	type Props = {
		device: Device
	}

	let { device }: Props = $props()

	$effect.pre(() => {
		Reactivity.useProps(device, ["eidos"])
	})

	let eidos = $derived(Reactivity.propOf(device, "eidos"))

	let isEthernet = $derived.by(() => {
		if (eidos?.network?.ssid === null) return true
		return false
	})

	let networkName = $derived.by(() => {
		if (eidos?.network?.ssid) return eidos.network.ssid
		else return undefined
	})

	let signalStrength = $derived.by(() => {
		if (isEthernet) return 100
		return eidos?.network?.signal_strength
	})
</script>

<div class="flex-row net-info-row">
	{#if isEthernet || networkName}
		<div class="flex-column">
			<span class="label">Connected to: </span>
			<span id="active-network">
				{isEthernet ? "Ethernet" : networkName}
			</span>
		</div>
		<div class="flex-row signal-indicator">
			<SignalStrengthIndicator strength={signalStrength / 100} />
			<div class="flex-column">
				<span class="label">Signal strength:</span>
				<span class="signal-percent">
					{signalStrength ?? 0}%
				</span>
			</div>
		</div>
	{:else}
		<div class="flex-column">
			<span id="active-network" class="disconnected"> Disconnected </span>
		</div>
		<div class="flex-row signal-indicator">
			<SignalStrengthIndicator strength={signalStrength / 100} />
			<div class="flex-column">
				<span class="label">Signal strength:</span>
				<span class="signal-percent">
					{signalStrength ?? 0}%
				</span>
			</div>
		</div>
	{/if}

	{#if device.features.includes("NETWORK_TEST")}
		<div class="test-button">
			<NetworkTestInitiator />
		</div>
	{/if}
</div>

<style>
	.flex-row {
		align-items: center;
	}

	.label {
		margin: 0 0.75rem 0 0;
		line-height: 1.25em;
		font-size: var(--h3);
		color: var(--color-text);
	}

	.signal-percent {
		font-family: var(--font-heading);
		color: var(--color-text-light);
		margin-left: 1rem;
		font-size: var(--text-h3);
		line-height: 1em;
	}

	.signal-indicator {
		margin-left: 1rem;
	}

	.signal-indicator .label {
		margin-left: 1rem;
	}

	#active-network {
		font-size: var(--text-h3);
		line-height: 1em;
	}

	.test-button {
		margin-left: auto;
	}
</style>
