<script lang="ts">
	import { ProjectorPreferencesManager } from "../../../ProjectorPreferenceManager.svelte"

	let cameraExposure = $derived(
		ProjectorPreferencesManager.pendingChanges.cameraExposure ??
			ProjectorPreferencesManager.preferences.cameraExposure
	)

	function onExposureChange(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		const target = e.currentTarget

		setTimeout(() => {
			const value = target.value
			ProjectorPreferencesManager.stageChange("cameraExposure", Number(value))
		})
	}
</script>

<div class="select-container">
	<label id="custom-exposure-label" for="camera-exposure">Camera Exposure: </label>
	<select id="camera-exposure" value={cameraExposure} onchange={onExposureChange}>
		<option value={-1}>Automatic</option>
		<option value={32}>Very Low</option>
		<option value={64}>Low</option>
		<option value={128}>Mid-Low</option>
		<option value={256}>Standard</option>
		<option value={512}>Mid-High</option>
		<option value={1024}>High</option>
		<option value={2048}>Very High</option>
	</select>
</div>

<style>
</style>
