<script lang="ts">
	import {
		GROUP_CONNECTION_TROUBLESHOOT_MAP,
		type GroupConnectionIssue,
	} from "./GroupConnectionTroubleshoot"
	import { CollapsibleSectionNoBind } from "svelte-comps/comps"

	type Props = {
		status: boolean
		groupConnectionIssue: GroupConnectionIssue
	}

	let { groupConnectionIssue, status }: Props = $props()
	let isOpen: boolean = $state()
</script>

<CollapsibleSectionNoBind expanded={isOpen}>
	{#snippet header()}
		<div class="status-instance">
			<span class="{status ? 'good' : 'bad'} status-indicator">
				{status ? "✔" : "✗"}
			</span>
			<span class="status-issue">{groupConnectionIssue}</span>
			{#if !status}
				{#if isOpen}
					<button
						class="link-button close-button"
						onclick={() => (isOpen = false)}>Close</button
					>
				{:else}
					<button class="link-button" onclick={() => (isOpen = true)}
						>Help</button
					>
				{/if}
			{/if}
		</div>
	{/snippet}
	{#snippet content()}
		<p class="status-fix">
			{@html GROUP_CONNECTION_TROUBLESHOOT_MAP[groupConnectionIssue]}
		</p>
	{/snippet}
</CollapsibleSectionNoBind>

<style>
	.good {
		color: var(--color-green);
	}

	.bad {
		color: var(--ct-red);
	}

	.status-indicator {
		width: 1rem;
		font-size: var(--h1);
		line-height: 1rem;
		vertical-align: middle; /* Aligns with the status text */
	}

	.status-issue {
		text-wrap: nowrap;
		/* margin-right: 1rem; */
	}

	.status-fix {
		line-height: 1.25rem;
		padding-bottom: 1rem;
		margin-left: 1.45rem;
		color: var(--color-text);
	}

	.status-instance .link-button {
		padding: 0;
		margin-left: 0.5rem;
		color: var(--color-main);
	}
</style>
