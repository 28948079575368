<script lang="ts">
	import { preventDefault } from 'svelte/legacy';

	import { navigate } from "svelte-routing"
	import PasswordInput from "../../reusable/input/PasswordInput.svelte"
	import { LoginControler } from "./LoginController"

	let { email = $bindable(), password = $bindable() } = $props();
</script>

<div class="login-inputs">
	<div class="input-container">
		<label for="email-input">Email</label>
		<input id="email-input" type="email" bind:value={email} />
	</div>

	<div class="input-container">
		<div class="password-label-container">
			<label for="pass-input">Password</label>
			<a id="forgot-password-link" onclick={preventDefault(() => LoginControler.goToReset())}
				>Forgot your password?</a
			>
		</div>
		<PasswordInput id="pass-input" bind:value={password} />
	</div>
</div>

<style>
	.password-label-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	#forgot-password-link {
		border: none;
		cursor: pointer;
		font-size: var(--h3);
	}

	#forgot-password-link:hover,
	#forgot-password-link:focus-visible {
		color: var(--color-main);
	}
</style>
