<script lang="ts">
	import { LoadingSpinner, LoadingText } from "svelte-comps/loading"
	import NetworkTestLoadingAnimation from "./NetworkTest_LoadingAnimation.svelte"
</script>

<div
	class="anim-container flex-row
"
>
	<NetworkTestLoadingAnimation />
	<LoadingText
		content={[
			"Tuning the virtual hamster wheel",
			"Double-checking the speed of light just in case",
			"Wrapping data packets with extra care",
			"Chasing electrons around the interwebs",
			"Feeding the bandwidth trolls",
			"Reminding the modem it's loved",
			"Teaching the router new tricks",
			"Googling how to google faster",
			"Petting the fiber cables for good luck",
			"Thinking about loading bar jokes",
			"Decrypting the secrets of Wi-Fi",
			"Consulting the ping oracle",
			"Refilling our data hamper",
			"Detangling invisible wires",
			"Spinning up the warp drive",
			"Applying quantum flux to your bits",
			"Shaking the dust off old packets",
			"Distracting the latency monster",
			"Doing a digital rain dance",
			"Reminding your ISP to be nice",
			"Negotiating with the signal gnomes",
			"Tickling the router for extra speed",
			"Testing your patience (and your ping)",
			"Praying to the bandwidth gods",
		]}
	/>
</div>

<div class="loading-state">
	<LoadingSpinner color="var(--color-main)" />
</div>

<style>
	.anim-container {
		font-size: var(--h1);
		margin-top: auto;
		margin-bottom: auto;
		color: var(--color-text);
		align-items: center;
		max-width: var(--network-test-width);
	}

	.loading-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		gap: 0.5rem;
		bottom: 1rem;
		right: 1rem;
	}
</style>
