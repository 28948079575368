<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 111.5">
	<g id="Layer_50" data-name="Layer 50">
		<g>
			<path
				stroke={strokeColor}
				class="cls-1 svg-stroke"
				d="m237,12c0,34.52-50.37,62.5-112.5,62.5S12,46.52,12,12"
			/>
			<line
				stroke={strokeColor}
				class="cls-1 svg-stroke"
				x1="124.5"
				y1="74.5"
				x2="124.5"
				y2="99.5"
			/>
			<line
				stroke={strokeColor}
				class="cls-1 svg-stroke"
				x1="180.75"
				y1="66.04"
				x2="187.22"
				y2="90.18"
			/>
			<line
				stroke={strokeColor}
				class="cls-1 svg-stroke"
				x1="68.25"
				y1="66.04"
				x2="61.78"
				y2="90.18"
			/>
		</g>
	</g>
</svg>

<style>
	.cls-1 {
		fill: none;
		stroke: #817e84;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 24px;
	}
</style>
