<script lang="ts">
	import type { Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openNewSceneOverlay } from "../../../reusable/overlays"
	import Guides from "luxedo-data/src/data/Guides"

	let device: Device = $state()
	SelectedDeviceStore.subscribe((dev) => (device = dev))
</script>

<div class="next-steps-heading">
	<h1>Creating Shows</h1>
</div>
<p>Now that your device has been calibrated, its time to create your first show!</p>

<div class="info">
	Before jumping in, there are two different types of shows you can create:
	<ul>
		<li>
			This first type is a <b class="warn">Scene</b> - these are rendered videos created using your calibrated
			snapshot as a guide, allowing you to map videos and images onto your projection space.
		</li>
		<li>
			The second is a <b class="warn">Lightshow</b> - these are rendered playlists of your
			<b class="warn">scenes</b>, which enables you to create a seamless masterpiece from your
			individual scenes. If you plan to design a complex show, using a lightshow is recommended.
		</li>
	</ul>
	<span>Both will be found within the My Shows page and can be scheduled to play any point.</span>
</div>

{#if device}
	<button
		class="outline-button"
		id="create-show-button"
		onclick={() => openNewSceneOverlay({ device })}>Create First Scene</button
	>
{/if}

<p class="help">
	Having trouble? Follow
	<a target="_blank" href={Guides["Luxedo Portal"]["Creating a New Scene"]}>this guide</a> for a
	walkthrough. Or check out our
	<a target="_blank" href="https://www.luxedo.com/pages/help">help center</a> for assistance.
</p>

<!-- <p class="help">
	Having trouble?
</p> -->

<style>
	ul {
		list-style-type: circle;
		margin-left: 1.5rem;
	}
</style>
