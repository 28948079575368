<script lang="ts">
	import { DeviceGroup, DeviceRPi } from "luxedo-data"
	import ProjectorSettingsBlock from "../../DeviceMenuBlock.svelte"
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import { Reactivity } from "svelte-comps/reactivity"
	import { getCurrentUser } from "../../../../../stores/UserStore"
	import ActionGrid from "./actions/ActionGrid.svelte"
	import ActionSpotlight from "./actions/ActionSpotlight.svelte"
	import ActionStopPlayback from "./actions/ActionStopPlayback.svelte"
	import ActionRestartGroup from "./actions/ActionRestartGroup.svelte"
	import ActionRestartPi from "./actions/ActionRestartPi.svelte"
	import ActionRollback from "./actions/ActionRollback.svelte"
	import ActionProjectorPower from "./actions/ActionProjectorPower.svelte"

	let device = $derived(ProjectorMenuController.ctx.device)

	let isDisabled = $state(false)

	$effect.pre(() => {
		Reactivity.useProps(device, ["isOnline", "hasConnectedProjector"])
		isDisabled = false
	})

	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))
	let hasConnectedProjector = $derived(
		Reactivity.propOf(device, "hasConnectedProjector")
	)

	let timeout
	function setButtonsDisabled(doDisable: boolean = true) {
		isDisabled = doDisable

		if (!doDisable && timeout) {
			clearTimeout(timeout)
		}

		if (doDisable) {
			timeout = setTimeout(() => {
				isDisabled = false
			}, 60 * 1000) // wait 60 before automatically enabling the buttons again, in case of an error
		}
	}
</script>

<div class="projector-menu">
	<ProjectorSettingsBlock heading="Actions">
		{#if isOnline}
			<div class="grid-columns">
				{#if device instanceof DeviceGroup}
					<ActionGrid {device} {isDisabled} {setButtonsDisabled} />
				{:else}
					<ActionSpotlight {device} {isDisabled} {setButtonsDisabled} />
				{/if}
				{#if hasConnectedProjector}
					<ActionProjectorPower
						device={device as DeviceRPi}
						{isDisabled}
						{setButtonsDisabled}
					/>
				{/if}
				<ActionStopPlayback {device} {isDisabled} {setButtonsDisabled} />
				{#if device instanceof DeviceRPi && getCurrentUser().hasPriv("beta_opt_in") && device.compareVersion(device.availableUpdate) > 0}
					<ActionRollback
						device={device as DeviceRPi}
						{isDisabled}
						{setButtonsDisabled}
					/>
				{/if}
				{#if device instanceof DeviceGroup}
					<ActionRestartGroup {device} {isDisabled} {setButtonsDisabled} />
				{:else if device instanceof DeviceRPi}
					<ActionRestartPi {device} {isDisabled} {setButtonsDisabled} />
				{/if}
			</div>
		{:else}
			<div class="warn-text">
				Your device is currently offline. To access all available actions,
				please turn it on and connect to the internet.
			</div>
		{/if}
	</ProjectorSettingsBlock>
</div>

<style>
	.grid-columns {
		gap: 1rem;
		margin-top: 1rem;
	}

	.warn-text {
		margin-top: 0.5rem;
	}

	:global(.action-container) {
		display: flex;
		flex-direction: column;
	}

	:global(.action-info) {
		padding: 0.5rem;
		padding-left: 0;
		color: var(--color-text);
		line-height: 1.25rem;
	}

	:global(.action-container:nth-of-type(1) button) {
		--border-color: var(--color-alt-1);
	}

	:global(.action-container:nth-of-type(2) button) {
		--border-color: var(--color-alt-2);
	}

	:global(.action-container:nth-of-type(3) button) {
		--border-color: var(--color-alt-5);
	}
	:global(.action-container:nth-of-type(4) button) {
		--border-color: var(--color-alt-6);
	}
	:global(.action-container:nth-of-type(5) button) {
		--border-color: var(--color-alt-7);
	}
	:global(.action-container:nth-of-type(6) button) {
		--border-color: var(--color-alt-3);
	}
	:global(.action-container:nth-of-type(8) button) {
		--border-color: var(--color-alt-8);
	}
	:global(.action-container:nth-of-type(9) button) {
		--border-color: var(--color-alt-9);
	}
</style>
