<script lang="ts">
	import { ProjectorMenuController } from "../../ProjectorMenuController.svelte"
	import AudioOutputInput from "../settings/inputs/AudioOutputInput.svelte"
	import LuxedoRadioSettings from "./LuxedoRadioSettings.svelte"
	import ProjectorSettingsBlock from "../../DeviceMenuBlock.svelte"
	import { Reactivity } from "svelte-comps/reactivity"

	let device = $derived(ProjectorMenuController.ctx.device)

	$effect.pre(() => {
		Reactivity.useProps(device, ["hasConnectedProjector", "isOnline"])
	})

	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))

	let hasConnectedProjector = $derived(
		Reactivity.propOf(device, "hasConnectedProjector")
	)
</script>

<div class="projector-menu">
	{#if hasConnectedProjector}
		{#if isOnline}
			<ProjectorSettingsBlock heading={"Audio Settings"}>
				<AudioOutputInput />
			</ProjectorSettingsBlock>
		{:else}
			<ProjectorSettingsBlock heading="Audio Settings">
				<div class="warn-text">
					Your device must be connected to the internet in order to access or
					modify its settings. Please power it on and connect it to your network
					and try again.
				</div>
			</ProjectorSettingsBlock>
		{/if}
	{:else}
		<br />
	{/if}

	<LuxedoRadioSettings />
</div>

<style>
	.projector-menu {
		justify-content: space-between;
	}

	.warn-text {
		margin-top: 0.5rem;
	}
</style>
