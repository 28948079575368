import { diff } from "deep-object-diff"
import {
	ConstructorSerializable,
	deepCopy,
	Serializable,
	SERIALIZE_METHOD_NAME,
} from "."
import {
	CanvasAsset,
	ImageAsset,
	Polygon,
	Text,
	VideoAsset,
	Ellipse,
} from "../../asset"
import { Track, TrackGroup } from "../../tracks"
import { FadeIn, FadeOut } from "../../lib"
import { log } from "numeric"
import { getEditor } from "../.."

export function isSerializable(target: any): target is Serializable {
	if (target === undefined) return false
	return SERIALIZE_METHOD_NAME in target
}

export function testSerialization(serializable: Object) {
	if (!isSerializable(serializable))
		throw TypeError(
			`Object ${serializable.constructor.name} is not serializable`
		)

	const serialized = serializable.serialize()

	// @ts-ignore
	const deserialized = serializable.constructor.loadJSON(
		getEditor(),
		serialized
	)

	const diffSerial = diff(serialized, deserialized)

	console.warn(diffSerial)
}

/** Generalized serialization method.
 * Using the provided options (see below), this method will package the class instance passed as the first argument (see Track for example).
 * REQUIRES the class instance to have 'toObject()' and 'getDefaultPropertyExports()' methods.
 *
 * @param objectToSerialize The object being serialized
 * @param options A dict with properties to include/exclude in the serialization
 * @param options.propertiesToInclude A list of property names to include (these will not be deep copied).
 * @param options.propertiesToExclude A list of properties to remove from the serialized result (usually circular references).
 * @param options.propertiesToDeepCopy A list of the properties to deep copy in order to ensure only the values are saved (not as references or objects).
 * @param options.propertyGetters An dict with keys referencing function names within the object to serialize and values referencing the properties which will need to be accessed using the key function (e.g., getBaseValue for animated assets)
 * @returns A serialized object which can be converted into JSON in order to save on the backend.
 */
export function convertInstanceToObject<T extends Serializable>(
	objectToSerialize: T,
	options?: {
		forExport?: boolean
		propertiesToInclude?: string[]
		propertiesToDeepCopy?: string[]
		propertiesToExclude?: string[]
		propertyGetters?: {
			[index: string]: string[]
		}
	}
): Partial<T> {
	if (objectToSerialize === undefined)
		throw new Error(
			"Cannot call _serialize if not passed a class instance."
		)

	const propertiesToInclude = options.propertiesToInclude ?? []
	const propertiesToDeepCopy = options.propertiesToDeepCopy ?? []
	const propertiesToExclude = options.propertiesToExclude ?? []

	const allProps = objectToSerialize.getDefaultPropertyExports()

	let shallowProps = [...propertiesToInclude, ...allProps.include]
	let deepCopyProps = [...propertiesToDeepCopy, ...allProps.deepCopy]
	let excludeProps = [
		...DEFAULT_EXCLUDES,
		...propertiesToExclude,
		...allProps.exclude,
	]
	if (!options.forExport && !excludeProps.includes("id"))
		excludeProps.push("id")

	// Ensure excluded properties are actually excluded
	let shallowProperties = []
	let deepCopyProperties = []
	for (const prop of shallowProps) {
		if (!excludeProps.includes(prop)) shallowProperties.push(prop)
	}
	for (const prop of deepCopyProps) {
		if (!excludeProps.includes(prop)) deepCopyProperties.push(prop)
	}

	// Copy base properties
	const json = objectToSerialize.toObject(shallowProperties)

	// Iterate through deep copy properties (if there are any)
	if (deepCopyProperties) {
		const deepCopiedProps = deepCopy(objectToSerialize, {
			properties: deepCopyProperties,
			forExport: options.forExport,
		})
		Object.assign(json, deepCopiedProps)
	}

	// If the property changes and needs to be retrieved with a specific member function
	if (options?.propertyGetters) {
		for (const [fnName, properties] of Object.entries(
			options.propertyGetters
		)) {
			for (const prop of properties) {
				json[prop] = objectToSerialize[fnName](prop)
			}
		}
	}

	console.log(objectToSerialize)

	if (objectToSerialize.constructor.name)
		json["__CONSTRUCTOR__"] = objectToSerialize.constructor.name

	// fabric to object applies some properties implicitly, if those are included within the returned json, remove them
	for (const prop of excludeProps) {
		if (prop in json) delete json[prop]
	}

	return json
}

const DEFAULT_EXCLUDES = [
	"editor",
	"aCoords",
	"lineCoords",
	"oCoords",
	"ownMatrixCache",
	"cacheWidth",
	"cacheHeight",
]

export function getSerializedConstructor(
	data: any & {
		__CONSTRUCTOR__: string
	}
): ConstructorSerializable {
	return DESERIALIZER_CONSTRUCTORS[data.__CONSTRUCTOR__]
}

const DESERIALIZER_CONSTRUCTORS: {
	[name: string]: ConstructorSerializable
} = {}

export function registerSerializableConstructor(
	classDef: ConstructorSerializable
) {
	if (!classDef.loadJSON)
		throw TypeError(`loadJSON not defined for ${classDef.name}`)

	DESERIALIZER_CONSTRUCTORS[classDef.name] = classDef
}
