<script lang="ts">
	import { NetworkTestController } from "./NetworkTestController"

	function beginNetworkTest() {
		NetworkTestController.beginTest()
	}

	function cancelNetworkTest() {
		NetworkTestController.cancelTest()
	}
</script>

<div class="network-instructions">
	<div class="network-step">
		<span class="step_number">1</span>
		Ensure your device is powered on and connected to the internet.
	</div>
	<div class="network-step">
		<span class="step_number">2</span>
		A stable, uninterrupted connection is required to get accurate results.
	</div>
	<div class="network-step">
		<span class="step_number">3</span>
		Once you begin the test, please wait until it completes before retrying.
	</div>
</div>

<div class="button-container">
	<button on:click={cancelNetworkTest} class="link-button">Cancel</button>
	<button on:click={beginNetworkTest}> Begin Test </button>
</div>

<style>
	.network-instructions {
		padding: 2rem 0;
	}

	.network-step {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		color: var(--color-text);
	}

	.step_number {
		display: inline-flex;
		width: 2.5rem;
		height: 2.5rem;
		border: 1px solid var(--color-text);
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		margin-right: 1rem;
		color: var(--color-text-light);
	}
</style>
