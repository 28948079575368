<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_615_68)">
		<path
			d="M46.4474 29.7954C46.5791 30.3725 46.4484 30.9783 46.0905 31.4497L34.8641 46.2362C34.4892 46.7301 33.9064 47.0221 33.2864 47.0268L15.2014 47.1649C14.581 47.1696 13.9936 46.8862 13.6112 46.3976L2.17581 31.7867C1.81085 31.3204 1.67128 30.7164 1.79469 30.1372L5.7177 11.7276C5.84361 11.1367 6.22982 10.6344 6.76848 10.361L23.0299 2.10615C23.5894 1.82213 24.2498 1.81714 24.8135 2.09268L41.1898 10.0971C41.733 10.3626 42.127 10.8596 42.2614 11.4491L46.4474 29.7954Z"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
		/>
	</g>
	<defs>
		<clipPath id="clip0_615_68">
			<rect width="48" height="49" rx="8" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
