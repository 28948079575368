<script lang="ts">
	import type { DeviceGroupSlot } from "luxedo-data"
	import GroupCanvas from "../../canvas/GroupCanvas.svelte"
	import { GroupEditorController } from "../../GroupEditorController"

	let slots: Array<DeviceGroupSlot> = $state([])

	GroupEditorController.Slots.subscribe((ctx) => {
		slots = ctx.slots
	})
</script>

<GroupCanvas {slots} onSlotClick={(id) => GroupEditorController.Slots.selectSlot(id)} />

<style>
</style>
