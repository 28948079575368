<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="34" height="60" viewBox="0 0 34 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M4.5 5L29.5 30L4.5 55"
		stroke="black"
		stroke-width="8.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
		id="indicator-path"
		class="svg-stroke"
	/>
</svg>
