<script lang="ts">
	import type { Snippet } from "svelte"

	interface Props {
		expanded?: boolean
		title?: string
		className?: string
		header?: Snippet<[any]>
		content?: Snippet
		onclick?: (doExpand?: boolean) => void
	}

	let {
		expanded,
		title = undefined,
		className = "",
		header,
		content,
		onclick,
	}: Props = $props()

	let contentHeight: number = $state()
	let contentContainer: HTMLDivElement = $state()

	const resizeObserver = new ResizeObserver((entries) => {
		const entry = entries[0]
		updateContentHeight(entry.target as HTMLDivElement)
	})

	function updateContentHeight(div: HTMLDivElement) {
		contentHeight = div.offsetHeight
	}

	function initializeResizeObserver(div: HTMLDivElement) {
		if (!div) return

		resizeObserver.observe(contentContainer)
	}

	$effect(() => {
		if (contentContainer) initializeResizeObserver(contentContainer)
	})
</script>

<div
	class="collapsible-section {className} {expanded ? 'section-expanded' : ''}"
	{title}
>
	<div class="section-header" onclick={() => onclick?.(!expanded)}>
		{@render header?.({ isExpanded: expanded })}
	</div>
	<div
		class="collapsible-section-body"
		style="height: {expanded ? contentHeight : 0}px;"
	>
		<div bind:this={contentContainer} class="content">
			{@render content?.()}
		</div>
	</div>
</div>

<style>
	.section-header {
		position: relative;
		width: 100%;
		display: flex;
		align-items: start;
	}

	.collapsible-section {
		position: relative;
		width: 100%;
	}

	.collapsible-section-body {
		position: relative;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;
		height: 0px;
		transition: height 250ms;
	}
</style>
