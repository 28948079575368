<script lang="ts">
	import { DataHandlerDevice, type CalibratedMask, DataHandlerMask, Snapshot } from "luxedo-data"
	import { MaskEditorController } from "../MaskEditorController"
	import { MaskInstance } from "svelte-comps/luxedo"
	import SidebarHeading from "./SidebarHeading.svelte"
	import { MaskSidebarController } from "./MaskEditorSidebarController"

	let snapshot: Snapshot = $state()
	let oldMasks: Array<CalibratedMask> = $state()

	let selectedMasks: { [index: number]: CalibratedMask } = {}
	let canImport = $state(false)

	MaskEditorController.subscribe((ctx) => {
		if (!ctx || !ctx.snapshot) return
		snapshot = ctx.snapshot
		const device = DataHandlerDevice.get(ctx.snapshot.dev_id)
		oldMasks = DataHandlerMask.filterByDevice(device)
	})

	function selectMask(mask: CalibratedMask) {
		MaskEditorController.previewMask(mask)
		selectedMasks[mask.id] = mask
		canImport = true
	}

	function deselectMask(mask: CalibratedMask) {
		MaskEditorController.clearPreview(mask.id)
		if (selectedMasks[mask.id]) delete selectedMasks[mask.id]
		if (Object.values(selectedMasks).length) canImport = true
		else canImport = false
	}

	async function importMasks() {
		MaskEditorController.clearPreview()
		await MaskEditorController.importMasks(Object.values(selectedMasks))
		MaskSidebarController.goTo("list")
	}
</script>

<div class="mask-list-menu">
	<div class="mask-list-container">
		<SidebarHeading title="Import Masks" goBack={() => MaskSidebarController.goTo("empty")} />
		<p class="info">Select masks from below to be used for this calibration.</p>
		<hr />
		{#if oldMasks.length}
			<div class="mask-list">
				{#each oldMasks as mask}
					<MaskInstance
						mask={mask.mask_json}
						{snapshot}
						onSelectedUpdate={(sel) => (sel ? selectMask(mask) : deselectMask(mask))}
					/>
				{/each}
			</div>
		{/if}
	</div>
	<div class="button-container create-buttons">
		{#if canImport}
			<button class="create-button outline-button" onclick={importMasks}>Import</button>
		{/if}
	</div>
</div>

<style>
	h3 {
		font-size: var(--h0);
		margin: 0;
	}

	hr {
		margin-top: 0;
	}

	h3 {
		font-size: var(--h0);
		margin: 0;
	}

	hr {
		margin-top: 0;
	}

	.mask-list-menu {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}

	.mask-list-container {
		flex-grow: 1;
		overflow: hidden;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: column;
	}

	.mask-list {
		height: 100%;
		padding-right: 0.5rem;
		overflow-y: auto;
	}

	.mask-list :global(.mask-instance) {
		width: 100%;
	}

	.mask-list :global(.mask-instance:last-of-type) {
		margin-bottom: 0;
	}
</style>
