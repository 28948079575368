<script lang="ts">
	import { TextRowSelector } from "svelte-comps/inputs"
	import { ProjectorMenuController } from "./ProjectorMenuController.svelte"
	import { ProjectorPreferencesManager } from "./ProjectorPreferenceManager.svelte"
	import { Toast } from "svelte-comps/toaster"

	let selectedDevice = $derived(ProjectorMenuController.ctx.device)
	let selectableViews = $derived(
		ProjectorMenuController.getSelectableViewsFromDevice(selectedDevice).sort()
	)

	let selectedView = $derived(ProjectorMenuController.ctx.view)

	// if there are any changes to device preferences pending
	let changesPending = $derived(
		Object.values(ProjectorPreferencesManager.pendingChanges).length > 0
	)

	let areChangesApplying = $state(false)

	async function applyChanges() {
		areChangesApplying = true
		Toast.text("Saving settings...")
		try {
			await ProjectorPreferencesManager.applyChanges()
			Toast.success("Settings saved successfully!")
		} catch (e) {
			console.error("UNABLE TO SAVE DEVICE SETTINGS", e)
			Toast.error("Unable to save settings. Please refresh and try again...")
		}
		areChangesApplying = false
	}
</script>

<div class="menu-navigator">
	<TextRowSelector
		onChange={ProjectorMenuController.setView}
		selectedOption={selectedView}
		options={selectableViews}
		optionInfo={{
			Calibration: `Calibration is the process of shining and capturing patterns from your Luxedo in order to accurately map your projection space.`,
			Advanced: `These settings are intended for advanced users or specific troubleshooting scenarios. Most users won't need to tinker with them.`,
		}}
		fontSize="var(--h1)"
	/>
	{#if changesPending}
		<button
			id="save-device-settings-button"
			onclick={applyChanges}
			disabled={areChangesApplying}>Save</button
		>
	{/if}
</div>

<style>
	.menu-navigator {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}

	.menu-navigator :global(.text-row-selector button.link-button) {
		border: none;
	}

	.menu-navigator :global(.text-row-selector) {
		overflow-x: auto;
	}

	#save-device-settings-button {
		margin-left: 1rem;
		width: fit-content;
	}
</style>
