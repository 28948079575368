<script lang="ts">
	import { run } from "svelte/legacy"

	/**
	 * ToggleSwitchAwait.svelte
	 * @author Josiah Eakle 2024
	 * ---
	 * This component is to be used when toggling using an async method.
	 * THIS COMPONENT ASSUMES THE ISACTIVE LOGIC IS BEING HANDLED BY THE PARENT
	 */

	import { onMount } from "svelte"
	import { tooltip } from "../tooltip"

	interface Props {
		isActive: boolean
		disabled?: boolean
		info?: string
		onUpdate: (newVal: boolean) => Promise<void>
	}

	let { isActive, disabled = $bindable(false), info = undefined, onUpdate }: Props = $props()

	let handleClick = async () => {
		if (disabled) return

		disabled = true
		try {
			if (onUpdate) await onUpdate(!isActive)
		} catch (e) {
			console.error("Failed to toggle", e)
		}
		disabled = false
	}

	async function handleKeyPress(e: KeyboardEvent) {
		if (e.key === "Enter") {
			await handleClick()
		}
	}
</script>

<div
	role="checkbox"
	aria-checked={isActive}
	class="toggle-switch {disabled ? 'disabled' : ''}"
	tabindex="0"
	onclick={handleClick}
	onkeydown={handleKeyPress}
	use:tooltip={{ content: info, pointing: "top" }}
>
	<div class="toggle-switch-indicator-container {isActive ? 'active' : ''}">
		<div class="toggle-switch-indicator"></div>
	</div>
</div>

<style>
	.toggle-switch {
		cursor: pointer;
		height: 1rem;
		width: 2rem;
		border: 1px solid var(--color-text);
		border-radius: 100vw;
		position: relative;
		overflow: hidden;
		transition: 150ms opacity;
	}

	.toggle-switch.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.toggle-switch.disabled .toggle-switch-indicator {
		background-color: var(--color-text);
	}

	.toggle-switch-indicator {
		background-color: var(--color-text-light);
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 100vw;
		margin-top: auto;
		margin-bottom: auto;
	}

	.toggle-switch-indicator-container {
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc(-100% + 0.75rem);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		transition:
			left 250ms ease-in-out,
			background-color 250ms ease-in-out;
	}

	.toggle-switch-indicator-container.active {
		background-color: var(--color-main);
		left: 0;
	}
</style>
