import { get, writable } from "svelte/store"

export namespace GroupCanvasController {
	type ContextType = {
		zoomLevel: number
		showSnapshots: boolean
		lockAspectRatio: boolean
		isExpanded: boolean
		gridImages: { [index: number]: string } // number: slotID, string: image data
		panPosition: [number, number]
		groupSize: [number, number]
	}

	const CONTEXT_DEFAULT: ContextType = {
		zoomLevel: 1,
		showSnapshots: true,
		gridImages: undefined,
		lockAspectRatio: true,
		isExpanded: false,
		panPosition: [0, 0],
		groupSize: [1920, 1080],
	}

	const store = writable<ContextType>(CONTEXT_DEFAULT)

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function reset() {
		store.set(CONTEXT_DEFAULT)
	}

	let canvas: HTMLDivElement
	const DEFAULT_ZOOM_ADJUST = 0.01
	const MIN_ZOOM_LEVEL = 0.01
	const MAX_ZOOM_LEVEL = 3

	/**
	 * Sets the "canvas" element
	 * @param element the HTMLDiv element being used as a canvas
	 */
	export function setCanvasElement(element: HTMLDivElement) {
		if (!element) return
		canvas = element

		setTimeout(refreshZoom)
	}

	export function setSnapshotVisibility(showSnapshots: boolean) {
		store.update((ctx) => ({ ...ctx, showSnapshots }))
	}

	function setZoom(zoom: number) {
		const newZoomLevel = Math.max(
			MIN_ZOOM_LEVEL,
			Math.min(MAX_ZOOM_LEVEL, zoom)
		)
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function refreshZoom(newWidth?: number, newHeigth?: number) {
		let width, height

		if (newWidth && newHeigth) {
			width = newWidth
			height = newHeigth
		} else {
			const [groupWidth, groupHeight] = get(store).groupSize
			width = groupWidth
			height = groupHeight
		}

		if (!canvas)
			return console.warn(
				"Refresh Zoom called, but no canvas initialized",
				{ canvas }
			)

		const paddingWidth =
			Number(
				window
					.getComputedStyle(canvas, null)
					.getPropertyValue("padding-left")
					.replace("px", "")
			) * 2
		const paddingHeight =
			Number(
				window
					.getComputedStyle(canvas, null)
					.getPropertyValue("padding-top")
					.replace("px", "")
			) * 2
		const viewportWidth =
			canvas.getBoundingClientRect().width - paddingWidth
		const viewportHeight =
			canvas.getBoundingClientRect().height - paddingHeight

		const zoomX = viewportWidth / width
		const zoomY = viewportHeight / height

		const bestZoom = Math.floor(Math.min(zoomX, zoomY) * 100) / 100

		setZoom(bestZoom)
	}

	export function zoomIn() {
		const newZoomLevel = Math.min(
			MAX_ZOOM_LEVEL,
			get(store).zoomLevel + DEFAULT_ZOOM_ADJUST
		)
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function zoomOut() {
		const newZoomLevel = Math.max(
			MIN_ZOOM_LEVEL,
			get(store).zoomLevel - DEFAULT_ZOOM_ADJUST
		)
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function toggleCanvasExpand(doExpand?: boolean) {
		if (doExpand === undefined)
			store.update((ctx) => ({ ...ctx, isExpanded: !ctx.isExpanded }))
		else store.update((ctx) => ({ ...ctx, isExpanded: doExpand }))

		setTimeout(refreshZoom)
	}

	/**
	 * Gets the bounding rectangle of the "canvas" element
	 * @returns the bounding rectangle of the "canvas" element
	 */
	export function getContainerBounds() {
		return canvas.getBoundingClientRect()
	}

	/**
	 * Updates the canvas's reference to the group's size
	 * @param width the group's width
	 * @param height the group's height
	 */
	export function updateGroupSize(width: number, height: number) {
		store.update((ctx) => ({
			...ctx,
			groupSize: [width, height],
		}))

		refreshZoom(width, height)
	}
}
