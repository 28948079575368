<script lang="ts">
	import { stopPropagation } from "svelte/legacy"

	import { PaletteIcon } from "svelte-comps/icons"
	import { openTagColorPicker } from "./tag-color-picker/TagColorPickerContext"
	import { DataHandlerTag, type Tag } from "luxedo-data"
	import {
		initContextMenu,
		type ContextMenuOptions,
	} from "svelte-comps/context-menu"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		tag: Tag
		name: string
		color: string
		isSelected?: boolean
		isLibraryContext: boolean
		onClick?: any
	}

	let {
		tag = $bindable(),
		name,
		color,
		isSelected = $bindable(false),
		isLibraryContext,
		onClick = () => {
			isSelected = !isSelected
		},
	}: Props = $props()

	let isRenaming = $state(false)

	let elem: HTMLDivElement = $state()
	let labelElem: HTMLSpanElement = $state()

	async function deleteTag() {
		try {
			await DataHandlerTag.deleteEntry(tag)
			Toast.success("Tag deleted.")
		} catch (e) {
			console.error("ERROR deleting tag", e)
			Toast.error("Unable to delete tag. Please try again...")
		}
	}

	function renameTag() {
		isRenaming = true
		setTimeout(() => {
			labelElem.focus()
		})
	}

	function clearRename(
		e: Event & {
			currentTarget: EventTarget & HTMLSpanElement
		}
	) {
		isRenaming = false
		e.currentTarget.textContent = tag.title
	}

	function recolorTag() {
		openTagColorPicker(tag, elem, isLibraryContext)
	}

	function handleRenameKeyDown(
		e: KeyboardEvent & {
			currentTarget: HTMLSpanElement
		}
	) {
		if (e.key === "Escape") {
			e.stopPropagation()
			e.preventDefault()
			clearRename(e)
			return
		}

		if (e.key === "Enter") {
			e.stopPropagation()
			e.preventDefault()
			const currentTarget = e.currentTarget
			setTimeout(async () => {
				try {
					tag.title = currentTarget.textContent
					await DataHandlerTag.save(tag)
					Toast.success("Tag renamed.")
				} catch (e) {
					console.error("[ERROR] ", e)
					Toast.error("Unable to rename tag. Please try again...")
				}
				labelElem.blur()
			})
		}
	}

	const ctxMenuOpts: ContextMenuOptions = [
		{
			title: "Change Color",
			onClick: recolorTag,
		},
		{
			title: "Rename",
			onClick: renameTag,
		},
		{
			title: "Delete",
			onClick: deleteTag,
		},
	]
</script>

<div
	onclick={() => {
		if (isRenaming) return
		else onClick()
	}}
	oncontextmenu={initContextMenu(ctxMenuOpts)}
	bind:this={elem}
	title={name}
	class="tag {isSelected ? 'selected' : ''}"
	style="background-color: {color}{isSelected ? '' : '44'};"
>
	<div class="selection-indicator"></div>

	<span
		bind:this={labelElem}
		class="tag-name"
		contenteditable={isRenaming ? "true" : "false"}
		onblur={clearRename}
		onkeydown={handleRenameKeyDown}
	>
		{name}
	</span>
	{#if isSelected}
		<button onclick={stopPropagation(recolorTag)} class="small icon">
			<PaletteIcon />
		</button>
	{/if}
</div>

<style>
	.tag {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.tag.selected .selection-indicator {
		opacity: 1;
		position: relative;
	}

	.tag.selected .selection-indicator:after {
		content: "✔";
		color: var(--color-text-light);
		position: absolute;
	}

	.selection-indicator {
		border-radius: 0.2rem;
		width: 0.75rem;
		min-width: 0.75rem;
		height: 0.75rem;
		margin-right: 0.25rem;
		opacity: 0.25;
		background-color: var(--color-bg-dark);
		cursor: pointer;
	}

	button {
		height: unset;
		padding: 0;
		margin: 0;
		margin-left: 0.25rem;
	}

	button.icon.small > :global(svg) {
		height: unset;
	}

	button.icon.small :global(.svg-fill) {
		fill: black;
	}

	button.icon.small:hover :global(.svg-fill) {
		fill: var(--color-text-dark);
	}
</style>
