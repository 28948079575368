import { DeviceRPi, type Snapshot } from "luxedo-data"
import { get, writable } from "svelte/store"
import { GroupEditorController } from "../../GroupEditorController"
import { Toast } from "svelte-comps/toaster"
import { DeviceCalibrationManager } from "../../../../../../modules/device-operation-managers/DeviceCalibrationManager"

export namespace GroupCalibrationController {
	type ContextType = {
		step: number
		message: string
		description?: string
		progress: number
		snapshots: Array<Snapshot>
		calibratingDevice: DeviceRPi
		deviceIndex: number
		deviceAmount: number

		failImages?: Array<string>
		errorCode?: string
	}

	const STEP_MAP = {
		INFO: 0,
		CALIBRATING: 1,
		COMPLETE: 2,
		ERROR: -1,
	}

	const CONTEXT_DEFAULT: ContextType = {
		step: 0,
		progress: 0,
		deviceIndex: 0,
		deviceAmount: 0,
		snapshots: [],
		message: undefined,
		description: undefined,
		calibratingDevice: undefined,
		errorCode: undefined,
		failImages: undefined,
	}

	let selectedDevices: Array<DeviceRPi> = []
	GroupEditorController.subscribe((ctx) => (selectedDevices = ctx.devices))

	const store = writable<ContextType>(CONTEXT_DEFAULT)

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	function next() {
		store.update((ctx) => ({ ...ctx, step: ctx.step + 1 }))
	}

	export function reset() {
		store.set(CONTEXT_DEFAULT)
	}

	export async function calibrate() {
		if (selectedDevices.filter((device) => !device.isOnline).length)
			return Toast.error("All devices must be online to calibrate!")

		store.set({ ...CONTEXT_DEFAULT, step: 1 })

		await GroupEditorController.Grid.deactivate()

		let snapshots: Array<Snapshot> = []
		let index = 0

		store.update((ctx) => ({
			...ctx,
			deviceAmount: selectedDevices.length,
		}))
		GroupEditorController.setNavigationLocked(true)

		try {
			for (const device of selectedDevices) {
				let devSnapshot = await new Promise(
					(res: (snap: Snapshot) => void, rej: () => void) => {
						const deviceCalibrationStore =
							DeviceCalibrationManager.startCalibration(
								device,
								res,
								rej
							)
						deviceCalibrationStore.subscribe(
							(calibrationStatus) => {
								store.update((ctx) => ({
									...ctx,
									progress: calibrationStatus.progress,
									calibratingDevice: device,
									message: calibrationStatus.message,
									description: calibrationStatus.description,
								}))
							}
						)
					}
				)

				snapshots.push(devSnapshot)
				index++

				store.update((ctx) => ({
					...ctx,
					calibratingDevice: undefined,
					deviceIndex: index,
				}))
			}

			store.update((ctx) => ({
				...ctx,
				step: 2,
				snapshots,
			}))
		} catch (e) {
			const images = await DeviceCalibrationManager.getCalibrationImages(
				selectedDevices[index]
			)
			store.update((ctx) => ({
				...ctx,
				step: -1,
				failImages: images,
			}))
			console.error("Calibration failed or errored", e, get(store))
		}

		GroupEditorController.setNavigationLocked(false)
	}
}
