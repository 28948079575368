<script lang="ts">
	import { getEditor } from "luxedo-canvas"
	import { MaskSidebarController } from "./MaskEditorSidebarController"

	interface Props {
		selectedShape: string;
	}

	let { selectedShape }: Props = $props();

	function onSave() {
		getEditor().mode.get().complete()
		getEditor().canvas.requestRenderAll()
		MaskSidebarController.goTo("list")
	}

	function cancel() {
		getEditor().mode.get().cancel()
	}
</script>

<div>
	<h3 id="create-heading">Draw Mask</h3>
	<p class="info">
		{#if selectedShape === "freedraw"}
			Click on the canvas to begin creating points which will create a mask. To automatically close
			the mask, click the save button below.
		{:else}
			Click and drag on the canvas to create a {selectedShape} shaped mask.
		{/if}
	</p>
</div>

<div class="button-container">
	<button class="link-button" onclick={cancel}>Cancel</button>
	{#if selectedShape === "freedraw"}
		<button class="outline-button create-button" onclick={onSave}>Complete</button>
	{/if}
</div>

<style>
	h3 {
		margin: 0;
	}

	.info {
		color: var(--color-text);
		margin-top: 1rem;
	}

	p {
		text-wrap: wrap;
	}

	.button-container {
		justify-content: flex-end;
		margin-bottom: 0;
		padding-right: 0.5rem;
	}
</style>
