<script lang="ts">
	import { DragController, type DraggableContent } from "../../../stores/DragContext"

	interface Props {
		path: Array<{ id: number; name: string }>
		root: {
			id: number
			name: string
		}
		updateActiveDirectory: (folderId: number) => void
		handleDrop: (e: DragEvent, content: DraggableContent, moveToId: number) => void
	}

	let { path, root, updateActiveDirectory, handleDrop }: Props = $props()

	/**
	 * Called when an object is being dragged over a path element
	 */
	function initDropHover(
		e: DragEvent & {
			currentTarget: EventTarget & HTMLAnchorElement
		}
	) {
		e.preventDefault()
		e.currentTarget.classList.add("hover")
	}

	/**
	 * Called when drag is canceled or finished
	 * @param e
	 */
	function clearDropHover(
		e: DragEvent & {
			currentTarget: EventTarget & HTMLAnchorElement
		}
	) {
		e.currentTarget.classList.remove("hover")
	}
</script>

<span class="current-path">
	<a
		href="#/"
		ondragend={handleDrop ? DragController.onDragEnd(clearDropHover) : undefined}
		ondragleave={handleDrop ? DragController.onDragEnd(clearDropHover) : undefined}
		ondragover={handleDrop ? DragController.onDragOver(initDropHover) : undefined}
		ondrop={handleDrop
			? DragController.onDrop((e, content) => handleDrop(e, content, root.id))
			: undefined}
		onkeypress={(e) => {
			e.preventDefault()
			if (e.key === "Enter") updateActiveDirectory(root.id)
		}}
		onclick={(e) => {
			e.preventDefault()
			updateActiveDirectory(root.id)
		}}
		>{root.name}
	</a>
	{#each path as { id, name }}
		<span>/</span>
		<a
			href="#/"
			ondragend={handleDrop ? DragController.onDragEnd(clearDropHover) : undefined}
			ondragleave={handleDrop ? DragController.onDragEnd(clearDropHover) : undefined}
			ondragover={handleDrop ? DragController.onDragOver(initDropHover) : undefined}
			ondrop={handleDrop
				? DragController.onDrop((e, content) => handleDrop(e, content, id))
				: undefined}
			onkeypress={(e) => {
				e.preventDefault()
				if (e.key === "Enter") updateActiveDirectory(id)
			}}
			onclick={(e) => {
				e.preventDefault()
				updateActiveDirectory(id)
			}}>{name}</a
		>
	{/each}
</span>

<style>
	.current-path {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		/* height: 3rem; */
		line-height: unset;
		background-color: var(--color-bg);
		border-radius: var(--br);
		padding: 0 0.5rem 0.25rem 0.5rem;
		box-shadow: var(--shadow-medium);
	}

	.current-path > a {
		height: 1.5rem;
		white-space: nowrap;
		display: inline-block;
		cursor: pointer;
		border-color: transparent;
		transition: border-color var(--transition-duration);
		padding: 0 0.25rem;
	}

	.current-path > a:hover,
	.current-path > a:focus-visible {
		border-color: var(--color-accent);
	}

	.current-path > a:last-of-type {
		cursor: default;
		text-decoration: none;
		border-color: transparent;
		color: var(--color-text);
	}

	/* width */
	/* .current-path :global(::-webkit-scrollbar) {
		width: 0.25rem;
		height: 0.25rem;
	} */

	/* Track */
	/* .current-path :global(::-webkit-scrollbar-track) {
		background: var(--color-bg-dark);
		border-radius: var(--br);
	} */

	/* Handle */
	/* .current-path :global(::-webkit-scrollbar-thumb) {
		transition: background var(--transition-duration);
		background: var(--color-bg-dark);
		border-radius: var(--br);
	} */

	/* Handle on hover */
	/* .current-path :global(::-webkit-scrollbar-thumb:hover) {
		background: var(--color-accent);
	} */
</style>
