<script lang="ts">
	import { onMount } from "svelte"

	interface Props {
		content: string[]
	}

	const SPEED_IN_SEC = 1

	let { content }: Props = $props()
	let ellipsis: string = $state("")
	let index: number = $state(0)

	onMount(() => {
		let interval: number = setInterval(() => {
			ellipsis = `${ellipsis}.`
			if (ellipsis == "....") {
				ellipsis = ""
				index++
				if (index >= content.length) index = 0
			}
		}, 1000 * SPEED_IN_SEC)

		return () => {
			clearInterval(interval)
		}
	})
</script>

<span>{content[index]}{ellipsis}</span>

<style>
</style>
