type GuideList = {
	[index: string]: /* Category */ {
		[index: string]: /* Title */ string /* url */
	}
}

const EditorGuides = {
	"Adding Media to Your Scene":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fadding-media-to-your-scene-390661",
	"Adding Shapes to Your Scene":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fadding-shapes-to-your-scene-457136",
	"Adding Text to Your Scene":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fadding-text-to-your-scene-457999",
	"Adding Masks":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fadding-masks-to-your-scene-458919",
	"Editing Masks":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fediting-masks-in-the-scene-editor-458920",
	"Creating Animations":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fanimating-in-the-scene-editor-459078",
	"Editing Media, Text or Shapes":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fediting-videos-images-audio-text-or-shapes-in-the-scene-editor-459070",
	"Saving and Rendering":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Frendering-your-scene-390688",
}

const PortalGuides = {
	"Scheduling a Scene or Lightshow":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fscheduling-a-scene-or-lightshow-390690",
	"Uploading Media":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fuploading-media-390669",
	"Organizing Uploaded Media":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Forganizing-uploaded-media-390675",
	"Creating a New Scene":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fcreating-a-new-scene-390681",
	"Creating and Managing Lightshows":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fcreating-and-managing-lightshows-390685",
	"Registering a Luxedo Device":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fregistering-a-luxedo-device-390657",
	"Creating Masks after Calibrating":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fcreating-masks-for-your-snapshot-455978",
	"Luxedo Radio":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fluxedo-radio-390689",
}

const DeviceGuides = {
	"Calibrating Your Device":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fcalibrating-your-device-1062364",
	"Troubleshooting Calibration":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Ftroubleshooting-calibration-luxedo-projection-system-v3-390614",
	"Connecting to Wi-Fi":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fconnecting-your-luxedo-device-to-wi-fi-438714",
	"Troubleshooting Connection To Wi-Fi":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Ftroubleshooting-connection-to-wi-fi-390646",
	"Getting Started with v3":
		"https://www.luxedo.com/pages/help?hcUrl=%2Fen-US%2Fgetting-started-with-the-luxedo-projection-system-v3-390615",
}

export default {
	Device: DeviceGuides,
	"Luxedo Portal": PortalGuides,
	"Scene Editor": EditorGuides,
	Other: {
		"Group Network Requirements":
			"https://luxedo.notion.site/Luxedo-Network-Requirements-1af3e583ce72806ab229f9b74439f454?pvs=4",
	},
}
