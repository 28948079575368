<script lang="ts">
	import { run } from "svelte/legacy"

	import {
		DataHandlerDevice,
		DataHandlerSnapshot,
		DeviceGroup,
		type Device,
		type Snapshot,
	} from "luxedo-data"
	import SnapshotTile from "../../../file-system/tiles/SnapshotTile.svelte"
	import { CalibrationOverlayController } from "../../calibration/CalibrationOverlayController"
	import DownloadIcon from "../../../icons/DownloadIcon.svelte"
	import { LoadingSpinner } from "svelte-comps/loading"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		selectedDeviceId?: number
		selectedSnapshotId: number
		isDirectUpload?: boolean
		onSnapshotClick?: (snapshotId: number) => void
	}

	let {
		selectedDeviceId = undefined,
		selectedSnapshotId = $bindable(),
		isDirectUpload = false,
		onSnapshotClick = undefined,
	}: Props = $props()

	let device: Device = $state()
	let snapshots: Array<Snapshot> = $state([])

	let isProjectorGroup = $state(false)
	let groupSnapshot: Snapshot = $state()

	function onSelectSnapshot(snapshot: Snapshot) {
		if (onSnapshotClick) onSnapshotClick(snapshot.id)
		selectedSnapshotId = snapshot.id
	}

	async function updateSnapshots() {
		device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			selectedSnapshotId = undefined
			device.getSnapshot().then((snap) => (groupSnapshot = snap))
			return (isProjectorGroup = true)
		}

		isProjectorGroup = false
		snapshots = DataHandlerSnapshot.filterByDevice(selectedDeviceId)
		if (device.defaultSnap) {
			selectedSnapshotId = device.defaultSnap
		}
	}

	async function initCalibration() {
		if (!device.isOnline)
			return Toast.error("Device must be online to calibrate.")
		await CalibrationOverlayController.open(device)
	}

	run(() => {
		selectedDeviceId && updateSnapshots()
	})
</script>

<div class="flex-column">
	<h3>
		{#if isDirectUpload}
			{#if isProjectorGroup}
				<div class="flex-row">
					Download snapshot
					<button
						class="small icon"
						title="Download snapshot"
						onclick={groupSnapshot.triggerDownload}><DownloadIcon /></button
					>
				</div>
			{:else}
				Select and download snapshot
			{/if}
		{:else if isProjectorGroup}
			Verify snapshot
		{:else}
			Select Snapshot
		{/if}
	</h3>
	<div class="snapshot-selector">
		{#if isProjectorGroup}
			{#await device.getSnapshot()}
				<LoadingSpinner height="4rem" />
			{:then snapshot}
				<img
					id="projector-group-snap"
					src={snapshot.src}
					alt=""
					onclick={snapshot.triggerDownload}
				/>
			{/await}
		{:else if !snapshots.length}
			<span id="projector-group-no-snap">
				There are no snapshots associated with this device.
				<br />
				<button
					class="small outline-button alt"
					onclick={() => initCalibration()}>Calibrate Now</button
				>
				to create your first snapshot.
				<br />
				<br />
				Snapshots are mapped images which show the perspective of the projector.
				Without one, you will be unable to see your mapped projection space and will
				be creating a show on a blank canvas.
			</span>
		{:else}
			{#each snapshots as snapshot}
				<SnapshotTile
					{snapshot}
					isSelected={selectedSnapshotId === snapshot.id}
					onClick={() => onSelectSnapshot(snapshot)}
				/>
			{/each}
		{/if}
	</div>
</div>

<style>
	.snapshot-selector {
		width: 100%;
		padding: 2rem 2rem 1rem 2rem;
		flex-grow: 1;
		background-color: var(--color-bg-dark-transparent);
		margin: 1rem 0;
		border-radius: var(--br);
		overflow-x: scroll;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;

		overflow-y: hidden;
		overflow-x: scroll;
	}

	.snapshot-selector:global(:has(.loader)) {
		justify-content: center;
	}

	.flex-column {
		flex-grow: 1;
		overflow: hidden;
	}

	h3 .flex-row {
		gap: 1rem;
		justify-content: space-between;
	}

	#projector-group-no-snap {
		color: var(--color-text);
	}

	.snapshot-selector:has(:global(#projector-group-snap)) {
		justify-content: center;
		align-items: center;
		padding: 1rem;
	}

	#projector-group-snap {
		height: 100%;
		width: auto;
		cursor: pointer;
	}

	.flex-row button.icon :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.flex-row button.icon:hover :global(.svg-fill),
	.flex-row button.icon:focus-visible :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
