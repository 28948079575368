import { openOverlay } from "../../overlay"
import type { Folder, Media } from "luxedo-data"
import MediaReUploadForm from "./MediaReUploadForm.svelte"
import MediaUploadForm from "./MediaUploadForm.svelte"

export { MediaUploadForm, MediaReUploadForm }

export function openMediaUploadOverlay(
	activeFolder: Folder,
	postSubmit: () => void
) {
	openOverlay(MediaUploadForm, {
		heading: "Select Media",
		classOverlay: "media-overlay no-pad",
		classHeading: "no-underline",
		props: {
			activeFolder,
			postSubmit,
		},
	})
}

export function openMediaReUploadOverlay(media: Media) {
	openOverlay(MediaReUploadForm, {
		heading: "Reupload Media",
		classOverlay: "media-overlay no-pad",
		classHeading: "no-underline",
		props: { media },
	})
}
