<script lang="ts">
	import { run } from "svelte/legacy"

	import type { Lightshow, Scene } from "luxedo-data"
	import { ArrowBack } from "svelte-comps/icons"
	import { ShowOverviewController } from "../ShowOverviewController"

	// ! need to handle lightshow playback using new playlist format

	let show: Scene | Lightshow = $state()
	let isOpen = $state(false)

	ShowOverviewController.subscribe((ctx) => {
		show = ctx.show
		isOpen = ctx.isPreviewing
	})

	let videoElem: HTMLVideoElement = $state()

	function resetPlayback() {
		if (!videoElem) return
		videoElem.pause()
		setTimeout(() => {
			videoElem.currentTime = 0
		}, 500)
	}

	run(() => {
		!isOpen && resetPlayback()
	})
	run(() => {
		isOpen && videoElem?.play()
	})
</script>

<!-- style="transform: scaleY({height})" -->
{#if show}
	<div class="video-player {isOpen ? 'open' : ''}">
		<button class="icon" onclick={ShowOverviewController.hideVideoPlayer}
			><ArrowBack /> Back</button
		>
		<video controls width="100%" bind:this={videoElem}>
			<source src={show.src.video} type="video/mp4" />
		</video>
	</div>
{/if}

<style>
	.video-player {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0rem;
		background-color: var(--color-bg-dark);
		overflow: hidden;
		transition: height;
		transition-duration: 750ms;
		transition-delay: 0ms;
	}

	.video-player.open {
		transition-delay: 250ms;
		height: 80vh;
	}

	.video-player video {
		width: 100%;
		height: 100%;
	}

	button {
		color: var(--color-text);
		padding: 1rem 1rem;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		/* padding: ; */
	}

	button :global(svg) {
		height: 1.5rem;
		width: 2rem;
	}

	button :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button:focus-visible :global(.svg-stroke),
	button:hover :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	button:focus-visible,
	button:hover {
		color: var(--color-main);
	}
</style>
