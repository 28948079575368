/**
 * A Svelte 5 redesign of the 'Controller' class.
 * This class exists to simplify managing state under standard circumstances.
 *
 * @example
 * // 1) Define your state shape (the "context" this controller will manage):
 * type View = "init" | "load" | "done"
 * interface MyControllerContext {
 *   route: View
 * 	 exampleString: string
 * 	 exampleNumber: number
 * }
 *
 * // 2) Extend ComponentController with that context type:
 * class MyOverlayController extends ComponentController<MyOverlayContext> {
 *   // Provide the default context state via super()
 *   // This will be used when calling super.reset() to reset the state
 *   constructor() {
 *     super({
 *       currentRoute: "init",
 *       userInput: "",
 *       counterExample: 0
 *     })
 *   }
 *
 *   // Optionally update reset if you need custom logic when resetting the state:
 *   public reset() {
 *     super.reset()
 * 		 // clean up / run side effect
 *   }
 *
 * 	 // 3) Add any custom functionality
 *   public runAction() {
 *     // do something
 *   }
 * }
 *
 * // 3) Finally, export an instance of your specialized controller:
 * export const OverlayExampleController = new MyOverlayController()
 */
export abstract class ComponentController<ContextType> {
	protected CTX_DEFAULT: ContextType // a copy of the default state values (used in reset)
	public ctx: ContextType = $state()

	constructor(ctxDefault: ContextType) {
		this.CTX_DEFAULT = ctxDefault
		this.reset()
	}

	public reset = () => {
		this.ctx = this.CTX_DEFAULT
	}
}

export type ControllerContext<C extends ComponentController<any>> =
	C extends ComponentController<infer T> ? T : never
