<script lang="ts">
	import { ErrorReportIcon } from "svelte-comps/luxedo"
	import { GroupCalibrationController } from "./GroupCalibrationController"
	import { GroupEditorController } from "../../GroupEditorController"
	import { ImageViewer } from "svelte-comps/comps"
	import { onMount } from "svelte"
	import { Guides } from "luxedo-data"

	let message: string = $state()
	let description: string = $state()
	let failImages: Array<string> = $state()

	let viewingImages = $state(false)

	GroupCalibrationController.subscribe((ctx) => {
		message = ctx.message
		description = ctx.description
		failImages = ctx.failImages
	})

	function viewImages() {
		viewingImages = true
	}

	onMount(() => (viewingImages = false))
</script>

<div class="calibration-error-container">
	{#if viewingImages}
		<div class="calibration-troubleshooting">
			<h3>Troubleshooting Calibration</h3>
			<p class="info">
				Checkout the images below and checkout <a
					target="_blank"
					href={Guides.default.Device["Troubleshooting Calibration"]}>this article</a
				>
				for help troubleshooting.
			</p>
			<ImageViewer images={failImages} />
		</div>
	{:else}
		<ErrorReportIcon />
		<div class="error-info">
			<div class="error-message">
				{message}
			</div>
			<div class="error-description">
				{#if description}
					{description}
				{:else}
					An error occured while calibrating. Given the complexity of calibration, there are many
					reasons this could have happened.
					{#if viewImages}
						Click "Troubleshoot" below to learn more.
					{/if}
				{/if}
			</div>
		</div>
	{/if}
</div>

<div id="step-buttons">
	<button id="back-step-button" class="link-button" onclick={() => GroupEditorController.back()}>
		Back
	</button>
	{#if failImages && !viewingImages}
		<button class="alt" onclick={viewImages}>Troubleshoot</button>
	{/if}
	<button id="next-step-button" onclick={() => GroupCalibrationController.calibrate()}>
		Retry
	</button>
</div>

<style>
	button.alt {
		margin-right: 0.5rem;
	}

	.error-info {
		text-align: center;
		padding: 0 4rem;
	}

	.calibration-error-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: 2rem;
	}

	.error-description {
		color: var(--color-error);
		font-size: var(--h3);
	}

	.calibration-troubleshooting a {
		color: var(--color-text);
		border-color: var(--color-alt);
		transition:
			color 250ms,
			border-color 250ms;
	}

	.calibration-troubleshooting a:hover {
		color: var(--color-text-light);
		border-color: var(--color-main);
	}

	p.info {
		color: var(--color-text);
		margin-top: 0;
	}
</style>
