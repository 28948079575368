<script lang="ts">
	import { run } from "svelte/legacy"

	import { PauseIcon, PlayIcon, SkipForwardIcon } from "../../../icons"
	import type { Media } from "luxedo-data"
	import type { MediaToolController } from "../MediaToolController"
	import MediaVideoTimeInput from "./MediaVideo_TimeInput.svelte"
	import { setContext } from "svelte"

	interface Controller extends MediaToolController<any> {}

	interface Props {
		videoController: Controller
		media: Media
		children?: import("svelte").Snippet
	}

	let { videoController, media, children }: Props = $props()

	let videoElem: HTMLVideoElement = $state()
	let isPlaying: boolean = $state(false)
	let timestamp: number = $state()

	let updateTimestamp: (newDuration: number) => void = $state() // passed upward from child component MediaVideoTimeInput to force updates from the current timestamp

	videoController.subscribe((ctx) => {
		isPlaying = ctx.isPlaying
		timestamp = ctx.timestamp
		if (updateTimestamp) updateTimestamp(ctx.timestamp)
	})

	function initVideoElem() {
		if (!videoElem) return
		videoController.initialize(videoElem, media)
	}

	function handleSpacePress(e: KeyboardEvent) {
		e.preventDefault()
		e.stopPropagation()
		if (e.code === "Space") videoController.play()
	}

	$effect(() => {
		if (videoElem) initVideoElem()
	})
</script>

<div class="video-container">
	<video preload="auto" crossorigin="anonymous" bind:this={videoElem}></video>
	<div class="top-row">
		<div class="video-controls">
			<button
				id="skip-backward-button"
				class="icon skip-button"
				onclick={videoController.skipToStart}
			>
				<SkipForwardIcon />
			</button>
			<button id="play-button" class="icon" onclick={videoController.play}>
				{#if isPlaying}
					<PauseIcon />
				{:else}
					<PlayIcon />
				{/if}
			</button>
			<button id="skip-forward-button" class="icon skip-button" onclick={videoController.skipToEnd}>
				<SkipForwardIcon />
			</button>
		</div>
		<MediaVideoTimeInput
			bind:updateDuration={updateTimestamp}
			bind:duration={timestamp}
			{media}
			readonly
		/>
	</div>
	{@render children?.()}
</div>
<svelte:document onkeydown={handleSpacePress} />

<style>
	.video-container {
		position: relative;
		width: 100%;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.top-row {
		position: absolute;
		bottom: 1.25rem;
		right: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* width: 100%; */
	}

	.video-controls {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.top-row :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.top-row :global(.input-container) {
		margin: 0;
	}

	.top-row :global(.svg-fill) {
		fill: var(--color-main);
	}

	#skip-backward-button :global(svg) {
		transform: rotate(180deg);
	}

	button {
		width: fit-content;
		padding: 0;
	}

	button :global(svg) {
		height: 1.5rem;
	}

	.skip-button :global(svg) {
		height: 1rem;
	}
</style>
