<script lang="ts">
	import { GroupAlignController } from "../GroupAlignController"
	import InputsDevicePriority from "./Inputs_SlotPriority.svelte"
	import InputsSlotProperties from "./Inputs_SlotProperties.svelte"
	import { GroupEditorController } from "../../../GroupEditorController"
	import GroupSettings from "../../GroupSettings.svelte"
</script>

<div id="group-align-inputs">
	<InputsDevicePriority />
	<InputsSlotProperties />
	<GroupSettings />
</div>

<div class="bottom-row">
	<div class="step-buttons">
		<button id="back-step-button" class="link-button" onclick={() => GroupAlignController.back()}>
			Back
		</button>
		<button
			id="next-step-button"
			class="outline-button"
			onclick={() => GroupEditorController.next()}
		>
			Next
		</button>
	</div>
</div>

<style>
	#group-align-inputs {
		padding: 2rem;
		padding-bottom: 4rem;
		display: flex;
		flex-direction: row;
	}

	.bottom-row {
		display: flex;
		flex-direction: row;
		padding: 0 1rem;

		position: absolute;
		width: 100%;
		bottom: 1rem;
		justify-content: flex-end;
	}
</style>
