import type { Component } from "svelte"
import {
	writable,
	get as storeGet,
	type Unsubscriber,
	type Writable,
} from "svelte/store"

/**
 * Extendable controller. Use this as the base for component level controllers.
 */
export abstract class Controller<ContextType> {
	store: Writable<ContextType>
	contextDefault: ContextType

	/**
	 * Defines the default state and initializes the store
	 * @param ctxDefault
	 */
	constructor(ctxDefault: ContextType) {
		this.contextDefault = ctxDefault
		this.store = writable<ContextType>(ctxDefault)
	}

	/**
	 * Resets the store to the default state
	 */
	protected reset() {
		this.store.set(this.contextDefault)
	}

	/**
	 * Triggers a callback whenever the store (state values) updates
	 * @param cb the callback to trigger
	 * @returns an unsubscriber (call this to "unsubscribe" from this store)
	 */
	subscribe(cb: (ctx: ContextType) => void): Unsubscriber {
		return this.store.subscribe(cb)
	}

	/**
	 * Updates the specified state, without reseting other values
	 * @param content the new state properties
	 * @returns void
	 */
	update(content: Partial<ContextType>) {
		return this.store.update((ctx) => ({ ...ctx, ...content }))
	}

	/**
	 * Gets the current value of the store (each state value)
	 */
	get(): ContextType
	/**
	 * Gets the current value of the specified property
	 * @param property: the propety to get the value of
	 */
	get<K extends keyof ContextType>(property: K): ContextType[K]
	get<K extends keyof ContextType>(property?: K) {
		const ctx = storeGet(this.store)
		if (property) return ctx[property]
		return ctx
	}
}
