<script lang="ts">
	import type { DeviceRPi } from "luxedo-data"
	import { Reactivity } from "svelte-comps/reactivity"
	import { openProjectorMenu } from "../.."
	import GroupNetworkIssue from "./group-network-menu/GroupNetworkIssue.svelte"

	interface Props {
		device: DeviceRPi
	}

	let { device }: Props = $props()

	$effect.pre(() => {
		Reactivity.useProps(device, ["eidos", "isOnline"])
	})

	let isOnline = $derived(Reactivity.propOf(device, "isOnline"))
	let isConnected = $derived(
		Reactivity.propOf(device, "eidos")?.lux2lux?.connected ?? false
	)

	function viewGroup() {
		openProjectorMenu(device.parent, "Network")
	}
</script>

<div class="group-connection-menu">
	<div class="flex-row">
		<h3 class="setting-heading">Group Connection</h3>
		<button
			id="view-group-button"
			class="outline-button small alt"
			onclick={viewGroup}>View Group</button
		>
	</div>
	<div class="info">
		See the current connection status of your device within its group. If any
		issues arise, verifying the connection status here can help troubleshoot and
		ensure seamless operation across all grouped projectors.
	</div>
	<div>
		<GroupNetworkIssue
			groupConnectionIssue="Connected to the internet"
			status={isOnline}
		/>
		<GroupNetworkIssue
			groupConnectionIssue="Connected to the group"
			status={isConnected}
		/>
	</div>
</div>

<style>
	.group-connection-menu {
		display: flex;
		flex-direction: column;
		/* margin-top: 2rem; */
		/* padding: 1rem; */
	}

	#view-group-button {
		margin-left: 1rem;
	}

	.info {
		margin-top: 0.5rem;
		line-height: 1.25rem;
		color: var(--color-text);
		margin-bottom: 1rem;
	}
</style>
