<script lang="ts">
	import {
		DataHandlerDevice,
		Device,
		DeviceGroup,
		DeviceRPi,
		type Lux2LuxEidosStatus_Client,
		type Lux2LuxEidosStatus_Master,
	} from "luxedo-data"
	import mermaid from "mermaid"
	import { Reactivity } from "svelte-comps/reactivity"
	import { openProjectorMenu } from "../../.."

	type Props = {
		device: Device
	}

	let { device }: Props = $props()

	let group: DeviceGroup = $derived(
		device instanceof DeviceGroup ? device : device?.getParent()
	)

	let children: Array<DeviceRPi> = $derived(
		Reactivity.propOf(group, "children").map(
			(slot) => DataHandlerDevice.get(slot.device_id) as DeviceRPi
		)
	)

	let diagramSVG: string = $state()
	let diagramContainer: HTMLDivElement = $state()

	function refreshClickListeners() {
		const blocks = diagramContainer.querySelectorAll("svg .root .nodes .node")
		for (const block of blocks) {
			const classList = Array.from(block.classList)
			const colorClass = classList.find((cls) => /^color\d+$/.test(cls)) // Find class matching 'colorXXXX'

			let id
			if (colorClass) {
				const match = colorClass.match(/\d+/) // Extract the numeric part
				id = match ? parseInt(match[0], 10) : null
			}

			;(block as SVGGElement).addEventListener("click", () => {
				const device = DataHandlerDevice.get(id)
				openProjectorMenu(device)
			})
		}
	}

	function defineDiagram(children: Array<DeviceRPi>) {
		const checkConnected = (
			dev: DeviceRPi,
			masterLux2Lux?: Lux2LuxEidosStatus_Master
		) => {
			// if can access the master eidos, show data from that
			if (masterLux2Lux) return masterLux2Lux.client_connections[dev.id]
			// if master device's eidos is unavailable, parse the status from the device's eidos
			const clientLux2Lux = dev?.eidos?.lux2lux as Lux2LuxEidosStatus_Client
			if (clientLux2Lux) return clientLux2Lux.connected
			// if the client's eidos is unavailable, we can assume the device is not connected
			return false
		}

		const createNode = (dev: DeviceRPi) => {
			return `${dev.id}("${dev.name}"):::color${dev.id}`
		}

		const createConnection = (
			dev: DeviceRPi,
			masterLux2Lux?: Lux2LuxEidosStatus_Master
		) => {
			return `${dev.id} --${checkConnected(dev, masterLux2Lux) ? "<span class='connect'>✔</span>" : "<span class='no-connect'>✗</span>"}--> ${master.id}`
		}

		const createClassDef = (dev: DeviceRPi) => {
			let isSelected =
				device instanceof DeviceGroup ? true : dev.id === device.id
			return `classDef color${dev.id} fill:${dev.color}${!isSelected ? "44" : ""} ${device.id === dev.id ? ", stroke: #fff, stroke-width: 3px" : ""}`
		}

		// check for the master through eidos first
		let master = children.find((dev) => {
			return dev?.eidos?.lux2lux?.role === "master"
		})

		// if the master is offline, assume it is the child with the lowest id
		master = children.sort((a, b) => a.id - b.id)[0]
		if (!master) throw new Error(`No master device found in group ${group?.id}`)

		const masterLux2Lux = master?.eidos?.lux2lux as Lux2LuxEidosStatus_Master
		children = children.filter((d) => d && d.id !== master.id)

		return `

			graph TB
				%% define nodes
				${createNode(master)}
				${children.map(createNode).join("\n")}

				%% define connections
				${children.map((child) => createConnection(child, masterLux2Lux)).join("\n")}

				%% define classes and colors
				${createClassDef(master)}
				${children.map(createClassDef).join("\n")}
				
				%% define style for connection icons
				linkStyle default font-size:2.5rem

		`
	}

	async function createDiagram(graphDef: string) {
		const { svg } = await mermaid.render("mermaid", graphDef)
		return svg
	}

	$effect(() => {
		if (!children) return

		const definition = defineDiagram(children)
		createDiagram(definition)
			.then((svg) => {
				diagramSVG = svg
				setTimeout(refreshClickListeners)
			})
			.catch((error) => {
				console.error(error)
			})
	})
</script>

<div id="group-diagram" bind:this={diagramContainer}>
	{@html diagramSVG}
</div>

<style>
	#group-diagram {
		width: fit-content;
		height: fit-content;
	}

	#group-diagram :global(#mermaid rect.basic.label-container) {
		fill: var(--color-bg-dark);
		stroke: var(--color-border);
	}

	#group-diagram :global(#mermaid .nodeLabel p) {
		color: var(--color-text-light);
	}

	#group-diagram :global(#mermaid .edgeLabel p) {
		background-color: transparent;
		border-radius: var(--br);
		color: var(--color-text-light);
	}

	#group-diagram :global(#mermaid .edgeLabel) {
		fill: none;
		font-size: var(--h1);
	}

	#group-diagram :global(#mermaid foreignObject div) {
		background-color: transparent;
	}

	#group-diagram :global(#mermaid .flowchart-link) {
		stroke: var(--color-border);
	}

	#group-diagram :global(#mermaid .connect) {
		border-radius: var(--br);
		color: var(--color-green);
	}

	#group-diagram :global(#mermaid .no-connect) {
		border-radius: var(--br);
		color: var(--ct-red);
	}

	#group-diagram :global(.nodes .node) {
		cursor: pointer;
	}
</style>
