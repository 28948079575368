<script lang="ts">
	import type { Component, SvelteComponent } from "svelte"
	import {
		ShapeSquare,
		ShapeTriangle,
		ShapeHeptagon,
		ShapeFreedraw,
		ShapeHexagon,
		ShapeOctagon,
		ShapePolygon,
		ShapeCircle,
	} from "../icons"
	import IconRowSelector from "./IconRowSelector.svelte"

	type ShapeOption =
		| "circle"
		| "triangle"
		| "square"
		| "pentagon"
		| "hexagon"
		| "heptagon"
		| "octagon"
		| "freedraw"

	interface Props {
		selectedShape: ShapeOption
		hiddenOptions?: Array<ShapeOption>
	}

	let { selectedShape = $bindable(), hiddenOptions = [] }: Props = $props()

	const SHAPE_OPTIONS: {
		[k in ShapeOption]: {
			comp: Component
			title: string
		}
	} = {
		circle: {
			comp: ShapeCircle,
			title: "Circle",
		},
		triangle: {
			comp: ShapeTriangle,
			title: "Triangle",
		},
		square: {
			comp: ShapeSquare,
			title: "Square",
		},
		pentagon: {
			comp: ShapePolygon,
			title: "Pentagon",
		},
		hexagon: {
			comp: ShapeHexagon,
			title: "Hexagon",
		},
		heptagon: {
			comp: ShapeHeptagon,
			title: "Heptagon",
		},
		octagon: {
			comp: ShapeOctagon,
			title: "Octagon",
		},
		freedraw: {
			comp: ShapeFreedraw,
			title: "Freedraw",
		},
	}

	function getShapeOptions(hidden: Array<ShapeOption>) {
		const opts = SHAPE_OPTIONS
		for (const shape of hidden) delete opts[shape]
		return opts
	}
</script>

<IconRowSelector
	bind:selectedOption={selectedShape}
	options={getShapeOptions(hiddenOptions)}
	canSelectMultiple={false}
/>

<style>
</style>
