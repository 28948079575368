<script lang="ts">
	interface Props {
		strokeColor?: string;
	}

	let { strokeColor = "black" }: Props = $props();
</script>

<svg width="48" height="37" viewBox="0 0 48 37" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_614_59)">
		<path
			d="M23.1434 2.42179C23.5322 1.77651 24.4678 1.77652 24.8566 2.4218L43.8713 33.984C44.2728 34.6505 43.7928 35.5 43.0147 35.5H4.9853C4.2072 35.5 3.7272 34.6505 4.12873 33.984L23.1434 2.42179Z"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
		/>
	</g>
	<defs>
		<clipPath id="clip0_614_59">
			<rect width="48" height="37" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
