<script lang="ts">
	import WarningIcon from "../../reusable/icons/WarningIcon.svelte"
	import InstructionCard from "./InstructionCard.svelte"
	import Header from "./WifiHeader.svelte"
	import { Guides } from "luxedo-data"

	let time = new Date().getTime()
</script>

<main>
	<Header />
	<div class="space-between">
		<span class="heading">
			<div class="icon-container">
				<WarningIcon />
			</div>
			<h1>You are not connected to your Luxedo</h1>
			<span class="subtitle"
				>It looks like you're trying to connect your Luxedo device to the internet.
			</span>
			<span class="subtitle"
				>You're at the right URL, but it seems you're not connected to the correct network yet.</span
			>
			<span class="subtitle">To proceed, please follow these steps: </span>
		</span>

		<div class="instruction-cards">
			<InstructionCard
				title={"Connect to the Luxedo Hotspot"}
				info={"On your phone or computer, go to your Wi-Fi settings and look for a network named Luxedo-XXXX (where XXXX is the unique identifier for your device). Connect to this network."}
			/>
			<InstructionCard
				title={"Stay Connected"}
				info={"Your phone might prompt you to disconnect from the Luxedo hotspot because it detects there's no internet connection. Please stay connected to the Luxedo network despite this prompt."}
			/>
			<InstructionCard
				title={"Disable Other Network Connections"}
				info={"If you're having trouble accessing the Luxedo setup page, temporarily disable other active network connections (e.g., mobile data or other Wi-Fi networks)."}
			/>
			<InstructionCard
				title={"Access the Setup Page"}
				info={"Once connected to the Luxedo hotspot, refresh this page. You should be able to access the setup interface to connect your Luxedo to your home Wi-Fi network."}
			>
				<a href="http://connect.luxedo.com?time={time}">
					<button class="small refresh-button">Refresh</button>
				</a>
			</InstructionCard>
		</div>
	</div>
	<p class="footer">
		For a detailed walkthrough, read <a
			target="_blank"
			href={Guides["default"]["Device"]["Connecting to Wi-Fi"]}>this article</a
		>
		or check out
		<a target="_blank" href={Guides.default["Device"]["Troubleshooting Connection To Wi-Fi"]}
			>this article</a
		>
		for help troubleshooting.<br /> For additional help, see our
		<a target="_blank" href="https://luxedo.com/pages/help">help desk</a>.
	</p>
</main>

<style>
	main {
		padding: 0 2rem 1rem 2rem;

		height: 100dvh;
		overflow-x: hidden;
		overflow-y: auto;

		display: flex;
		flex-direction: column;
		align-items: center;
	}

	a:has(:global(.refresh-button)) {
		border: none;
		margin-left: 0.25rem;
	}

	.instruction-cards {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 0 8vw;
	}

	.refresh-button {
		padding: 0 0.5rem;
		margin: 0;
	}

	.space-between {
		width: 100%;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}

	h1 {
		font-size: var(--text-h3);
		color: var(--color-main);
		margin: 1rem 0 0 0;
	}

	.icon-container :global(svg) {
		height: 10vh;
		width: auto;
	}

	.icon-container :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: 1rem 0;
	}

	.subtitle {
		color: var(--color-text-light);
	}

	.instructions {
		max-width: 40vw;
	}

	.step_number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 3rem;
		width: 3rem;
		height: 3rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.connection-step {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-main);
		align-items: center;
	}

	.info {
		color: var(--color-text-light);
		font-size: var(--h3);
	}

	.footer {
		color: var(--color-text);
		font-size: var(--h2);
		margin-bottom: 0;
		text-align: center;
	}

	.underline {
		text-decoration: underline;
	}

	a {
		transition:
			border-color 250ms,
			color 250ms;
		color: var(--color-text);
		border-color: var(--color-text);
	}

	a:hover,
	a:focus-visible {
		color: var(--color-main);
		border-color: var(--color-main);
	}

	@media only screen and (max-width: 1000px) {
		.instruction-cards {
			margin: 0;
		}
	}

	@media only screen and (max-width: 800px) {
		main {
			padding: 0.5rem 1rem;
		}

		.instruction-cards {
			flex-direction: column;
			margin: 0;
		}

		.instructions {
			max-width: 90vw;
		}

		h1 {
			font-size: var(--h1);
			margin-top: 0.5rem;
		}

		.footer,
		.subtitle {
			font-size: var(--h3);
		}

		.step_number {
			width: 2rem;
			height: 2rem;
			min-width: 2rem;
			margin-right: 0.5rem;
		}
	}
</style>
